<template>
    <div>
      <!-- snackbar -->
      <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
      <!-- end snackbar -->
  
      <!-- dialog konfirmasi -->
      <v-dialog v-model="dialog.konfirmasi" persistent width="450">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Konfirmasi</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.konfirmasi = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5 text-center mt-2">
            <span class="font-weight-bold title">
              <span v-if="save_type === 'mulai'">Mulai pengujian ?</span>
              <span v-if="save_type === 'batalkan'">Batalkan pengujian ?</span>
              <span v-if="save_type === 'ajukan'">Ajukan selesai pengujian ?</span>
              <span v-if="save_type === 'simpan'">Simpan data yang diinputkan ?</span>
            </span>
            <v-card-subtitle class="pt-0">
              <span class="body-2" v-if="save_type === 'mulai'">Pastikan pengujian yang dimulai sudah sesuai</span>
              <span class="body-2" v-if="save_type === 'batalkan'">Pengujian akan dibatalkan</span>
              <span class="body-2" v-if="save_type === 'ajukan'">Pastikan sudah membuat laporan akhir pengujian</span>
              <span class="body-2" v-if="save_type === 'simpan'">Pastikan data sudah sesuai</span>
            </v-card-subtitle>
            <ValidationProvider
              v-if="save_type === 'batalkan'"
              name="Alasan"
              rules="required"
              v-slot="{ errors }">
              <v-text-field
                v-model="form.alasan"
                outlined
                label="Alasan pembatalan"
                dense
                color="#0299B7"
                :error-messages="errors">
              </v-text-field>
            </ValidationProvider>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-text class="pt-0 pb-0">
          </v-card-text>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.konfirmasi = false;">
              Batal
            </v-btn>
            <v-btn
              :color = "save_type === 'batalkan' ? 'red' : '#0299B7'"
              elevation="0"
              class="text-capitalize white--text mx-1"
              :loading="process.form"
              :disabled="process.form"
              @click="doDialogAction()">
              Ya, 
              <span class="text-lowercase" v-if="save_type === 'mulai'">mulai</span>
              <span class="text-lowercase" v-if="save_type === 'batalkan'">batalkan</span>
              <span class="text-lowercase" v-if="save_type === 'ajukan'">ajukan</span>
              <span class="text-lowercase" v-if="save_type === 'simpan'">simpan</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog konfirmasi -->

      <!-- dialog laporan -->
      <v-dialog v-model="dialog.laporan" persistent width="850">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Buat laporan</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.laporan = false, reset()">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5 px-2 text-center mt-2">
            <v-card-subtitle class="pt-0">
              <ValidationObserver ref="observer">
                <v-form>  
                  <v-row>
                    <v-col cols="12" class="text-left pb-0">
                      <div class="color-first title font-weight-bold mt-2">
                        Jenis laporan
                      </div>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0">
                      <v-radio-group v-model="sort.selected_laporan">
                        <v-radio
                          v-for="(status, index) in sort.status_laporan"
                          :key="index" 
                          :label="status.text"
                          :value="status.id"
                          hide-details
                          color="#0499b7"
                          class="py-0 mt-0">
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0">
                      <div class="color-first title font-weight-bold title pt-0 pb-2 text-left">
                        <span>Isi laporan</span>
                      </div>
                      <v-row>
                        <v-col cols="8" class="pb--">
                          <v-menu
                            ref="menunow"
                            v-model="form.item_now"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            class="z-my"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                              <ValidationProvider
                                name="Tanggal"
                                rules="required"
                                v-slot="{ errors }">
                                <v-text-field
                                  :value="formatDateNow()"
                                  label="Tanggal bayar"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  outlined
                                  dense
                                  color="#0299B7"
                                  :error-messages="errors"
                                  v-on="on">
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="form.tanggal_bayar"
                              :min="reminder" 
                              v-on:change="changedate()" 
                              color="#0299B7"
                              format="D MMMM YYYY"
                              @input="form.item_now = false">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                          <v-menu
                            ref="menuTimeStart"
                            v-model="form.start_time"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="form.time_start"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <ValidationProvider
                                name="Waktu"
                                rules="required"
                                v-slot="{ errors }">
                                <v-text-field
                                  v-model="form.waktu_bayar"
                                  label="Waktu"
                                  prepend-inner-icon="mdi-clock"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  :error-messages="errors"
                                  color="#0299B7"
                                  v-on="on"
                                  :disabled="form.start_date === ''">
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-time-picker
                              v-if="form.start_time"
                              v-model="form.waktu_bayar"
                              format="24hr"
                              full-width
                              color="#0299B7"
                              @click:minute="$refs.menuTimeStart.save(form.waktu_bayar)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <div>
                        <quill-editor 
                          ref="myTextEditor" 
                          v-model="form.desc"
                          height= '250px'
                          :options="option">
                        </quill-editor>
                      </div>
                    </v-col>
                    <v-col cols="12" md="12" v-if="sort.selected_laporan === 'akhir'">
                      <ValidationProvider
                        name="Parameter uji">
                        <v-text-field
                          dense
                          filled
                          disabled
                          outlined
                          label="Parameter uji"
                          color="#0299B7"
                          hide-details
                          v-model="form.parameter_uji"
                          require>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-0" v-if="sort.selected_laporan === 'akhir'">
                      <ValidationProvider
                        name="Hasil pengujian"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          dense
                          outlined
                          label="Hasil pengujian"
                          color="#0299B7"
                          :error-messages="errors"
                          v-model="form.hasil_uji"
                          require>
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </v-card-subtitle>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-text class="pt-0 pb-0">
          </v-card-text>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.konfirmasi = false;">
              Batal
            </v-btn>
            <v-btn
              :color = "save_type === 'batalkan' ? 'red' : '#0299B7'"
              elevation="0"
              class="text-capitalize white--text mx-1"
              :disabled="process.form"
              @click="save_type = 'simpan', cekForm()">
              Ya, 
              <span class="text-lowercase">simpan</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog laporan -->

      <!-- dialog detail -->
      <v-dialog v-model="dialog.detail" persistent width="1000">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Detail laporan</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.detail = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-row>
              <v-col cols="12" class="text-left pb-0">
                <div class="color-first title font-weight-bold mt-2">
                  Jenis laporan
                </div>
              </v-col>
              <v-col cols="12">
                <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                  <tr>
                    <td width="35%">Jenis laporan</td>
                    <td width="3%">:</td>
                    <td>{{ form?.jenis_laporan }}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-left pb-0">
                <div class="color-first title font-weight-bold mt-2">
                  Isi laporan
                </div>
              </v-col>
              <v-col cols="12">
                <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                  <tr>
                    <td width="35%">Tanggal</td>
                    <td width="3%">:</td>
                    <td>{{ form?.tanggal_bayar | datetime }}</td>
                  </tr>
                  <tr>
                    <td width="30%" class="td-flex">Isi laporan</td>
                    <td width="1%">:</td>
                    <td>  
                      <div>
                        <quill-editor 
                          ref="myTextEditor" 
                          v-model="form.desc"
                          height= '250px'
                          :options="option">
                        </quill-editor>
                      </div> 
                    </td>
                  </tr>
                  <tr v-if="form.kd_status_progres_parameter === 4">
                    <td width="35%">Parameter</td>
                    <td width="3%">:</td>
                    <td>{{ detail.parameter?.parameterTest.namaParameterUji || '-'}}</td>
                  </tr>
                  <tr v-if="form.kd_status_progres_parameter === 4">
                    <td width="35%">Hasil uji</td>
                    <td width="3%">:</td>
                    <td>{{ form?.hasil_uji || '-' }}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.detail = false; reset()">
              Kembali
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog detail -->

      <LoadingComponent v-if="process.loading"></LoadingComponent>
  
      <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <!-- sampel -->
                  <ValidationObserver ref="observer">
                    <v-form>  
                      <v-row>
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold title mt-3 pb-2">
                            Sampel
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <table width="100%" class="body-2">
                            <tr>
                              <td width="30%">Kode sampel</td>
                              <td width="1%">:</td>
                              <td>{{ detail.parameter?.sample.kodeSampel || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Nama</td>
                              <td width="1%">:</td>
                              <td>{{ detail.parameter?.sample.nama || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Jumlah</td>
                              <td width="1%">:</td>
                              <td>{{ detail.parameter?.sample.jumlah || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Bentuk </td>
                              <td width="1%">:</td>
                              <td>{{ detail.parameter?.sample.phase.namaBentukSampel || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Penyimpanan</td>
                              <td width="1%">:</td>
                              <td>{{ detail.parameter?.sample.storage.namaPenyimpananSampel || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Kondisi</td>
                              <td width="1%">:</td>
                              <td>{{ detail.parameter?.sample.condition.namaKondisiSampel || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Kemasan</td>
                              <td width="1%">:</td>
                              <td>{{ detail.parameter?.sample.kemasan || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%" class="td-flex">Gambar</td>
                              <td width="1%">:</td>
                              <td>
                                <v-expansion-panels>
                                  <v-expansion-panel>
                                    <v-expansion-panel-header color="#0299B7">
                                      <template v-slot:actions>
                                        <v-icon color="white">
                                          $expand
                                        </v-icon>
                                      </template>
                                      <span class="white--text"></span>
                                    </v-expansion-panel-header>     
                                    <v-expansion-panel-content>
                                      <div>
                                        <iframe
                                          v-if="file && file?.startsWith('data:application/pdf') || file?.startsWith('blob:http://')" 
                                          width="100%" 
                                          :height="file === null ? '110px' : '500px'" 
                                          :src="file" 
                                          :lazy-src="file"
                                          frameborder="0"
                                          allowfullscreen>
                                          Oops! an error has occurred.
                                        </iframe>
                                        <v-img 
                                          v-else-if="file && file?.startsWith('data:image')"
                                          :src="file" 
                                          :lazy-src="file" 
                                          width="100%" 
                                          aspect-ratio="2">
                                        </v-img>
                                      </div>            
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                        <v-col cols="12">
                          <table width="100%" class="body-2">
                            <tr>
                              <td width="30%">Status penerimaan sampel</td>
                              <td width="1%">:</td>
                              <td>
                                {{ detail.parameter?.sample.status.statusSampel || '-'}}
                              </td>
                            </tr>
                            <tr>
                              <td width="30%">Tanggal penerimaan sampel</td>
                              <td width="1%">:</td>
                              <td>
                                {{ detail.parameter?.sample.tglPenerimaanSampel | date}}
                              </td>
                            </tr>
                            <tr>
                              <td width="35%" style="display: flex;">Parameter uji</td>
                              <td width="1%">:</td>
                              <td>
                                {{ detail.parameter?.parameterTest.namaParameterUji || '-'}} 
                                | <span class="caption" :class="getColor(detail.parameter?.status.kdStatusSampelParameter)"> {{ detail.parameter?.status.statusSampelParameter || '-'}} </span>
                              </td>
                            </tr>
                            <tr>
                              <td width="35%" style="display: flex;"></td>
                              <td width="1%"></td>
                              <td>
                                Analisis: {{ detail.userAnalyst?.nama || '-'}} ({{ detail.userAnalyst?.noInduk }})
                              </td>
                            </tr>
                            <tr>
                              <td width="35%" style="display: flex;"></td>
                              <td width="1%"></td>
                              <td>
                                Penyelia: {{ detail.userSupervisor?.nama || '-'}} ({{ detail.userSupervisor?.noInduk }})
                              </td>
                            </tr>
                            <tr>
                              <td width="30%">Metode uji</td>
                              <td width="1%">:</td>
                              <td>
                                {{ detail.parameter?.parameterTest.metodeUji || '-'}}                              
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </ValidationObserver>
                  <!-- sampel -->
  
                  <!-- pengujian -->
                  <v-row v-if="save_status.kd_status_pengajuan !== 1 && save_status.kd_status_pengajuan !== 6">
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold mt-2">
                        Pengujian
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-right" v-if="!isMulai && !isDetail && !isDone">
                      <v-btn
                        @click="dialog.konfirmasi = true, save_type = 'mulai', reset()"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize white--text">
                          <v-icon size="20" class="mr-3">mdi-play</v-icon>
                          Mulai pengujian
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="text-right" v-if="isMulai && !isDetail && !isDone">
                      <v-btn
                        @click="dialog.konfirmasi = true, save_type = 'batalkan', reset()"
                        text
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize red--text">
                          <v-icon size="20" class="mr-3">mdi-close</v-icon>
                          Batalkan pengujian
                      </v-btn>
                     
                      <v-btn
                        @click="dialog.konfirmasi = true, save_type = 'ajukan', reset()"
                        elevation="0"
                        :disabled="process.run"
                        outlined
                        class="text-capitalize color-first mx-2">
                          <v-icon size="20" class="mr-3">mdi-stop</v-icon>
                          Ajukan selesai pengujian
                      </v-btn>

                      <v-btn
                        @click="dialog.laporan = true, save_type = 'simpan', reset()"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize white--text">
                          <v-icon size="20" class="mr-3">mdi-plus</v-icon>
                          Buat laporan
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-skeleton-loader
                        :loading="process.run"
                        transition="scale-transition"
                        :tile="false"
                        :style="process.run ? 'height: calc(50vh - 305px)' : ''"
                        type="table-tbody">
                        <v-data-table
                          :headers="headers"
                          :items="row"
                          :items-per-page="10"
                          class="elevation-1 required">
                          <template v-slot:[`item.status_pengujian`]="{ item }">
                            <td>
                              <span :class="getStatusClass(item.status_pengujian)">{{ item.status_pengujian }}</span>
                            </td>
                          </template>

                          <template v-slot:[`item.aksi`]="{ item }">
                            <v-tooltip top v-if="item.kd_status_progres_parameter !== 5">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  :disabled="process.run"
                                  @click="openDialogDetail(item)"
                                  class="text-capitalize color-first pa-0"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Detail</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <!-- end pengujian -->    
                </v-card-text>
                <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                <v-card-actions class="mt-2 pb-5 justify-center">
                  <v-btn
                    outlined
                    class="text-capitalize color-first mx-1"
                    :disabled="process.form"
                    @click="toPage()">
                    Kembali
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>        
      </v-row>
  
    </div>
  </template>
  
  <script>
  import LoadingComponent from '@/components/Loading.vue'
  import Snackbar from '@/components/Snackbar.vue';
  import { TokenService } from "@/service/Storage.Service";
  import moment from 'moment';
  import 'moment/locale/id';
  import { get, put } from "@/service/Axios";
  import statusMixin from '@/mixin';
  const { VUE_APP_API_URL } = process.env;
  import { serialize } from 'object-to-formdata';

  export default {
    mixins: [statusMixin],  
    data() {
      return {
        detail: {},
        dialog: {
          konfirmasi: false,
          laporan: false,
          detail: false
        },
        form: {
          desc: '',
          gambar: '',
          alasan: '',
          item_now: false,
          start_time: false,
          tanggal_bayar: '',
          waktu_bayar: '',
          parameter_uji: '',
          hasil_uji: '',
          jenis_laporan: '',
          kd_status_progres_parameter: ''
        },
        file: '',
        process: {
          run: false,
          loading: false,
          form: false
        },
       isMulai: false,
       isDetail: false,
       isDone: false,
        headers: [
          { text: 'No.', value: 'no', sortable: false, },
          { text: 'Tanggal', value: 'tanggal', sortable: false,},
          { text: 'Status pengujian', value: 'status_pengujian', sortable: false,},
          { text: 'Proses selanjutnya', value: 'proses_selanjutnya', sortable: false, },
          { text: 'Aksi', value: 'aksi', align: 'center' },
        ],
        option: {
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'align': [] }],
              ['image'],
            ]
          },
        },
        row: [],
        snackbar: false,
        text: '',
        timeout: 4000,
        save_status: '',
        save_type: '',
        sort: {
          selected_laporan: 'progres',
          status_laporan: [
            {
              id: 'progres',
              text: 'Laporan progress'
            },
            {
              id: 'akhir',
              text: 'Laporan akhir'
            },
          ],
        },
      }
    },
  
    components: {
      LoadingComponent,
      Snackbar
    },
  
    computed: {
      reminder () {
        let now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate())
        return next.toISOString().substr(0, 10)
      },
  
      roles () {
        return this.$store.state.roles
      },
  
      token () {
        return this.$store.state.access_token
      }
    },
  
    watch: {
      'form.sampel'(newSampel, oldSampel) {
        newSampel.forEach((element, index) => {
          if (element.file && element.file !== oldSampel[index].file) {
            this.form.sampel[index].isProgress = true;
            setTimeout(() => {
              this.form.sampel[index].isProgress = false;
              this.$set(this.form.sampel[index], element.file);
            }, 2000); 
          }
        })
      },
    },
  
    mounted () {
      if (TokenService.getUuid() === null) {
        this.$router.push(`/persetujuan-pengujian`)
      } else {
        this.cekParams()
      }  
    },
  
    methods: {
      cekParams() {
        this.process.loading = true
        const data = JSON.parse(localStorage.getItem('filter'));
        this.save_status = data;
        const params = this.$route.params.page
        this.isDetail = params === 'detail' ? true : false
        this.fetch()
      },
  
      async fetch() {
        try {
          const uuid = TokenService.getUuid()
          const params = {
            uuid: uuid,
            flag_officer: '1'
          }
          const response = await get(`test-analysis/analyst`, {params})
          if (response.status === 200) {
            const res = response.data.data;
            this.detail = res[0];
            this.row = this.detail.history.map((item, index) => {
              return {
                no: index + 1 || '-',
                tanggal: item.updatedAt || '-',
                status_pengujian: item.statusProgres.statusProgresParameter || '-',
                proses_selanjutnya: item.statusProgres.prosesSelanjutnya || '-',
                kd_status_progres_parameter: item.statusProgres.kdStatusProgresParameter || '-',
                uuid: item.uuid || '-'
              }
            })
          }
          this.isMulai = this.detail.idStatusProgresParameter !== null ? true : false
          this.isDone = this.detail.idStatusProgresParameter === 5 || this.detail.idStatusProgresParameter === 6 || this.detail.idStatusProgresParameter === 7
          this.form.parameter_uji = this.detail.parameter?.parameterTest.namaParameterUji
        } catch (error) {
          console.log(error)
        } finally {
          this.getFile()
        }
      },
  

      async getFile() {
        const params = {
          url: this.detail.parameter.sample.fileGambar
        }
        await get(`file-test`, {params}).then((response) => {
          if (response.status === 200) {
            let res = response.data
            this.file = res.data
          }
        })
        this.process.loading = false
      },

      async cekForm() {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          this.dialog.konfirmasi = true
        } else {
          this.dialog.konfirmasi = false
        }
      },

      changedate() {
        if (this.form.tanggal_bayar === this.reminder) {
          this.formatDateNow()
        }
      },

      formatDateNow() {
        return this.form.tanggal_bayar ? moment(this.form.tanggal_bayar).format('DD MMMM YYYY') : ''
      },
  
      getColor(status) {
        switch (status) {
          case 1:
            return 'grey--text'
          case 3:
            return 'green--text';
          case 4:
            return 'red--text';
          default:
            return '';
        }
      },

      async openDialogDetail(item) {
        try {
          const params = {
            uuid: item.uuid,
          }
          const response = await get(`test-analysis/progress-detail`, {params})
          if (response.status === 200) {
            const res = response.data.data[0];
            this.form = {
              jenis_laporan: res.statusProgres.statusProgresParameter,
              tanggal_bayar: res.statusProgres.updatedAt,
              desc: res.catatan,
              kd_status_progres_parameter: res.statusProgres.kdStatusProgresParameter,
              hasil_uji: res.parameter.hasilPengujian
            }
            this.dialog.detail = true
          }
        } catch (error) {
          console.log(error)
        }
      },

      doDialogAction() {
        const { save_type, dialog } = this
        if (save_type === 'mulai') {
          this.updatesStartTesting();
          this.isMulai = true
          dialog.konfirmasi = false
        } else if (save_type === 'simpan') {
          this.createReportProgress();
        } else if (save_type === 'batalkan') {
          this.updatesCancelTesting();
        } else if (save_type === 'ajukan') {
          this.updatesDoneTesting();
        }
      },
      
      async updatesStartTesting() {
        this.process.run = true
        try {
          const params = {
            uuid: this.detail.uuid,
          };
          const response = await put(`test-analysis/start`, { params });
          if (response.status === 200) {
            this.fetch()
            this.text = response.data.info;
            this.snackbar = true;
            this.reset()
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.process.run = false
        }
        
      },

      async createReportProgress() {
        this.process.form = true;
        this.process.run = true
        const value = {
          uuid: this.detail.uuid,
          jenis_laporan: this.sort.selected_laporan,
          tanggal: this.form.tanggal_bayar,
          jam: this.form.waktu_bayar,
          isi: this.form.desc,
          hasil_pengujian: this.form.hasil_uji,
        };

        const options = {
          indices: true,
          nullsAsUndefineds: false,
          booleansAsIntegers: false,
          allowEmptyArrays: false,
          noFilesWithArrayNotation: false,
          dotsForObjectNotation: false,
        };

        const formData = serialize(value, options);

        const authHeader = new Headers({ 'Authorization': `Bearer ${this.token}` });

        const config = { method: 'POST', headers: authHeader, body: formData };

        const response = await fetch(`${VUE_APP_API_URL}test-analysis/report`, config);
        if (response.status === 200) {
          this.text = "Data laporan analisis berhasil disimpan";
          this.snackbar = true;
          this.dialog.konfirmasi = false;
          this.dialog.laporan = false;
          this.process.form = false;
          this.process.run = false
          this.fetch()
          this.reset()
        }
      },

      async updatesCancelTesting() {
        this.process.run = true;
        try {
          const params = {
            uuid: this.detail.uuid,
            catatan: this.form.alasan
          };
          const response = await put(`test-analysis/cancel`, { params });
          if (response.status === 200) {
            this.fetch()
            this.text = response.data.info;
            this.snackbar = true;
            this.dialog.konfirmasi = false;
            this.dialog.laporan = false;
            this.reset()
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.process.run = false
        }
      },

      async updatesDoneTesting() {
        this.process.run = true;
        try {
          const params = {
            uuid: this.detail.uuid,
          };
          const response = await put(`test-analysis/request-done`, { params });
          if (response.status === 200) {
            this.fetch()
            this.text = response.data.info;
            this.snackbar = true;
            this.dialog.konfirmasi = false;
            this.dialog.laporan = false;
            this.reset()
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.process.run = false
        }
      },

      reset() {
        this.$refs.observer.reset()
        this.form = {
          desc: '',
          gambar: '',
          alasan: '',
          item_now: false,
          start_time: false,
          tanggal_bayar: '',
          waktu_bayar: '',
          hasil_uji: '',
          jenis_laporan: '',
          parameter_uji: this.detail.parameter?.parameterTest.namaParameterUji,
          kd_status_progres_parameter: ''
        }
      },

      toPage() {
        this.$router.push(`/analisis-sampel`)
      },
  
    },
  }
  </script>
  
  <style scoped>
  .v-input--selection-controls {
    margin-top: 5px;
    padding-top: 4px;
  }

  .v-messages {
    display: none;
  }  
  </style>