import axios from "axios";
import { mapKeys, mapValues, camelCase, snakeCase } from "lodash";
import { TokenService } from "@/service/Storage.Service.js"

const { VUE_APP_API_URL } = process.env;

function getAccessToken() {
  // @todo: load access token from cookie or locale storage
  return TokenService.getToken();
}

function transformKeys(data, iteratee) {
  if (Array.isArray(data)) {
    return data.map((d) => transformKeys(d, iteratee));
  }

  if (data instanceof Object) {
    return mapValues(
      mapKeys(data, (_, k) => iteratee(k)),
      (v) => transformKeys(v, iteratee)
    );
  }

  return data;
}

export function camelCaseKeys(data) {
  return transformKeys(data, camelCase);
}

export function snakeCaseKeys(data) {
  return transformKeys(data, snakeCase);
}

// @see: https://github.com/mzabriskie/axios#axios-api
export function request(method, url, config = {}, options = {}) {
  const {
    params,
    data,
    headers,
    maxContentLength
  } = config;

  // non-axios specific params
  const {
    suppressAuth
  } = options;

  const baseURL = VUE_APP_API_URL;
  // const xauth = VUE_APP_XAUTH;

  // @see: https://tools.ietf.org/html/rfc6750
  const bearerToken = `Bearer ${getAccessToken()}`;

  return new Promise((resolve) => {
    axios({
      method,
      baseURL,
      url,
      params,
      data: data,
      headers: suppressAuth ? headers : { ...headers, Authorization: bearerToken},
      maxContentLength
    })
      .then((response) => {
        resolve({
          ...response,
          data: camelCaseKeys(response.data)
        });
      })
      .catch((error) => {
        // Do something with response error
        if (error.response.status === 401) {
          TokenService.removeToken()
          window.location = `/login`
        }
        return Promise.reject(error.response)
      })
  });
}

export function get(url, config, options) {
  return request("GET", url, config, options);
}

export function post(url, config, options) {
  return request("POST", url, config, options);
}

export function put(url, config, options) {
  return request("PUT", url, config, options);
}

// not "delete()" because of reserved word
export function destroy(url, config, options) {
  return request("DELETE", url, config, options);
}