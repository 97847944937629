<template>
    <div>
      <!-- snackbar -->
      <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
      <!-- end snackbar -->
  
      <!-- dialog konfirmasi -->
      <v-dialog v-model="dialog.konfirmasi" persistent width="450">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Konfirmasi</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.konfirmasi = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5 text-center mt-2">
            <span class="font-weight-bold title">
              Simpan data yang diinputkan ?
            </span>
            <v-card-subtitle class="pt-0">
              <span class="body-2">Pastikan data sudah sesuai</span>
            </v-card-subtitle>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-text class="pt-0 pb-0">
          </v-card-text>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.konfirmasi = false;">
              Batal
            </v-btn>
            <v-btn
              color='#0299B7'
              elevation="0"
              class="text-capitalize white--text mx-1"
              :loading="process.form"
              :disabled="process.form"
              @click="save">
              Ya, 
              <span>simpan</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog konfirmasi -->
  
      <LoadingComponent v-if="process.loading"></LoadingComponent>
  
      <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <!-- data diri -->
                  <v-row>
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold title mt-3 pb-2">
                        Data diri
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">ID registrasi</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.idRegistrasi || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Nama Lengkap</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.nama || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Nomor Mahasiswa/Kepegawaian</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.noInduk || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">No. WhatsApp aktif</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.noHp || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Instansi</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.namaInstitusi || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Perguruan tinggi</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.namaPerguruanTinggi || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Fakultas</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.namaFakultas || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Program studi</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.user?.namaProgramStudi || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <!-- end data diri -->
  
                  <!-- jenis penggunaan -->
                  <v-row>
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold title mt-3 pb-2">
                       Jenis penggunaan
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Jenis</td>
                          <td width="1%">:</td>
                          <td>{{ detail?.usageType?.jenisPenggunaan || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <!-- end jenis penggunaan -->
  
                  <!-- sampel -->
                  <ValidationObserver ref="observer">
                    <v-form>  
                      <v-row v-for="(item, i) in form.sampel" :key="i">
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold title mt-3 pb-2">
                            Sampel {{ i + 1 }}
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <table width="100%" class="body-2">
                            <tr>
                              <td width="30%">Kode sampel</td>
                              <td width="1%">:</td>
                              <td>{{ item.kode_sampel || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Nama</td>
                              <td width="1%">:</td>
                              <td>{{ item.nama || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Jumlah</td>
                              <td width="1%">:</td>
                              <td>{{ item.jumlah || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Bentuk </td>
                              <td width="1%">:</td>
                              <td>{{ item.bentuk || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Penyimpanan</td>
                              <td width="1%">:</td>
                              <td>{{ item.penyimpanan || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Kondisi</td>
                              <td width="1%">:</td>
                              <td>{{ item.kondisi || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%">Kemasan</td>
                              <td width="1%">:</td>
                              <td>{{ item.kemasan || '-' }}</td>
                            </tr>
                            <tr>
                              <td width="30%" class="td-flex">Gambar</td>
                              <td width="1%">:</td>
                              <td>
                                <v-expansion-panels>
                                  <v-expansion-panel>
                                    <v-expansion-panel-header color="#0299B7">
                                      <template v-slot:actions>
                                        <v-icon color="white">
                                          $expand
                                        </v-icon>
                                      </template>
                                      <span class="white--text"></span>
                                    </v-expansion-panel-header>     
                                    <v-expansion-panel-content>
                                      <div v-if="isProgress" class="d-flex justify-center mt-5">
                                        <v-progress-linear
                                          color="#0299B7"
                                          indeterminate
                                          rounded
                                          height="6"
                                          style="width: 300px;">
                                        </v-progress-linear>
                                      </div>
                                      <div v-else-if="!isProgress">
                                        <iframe
                                          v-if="item.file && item.file.startsWith && item.file.startsWith('data:application/pdf')" 
                                          width="100%" 
                                          :height="item.file === null ? '110px' : '500px'" 
                                          :src="item.file" 
                                          :lazy-src="item.file"
                                          frameborder="0"
                                          allowfullscreen>
                                          Oops! an error has occurred.
                                        </iframe>
                                        <v-img 
                                          v-else-if="item.file && item.file.startsWith && item.file.startsWith('data:image')"
                                          :src="item.file" 
                                          :lazy-src="item.file" 
                                          width="700" 
                                          :height="item.file === null ? '110px' : '500px'" 
                                          cover>
                                        </v-img>
                                      </div>            
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                        <v-col cols="12">
                          <table width="100%" class="body-2">
                            <tr>
                              <td width="30%">Status penerimaan sampel</td>
                              <td width="1%">:</td>
                              <td>
                               {{ item.status_sampel || '-' }}
                              </td>
                            </tr>
                            <tr class="mb-2 body-2">
                              <td width="30%">Tanggal penerimaan sampel</td>
                              <td width="1%">:</td>
                              <td>
                                {{ item.start_date || '-' }}
                              </td>
                            </tr>
                            <template v-if="!isDetail">
                              <tr v-for="(parameter, parameterIndex) in detail.sample[i].parameter" :key="parameterIndex">
                                <td width="35%" style="display: flex;">Parameter uji {{parameterIndex + 1}}</td>
                                <td width="1%">:</td>
                                <td>
                                  <div v-if="parameter.parameterTest">
                                    {{ parameter.parameterTest.namaParameterUji }} | <span class="caption"> {{ parameter.status.statusSampelParameter }} </span>
                                    <v-radio-group v-model="parameter.id_status_sampel_parameter">
                                      <v-radio
                                        v-for="(status, statusIndex) in sort.status_parameter"
                                        :key="statusIndex" 
                                        :label="status.text"
                                        :value="status.id"
                                        color="#0499b7"
                                        class="py-0 mt-0"
                                        @change="updateParameter(i, parameterIndex, status.id, parameter)">
                                      </v-radio>
                                    </v-radio-group>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            
                            <template v-if="isDetail">
                              <tr v-for="(parameter, parameterIndex) in detail.sample[i].parameter" :key="parameterIndex">
                                <td width="35%" style="display: flex;">Parameter uji {{parameterIndex + 1}}</td>
                                <td width="1%">:</td>
                                <td>
                                  <div v-if="parameter.parameterTest">
                                    {{ parameter.parameterTest.namaParameterUji }} | <span class="caption" :class="getColor(parameter.status.statusSampelParameter)"> {{ parameter.status.statusSampelParameter || '-' }} </span>
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </table>
                        </v-col>
                      </v-row>
                    </v-form>
                  </ValidationObserver>
                  <!-- sampel -->
  
                  <!-- riwayat -->
                  <v-row>
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold py-2 mt-5">
                        Riwayat
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-timeline 
                        align-top
                        dense>
                        <v-timeline-item
                          small 
                          color="#0299B7"
                          v-for="(item, j) in detail.history" :key="j">
                          <v-row>
                            <v-col>
                              <span class="color-first body-2">{{item.createdAt | datetime}} WIB</span>
                              <div class="caption font-weight-bold"> {{item.statusSubmission.statusPengajuan}} </div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </v-col>
                  </v-row>
                  <!-- end riwayat -->
  
                  <!-- persetujuan -->
                  <v-row v-if="!isDetail">
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold mt-2">
                        Persetujuan
                      </div>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-radio-group v-model="sort.selected_persetujuan">
                        <v-radio
                          v-for="(status, index) in sort.status_persetujuan"
                          :key="index" 
                          :label="status.text"
                          :value="status.id"
                          color="#0499b7"
                          class="py-0 mt-0">
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" v-if="sort.selected_persetujuan == '0'">
                      <v-text-field
                        dense
                        outlined
                        label="Catatan (Optional)"
                        color="#0299B7"
                        v-model="form.catatan">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- end persetujuan -->                
                </v-card-text>
                <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                <v-card-actions class="mt-2 pb-5 justify-center">
                  <v-btn
                    outlined
                    class="text-capitalize color-first mx-1"
                    :disabled="process.form"
                    @click="toPage()">
                    Kembali
                  </v-btn>
                  <v-btn
                    v-if="!isDetail"
                    color="#0299B7"
                    elevation="0"
                    class="text-capitalize white--text mx-1"
                    :disabled="process.run"
                    @click="cekForm()">
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>        
      </v-row>
  
    </div>
  </template>
  
  <script>
  const { VUE_APP_API_URL } = process.env;
  import LoadingComponent from '@/components/Loading.vue'
  import Snackbar from '@/components/Snackbar.vue';
  import { TokenService } from "@/service/Storage.Service";
  import 'moment/locale/id';
  import { get } from "@/service/Axios";
  import { serialize } from 'object-to-formdata';
  
  export default {
    data() {
      return {
        detail: {},
        dialog: {
          konfirmasi: false,
        },
        form: {
          sampel: [{
            file: [],
            kode_sampel: '',
            nama: '',
            jumlah: '',
            bentuk: '',
            penyimpanan: '',
            kondisi: '',
            kemasan: '',
            status_sampel: '',
            gambar: '',
            uuid: '',
            parameter_uji: [
              {
                uuid: '',
                id_status_sampel_parameter: ''
              },
            ]
          }],
          catatan: '',
        },
        isImage: '',
        isDetail: false,
        isProgress: false,
        file: [],
        process: {
          run: false,
          loading: false,
          form: false
        },
        list: {
          parameter: [],
          kalab: [],
        },
        sort: {
          selected_parameter: [],
          selected_persetujuan: '1',
          status_parameter: [],
          status_persetujuan: [
          {
              id: '1',
              text: 'Disetujui'
            },
            {
              id: '0',
              text: 'Ditolak'
            },
          ],
        },
        headers: [
          { text: 'No.', value: 'no', sortable: false, },
          { text: 'Sampel', value: 'persen_pembagian', sortable: false,},
          { text: 'Parameter uji', value: 'persen_pembagian', sortable: false,},
          { text: 'Harga per pengujian', value: 'harga_external', sortable: false, align: 'right' },
          { text: 'Harga replikasi', value: 'harga_internal', sortable: false, align: 'right' },
          { text: 'Harga total', value: 'harga_internal', sortable: false, align: 'right' },
        ],
        row: [],
        snackbar: false,
        text: '',
        timeout: 4000,
        save_status: '',
      }
    },
  
    components: {
      LoadingComponent,
      Snackbar
    },
  
    computed: {
      reminder () {
        let now = new Date(),
            next = new Date(now)
        next.setDate(now.getDate())
        return next.toISOString().substr(0, 10)
      },
  
      roles () {
        return this.$store.state.roles
      },
  
      token () {
        return this.$store.state.access_token
      }
    },
  
    watch: {},
  
    mounted () {
      if (TokenService.getUuid() === null) {
        this.$router.push(`/persetujuan-pengujian`)
      } else {
        this.cekParams()
      }  
    },
  
    methods: {
      cekParams() {
        const data = JSON.parse(localStorage.getItem('filter'));
        this.save_status = data;
        const params = this.$route.params.page
        this.isDetail = params === 'detail' ? true : false
        this.getTestSampleParameterStatus()
      },
  
      async getTestSampleParameterStatus() {
        try {
          this.process.run = true
          this.process.loading = true
          const params = {
            role: this.roles
          }
          const response = await get(`test-submission-sample-parameter-status`, { params })
          if (response.status === 200) {
            const res = response.data.data;
            this.process.run = false
            const parameter = res.map((el) => ({
              id: el.kdStatusSampelParameter,
              text: el.statusSampelParameter,
              uuid: el.uuid
            }))
            this.sort.status_parameter = parameter
          }
        } catch (error) {
          console.log(error) 
        } finally {
          this.process.run = false;
          this.getOfficer();
        }
      },
  
      async getOfficer() {
        try {
          this.process.run = true
          const response = await get(`officer`)
          if (response.status === 200) {
            const res = response.data.data;
            this.process.run = false
            const data = res.map((el) => ({
              label: el.nama,
              value: el.id,
            }))
            this.list.kalab = data
          }
        } catch (error) {
          console.log(error) 
        } finally {
          this.fetch()
        }
      },
  
      async fetch() {
        try {
          const uuid = TokenService.getUuid()
          const params = {
            uuid: uuid,
            flag_officer: '1'
          }
          const response = await get(`test`, {params})
          if (response.status === 200) {
            const res = response.data.data;
            this.detail = res[0];
            const formattedSamples = this.detail.sample.map((item) => ({
              kode_sampel: item.kodeSampel || '',
              nama: item.nama || '',
              jumlah: item.jumlah || '',
              bentuk: item.phase.namaBentukSampel || '',
              penyimpanan: item.storage.namaPenyimpananSampel || '',
              kondisi: item.condition.namaKondisiSampel || '',
              kemasan: item.kemasan || '',
              gambar: item.fileGambar || '',
              uuid: item.uuid || '',
              status_sampel: item.status?.statusSampel || '',
              start_date:  this.$callbackdate(item.tglPenerimaanSampel) || '',
              parameter_uji: []
            }));
            this.form.sampel = formattedSamples;
            this.detail.sample.forEach((item) => {
              item.parameter.forEach((sampel) => {
                const statusSampelParameter = sampel.status.statusSampelParameter;
                this.list.parameter.forEach((tmp) => {
                  tmp.status = statusSampelParameter
                })
              });
            });
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.getFile()
        }
      },
  
      async getFile() {
        try {
          let params = [];
          this.detail.sample.forEach(element => {
            params.push({ url: element.fileGambar });
          });
          for (let index = 0; index < params.length; index++) {
            const response = await get(`file-test`, { params: params[index] });
            if (response.status === 200) {
              let res = response.data;
              this.form.sampel[index].file = res.data || null;
            }
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.process.loading = false
          this.process.run = false
        }
      },

      getColor(status) {
        switch (status) {
          case 1:
            return 'grey--text'
          case 3:
            return 'green--text';
          case 4:
            return 'red--text';
          default:
            return '';
        }
      },
  
      async cekForm() {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          this.dialog.konfirmasi = true
        } else {
          this.dialog.konfirmasi = false
        }
      },
  
      updateParameter(sampelIndex, parameterIndex, selectedStatusId, parameter) {
        this.form.sampel[sampelIndex].parameter_uji[parameterIndex] = {
          id_status_sampel_parameter: selectedStatusId,
          uuid: parameter.uuid
        };
      },
    
      async save() {
       this.createVerification()
      },
  
      async createVerification() {
        const { form, token, roles, sort, detail } = this;
        const sampel = form.sampel.map(element => {
          const parameter_uji = element.parameter_uji.map(val => (
            { 
              uuid: val.uuid ,
              id_status_sampel_parameter: val.id_status_sampel_parameter 
            }));
          return { 
            uuid: element.uuid,
            parameter_uji
          }
        })
        const value = {
          role: roles,
          flag_persetujuan:  sort.selected_persetujuan,
          uuid: detail.uuid,
          catatan_tambahan: form.catatan,
          id_user_verifikasi_kalab: form.kalab,
          sampel
        };
  
        const options = {
          indices: true,
          nullsAsUndefineds: false,
          booleansAsIntegers: false,
          allowEmptyArrays: false,
          noFilesWithArrayNotation: false,
          dotsForObjectNotation: false,
        };
  
        const formData = serialize(
          value,
          options,
        );
  
        try {
          const authHeader = new Headers();
          authHeader.append('Authorization', 'Bearer ' + token);
          const config = {
            method: 'POST',
            headers: authHeader,
            body: formData
          };
          this.process.loading = true;
          const response = await fetch(`${VUE_APP_API_URL}test/verification`, config);
          if (response.status === 200) {
            this.dialog.konfirmasi = false;
            this.text = 'Data penelitian berhasil disimpan';
            this.snackbar = true;
            this.process.loading = false;
            this.toPage();
          }
  
        } catch (error) {
          console.log(error)
        } finally {
          this.dialog.konfirmasi = false;
        }
      },
  
      toPage() {
        this.$router.push(`/persetujuan-kalab-lpmok`)
      },
  
    },
  }
  </script>
  
  <style scoped>
  .v-input--selection-controls {
    margin-top: 5px;
    padding-top: 4px;
  }
  </style>