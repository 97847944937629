<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <v-row class="mt-3 px-3">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                  Surat bebas lab
                </div>

                <!-- row filter -->
                <v-row class="pt-5">
                <v-col cols="12" md="3">
                  <v-select
                    dense
                    :disabled="process.run"
                    outlined
                    :items="list.status"
                    item-text="status_bebas_lab"
                    item-value="kd_status_bebas_lab"
                    v-model="form.status"
                    v-on:change="onSelectStatus($event)"
                    label="Status"
                    color="#0299B7"
                    hide-details>
                    <template v-slot:append>
                      <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                    </template>
                  </v-select>
                </v-col>
                </v-row>
                <!-- end row filter -->

                <!-- row  button-->
                <v-row class="mt-8 justify-space-between">
                  <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                    <div class="text-left font-weight-600 title ml-3">
                      Riwayat penelitian
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :disabled="process.run"
                      outlined
                      dense
                      :hide-details="true"
                      placeholder="Cari . . ."
                      clearable
                      color="#0299B7"
                      class="mx-3"
                      v-model="form.cari"
                      @click:clear="form.cari = ''; getList();"
                      v-on:keyup.enter="getList();"
                      prepend-inner-icon="mdi-magnify">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- end row button -->

                <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                  <v-skeleton-loader
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                    type="table-tbody">
                    <v-data-table
                      :headers="headers"
                      :items="row"
                      :items-per-page="10"
                      class="elevation-1 required">
                        <template v-slot:[`item.status`]="{ item }">
                          <td>
                            <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                          </td>
                        </template>
                        <template v-slot:[`item.aksi`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                fab
                                icon
                                min-width="25"
                                color="#0299B7"
                                :disabled="process.detail"
                                :loading="process.detail"
                                @click="toPage(item, 'detail', 'detail')"
                                class="text-capitalize color-first pa-0 mx-1"
                                v-bind="attrs"
                                v-on="on">
                                <v-icon>mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>Detail</span>
                          </v-tooltip>
                          <v-tooltip top v-if="item.kd_status_bebas_lab === 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="30"
                              color="orange"
                              @click="toPage(item, 'edit', 'edit')"
                              v-bind="attrs"
                              v-on="on"
                              class="text-capitalize color-first pa-0 mx-1">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-skeleton-loader>
                </v-card-text>        
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TokenService } from "@/service/Storage.Service";
import { get } from "@/service/Axios";
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
export default {
  mixins: [statusMixin],  
  data() {
    return {
      detailData: {},
      form: {
        status: '',
        cari: '',
      },
      list: {
        status: [],
      },
      process: {
        run: false
      },
      isEdit: false,
      isUII: false,
      row: [],
      headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Tanggal pengajuan', value: 'tanggal_pengajuan', sortable: false, },
        { text: 'Status pembayaran', value: 'status', sortable: false, },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya',sortable: false, },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      hasDetail: false,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },

  components: {
    Snackbar
  },

  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    },
    roles () {
      return this.$store.state.roles
    }
  },
  
  mounted () {
    TokenService.removeUUID(TokenService.getUuid());
    this.cekUserSelf()
  },
  methods: {
    async cekUserSelf() {
      this.process.run = true;
      try { 
        const response = await get(`user/self`);
        if (response.status === 200) { 
          const res = response.data.data;
          this.detailData = res
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
        this.fetch()
      }
    },

    async fetch() {
      const data = JSON.parse(localStorage.getItem('filter'))
      if (data) {
        const { form } = this
        this.hasDetail = true
        form.status = data.status
        localStorage.removeItem("filter");
      }
      const email = this.user.email;
      this.isUII = email.endsWith("uii.ac.id") ? false : true;
      await Promise.all([
        this.getStatus(),
      ]);
    },

    async getStatus() {
      try {
        this.process.run = true
        const response = await get(`lab-free-status`)
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          const status = res.map((el) => ({
            id: el.id,
            kd_status_bebas_lab: el.kdStatusBebasLab,
            status_bebas_lab: el.statusBebasLab,
            proses_selanjutnya: el.prosesSelanjutnya
          }))
          status.unshift({
            id: 'all',
            kd_status_bebas_lab: 'all',
            status_bebas_lab: 'Semua',
          })
          this.list.status = status
          this.form.status = this.hasDetail ? this.form.status : this.form.status === undefined ? status[0].id : status[0].id;
        }
      } catch (error) {
        console.log(error) 
      } finally {
        this.process.run = false;
        this.getList()
      }
    },

    async getList() {
      try {
        this.process.run = true;
        const params = {
          search: this.form.cari,
          id_status_bebas_lab: this.form.status,
          id_laboratorium: this.detailData.admin[0].lab.id,
          role: this.roles,
          flag_officer: '1'
        }
        const { data, status } =  await get('submission-lab-free', { params })
        if (status === 200 && data.data) {
          const row = data.data.map((item, index) => ({
            no: index + 1,
            tanggal_pengajuan: `${this.$callbackdate(item.createdAt)}`,
            status: item.statusSubmission.statusBebasLab,
            proses_selanjutnya: item.statusSubmission.prosesSelanjutnya,
            kd_status_bebas_lab: item.statusSubmission.kdStatusBebasLab,
            uuid: item.uuid
          }));
          this.row = row;
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
      }
    },

    onSelectStatus(val) {
      this.form.status = val
      this.getList()
    },

    toPage(val, type, page) {
      const { form } = this
      const data = {
        status: form.status
      }
      localStorage.setItem('filter', JSON.stringify(data))
      TokenService.setUuid(val.uuid);
      try {
        TokenService.setUuid(val.uuid);
        let path;
        path = page === 'detail' ? '/surat-petugas-pembayaran/ajukan/detail' : '/surat-petugas-pembayaran/ajukan/edit';
        this.$router.push({path: path})
      } catch (error) {
        console.error(error);
      }
    }
  },
}
</script>