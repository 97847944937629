export default {
    methods: {
      getStatusClass(status) {
        switch(status) {
          case 'Aktif':
            return 'status-disetujui-laboran';
          case 'Perlu verifikasi':
            return 'status-perlu-pembayaran';
          case 'Tidak aktif':
            return 'status-grey';
          case 'Diajukan selesai penggunaan':  
            return 'status-diajukan' 
          case 'Diajukan':
            return 'status-diajukan'
          case 'Perlu verifikasi kalab':
            return 'status-diajukan'
          case 'Disetujui kalab':
            return 'status-disetujui-kalab'
          case 'Disetujui laboran':
            return 'status-disetujui-laboran'
          case 'Tambah alat/bahan/instrumen':
            return 'status-tambah-alat'
          case 'Perlu pembayaran DP 50%':
            return 'status-tambah-alat'
          case 'Perlu pembayaran akhir':
            return 'status-tambah-alat'
          case 'Penambahan disetujui laboran':
            return 'status-penambahan-disetujui'
          case 'Pembayaran DP 50% terverifikasi':
            return 'status-penambahan-disetujui'
          case 'Diajukan selesai':
            return 'status-diajukan-selesai'
          case 'Permintaan dokumen tambahan':
            return 'status-perlu-pembayaran'
          case 'Perlu pembayaran':
            return 'status-perlu-pembayaran'  
          case 'Pembayaran terverifikasi':
            return 'status-pembayaran-terverifikasi'
          case 'Pembayaran akhir terverifikasi':
            return 'status-pembayaran-terverifikasi'
          case 'Terbayar lunas':
            return 'status-pembayaran-terverifikasi'
          case 'Selesai penggunaan':
            return 'status-selesai-penggunaan'
          case 'Dokumen tambahan tersedia':
            return 'status-selesai-penggunaan'
          case 'Verifikasi pembayaran':
            return 'status-verifikasi-pembayaran'
          case 'LHU tersedia':
            return 'status-verifikasi-pembayaran'
          case 'Batal penggunaan':
            return 'status-batal-penggunaan'
          case 'Belum bayar':
            return 'status-ditolak' 
          case 'Ditolak':
            return 'status-ditolak'
          case 'Penambahan ditolak laboran':
            return 'status-ditolak'   
          case 'Sudah dikembalikan':
            return 'status-pembayaran-terverifikasi'
          case 'Pembayaran deposit terverifikasi':
            return 'status-pembayaran-terverifikasi'
          case 'Belum dikembalikan':
            return 'status-ditolak'
          case 'Masuk antrian pengujian':
            return 'status-grey'
          case 'Pengujian berlangsung':
            return 'status-green-tosca'
          case 'Pengujian dibatalkan':
            return 'status-ditolak'
          case 'Selesai pengujian':
            return 'status-selesai-penggunaan'       
          case 'Laporan akhir':
            return 'status-diajukan'   
          case 'Diajukan selesai pengujian':
            return 'status-diajukan'   
          case 'Laporan progress':
            return 'status-belum-lunas'    
          case 'Review penyelia':
            return 'status-red-light'            
          default:
            return '';
        }
       
      },
    },
  }