<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
           Ajukan surat bebas lab ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan pengajuan sudah memenuhi dua persyaratan pengajuan.</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.run"
            :disabled="process.run"
            @click="save">
            Ya, ajukan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

     <!-- dialog detail -->
     <v-dialog
      v-model="dialog.detail_modal"
      scrollable
      persistent
      width="850">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Detail</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="dialog.detail_modal = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <v-row class="mb-2 align-center">
            <v-col cols="12" md="8">
             <div class="text-left font-weight-bold body-1">
              Riwayat pendaftaran
             </div>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                :disabled="process.detail"
                :hide-details="true"
                placeholder="Cari . . ."
                clearable
                color="#0299B7"
                v-model="filter.history_detail"
                @click:clear="filter.history_detail = ''; getReserch();"
                v-on:keyup.enter="getReserch();"
                prepend-inner-icon="mdi-magnify">
              </v-text-field>
            </v-col>
          </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
              :loading="process.detail"
              transition="scale-transition"
              :tile="false"
              :style="process.detail ? 'height: calc(50vh - 305px)' : ''"
              type="table-tbody">
              <v-data-table
                :headers="headers_detail"
                :items="row_detail"
                :items-per-page="10"
                class="elevation-1 required">
                <template v-slot:[`item.status`]="{ item }">
                  <td>
                    <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                  </td>
                </template>
              </v-data-table>
            </v-skeleton-loader>
          </v-col>
        </v-row>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            color="#0299B7"
            elevation="0"
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.detail_modal = false;">
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog detail -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div>
                  <!-- row table -->
                  <v-row>
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold title mt-3 pb-2">
                        Data diri
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Nama Lengkap</td>
                          <td width="1%">:</td>
                          <td>{{ detailData.nama || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Nomor Mahasiswa/Kepegawaian</td>
                          <td width="1%">:</td>
                          <td>{{ detailData.noInduk || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">No. WhatsApp aktif</td>
                          <td width="1%">:</td>
                          <td>{{ detailData.noHp || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Instansi</td>
                          <td width="1%">:</td>
                          <td>{{ detailData.namaInstitusi || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Perguruan tinggi</td>
                          <td width="1%">:</td>
                          <td>{{ detailData.namaPerguruanTinggi || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Fakultas</td>
                          <td width="1%">:</td>
                          <td>{{ detailData.namaFakultas || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Program studi</td>
                          <td width="1%">:</td>
                          <td>{{ detailData.namaProgramStudi || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <!-- end row table -->

                  <v-row class="mb-5">
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold title mt-3 pb-2">
                        Penelitian
                      </div>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="background-blue">
                        <div>
                          <v-icon color="#0077BB" class="mr-2">mdi-information</v-icon>
                        </div>
                        <div>
                          <span>Surat bebas lab dapat diajukan setelah memenuhi dua syarat berikut:</span><br>
                          <div class="d-flex">
                            <span>1</span>
                            <span style="max-width: 50px; min-width: 13px;">.</span>
                            <span>Semua <b>penelitian laboratorium</b> sudah selesai penggunaan.</span>
                          </div>
                          <div class="d-flex">
                            <span>2</span>
                            <span style="max-width: 50px; min-width: 13px;">.</span>
                            <span>Sudah menginputkan data untuk <b>penelitian non-laboratorium</b></span>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="mb-2 justify-end">
                    <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                      <div class="text-left font-weight-600 title ml-3">
                        Riwayat penelitian
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-text-field
                        outlined
                        dense
                        :disabled="process.run"
                        :hide-details="true"
                        placeholder="Cari . . ."
                        clearable
                        color="#0299B7"
                        class="mx-3"
                        v-model="filter.history"
                        @click:clear="filter.history = ''; getHistoryStudy();"
                        v-on:keyup.enter="getHistoryStudy();"
                        prepend-inner-icon="mdi-magnify">
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-card-text class="pa-5 px-0" style="height: calc(100vh - 100px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="row"
                        :items-per-page="10"
                        class="elevation-1 required">
                          <template v-slot:[`item.status`]="{ item }">
                            <td>
                              <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                            </td>
                          </template>
                          <template v-slot:[`item.aksi`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  @click="detailDialog(item)"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Detail</span>
                            </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>

                    <div class="py-2 mt-10" v-if="isDetail">
                      <div class="color-first title font-weight-bold pb-2 mt-1 mb-3">
                      Riwayat
                      </div>
                      <v-timeline 
                        align-top
                        dense>
                        <v-timeline-item
                          small 
                          color="#0299B7"
                          v-for="(item, i) in detail.historySubmission" :key="i">
                          <v-row>
                            <v-col>
                              <span class="color-first body-2">{{item.statusSubmissionLabFree.createdAt | datetime}} WIB</span>
                              <div class="caption font-weight-bold"> {{item.statusSubmissionLabFree.statusBebasLab}} </div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                    <v-divider></v-divider>

                    <!-- row simpan -->
                    <v-card-actions class="mt-4 justify-center">
                      <v-btn
                        large
                        width="120"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize"
                        @click="toHome()">
                        Batal
                      </v-btn>
                      <v-btn
                        v-if="!isDetail"
                        :disabled="!isAjukan"
                        large
                        width="120"
                        color="#0299B7"
                        elevation="0"
                        :loading="process.run"
                        @click="cekForm"
                        class="white--text text-capitalize">
                        ajukan
                      </v-btn>
                    </v-card-actions>
                    <!-- end row -->

                  </v-card-text>
                  
                </div>  
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
import LoadingComponent from '@/components/Loading.vue'
import { TokenService } from '@/service/Storage.Service';
import { get } from "@/service/Axios";
export default {
  mixins: [statusMixin],  
  data() {
    return {
      detailUuid: '',
      detail: {},
      detailData: {},
      dialog: {
        detail: false,
        konfirmasi: false,
        detail_modal: false
      },
      filter: {
        history: '',
        history_detail: '',
      },
      process: {
        run: false,
        loading: false,
        detail: false
      },
      row: [],
      row_detail: [],
      headers_detail: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Jenis', value: 'jenis_penelitian', sortable: false, },
        { text: 'Judul atau nama sampel', value: 'judul', sortable: false, },
        { text: 'Tanggal penggunaan', value: 'tanggal_penggunaan', sortable: false, },
        { text: 'Status', value: 'status', sortable: false, },
      ],
      headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Laboratorium', value: 'laboratorium', sortable: false, },
        { text: 'Jumlah penelitian', value: 'jumlah_penelitian', sortable: false, align: 'right'},
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      isAjukan: false,
      isDetail: false,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },

  components: {
    Snackbar,
    LoadingComponent
  },

  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },

  mounted () {
    this.cekUserSelf()
    console.log('isi', this.$route.query.type);
    if (this.$route.query.type === 'detail') {
      this.isDetail = true
    } else { 
      this.isDetail = false
    }
  },

  methods: {
    async cekUserSelf() {
      this.process.run = true;
      try { 
        const response = await get(`user/self`);
        if (response.status === 200) { 
          const res = response.data.data;
          this.detailData = res
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
        this.fetch()
      }
    },
    async fetch() {
      try {
        this.process.run = true;
        this.process.loading = true;
        const uuid = TokenService.getUuid();
        const params = { uuid };
        const { data, status } =  await get('submission-lab-free', { params })
        if (status === 200 && data.data) {
          const res = data.data;
          this.detail = res[0];
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
        this.process.loading = false;
        this.getHistoryStudy();
      }
    },

    async getHistoryStudy() {
      try {
        this.process.run = true;
        const params = {
          search: this.filter.history,
          // // id_laboratorium: this.form.status,
          id_user: this.detailData.id
        }
        const { data, status } =  await get('submission-lab-free/lab-history', { params })
        if (status === 200 && data.data) {
          const row = data.data.map((item, index) => ({
            no: index + 1,
            laboratorium: item.laboratorium,
            jumlah_penelitian: item.jlhPenelitian,
            id_laboratorium: item.idLaboratorium,
          }));
          this.row = row;
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
        this.cekFlagMengajukan();
      }
    },

   
    async cekFlagMengajukan() {
      try { 
        const response = await get(`submission-lab-free/check-can-submission`);
        if (response.status === 200) { 
          const res = response.data.data;
          this.isAjukan = res.flagDapatMengajukan
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
      }
    },

    async getReserch() {
      this.process.detail = true;
      const id_laboratorium = this.detailUuid
      const search = this.filter.history_detail
      const params = { id_laboratorium, search };
      try {
        const response = await get(`research`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.row_detail = res.map((data, index) => {
            data.createdAt = this.$callbackdatetime(data.createdAt)
            return {
              no: index + 1,
              jenis_penelitian: data.researchType.jenisPenelitian,
              judul: data.judul,
              tanggal_penggunaan: `${data.lab.namaLaboratorium} ${this.$callbackdate(data.tglMulai)} - ${this.$callbackdate(data.tglSelesai)}`,
              proses_selanjutnya: data.statusSubmission.prosesSelanjutnya,
              status: data.statusSubmission.statusPengajuan,
              uuid: data.uuid
            }
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.detail = false;
      }
    },

    detailDialog(data) {
      const { id_laboratorium } = data;
      this.detailUuid = id_laboratorium;
      this.dialog.detail_modal = true;
      this.getReserch()
    },

    async cekForm() {
      this.dialog.konfirmasi = true
    },

    async save() {
      this.process.run = true;
      const { token } = this
      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader };

      const response = await fetch(`${VUE_APP_API_URL}submission-lab-free`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.text = 'Data pengajuan bebas lab berhasil disimpan';
        this.snackbar = true;
        this.process.run = false;
        this.getHistoryStudy();
        this.toHome();
      } else {
        this.dialog.konfirmasi = false;
      }
    },

    toHome() {
      TokenService.removeUUID(TokenService.getUuid())
      this.$router.push(`/surat`)
    }
  },

}
</script>
