<template>
  <div>
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
          <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title" v-if="save_type !== 'creates'">
            Simpan data yang diinputkan ?
          </span>
          <span class="font-weight-bold title" v-else>
            Buat invoice ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2" v-if="save_type !== 'creates'">Pastikan data sudah sesuai</span>
            <span class="body-2" v-else>Hasil invoice dapat dilihat di tab <b>Riwayat pembayaran</b></span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog download -->
    <v-dialog
      v-model="dialog.download"
      scrollable
      persistent
      width="400">
      <v-card flat>
        <v-card-text class="text-center">
          <p class="mb-0 mt-3">Proses download pdf....</p>
          <div class="mt-2">
            <v-progress-linear
              color="#0299B7"
              indeterminate
              rounded
              height="6">
            </v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog download -->

    <!-- dialog detail -->
    <v-dialog
      v-model="dialog.detail_buat_invoice"
      scrollable
      persistent
      width="850">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Detail tambah alat/bahan/instrumen</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="dialog.detail_buat_invoice = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <v-row class="mb-2 align-center">
            <v-col cols="12" md="8">
              <div class="text-left font-weight-bold body-1">
              Penggunaan
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                :disabled="process.run"
                :hide-details="true"
                placeholder="Cari . . ."
                clearable
                color="#0299B7"
                v-model="filter.history_detail"
                @click:clear="filter.history_detail = ''; getDetailHistoryAddition();"
                v-on:keyup.enter="getDetailHistoryAddition();"
                prepend-inner-icon="mdi-magnify">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                :loading="process.detail"
                transition="scale-transition"
                :tile="false"
                :style="process.detail ? 'height: calc(50vh - 305px)' : ''"
                type="table-tbody">
                <v-data-table
                  :headers="headers_detail"
                  :items="row_detail"
                  :items-per-page="10"
                  class="elevation-1 required">
                  <template slot="body.append">              
                    <tr>
                      <td colspan="8" class="text-left pl-4 font-weight-bold">Total tagihan</td>
                      <td class="font-weight-bold text-right">{{totalAmount | price}}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            color="#0299B7"
            elevation="0"
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.detail_buat_invoice = false;">
            Kembali
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog detail -->

    <!-- dialog bayar riwayat invoice -->
    <v-dialog
      v-model="dialog.riwayat_invoice"
      scrollable
      persistent
      width="780">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Upload bukti pembayaran</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="reset(), dialog.riwayat_invoice = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="color-first title font-weight-bold title mt-7 mb-2">
            Invoice
          </div>
          <v-row class="mb-4">
            <v-col cols="12">
              <table width="100%" class="body-2">
                <tr>
                  <td width="25%"><b>No invoice</b></td>
                  <td width="1%">:</td>
                  <td>{{ detail_riwayat?.noInvoice }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <ValidationObserver ref="observer">
            <v-form>
              <v-row>
                <v-col cols="12" md="12">
                  <v-menu
                    ref="menunow"
                    v-model="form.upload_bukti_pembayaran.item_now"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    class="z-my"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <ValidationProvider
                        name="Tanggal bayar"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          :value="formatDateNow()"
                          label="Tanggal bayar"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form.upload_bukti_pembayaran.tanggal_bayar"
                      :max="reminder" 
                      v-on:change="changedate()" 
                      color="#0299B7"
                      format="D MMMM YYYY"
                      @input="form.upload_bukti_pembayaran.item_now = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                  <ValidationProvider
                    name="Total tagihan"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      filled
                      disabled
                      readonly
                      type="text"
                      v-model="form.upload_bukti_pembayaran.total_tagihan"
                      label="Total tagihan (Rp)"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Nomor rekening"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.upload_bukti_pembayaran.nomor_rekening"
                      label="Nomor rekening"
                      type="number"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Bank"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.upload_bukti_pembayaran.bank"
                      label="Nama bank"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Nama pemilik rekening"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.upload_bukti_pembayaran.nama_pemilik_rekening"
                      label="Nama pemilik rekening"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Bukti pembayaran"
                    rules="required"
                    v-slot="{ errors }">
                    <v-file-input
                      v-model="form.upload_bukti_pembayaran.file"
                      label="Bukti pembayaran"
                      outlined
                      dense
                      prepend-inner-icon="mdi-attachment"
                      color="#0299B7"
                      accept=".pdf"
                      :rules="[fileSizeRule]"
                      :error-messages="errors">
                    </v-file-input>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <ValidationProvider
                    name="Keterangan">
                    <v-text-field
                      v-model="form.upload_bukti_pembayaran.keterangan"
                      label="Keterangan(Optional)"
                      outlined
                      dense
                      color="#0299B7">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="reset(), dialog.riwayat_invoice = false;">
            Tutup
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :disabled="process.form"
            @click="save_type = 'creates_payment', cekForm()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog bayar riwayat invoice -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="background-blue">
                  <div>
                    <v-icon color="#0077BB" class="mr-2">mdi-alert-circle</v-icon>
                  </div>
                  <div v-if="type.jenis === 1 || type.jenis === 2">
                    <div class="payment-steps">
                      <span>Langkah melakukan pembayaran:</span><br>
                      <div v-for="(step, index) in paymentSteps[type.jenis]" :key="index">
                        <div class="d-flex">
                          <span>{{ index + 1 }}</span>
                          <span style="max-width: 50px; min-width: 13px;">.</span>
                          <span v-html="step.description"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <div class="color-first font-weight-bold title mt-3 pb-2">
                  Pembayaran
                </div>
              </v-col>
              <v-col cols="12" v-if="type.jenis === 1">
                <table width="100%" class="body-2">
                  <tr>
                    <td width="30%">Judul</td>
                    <td width="1%">:</td>
                    <td>{{ detail?.judul }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Total tagihan (Rp)</td>
                    <td width="1%">:</td>
                    <td>{{ detail?.totalTagihan | price }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Sudah bayar (Rp)</td>
                    <td width="1%">:</td>
                    <td>{{ detail?.totalTagihanTerbayar | price }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Belum bayar (Rp)</td>
                    <td width="1%">:</td>
                    <td>{{ detail?.totalTagihanBelumTerbayar | price }}</td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="12" v-if="type.jenis === 2">
                <table width="100%" class="body-2">
                  <tr>
                    <td width="30%">Sampel</td>
                    <td width="1%">:</td>
                    <td>{{ detail?.namaSampel }}</td>
                  </tr>
                  <tr>
                    <td width="30%">Total tagihan (Rp)</td>
                    <td width="1%">:</td>
                    <td>{{ detail?.totalTagihan | price }}</td>
                  </tr>
                  <tr>
                    <td width="30%">DP 50%</td>
                    <td width="1%">:</td>
                    <td>{{ detail?.totalTagihanDp | price }}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-tabs 
                  color="#0299B7"
                  background-color="transparent"
                  style="border-bottom: 1px solid lightgray"
                  light
                  v-model="tab">
                  <v-tab v-for="item in items" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>
                <div v-if="type.jenis === 1">
                  <div v-if="tab === 0">
                    <v-row class="mt-5 justify-end">
                      <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                        <div class="text-left font-weight-600 title ml-2">
                          Buat invoice
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          dense
                          :disabled="process.run"
                          :hide-details="true"
                          placeholder="Cari . . ."
                          clearable
                          color="#0299B7"
                          class="mx-3"
                          v-model="filter.invoice"
                          @click:clear="filter.invoice = ''; getCreateInvoice();"
                          v-on:keyup.enter="getCreateInvoice();"
                          prepend-inner-icon="mdi-magnify">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-skeleton-loader
                          :loading="process.create_invoice"
                          transition="scale-transition"
                          :tile="false"
                          :style="process.run ? 'height: calc(60vh - 305px)' : ''"
                          type="table-tbody">
                          <v-data-table
                            v-model="selected_table"
                            :headers="headers_invoice"
                            :items="row_invoice"
                            item-key="uuid"
                            select-class="blue darken-1"
                            :items-per-page="10"
                            class="elevation-1 required"
                            hide-default-header>
                            <template v-slot:header>
                              <thead>
                                <tr>
                                  <th v-if="detail?.uuid">
                                    <v-checkbox
                                      color="#0299B7"
                                      v-model="addAll"
                                      v-on:change="addAllInvoice()">
                                    </v-checkbox>
                                  </th>
                                  <th>No</th>
                                  <th>Nama tagihan</th>
                                  <th class="text-right">Tagihan (Rp)</th>
                                  <th>Aksi</th>
                                </tr>
                              </thead>
                            </template>
                            <template v-slot:body>
                              <tbody>
                                <tr v-for="(item, index) in row_invoice" :key="item.uuid">
                                  <td>
                                    <v-checkbox
                                      v-if="item.flag_invoice !== 1"
                                      v-model="selected_table"
                                      color="#0299B7"
                                      :value="item"
                                    ></v-checkbox>
                                  </td>
                                  <td>{{ index+1 }}</td>
                                  <td>{{ item.nama_tagihan }} </td>
                                  <td class="text-right">{{ item.tagihan }}</td>
                                  <td v-if="item.uuid !== null">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          x-small
                                          fab
                                          icon
                                          min-width="25"
                                          color="#0299B7"
                                          :disabled="process.run"
                                          @click="detailDialog(item)"
                                          v-bind="attrs"
                                          v-on="on"
                                          class="text-capitalize color-first pa-0 mx-1">
                                          <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                      </template>
                                    <span>Detail</span>
                                  </v-tooltip>      
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-data-table>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>

                    <!-- row pembiyayaan -->
                    <ValidationObserver ref="observer">
                      <v-form>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0">
                            <div class="color-first title font-weight-bold mt-10 pb-1">
                              Pembiayaan
                            </div>
                            <v-radio-group v-model="sort.selected_pembiyayaan">
                              <v-radio
                                v-for="(status, index) in sort.status_pembiyayaan"
                                :key="index" 
                                :label="status.text"
                                :value="status.id"
                                color="#0499b7"
                                class="py-0">
                              </v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-row v-if="sort.selected_pembiyayaan == '2' ">
                          <v-col cols="12" :md="form.pembiyayaan.dosen_pembimbing_pembiyayaan === '999' ? 6 : 12" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Dosen pembimbing"
                              rules="required"
                              v-slot="{ errors }">
                              <v-select
                                  dense
                                  :items="list.dosen"
                                  v-model="form.pembiyayaan.dosen_pembimbing_pembiyayaan"
                                  label="Dosen pembimbing / Ketua peneliti"
                                  color="#0299B7"
                                  item-text="nama_dosen"
                                  item-value="id"
                                  :error-messages="errors"
                                  outlined>
                                </v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0 pt-0" v-if="form.pembiyayaan.dosen_pembimbing_pembiyayaan === '999'">
                            <ValidationProvider
                              name="Nama dosen"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Nama dosen pembimbing/Ketua peneliti"
                                color="#0299B7"
                                :error-messages="errors"
                                v-model="form.pembiyayaan.dosen_freetext">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row v-if="sort.selected_pembiyayaan == '3'">
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Lainnya"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Lainnya"
                                color="#0299B7"
                                v-model="form.pembiyayaan.lainnya"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-form>
                    </ValidationObserver>
                    <!-- end row pembiyayaan -->
                    
                    <div class="mt-5 pb-3 text-center">
                      <v-btn
                        large
                        width="120"
                        elevation="0"
                        class="text-capitalize mr-2"
                        :disabled="process.form"
                        @click="toHome()">
                        Batal
                      </v-btn>
                      <v-btn
                        large
                        width="120"
                        color="#0299B7"
                        elevation="0"
                        class="text-capitalize white--text mx-1"
                        :disabled="process.form || selected_table.length < 1"
                        @click="save_type = 'creates'; cekForm()">
                        Buat invoice
                      </v-btn>
                    </div>
                  </div>
                  <div v-if="tab === 1">
                    <v-row class="mt-5 justify-end">
                      <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                        <div class="text-left font-weight-600 title ml-2">
                          Riwayat invoice
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          dense
                          :disabled="process.history_invoice"
                          :hide-details="true"
                          placeholder="Cari . . ."
                          clearable
                          color="#0299B7"
                          class="mx-3"
                          v-model="filter.history_invoice"
                          @click:clear="filter.history_invoice = ''; getHistoryInvoice();"
                          v-on:keyup.enter="getHistoryInvoice();"
                          prepend-inner-icon="mdi-magnify">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-skeleton-loader
                          :loading="process.history_invoice"
                          transition="scale-transition"
                          :tile="false"
                          :style="process.history_invoice ? 'height: calc(60vh - 305px)' : ''"
                          type="table-tbody">
                          <v-data-table
                            :headers="headers_riwayat"
                            :items="row_riwayat"
                            :items-per-page="10"
                            class="elevation-1 required">
                            <template v-slot:[`item.status`]="{ item }">
                              <td>
                                <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                              </td>
                            </template>
                            <template v-slot:[`item.aksi`]="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="item?.kd_status === 1"
                                    x-small
                                    fab
                                    icon
                                    min-width="25"
                                    color="green"
                                    :disabled="process.run"
                                    @click="detailDialogRiwayat(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="text-capitalize color-first pa-0 mx-1">
                                    <div class="d-flex align-center text-capitalize body-2">
                                      <img :src="require('@/assets/image/vector.png')" alt="">
                                    </div>
                                  </v-btn>
                                </template>
                                <span>Unggah bukti bayar</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    x-small
                                    fab
                                    icon
                                    min-width="22"
                                    color="#0299B7"
                                    :disabled="process.run"
                                    @click="nilai_parameter = 'pembayaran'; handleBeforeDownload(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="text-capitalize color-first pa-0 mx-1">
                                    <div class="d-flex align-center text-capitalize body-2">
                                      <img :src="require('@/assets/image/download_pdf.png')" alt="">
                                    </div>
                                  </v-btn>
                                </template>
                                <span>Invoice</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </v-skeleton-loader>
                      </v-col>
                      <v-col cols="12">
                        <div class="mt-5 pb-3 text-center">
                          <v-btn
                            large
                            width="120"
                            elevation="0"
                            class="text-capitalize mr-2"
                            :disabled="process.form"
                            @click="toHome()">
                            Batal
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="tab === 2">
                    <v-row class="mt-5">
                      <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                        <div class="text-left font-weight-600 title ml-2">
                          Pengembalian deposit
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          dense
                          :disabled="process.history_return"
                          :hide-details="true"
                          placeholder="Cari . . ."
                          clearable
                          color="#0299B7"
                          class="mx-3"
                          v-model="filter.history_return"
                          @click:clear="filter.history_return = ''; getReturnDeposit();"
                          v-on:keyup.enter="getReturnDeposit();"
                          prepend-inner-icon="mdi-magnify">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-skeleton-loader
                          :loading="process.history_return"
                          transition="scale-transition"
                          :tile="false"
                          :style="process.history_invoice ? 'height: calc(60vh - 305px)' : ''"
                          type="table-tbody">
                          <v-data-table
                            :headers="headers_pengembalian"
                            :items="row_pengembalian"
                            :items-per-page="10"
                            class="elevation-1 required">
                            <template v-slot:[`item.status`]="{ item }">
                              <td>
                                <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                              </td>
                            </template>
                            <template v-slot:[`item.aksi`]="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    x-small
                                    fab
                                    icon
                                    min-width="22"
                                    color="#0299B7"
                                    :disabled="process.run"
                                    @click="nilai_parameter = 'pengembalian'; handleBeforeDownload(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="text-capitalize color-first pa-0 mx-1">
                                    <div class="d-flex align-center text-capitalize body-2">
                                      <img :src="require('@/assets/image/download_pdf.png')" alt="">
                                    </div>
                                  </v-btn>
                                </template>
                                <span>Download pdf</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </v-skeleton-loader>
                      </v-col>
                      <v-col cols="12">
                        <div class="mt-5 pb-3 text-center">
                          <v-btn
                            large
                            width="120"
                            elevation="0"
                            class="text-capitalize mr-2"
                            :disabled="process.form"
                            @click="toHome()">
                            Batal
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div v-if="type.jenis === 2">
                  <div v-if="tab === 0">
                    <v-row class="mt-5 justify-end">
                      <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                        <div class="text-left font-weight-600 title ml-2">
                          Riwayat invoice
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          dense
                          :disabled="process.history_invoice"
                          :hide-details="true"
                          placeholder="Cari . . ."
                          clearable
                          color="#0299B7"
                          class="mx-3"
                          v-model="filter.history_invoice"
                          @click:clear="filter.history_invoice = ''; getHistoryInvoice();"
                          v-on:keyup.enter="getHistoryInvoice();"
                          prepend-inner-icon="mdi-magnify">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-skeleton-loader
                          :loading="process.history_invoice"
                          transition="scale-transition"
                          :tile="false"
                          :style="process.history_invoice ? 'height: calc(60vh - 305px)' : ''"
                          type="table-tbody">
                          <v-data-table
                            :headers="headers_riwayat_pengujian"
                            :items="row_riwayat"
                            :items-per-page="10"
                            class="elevation-1 required">
                            <template v-slot:[`item.status`]="{ item }">
                              <td>
                                <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                              </td>
                            </template>
                            <template v-slot:[`item.aksi`]="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="item?.kd_status === 1"
                                    x-small
                                    fab
                                    icon
                                    min-width="25"
                                    color="green"
                                    :disabled="process.run"
                                    @click="detailDialogRiwayat(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="text-capitalize color-first pa-0 mx-1">
                                    <div class="d-flex align-center text-capitalize body-2">
                                      <img :src="require('@/assets/image/vector.png')" alt="">
                                    </div>
                                  </v-btn>
                                </template>
                                <span>Unggah bukti bayar</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    x-small
                                    fab
                                    icon
                                    min-width="22"
                                    color="#0299B7"
                                    :disabled="process.run"
                                    @click="nilai_parameter = 'pengujian'; handleBeforeDownload(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="text-capitalize color-first pa-0 mx-1">
                                    <div class="d-flex align-center text-capitalize body-2">
                                      <img :src="require('@/assets/image/download_pdf.png')" alt="">
                                    </div>
                                  </v-btn>
                                </template>
                                <span>Invoice</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </v-skeleton-loader>
                      </v-col>
                      <v-col cols="12">
                        <div class="mt-5 pb-3 text-center">
                          <v-btn
                            large
                            width="120"
                            elevation="0"
                            class="text-capitalize mr-2"
                            :disabled="process.form"
                            @click="toHome()">
                            Batal
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="tab === 1">
                    <v-row class="mt-5">
                      <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                        <div class="text-left font-weight-600 title ml-2">
                          Pengembalian dana
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          dense
                          :disabled="process.history_fund"
                          :hide-details="true"
                          placeholder="Cari . . ."
                          clearable
                          color="#0299B7"
                          class="mx-3"
                          v-model="filter.history_fund"
                          @click:clear="filter.history_fund = ''; getReturnFund();"
                          v-on:keyup.enter="getReturnFund();"
                          prepend-inner-icon="mdi-magnify">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-skeleton-loader
                          :loading="process.history_fund"
                          transition="scale-transition"
                          :tile="false"
                          :style="process.history_fund ? 'height: calc(60vh - 305px)' : ''"
                          type="table-tbody">
                          <v-data-table
                            :headers="headers_pengembalian_dana"
                            :items="row_pengembalian_dana"
                            :items-per-page="10"
                            class="elevation-1 required">
                            <template v-slot:[`item.status`]="{ item }">
                              <td>
                                <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                              </td>
                            </template>
                            <template v-slot:[`item.aksi`]="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    x-small
                                    fab
                                    icon
                                    min-width="22"
                                    color="#0299B7"
                                    :disabled="process.run"
                                    @click="nilai_parameter = 'pengembalian'; handleBeforeDownload(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="text-capitalize color-first pa-0 mx-1">
                                    <div class="d-flex align-center text-capitalize body-2">
                                      <img :src="require('@/assets/image/download_pdf.png')" alt="">
                                    </div>
                                  </v-btn>
                                </template>
                                <span>Download pdf</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </v-skeleton-loader>
                      </v-col>
                      <v-col cols="12">
                        <div class="mt-5 pb-3 text-center">
                          <v-btn
                            large
                            width="120"
                            elevation="0"
                            class="text-capitalize mr-2"
                            :disabled="process.form"
                            @click="toHome()">
                            Batal
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="pdf.noInvoice"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf">
            <section slot="pdf-content">
              <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files"/>
            </section>
          </vue-html2pdf>
        </div>

        <div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="pdf.noInvoice"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf">
            <section slot="pdf-content">
              <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files"/>
            </section>
          </vue-html2pdf>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
const { VUE_APP_API_URL } = process.env;
import { TokenService } from '@/service/Storage.Service';
import { get } from "@/service/Axios";
import { serialize } from 'object-to-formdata';
import Pdf from "@/components/Pdf.vue";
import LoadingComponent from '@/components/Loading.vue'
import moment from 'moment';
import 'moment/locale/id';
import Snackbar from '@/components/Snackbar.vue';
import statusMixin from '@/mixin';
export default {
  mixins: [statusMixin],
  data() {
    return {
      addAll: false,
      files: null,
      dialog: {
        buat_invoice: false,
        detail_buat_invoice: false,
        riwayat_invoice: false,
        konfirmasi: false,
        download: false
      },
      detail: {},
      detail_riwayat: {},
      detailUuid: null,
      filename: '',
      form: {
        pembiyayaan: {
          dosen_pembimbing_pembiyayaan: '',
          dosen_freetext: '',
          lainnya: ''
        },
        upload_bukti_pembayaran: {
          uuid: '',
          item_now: false,
          tanggal_bayar: '',
          total_tagihan: '',
          nomor_rekening: '',
          bank: '',
          nama_pemilik_rekening: '',
          file: null,
          keterangan: '',
          no_invoice: '',
        },
      },
      fileSizeRule: (value) => {
        if (!value) {
          return true;
        }
        const size = value.size / 1024 / 1024;
        return size <= 5 ? true : 'Ukuran file tidak boleh lebih dari 5MB';
      },
      save_type: '',
      totalAmount: 0,
      isFlagInvoice: false,
      selected_table: [],
      snackbar: false,
      text: '',
      timeout: 3000,
      process: {
        run: false,
        create_invoice: false,
        history_invoice: false,
        history_return: false,
        history_fund: false,
        detail: false,
        form: false,
        loading: false
      },
      paymentSteps: {
        1: [
          { description: "<span>Pada tab Buat invoice, <b>pilih tagihan</b> yang ingin dibayarkan (dapat memilih lebih dari satu) kemudian pilih sumber pembiayaan.</span>" },
          { description: "<span><b>Klik buat tagihan</b>, akan muncul konfirmasi buat tagihan lalu klik Ya, buat tagihan. <br> Invoice akan dibuat dan dapat dilihat pada tab <b>Riwayat pembayaran</b></span>" },
          { description: "<span>Lakukan pembayaran sesuai nominal yang tertera di invoice ke <b>Bank Mandiri</b> dengan no rekening: <br> <b>1370007470210 (a.n Universitas Islam Indonesia - Lab Farmasi)</b> .</span>" },
          { description: "Unggah bukti pembayaran pada tab riwayat pembayaran." },
          { description: "Menunggu konfirmasi pembayaran oleh petugas." },
          { description: "Selesai." }
        ],
        2: [
          { description: "<span>Lakukan pembayaran sesuai nominal yang tertera di invoice ke <b>Bank Mandiri</b> dengan no rekening: <br> <b>1370007470210 (a.n Universitas Islam Indonesia - Lab Farmasi)</b> .</span>" },
          { description: "Unggah bukti pembayaran pada tab riwayat pembayaran." },
          { description: "Menunggu konfirmasi pembayaran oleh petugas." },
          { description: "Selesai." }
        ]
      },
      currentPage: 1,
      perPage: 10,
      list: {
        dosen: []
      },
      filter: {
        title: '',
        invoice: ''
      },
      nilai_parameter: '',
      headers_invoice: [
        { text: '', value: 'checkbox', checkbox: true },
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'Nama tagihan', value: 'nama_tagihan', sortable: false, },
        { text: 'Tagihan (Rp)', value: 'tagihan', sortable: false, align: 'right' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      headers_detail: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'Tanggal', value: 'tanggal', sortable: false, },
        { text: 'Jenis', value: 'jenis', sortable: false, },
        { text: 'Kode', value: 'kode',sortable: false, },
        { text: 'Nama', value: 'nama_barang',sortable: false, },
        { text: 'Pemakaian', value: 'pemakaian', sortable: false, align: 'right'},
        { text: 'Satuan', value: 'satuan',sortable: false, },
        { text: 'Harga satuan (Rp)', value: 'harga_satuan', sortable: false, align: 'right', width: '13%'},
        { text: 'Harga total (Rp)', value: 'harga_total', sortable: false, align: 'right'},
      ],
      headers_riwayat: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'No. invoice', value: 'no_invoice', sortable: false, },
        { text: 'Total tagihan (Rp)', value: 'total_tagihan', sortable: false, align: 'right' },
        { text: `Status pembayaran`, value: 'status', sortable: false, },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya', sortable: false, },
        { text: 'Tanggal pembayaran', value: 'tanggal_pembayaran', sortable: false, },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      headers_riwayat_pengujian: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'No. invoice', value: 'no_invoice', sortable: false, },
        { text: 'Total tagihan (Rp)', value: 'total_tagihan', sortable: false, align: 'right' },
        { text: 'Status pengembalian', value: 'status', sortable: false, },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya', sortable: false, },
        { text: 'Tanggal pembayaran', value: 'tanggal_pembayaran', sortable: false, },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      headers_pengembalian: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Nama', value: 'nama', sortable: false, },
        { text: 'Jenis', value: 'jenis_penelitian', sortable: false, },
        { text: 'Judul atau nama sampel', value: 'judul', sortable: false, },
        { text: 'No. invoice', value: 'no_invoice', sortable: false, },
        { text: 'Pengembalian deposit (Rp)', value: 'pengembalian_deposit',sortable: false, },
        { text: 'Status pengembalian', value: 'status', sortable: false, width: '12%' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      headers_pengembalian_dana: [
      {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'No. invoice', value: 'no_invoice', sortable: false, },
        { text: 'Pengembalian dana (Rp)', value: 'pengembalian_dana', sortable: false, },
        { text: 'Status pengembalian', value: 'status', sortable: false, },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya', sortable: false, },
        { text: 'Tanggal pengembalian', value: 'tanggal_pembayaran',sortable: false, },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      row_invoice: [],
      row_detail: [],
      row_riwayat: [],
      row_pengembalian: [],
      row_pengembalian_dana: [],
      selected_pembiyayaan: '1',
      sort: {
        selected_pembiyayaan: '1',
        status_pembiyayaan: [
          {
            id: '1',
            text: 'Saya sendiri'
          },
          {
            id: '2',
            text: 'Dosen pembimbing'
          },
          {
            id: '3',
            text: 'Lainnya'
          },
        ],
      },
      type: '',
      tab: null,
      items: [],
      
    }
  },
  components: {
    Pdf,
    LoadingComponent,
    Snackbar
  },
  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
    token () {
      return this.$store.state.access_token
    },
    user () {
      return this.$store.state.user
    },
    pdf () {
      return this.$store.state.data_pdf
    }
  },
  watch: {
    files: {
      handler: function () {
       this.dialog.download = this.files.add
      },
      deep: true
    },
  },
  mounted () {
    const data = JSON.parse(localStorage.getItem('filter'));
    this.type = data
    this.fetch()
  },
  methods: {
    fetch() {
      this.getFetch();
      this.getLecturer();
      const email = this.user.email;
      if (email.endsWith("uii.ac.id")) {
        if (this.type.jenis === 1) {
          this.items = ['Buat invoice', 'Riwayat invoice'];
        } else if (this.type.jenis === 2) {
          this.items = ['Riwayat invoice', 'Pengembalian dana'];
        }
      } else {
        this.items = ['Buat invoice', 'Riwayat invoice', 'Pengembalian deposit'];
      }
    },

    async getFetch() {
      try {
        this.process.run = true;
        this.process.loading = true;
        const uuid = TokenService.getUuid()
        let params;
        params = {
          uuid: uuid,
        };
        let endpoint = this.type.jenis === 1 ? 'research-payment' : 'test-payment';
        const response = await get(endpoint, { params });   
        if (response.status === 200) {    
          const res = response.data.data;
          this.detail = res[0];
          if (this.type.jenis === 1) {
            this.sort.selected_pembiyayaan = this.detail?.idJenisPembiayaan.toString()
            this.form.pembiyayaan.dosen_pembimbing_pembiyayaan = this.detail?.nikDosenPembiayaan.toString()
          }
          this.form.pembiyayaan.lainnya = this.detail?.namaPembiayaan
          this.process.run = false;
          this.process.loading = false;
        } else {
          this.process.run = false;
          this.process.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.process.run = false;
      } finally {
        this.getCreateInvoice();
        this.getHistoryInvoice();
        this.getReturnDeposit()
      }
    },

    async getCreateInvoice() {
      this.process.create_invoice = true
      const params = {
        uuid_penelitian: this.detail?.uuid,
        search: this.filter.invoice,
        flag_officer: 1,
      };
      try {
        const response = await get(`research-payment/bill`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.row_invoice = res.map((data, index) => {
            // const isInvoiceFlag = this.row_invoice.some((invoice) => invoice.flag_invoice === 1);
            // this.isFlagInvoice = isInvoiceFlag
            const totalTagihan = data.totalTagihan.toLocaleString('id-ID');
            return {
              no: index + 1,
              nama_tagihan: `${data.namaTagihan} ${data.namaDetailTagihan}`,
              tagihan: totalTagihan,
              uuid: data.uuid,
              flag_invoice: data.flagSudahInvoice,
            }
          });
          this.process.create_invoice = false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getDetailHistoryAddition() {
      this.process.detail = true;
      const params = {
        uuid: this.detailUuid,
        search: this.filter.history_detail
      }
      try {
        const response = await get(`research-usage/history-detail`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.row_detail = res.map((data, index) => {
            data.createdAt = this.$callbackdatetime(data.createdAt);
            const hargaSatuan = data.hargaSatuan.toLocaleString('id-ID');
            const hargaTotal = data.hargaTotal.toLocaleString('id-ID');
            return {
              no: index + 1,
              tanggal: data.createdAt + ' WIB',
              jenis: data.namaJenisBarang,
              kode: data.kdBarang,
              nama_barang: data.namaBarang,
              pemakaian: data.jlhPenggunaan,
              satuan: data.satuan,
              harga_satuan: hargaSatuan,
              harga_total: hargaTotal,
              uuid: data.uuid
            }
          });
          const total = response.data.total
          this.totalAmount = total
          this.process.detail = false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getHistoryInvoice() {
      this.process.history_invoice = true
      let keyName = '';
      if (this.type.jenis === 1) {
        keyName = 'uuid_penelitian';
      } else if (this.type.jenis === 2) {
        keyName = 'uuid_pengujian';
      }
      const params = {
        [keyName]: this.detail?.uuid,
        search: this.filter.history_invoice,
      };
      try {
        let endpoint = this.type.jenis === 1 ? 'research-payment/invoice' : 'test-payment/invoice';
        const response = await get(endpoint, { params });   
        if (response.status === 200) {
          const res = response.data.data
          if (this.type.jenis === 1) {
            this.row_riwayat = res.map((data, index) => {
              const tanggal_pembayaran = this.$callbackdate(data.tglPembayaran) || '-';
              const totalTagihan = data.totalTagihan.toLocaleString('id-ID');
              const status = data.statusPayment.statusPembayaran
              const proses_selanjutnya = data.statusPayment.prosesSelanjutnya
              const kd_status_pembayaran = data.statusPayment.kdStatusPembayaran
              return {
                no: index + 1,
                no_invoice: data.noInvoice,
                total_tagihan: totalTagihan,
                status: status,
                proses_selanjutnya: proses_selanjutnya,
                kd_status: kd_status_pembayaran,
                tanggal_pembayaran: tanggal_pembayaran,
                uuid: data.uuid
              }
            });
          } else if (this.type.jenis === 2) {
            this.row_riwayat = res.map((data, index) => {
              const tanggal_pembayaran = this.$callbackdate(data.tglPembayaran) || '-';
              const totalTagihan = data.totalTagihan.toLocaleString('id-ID');
              const status = data.statusPayment.statusPembayaran
              const proses_selanjutnya = data.statusPayment.prosesSelanjutnya
              const kd_status_pembayaran = data.statusPayment.kdStatusPembayaran
              return {
                no: index + 1,
                no_invoice: data.noInvoice,
                total_tagihan: totalTagihan,
                status: status,
                proses_selanjutnya: proses_selanjutnya,
                tanggal_pembayaran: tanggal_pembayaran,
                kd_status: kd_status_pembayaran,
                uuid: data.uuid
              }
            })
          }
         
          this.process.history_invoice = false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getDetailHistoryInvoice() {
      this.process.detail = true;
      const params = {
        uuid: this.detailUuid,
      }
      try {
        let endpoint = this.type.jenis === 1 ? 'research-payment/invoice/detail' : 'test-payment/bill'
        const response = await get(endpoint, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.detail_riwayat = this.type.jenis === 1 ? res : res
          console.log('hai', this.detail_riwayat);
          const total_tagihan =  this.type.jenis === 1 ? this.detail_riwayat.totalTagihan.toLocaleString('id-ID') : this.detail_riwayat.totalTagihanPengujian.toLocaleString('id-ID')
          this.form.upload_bukti_pembayaran.total_tagihan = total_tagihan
          this.form.upload_bukti_pembayaran.uuid = this.detail.uuid
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getReturnDeposit() {
      try {
        this.process.history_return = true
        let keyName = '';
        if (this.type.jenis === 1) {
          keyName = 'uuid_penelitian';
        } else if (this.type.jenis === 2) {
          keyName = 'uuid_pengujian';
        }
        const params = {
          [keyName]: this.detail?.uuid,
          search: this.filter.history_invoice,
        };
        const response = await get(`return-deposit`, { params })
        if (response.status === 200) {
          const res = response.data.data;
          this.process.history_return = false
          if (this.type.jenis === 1) {
              this.row_pengembalian = res.map((item, index) => {
              const totalTagihan = item?.nominalPengembalian.toLocaleString('id-ID');
              return {
                no: index + 1,
                nama: item?.nama,
                jenis_penelitian: item?.jenisPenggunaan,
                judul: item?.judul,
                no_invoice: item?.noInvoice,
                pengembalian_deposit: totalTagihan,
                status: item.statusPengembalian,
                uuid: item.uuid
              }
            })
          } else if (this.type.jenis === 2) {
            this.row_pengembalian_dana = res.map((item, index) => {
              const totalTagihan = item?.nominalPengembalian.toLocaleString('id-ID');
              const tanggal_pembayaran = this.$callbackdate(item.tglPembayaran) || '-';
              return {
                no: index + 1,
                no_invoice: item?.noInvoice,
                pengembalian_dana: totalTagihan,
                status: item.statusPengembalian,
                pengembalian_deposit: totalTagihan,
                proses_selanjutnya: item?.prosesSelanjutnya,
                tanggal_pembayaran: tanggal_pembayaran,
                uuid: item.uuid
              }
            })
          }
        
        } else {
          this.process.history_return = false
        }
      } catch (error) {
        console.log(error)
        this.process.history_return = false
      }
    },

    async getLecturer() {
        try {
        const response = await get(`lecturer`);
        if (response.status === 200) {
          const res = response.data.data;
          const dosen = res.map((item) => ({
            nama_dosen: item.namaDosen,
            id: item.nipDosen
          }));
          dosen.unshift({
            id: '0',
            nama_dosen: 'Tidak ada'
          }, {
            id: '999',
            nama_dosen: 'Lainnya'
          })
          this.list.dosen = dosen
        }
      } catch (error) {
        console.log(error);
      }
    },

    addAllInvoice() {
      this.selected_table = []
      if (this.addAll === true) {
        for (let i in this.row_invoice) {
          if (this.row_invoice[i].flag_invoice !== 1) {
            this.selected_table.push(
              this.row_invoice[i]
            )
          }
        }
      } else {
        this.selected_table = []
      }
    },

    detailDialog(data) {
      const { uuid } = data
      this.dialog.detail_buat_invoice = true
      this.detailUuid = uuid
      this.getDetailHistoryAddition()
    },

    detailDialogRiwayat(data) {
      const { uuid } = data
      this.dialog.riwayat_invoice = true
      this.detailUuid = uuid
      this.form.upload_bukti_pembayaran.tanggal_bayar = this.reminder
      this.getDetailHistoryInvoice()
    },

    changedate() {
      if (this.form.upload_bukti_pembayaran.tanggal_bayar === this.reminder) {
        this.formatDateNow()
      }
    },

    formatDateNow() {
      return this.form.upload_bukti_pembayaran.tanggal_bayar ? moment(this.form.upload_bukti_pembayaran.tanggal_bayar).format('DD MMMM YYYY') : ''
    },

    reset() {
      this.detailUuid = ''
      this.save_type = ''
      this.form = {
        pembiyayaan: {
          dosen_pembimbing_pembiyayaan: '',
          dosen_freetext: '',
          lainnya: ''
        },
        upload_bukti_pembayaran: {
          tanggal_bayar: '',
          total_tagihan: '',
          nomor_rekening: '',
          bank: '',
          nama_pemilik_rekening: '',
          file: null,
          keterangan: '',
          no_invoice: '',
        }
      }
    },
    
    async cekForm() {
      if (this.save_type !== 'creates') {
        const isValid = await this.$refs.observer.validate()
        this.dialog.konfirmasi = isValid 
      } else {
        this.dialog.konfirmasi = true
      }
     
    },

    save() {
      switch(this.save_type) {
        case 'creates':
          this.creates();
          break;
        case 'creates_payment':
          this.creates_payment();
          break;
        default:
      }
    },

    async creates() {
      this.process.form = true;
      this.process.create_invoice = true;
      this.process.history_invoice = true;
      const { token, sort, detail, form, list } = this
      const uuid_tagihan = this.selected_table.map(obj => obj.uuid);
      const nama_dosen = list.dosen.find(x => x.id === form.pembiyayaan.dosen_pembimbing_pembiyayaan)?.nama_dosen;
      const value = {
        uuid_penelitian: detail.uuid,
        uuid_tagihan,
        id_jenis_pembiayaan: sort.selected_pembiyayaan,
        nik_dosen_pembiayaan: form.pembiyayaan.dosen_pembimbing_pembiyayaan,
        nama_dosen_pembiayaan: nama_dosen,
        nama_pembiayaan: form.pembiyayaan.lainnya
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}research-payment/invoice`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.text = 'Data invoice berhasil disimpan';
        this.snackbar = true;
        this.process.form = false;
        this.process.create_invoice = false;
        this.process.history_invoice = false;
        this.getCreateInvoice();
        this.getHistoryInvoice();
        this.save_type = ''
        this.$refs.observer.reset()
      } else {
        this.dialog.konfirmasi = false;
      }
    },

    async creates_payment() {
      this.process.form = true;
      this.process.history_invoice = true;
      const { token, detailUuid, form, detail_riwayat } = this
      
      const value = {
        uuid: detailUuid,
        tgl_bayar: form.upload_bukti_pembayaran.tanggal_bayar,
        no_rekening: form.upload_bukti_pembayaran.nomor_rekening,
        nama_bank: form.upload_bukti_pembayaran.bank,
        nama_pemilik_rekening: form.upload_bukti_pembayaran.nama_pemilik_rekening,
        no_invoice: detail_riwayat.noInvoice,
        file_bukti_pembayaran: form.upload_bukti_pembayaran.file,
        keterangan: form.upload_bukti_pembayaran.keterangan,
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };
      
      const endpoint = this.type.jenis === 1 ? 'research-payment/invoice/payment' : 'test-payment/invoice/payment'
      const response = await fetch(`${VUE_APP_API_URL}${endpoint}`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.riwayat_invoice = false
        this.text = 'Data invoice berhasil disimpan';
        this.snackbar = true;
        this.process.form = false;
        this.process.history_invoice = false;
        this.getHistoryInvoice();
        this.$refs.observer.reset()
        this.save_type = ''
      } else {
        this.dialog.konfirmasi = false;
      }
    },

    handleBeforeDownload(data) {
      this.dialog.download = true
      this.getInvoicePrint(data, this.$refs.html2Pdf);
    },

    async getInvoicePrint(data, ref) {
      try {
        const params = {
          uuid: data.uuid,
          no_invoice: data.no_invoice
        };
        const pengembalian = this.nilai_parameter === 'pembayaran'

        let response;
        let endpoint;
        if (this.type.jenis === 1) {
          if (pengembalian) {
          endpoint = 'research-payment/invoice/print'
          response = await get(endpoint, {params});
          } else {
            response = await get(`return-deposit`, {params});
          }
        } else if (this.type.jenis === 2) {
          endpoint = 'test-payment/invoice/print'
          response = await get(endpoint, {params});
        }
       
        if (response.status === 200) {
          const res = response.data.data
          this.$store.dispatch('getDataPdf', res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.pdf.downloadPdf(data, ref);
      }

    },

    toHome() {
      TokenService.removeUUID(TokenService.getUuid())
      this.$router.push(`/pembayaran`)
    },

  },
}
</script>

<style>
.v-data-table .v-data-footer .v-select__selections .v-btn {
  background-color: 0299B7;
  color: white;
}
.background-warning {
  background: #FFF59D;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.v-input__prepend-outer {
  display: none;
}
</style>