<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <v-row class="mt-3 px-3">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                  Persetujuan
                </div>
                <!-- row filter -->
                <v-row class="pt-5">
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.lab"
                      item-text="nama_laboratorium"
                      item-value="id"
                      v-model="form.filter.lab"
                      v-on:change="onSelectLab($event)"
                      label="Laboratorium"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.status"
                      item-text="status_verifikasi_user"
                      item-value="id"
                      v-model="form.filter.status"
                      v-on:change="onSelectStatus($event)"
                      label="Status"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- end row filter -->

                <v-card-text class="pa-5 px-0" style="height: calc(100vh - 145px);">
                  <v-skeleton-loader
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                    type="table-tbody">
                    <v-data-table
                      :headers="headers"
                      :items="rows"
                      :items-per-page="10"
                      class="elevation-1 required">
                      <template v-slot:[`item.status`]="{ item }">
                        <td>
                          <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                        </td>
                      </template>
                      <template v-slot:[`item.aksi`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="#0299B7"
                              :disabled="process.run"
                              @click="toPage(item, 'detail')"
                              class="text-capitalize color-first pa-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Detail</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="orange"
                              :disabled="process.run"
                              :loading="process.run"
                              @click="toPage(item, 'edit')"
                              class="text-capitalize color-first pa-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-skeleton-loader>
                </v-card-text>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get } from '@/service/Axios'
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
import { TokenService } from "@/service/Storage.Service";

export default {
  mixins: [statusMixin],
  data() {
    return {
      detailUuid: '',
      detail: {},
      dialog: {
        konfirmasi: false,
        detail: false
      },
      form: {
        dialog: {
          catatan: ''
        },
        filter: {
          lab: '',
          status: '',
        },
      },
      filter: {
        title: ''
      },
      id: '',
      rows: [],
      headers: [
        { text: 'No.', value: 'no', sortable: false },
        { text: 'Nama', value: 'nama', sortable: false, },
        { text: 'Judul atau nama sampel', value: 'nama_sampel', sortable: false, },
        { text: 'Tanggal penggunaan', value: 'tanggal_penggunaan', sortable: false, },
        { text: 'Status', value: 'status', sortable: false, width: '13%' },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya', sortable: false, width: '11%' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      process: {
        run: false,
        form: false
      },
      list: {
        lab: [],
        status: []
      },
      text_confirmed: '',
      save_type: '',
      type: '',
      switch_status: null,
      hasDetail: false,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },

  components: {
    Snackbar,
  },

  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },

  mounted () {
   this.fetch()
  },

  methods: {
    async fetch() {
      this.process.run = true
      const data = JSON.parse(localStorage.getItem('filter'))
      if (data) {
        const { form } = this
        this.hasDetail = true
        form.filter.lab = data.lab
        form.filter.status = data.status
        localStorage.removeItem("filter");
      }
      this.getLaboratory()
    },
    
    async getLaboratory() {
      try {
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          labList.unshift({
            id: 'all',
            kd_laboratorium: 'all',
            nama_laboratorium: 'Semua',
          })
          this.list.lab = labList
          this.form.filter.lab = this.hasDetail ?  this.form.filter.lab :  this.form.filter.lab === undefined ? labList[0].id : labList[0].id;
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.getStatus()
      }
    },

    async getStatus() {
     try {
      const response = await get(`verification-user-status`)
      if (response.status === 200) {
        const res = response.data.data;
        this.process.run = false
        const status = res.map((el) => ({
          id: el.id,
          kd_status_verifikasi_user: el.kdStatusVerifikasiUser,
          status_verifikasi_user: el.statusVerifikasiUser,
          proses_selanjutnya: el.prosesSelanjutnya
        }))
        status.unshift({
          id: 'all',
          kd_status_verifikasi_user: 'all',
          status_verifikasi_user: 'Semua',
        })
        this.list.status = status
        this.form.filter.status = this.hasDetail ?  this.form.filter.status :  this.form.filter.status === undefined ? status[0].id : status[0].id;

      }
     } catch (error) {
        console.log(error) 
     } finally {
        this.getList()
     }
    },

    async getList() {
      try {
        const { filter, form } = this
        const params = {
          id_laboratorium: form.filter.lab,
          id_status_verifikasi: form.filter.status,
          flag_officer: '1',
          search: filter.title,
        }
        const response = await get(`test`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          this.rows = res.map((item, index) => {
            return {
              no: index + 1,
              nama: item?.user.nama || '-',
              nama_sampel: item?.namaSampel || '-',
              tanggal_penggunaan: item?.email || '-',
              status: item?.statusSubmission.statusPengajuan || '-',
              kd_status_pengajuan: item?.statusSubmission.kdStatusPengajuan || '-',
              proses_selanjutnya: item?.statusSubmission.prosesSelanjutnya || '-',
              uuid: item?.uuid || '-'
            }
          })
        } else {
          this.process.run = false
        }
      } catch (error) {
        console.log(error)
        this.process.run = false
      }
    },

    onSelectLab(val) {
      this.form.filter.lab = val
      this.process.run = true
      this.getList()
    },

    onSelectStatus(val) {
      this.form.filter.status = val
      this.process.run = true
      this.getList()
    },

    toPage(val, page) {
      try {
      const { form } = this
      const data = {
        lab: form.filter.lab,
        status: form.filter.status,
      }
      localStorage.setItem('filter', JSON.stringify(data))
        TokenService.setUuid(val.uuid);
        const path = page === 'edit' ? 'edit' : 'detail';
        this.$router.push(`/persetujuan-kalab-lpmok/${path}`);
      } catch (error) {
        console.error(error);
      }
    },
  }
};
</script>

<style lang="scss" scoped>

</style>