import Vue from 'vue'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 0,
	autoDecimalMode: false,
	min: null,
	max: null,
	defaultValue: null,
	valueAsInteger: false,
	allowNegative: false
})