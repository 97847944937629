<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
       <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.penggunaan = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.run"
            :disabled="process.run"
            @click="save">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog waktu penggunaan -->
    <v-dialog v-model="dialog.penggunaan" persistent width="750">
      <ValidationObserver ref="observer">
        <v-form>
          <v-card>
            <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
              <span>Ubah waktu penggunaan</span>
              <v-spacer />
                <v-btn
                  color="#0299B7"
                  small
                  icon
                  elevation="0"
                  fab
                  class="text-capitalize color-first"
                  @click="reset(); dialog.penggunaan = false">
                  <v-icon small color="#FFFFFF">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <ValidationProvider
                    name="Lab">
                    <v-text-field
                      dense
                      disabled
                      filled
                      outlined
                      label="Lab"
                      color="#0299B7"
                      v-model="form.dialog.lab">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="3" class="pt-0">
                  <v-menu
                    ref="menustart"
                    v-model="form.dialog.item_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    class="z-my"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <ValidationProvider
                        name="Tanggal mulai"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          :value="formatDateStart()"
                          label="Tanggal mulai"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form.dialog.start_date"
                      format="D Month, Yr"
                      :min="reminder" 
                      v-on:change="startdate()" 
                      color="#0299B7"
                      @input="form.dialog.item_start = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3" class="pt-0">
                  <v-menu
                    ref="menuend"
                    v-model="form.dialog.item_end"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    class="z-my"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <ValidationProvider
                        name="Tanggal selesai"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          label="Tanggal selesai"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          :value="formatDateEnd()"
                          dense
                          hide-details
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker 
                      v-model="form.dialog.end_date"
                      format="D Month, Yr"
                      :min="form.dialog.start_date" 
                      v-on:change="enddate()" 
                      color="#0299B7"
                      @input="form.dialog.item_end = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3" class="pt-0">
                  <v-menu
                    ref="menuTimeStart"
                    v-model="form.dialog.start_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.dialog.time_start"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.dialog.time_start"
                        label="Jam mulai (setiap hari)(optional)"
                        prepend-inner-icon="mdi-clock"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        hide-details
                        color="#0299B7"
                        v-on="on"
                        :disabled="form.dialog.start_date === ''">
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="form.dialog.start_time"
                      v-model="form.dialog.time_start"
                      full-width
                      format="24hr"
                      color="#0299B7"
                      @click:minute="$refs.menuTimeStart.save(form.dialog.time_start)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3" class="pb-0 pt-0">
                  <v-menu
                    ref="menuTimeEnd"
                    v-model="form.dialog.end_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.dialog.time_end"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.dialog.time_end"
                        label="Jam selesai (setiap hari)(optional)"
                        prepend-inner-icon="mdi-clock"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        hide-details
                        color="#0299B7"
                        v-on="on"
                        :disabled="form.dialog.start_date === ''">
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="form.dialog.end_time"
                      v-model="form.dialog.time_end"
                      full-width
                      format="24hr"
                      color="#0299B7"
                      @click:minute="$refs.menuTimeEnd.save(form.dialog.time_end)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" class="pb-0">
                  <ValidationProvider
                    name="Alat"
                    rules="required"
                    v-slot="{ errors }">
                    <v-combobox
                      dense
                      v-model="form.dialog.alat"
                      label="Alat"
                      color="#0299B7"
                      :items="list.alat"
                      item-text="nama_alat"
                      item-value="id"
                      multiple
                      chips
                      item-closeable
                      :error-messages="errors"
                      outlined>
                    </v-combobox>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Bahan">
                    <v-combobox
                      dense
                      v-model="form.dialog.bahan"
                      :items="list.bahan"
                      label="Bahan (Optional)"
                      color="#0299B7"
                      item-text="nama_bahan"
                      item-value="id"
                      chips
                      multiple
                      outlined>
                    </v-combobox>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Instrumen">
                    <v-combobox
                      dense
                      v-model="form.dialog.instrumen"
                      :items="list.instrumen"
                      label="Instrumen (Optional)"
                      color="#0299B7"
                      item-text="nama_instrumen"
                      item-value="id"
                      chips
                      multiple
                      outlined>
                    </v-combobox>
                  </ValidationProvider>
                </v-col>

              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="mt-2 pb-3 justify-center">
              <v-btn
                outlined
                class="text-capitalize color-first mx-1"
                :disabled="process.dialog"
                @click="dialog.penggunaan = false;">
                Batal
              </v-btn>
              <v-btn
                color="#0299B7"
                elevation="0"
                class="text-capitalize white--text mx-1"
                :loading="process.dialog"
                :disabled="process.dialog"
                @click="saveDialog">
                Ya, simpan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
    <!-- end waktu penggunaan -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <div  v-if="!process.loading">
      <v-row class="mt-3 px-3">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div class="color-first title font-weight-bold title mt-3 pb-2">
                    Data diri
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Nama lengkap</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.nama }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Nomor Mahasiswa/Kepegawaian</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.noInduk}}</td>
                        </tr>
                        <tr>
                          <td width="30%">No. Whatsapp aktif</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.noHp }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Instansi</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaInstitusi }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Perguruan tinggi</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaPerguruanTinggi }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Fakultas</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaFakultas || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Program studi</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaProgramStudi || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Jenis penggunaan
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Jenis</td>
                          <td width="1%">:</td>
                          <td>{{ detail.researchType?.jenisPenelitian || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Keterangan</td>
                          <td width="1%">:</td>
                          <td>{{ detail.keteranganJenisPenelitian || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Judul
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Judul</td>
                          <td width="1%">:</td>
                          <td>{{detail.judul}}</td>
                        </tr>
                        <tr>
                          <td width="30%">Dosen pembimbing/Ketua peneliti</td>
                          <td width="1%">:</td>
                          <td>{{ detail.namaDosen }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Judul payung</td>
                          <td width="1%">:</td>
                          <td> {{ detail.judulPayung ? detail.judulPayung : '-'}} </td>
                        </tr>
                        <tr v-if="detail.user?.kdProgramStudi === '613'">
                          <td width="30%">Peta penelitian</td>
                          <td width="1%">:</td>
                          <td>{{ detail.map?.petaPenelitian || '-' }}</td>
                        </tr>
                        <tr v-if="isUii && detail.researchType?.kdJenisPenelitian !== '2'">
                          <td width="30%" class="td-flex">Surat persetujuan Dosen pembimbing/Ketua peneliti</td>
                          <td width="1%">:</td>
                          <td>
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header color="#0299B7">
                                  <template v-slot:actions>
                                    <v-icon color="white">
                                      $expand
                                    </v-icon>
                                  </template>
                                  <span class="white--text">Surat persetujuan Dosen pembimbing/Ketua peneliti</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <iframe 
                                    width="100%" 
                                    :height="this.file === null ? '110px' : '500px'" 
                                    :src="this.file" 
                                    :lazy-src="this.file"
                                    frameborder="0"
                                    allowfullscreen>
                                    Oops! an error has occurred.
                                  </iframe>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </td>
                        </tr>

                        <tr v-if="!isUii && detail.researchType?.kdJenisPenelitian !== '2'">
                          <td width="30%" class="td-flex">Surat proposal</td>
                          <td width="1%">:</td>
                          <td>
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header color="#0299B7">
                                  <template v-slot:actions>
                                    <v-icon color="white">
                                      $expand
                                    </v-icon>
                                  </template>
                                  <span class="white--text">Surat proposal</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <iframe 
                                    width="100%" 
                                    :height="this.fileProposal === null ? '110px' : '500px'" 
                                    :src="this.fileProposal" 
                                    :lazy-src="this.fileProposal"
                                    frameborder="0"
                                    allowfullscreen>
                                    Oops! an error has occurred.
                                  </iframe>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div v-if="detail.researchType?.kdJenisPenelitian !== '2'">
                    <div class="color-first title font-weight-bold py-2 mt-5">
                      Sertifikat pelatihan safety lab
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%"></td>
                            <td width="1%"></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td width="30%" class="td-flex">Sertifikat pelatihan safety lab</td>
                            <td width="1%">:</td>
                            <td>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header color="#0299B7">
                                    <template v-slot:actions>
                                      <v-icon color="white">
                                        $expand
                                      </v-icon>
                                    </template>
                                    <span class="white--text">Sertifikat pelatihan safety lab</span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <iframe 
                                      width="100%" 
                                      :height="this.fileSafety === null ? '110px' : '500px'" 
                                      :src="this.fileSafety" 
                                      :lazy-src="this.fileSafety"
                                      frameborder="0"
                                      allowfullscreen>
                                      Oops! an error has occurred.
                                    </iframe>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="d-flex justify-space-between align-center">
                    <div class="color-first title font-weight-bold py-2 mt-5 mb-2">
                      <span> {{ detail.researchType?.kdJenisPenelitian !== '2' ? 'Rencana penggunaan' : 'Tempat penelitian' }}</span>
                    </div>
                    <div v-if="isEdit">
                      <v-btn
                      large
                      color="#0299B7"
                      elevation="0"
                      @click="changeTimeUsage()"
                      class="white--text text-capitalize">
                      <v-icon class="mr-2">mdi-calendar-text</v-icon>
                        Ubah waktu penggunaan
                      </v-btn>
                    </div>
                  </div>
                 
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Lab</td>
                          <td width="1%">:</td>
                          <td>{{ detail.lab?.namaLaboratorium || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Waktu</td>
                          <td width="1%">:</td>
                          <td>{{ detail.tglMulai | date }} - {{ detail.tglSelesai | date }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Jam (setiap hari)</td>
                          <td width="1%">:</td>
                          <td>{{ detail.jamMulai || '-' }} - {{ detail.jamSelesai || '-' }} WIB </td>
                        </tr>
                        <tr>
                          <td width="30%">Alat</td>
                          <td width="1%">:</td>
                          <td>
                            <span v-if="detail.researchLabTool && detail.researchLabTool.length > 0">
                              <span v-for="(alat, i) in detail.researchLabTool" :key="i">
                                <span v-if="i !== alat.length - 1">{{ alat.tool?.namaAlat }}, </span>
                              </span>
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">Bahan</td>
                          <td width="1%">:</td>
                          <td>
                            <span v-if="detail.researchLabItem && detail.researchLabItem.length > 0">
                              <span v-for="(bahan, i) in detail.researchLabItem" :key="'A'+i">
                                <span v-if="i !== bahan.length - 1">{{ bahan.item?.namaBahan }}, </span>
                              </span>
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">Instrumen</td>
                          <td width="1%">:</td>
                          <td>
                            <span v-if="detail.researchLabInstrument && detail.researchLabInstrument.length > 0">
                              <span v-for="(instrument, i) in detail.researchLabInstrument" :key="'B'+i">
                                {{ instrument.instrument?.namaInstrumen || '-' }}
                                <span v-if="i !== detail.researchLabInstrument.length - 1">, </span>
                              </span>  
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Pembiayaan
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Oleh</td>
                          <td width="1%">:</td>
                          <td>{{ detail.paymentType?.jenisPembiayaan }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Sumber pendanaan</td>
                          <td width="1%">:</td>
                          <td>{{ detail.namaDosenPembiayaan || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <!-- history -->
                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Riwayat
                  </div>
                  <v-timeline 
                    align-top
                    dense>
                    <v-timeline-item
                      small 
                      color="#0299B7"
                      v-for="(item, j) in detail.researchHistory" :key="j">
                      <v-row>
                        <v-col>
                          <span class="color-first body-2">{{item.createdAt | datetime}} WIB</span>
                          <div class="caption font-weight-bold"> {{item.statusSubmission.statusPengajuan}} </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                  <!-- end history -->

                  <!-- persetujuan -->
                  <div class="color-first title font-weight-bold py-2 mt-5" v-if="isEdit">
                    Persetujuan
                  </div>
                  <v-row v-if="isEdit">
                    <v-col cols="12" md="12" class="pt-0">
                      <v-radio-group v-model="sort.selected_persetujuan">
                        <v-radio
                          v-for="(status, index) in sort.list_persetujuan"
                          :key="index" 
                          :label="status.text"
                          :value="status.id"
                          color="#0499b7"
                          class="py-0">
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="sort.selected_persetujuan == '2'">
                    <v-text-field
                      dense
                      outlined
                      label="Catatan (Optional)"
                      color="#0299B7"
                      v-model="form.persetujuan.catatan">
                    </v-text-field>
                  </v-row>
                  <!-- end persetujuan -->
                </v-card-text>
                <v-divider class="mt-8 mb-3"></v-divider>
                <!-- row button -->
                <v-card-actions class="mt-8 pb-8 justify-center">
                  <v-btn
                    large
                    width="120"
                    elevation="0"
                    :disabled="process.run"
                    class="text-capitalize"
                    @click="toHome()">
                    Batal
                  </v-btn>
                  <v-btn
                    v-if="isEdit"
                    large
                    width="120"
                    color="#0299B7"
                    elevation="0"
                    :disabled="process.run"
                    @click="dialog.konfirmasi = true"
                    class="white--text text-capitalize">
                    Simpan
                  </v-btn>
                </v-card-actions>
                <!-- end row button  -->
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { get, put } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import moment from 'moment';
import 'moment/locale/id';
import LoadingComponent from '@/components/Loading.vue'
import Snackbar from '@/components/Snackbar.vue';
export default {
  data() {
    return {
      detail: {},
      dialog: {
        konfirmasi: false,
        penggunaan: false
      },
      isEdit: false,
      isUii: false,
      file: '',
      fileSafety: '',
      fileProposal: '',
      form: {
        dialog: {
          lab: '',
          start_date: '',
          end_date: '',
          item_start: false,
          item_end: false,
          time_start: '',
          time_end: '',
          start_time:false,
          end_time:false,
          end_time_min: '',
          start_time_min: '',
          alat: [],
          bahan: [],
          instrumen: []
        },
        persetujuan: {
          catatan: ''
        }
      },
      list: {
        alat: [],
        bahan: [],
        instrumen: [],
      },
      process: {
        run: false,
        dialog: false,
        loading: false
      },
      sort: {
        selected_persetujuan: '1',
        list_persetujuan: [
          {
            id: '1',
            text: 'Disetujui'
          },
          {
            id: '2',
            text: 'Ditolak'
          },
        ]
      },
      snackbar: false,
      text: '',
      timeout: 4000,
    }
  },
  components: {
    LoadingComponent,
    Snackbar
  },
  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    },
    roles () {
      return this.$store.state.roles
    }
  },
  mounted () {
    if (TokenService.getUuid() === null) {
      this.$router.push(`/persetujuan-laboran`)
    } else {
      const params = this.$route.params.page
      if (params.endsWith("edit")) {
        this.isEdit = true
      } else { 
        this.isEdit = false
      }
      this.fetch();
    }
  },
  methods: {
    async fetch() {
      try {
        this.process.run = true
        this.process.loading = true
        const uuid = TokenService.getUuid()
        const params = {
          uuid: uuid,
          flag_officer: '1'
        }
        const response =  await get(`research`, {params})
        if (response.status === 200) {
          const res = response.data.data
          this.detail = res[0];
          const email = this.detail.user.email
          if (email.endsWith("uii.ac.id")) {
            this.isUii = true
          } else {
            this.isUii = false
          }
          this.process.run = false
          this.process.loading = false
        } else {
          this.process.run = false
          this.process.loading = false
        }
      } catch (error) {
        console.log()
      } finally {
        this.getFile()
        this.getFileSafety()
        this.getFileProposal()
      }
    },

    getFile() {
      const params = {
        url: this.detail.filePersetujuanDosen
      }
      get(`file`, {params}).then((response) => {
        if (response.status === 200) {
          let res = response.data
          this.file = res.data
        }
      })
    },

    getFileSafety() {
      const params = {
        url: this.detail.fileSertifikatPelatihan
      }
      get(`file`, {params}).then((response) => {
        if (response.status === 200) {
          let res = response.data
          this.fileSafety = res.data
        }
      })
    },

    getFileProposal() {
      const params = {
        url: this.detail.fileProposal
      }
      get(`file`, {params}).then((response) => {
        if (response.status === 200) {
          let res = response.data
          this.fileProposal = res.data
        }
      })
    },

    startdate() {
      let today = new Date()
      let time = today.getHours()+ ":" + today.getMinutes()+ ":" + today.getSeconds();
      if (this.form.dialog.start_date === this.reminder) {
          this.form.dialog.start_time_min = time
      } else {
        this.form.dialog.start_time_min = ''
      }
      this.form.dialog.time_start = ''
      this.form.dialog.end_date = ''
      this.form.dialog.time_end = ''
      this.formatDateStart()
    },

    enddate() {
      if (this.form.dialog.end_date ===  this.form.dialog.start_date) {
        this.form.dialog.end_time_min = this.form.dialog.start_date
      } else {
        this.form.dialog.end_time_min = ''
      }
      this.formatDateEnd()
    },

    formatDateStart() {
      return this.form.dialog.start_date  ? moment(this.form.dialog.start_date ).format('DD MMMM YYYY') : ''
    },

    formatDateEnd() {
      return this.form.dialog.end_date  ? moment(this.form.dialog.end_date ).format('DD MMMM YYYY') : ''
    },

    changeTimeUsage() {
      this.dialog.penggunaan = true
      this.form.dialog.lab = this.detail.lab.namaLaboratorium
      this.form.dialog.start_date = this.detail.tglMulai
      this.form.dialog.end_date = this.detail.tglSelesai
      this.form.dialog.time_start = this.detail.jamMulai
      this.form.dialog.time_end = this.detail.jamSelesai
      let dataAlat = this.detail.researchLabTool
      let dataBahan = this.detail.researchLabItem
      let dataInstrumen = this.detail.researchLabInstrument

      for (let i = 0; i < dataAlat.length; i++) {
        const element = dataAlat[i];
        this.list.alat.push({
          id: element.tool.id,
          nama_alat: element.tool.namaAlat,
        })
        this.form.dialog.alat.push({
          id: element.tool.id,
          nama_alat: element.tool.namaAlat,
        })
      }

      for (let i = 0; i < dataBahan.length; i++) {
        const element = dataBahan[i];
        this.list.bahan.push({
          id: element.item.id,
          kd_bahan: element.item.kdBahan,
          nama_bahan: element.item.namaBahan,
        })
        this.form.dialog.bahan.push({
          id: element.item.id,
          kd_bahan: element.item.kdBahan,
          nama_bahan: element.item.namaBahan,
        })
      }

      for (let i = 0; i < dataInstrumen.length; i++) {
        const element = dataInstrumen[i];
        this.list.instrumen.push({
          id: element.instrument.id,
          kd_instrumen: element.instrument.kdInstrumen,
          nama_instrumen: element.instrument.namaInstrumen,
          id_laboratorium: element.instrument.idLaboratorium
        })
        this.form.dialog.instrumen.push({
          id: element.instrument.id,
          kd_instrumen: element.instrument.kdInstrumen,
          nama_instrumen: element.instrument.namaInstrumen,
          id_laboratorium: element.instrument.idLaboratorium
        })
      }
    },

    async saveDialog() {
      const alat = []
      const bahan = []
      const instrumen = []
      this.form.dialog.alat.forEach(element => {
        alat.push({
          id_alat: element.id
        })
      });
      this.form.dialog.bahan.forEach(element => {
        bahan.push({
          id_bahan: element.id
        })
      });
      this.form.dialog.instrumen.forEach(element => {
        instrumen.push({
          id_instrumen: element.id
        })
      });
      const params = {
        tgl_mulai: this.form.dialog.start_date,
        tgl_selesai:  this.form.dialog.end_date,
        jam_mulai:  this.form.dialog.time_start,
        jam_selesai:  this.form.dialog.time_end,
        alat,
        bahan,
        instrumen,
        uuid_penelitian: this.detail.uuid
      }
      const isValid = this.$refs.observer.validate()
      if (isValid) {
        this.process.dialog = true
        await put (`research/update-usage-period`, {params}).then(response => {
          if (response.status == 201) {
            this.dialog.penggunaan = false
            this.text = 'Data berhasil ditambah'
            this.snackbar = true
            this.process.dialog = false
          } else {
            this.dialog.penggunaan = false
          }
        });
      } else {
        this.dialog.konfirmasi = false
      }
    },

    save() {
      this.process.run = true
      const params = {
        uuid_penelitian: this.detail.uuid,
        role: this.roles,
        flag_persetujuan: this.sort.selected_persetujuan,
        catatan: this.form.persetujuan.catatan
      }
      put(`research/verification`, {params}).then((response) => {
        if (response.status === 201) {
          this.process.run = false
          this.text = 'Data berhasil diubah'
          this.snackbar = true
          this.$router.push(`/persetujuan-laboran`)
        }
      })
    },
    reset() {
      this.$refs.observer.reset();
    },
    toHome() {
      this.$router.push(`/persetujuan-laboran`)
    }
  },
}
</script>