<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.run"
            :disabled="process.run"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog survei -->
    <v-dialog
      v-model="dialog.survei"
      scrollable
      persistent      
      width="1000"
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Survei</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="toPage(); reset()">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="text-center my-3">
          <v-progress-circular
            v-if="process.loding_question"
            :size="90"
            :width="8"
            indeterminate
            color="#0299B7">
          </v-progress-circular>
        </div>
        <v-card-text class="px-8" v-if="!process.loding_question">
          <v-row class="text-center mb-3">
            <v-col cols="12">
              <span class="font-weight-bold title">Survei Pengujian</span><br>
              <v-divider class="mt-4"></v-divider>
            </v-col>
          </v-row>
          <ValidationObserver ref="observer">
            <v-form>
              <v-row v-for="(item, i) in data_survei" :key="i" class="mt-4 mb-8">
                <v-col cols="12" class="font-weight-bold pt-0 pb-0 body-1">
                  <span>{{ i + 1 }}. </span><span>{{ item.pertanyaan }}</span>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="item.jawaban.kdJawaban === 'PG'">
                  <ValidationProvider name="Pilihan Jawaban" rules="required" v-slot="{ errors }">
                    <v-radio-group
                      :row="$vuetify.breakpoint.smAndUp"
                      :column="$vuetify.breakpoint.xs"
                      v-model="form.dialog.jawaban_pg[i]"
                      hide-details>
                      <v-radio
                        color="#0499b7"
                        class="py-0 mt-0"
                        :error-messages="errors"
                        v-for="(jawaban, j) in item.jawaban.answerRange"
                        :key="j"
                        :label="jawaban.jawaban"
                        :value="jawaban.uuid"
                      ></v-radio>
                    </v-radio-group>
                    <span v-if="errorVisible[i] === false" class="caption text-left mt-2 red--text">Pilihan jawaban harus diisi</span>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pb-0" v-if="item.jawaban.kdJawaban === 'IS'">
                  <ValidationProvider
                    name="Usulan"
                    :rules="item.flag_opsional === 1 ? '' : 'required'"
                    v-slot="{ errors }">
                    <v-text-field
                      color="#0299B7"
                      v-model="form.dialog.isian[i]" 
                      label="Usulan (optional)" 
                      :error-messages="errors"
                      outlined>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0" v-if="item.jawaban.kdJawaban === 'PG'">
                  <v-text-field 
                    color="#0299B7"
                    v-model="form.dialog.komentar[i]" 
                    label="Komentar (optional)" 
                    outlined>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run || process.loding_question"
            @click="toPage(); reset()">
            Tutup
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :disabled="process.run || process.loding_question"
            @click="cekForm()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog survei -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <ValidationObserver ref="observer2">
            <v-form>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-card-text>
                    <div>
                      <v-row v-if="isDokumenTambahan === 16">
                        <v-col cols="12" class="pb-0">
                          <div class="color-first title font-weight-bold mt-2 pb-1">
                            <span>Lainnya</span>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            class="mt-0 switch"
                            v-model="switch_status"
                            color="#0499b7"
                            value = "true"
                            label="Ajukan dokumen tambahan"
                            hide-details
                          ></v-switch>
                        </v-col>
                        <v-col cols="12" v-if="switch_status === 'true'">
                          <div class="mt-4">
                            <ValidationProvider
                              name="Nama dokumen"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Nama dokumen"
                                color="#0299B7"
                                v-model="form.nama_dokumen"
                                :error-messages="errors">
                              </v-text-field>
                            </ValidationProvider>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row v-else class="mt-5">
                        <v-col cols="12" class="pb-0">
                          <div class="color-first title font-weight-bold mt-2 pb-1">
                            <span>Dokumen tambahan</span>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                            <tr>
                              <td width="20%">Tanggal permintaan</td>
                              <td width="1%">:</td>
                              <td>{{ detail?.tglRequestDokumenTambahan | datetime }} WIB</td>
                            </tr>
                            <tr>
                              <td width="20%">Nama dokumen</td>
                              <td width="1%">:</td>
                              <td>{{ detail?.deskripsiRequestDokumenTambahan }}</td>
                            </tr>
                          </table>
                        </v-col>
                        <v-col cols="12">
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header color="#0299B7">
                                <template v-slot:actions>
                                  <v-icon color="white">
                                    $expand
                                  </v-icon>
                                </template>
                                <span class="white--text">Contoh berkas</span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <iframe 
                                  width="100%" 
                                  :height="this.file === null ? '110px' : '500px'" 
                                  :src="this.file" 
                                  :lazy-src="this.file"
                                  frameborder="0"
                                  allowfullscreen>
                                  Oops! an error has occurred.
                                </iframe>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <v-divider></v-divider>
          <!-- row simpan -->
          <v-card-actions class="mt-4 justify-center">
            <v-btn
              large
              width="120"
              elevation="0"
              :disabled="process.run"
              class="text-capitalize"
              @click="toPage()">
              <span v-if="isDokumenTambahan !== 16">Batal</span>
              <span v-else>Kembali</span>
            </v-btn>
            <v-btn
              v-if="isDokumenTambahan === 16"
              large
              width="120"
              color="#0299B7"
              elevation="0"
              :disabled="switch_status !== 'true'"
              :loading="process.run"
              @click="save_type = 'ajukan'; cekForm();"
              class="white--text text-capitalize">
              Ajukan
            </v-btn>
          </v-card-actions>
          <!-- end row -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import { serialize } from 'object-to-formdata';
import Snackbar from '@/components/Snackbar.vue';
import LoadingComponent from '@/components/Loading.vue'
import { get, put } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
export default {
  data() {
    return {
      dialog: {
        survei: false,
        konfirmasi: false
      },
      detail: {},
      data_survei: [],
      errorVisible: [],
      form: {
        nama_dokumen: '',
        dialog: {
          now_date: '',
          item_now: false,
          jawaban_pg: [],
          komentar: [],
          isian: [],
        },
      },
      file: '',
      process: {
        run: false,
        loading: false,
        loding_question: false
      },
      isDokumenTambahan: '',
      save_type: '',
      switch_status: null,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },

  components: {
    LoadingComponent,
    Snackbar
  },


  mounted () {
    if (TokenService.getUuid() === null) {
      this.$router.push(`pendaftaran`)
    } else {
      this.fetch();
      const data = JSON.parse(localStorage.getItem('filter'));
      this.isDokumenTambahan = data.kd_status_pengajuan
    }
  },

  methods: {
    async fetch() {
      this.process.loading = true
      try {
        this.process.run = true;
        const uuid = TokenService.getUuid();
        const params = { uuid };
        const response = await get(`test`, { params });
        if (response.status === 200) {
          this.detail = response.data.data[0];
          this.process.run = false;
          this.getFile();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getFile() {
      try {
        const params = {
          url: this.detail.fileDokumenTambahan
        }
        const response = await get(`file-test`, { params });
        if (response.status === 200) {
          this.file = response.data.data;
          this.process.loading = false
          if (this.isDokumenTambahan === 16) {
            this.openModelSurvei()
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    openModelSurvei() {
      this.getSurvei()
    },

    async getSurvei() {
      this.process.loding_question = true
      this.dialog.survei = true;
      this.save_type = 'survei'
      try {
        const response = await get(`survey/question`);
        if (response.status === 200) {
          const res = response.data.data
          const value = await res
          this.data_survei = value.map((data) => {
            return {
              no_urut: data.noUrut,
              pertanyaan: data.pertanyaan,
              uuid: data.uuid,
              jawaban: data.answerOption,
              flag_opsional: data.flagOpsional
            }
          })
          this.form.dialog.jawaban_pg = new Array(this.data_survei.length - 1).fill(null);
          this.errorVisible = new Array(this.data_survei.length - 1).fill(true);
          this.process.loading = false
        }
      } catch (error) {
        console.error(error);
        this.process.loding_question = false
      } finally {
        this.process.loding_question = false
      }
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate();
      const isValid2 = await this.$refs.observer2.validate();
      const { save_type } = this
      if (save_type === 'ajukan') {
        this.dialog.konfirmasi = isValid2
      } else {
        const hasUndefined = this.form.dialog.jawaban_pg.some((val, index) => {
          if (val === undefined || val === null) {
            this.$set(this.errorVisible, index, false);
          } else {
            this.$set(this.errorVisible, index, true);
          }
        });
        this.dialog.konfirmasi = isValid && !hasUndefined
      }
     
    },

    save() {
      switch (this.save_type) {
        case 'survei':
          this.updates_survei()
          break;
        case 'ajukan':
          this.ajukan()
          break;
        default:
          break;
      }
    },

    async updates_survei() {
      this.process.run = true;
      const { token, detail, data_survei } = this

      const uuid_penelitian = detail.uuid;
      const uuid_pertanyaan = data_survei.map((element) => element.uuid);
      const uuid_jawaban = this.form.dialog.jawaban_pg.map((element) => element)
      const komentar = this.form.dialog.komentar.map((element) => element)
      const isian = this.form.dialog.isian.map((element) => element)

      const jawaban = uuid_pertanyaan.map((pertanyaan, index) => ({
        uuid_penelitian: uuid_penelitian,
        uuid_pertanyaan: pertanyaan,
        uuid_jawaban: uuid_jawaban[index],
        komentar: komentar[index],
        isian: isian[index]
      }));

      const value = {
        jawaban
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}survey`, config);

      if (response.status === 201) {
        this.text = 'Data survey berhasil disimpan';
        this.dialog.survei = false
        this.snackbar = true;
        this.process.run = false;
      }
    },

    async ajukan() {
      this.process.run = true
      const { form, switch_status } = this
      const params = {
        deskripsi_request_dokumen_tambahan: form.nama_dokumen,
        flag_request_dokumen_tambahan: switch_status !== null ? 1 : 0,
        uuid: TokenService.getUuid()
      }
      const response = await put(`test-report/request-document`, { params });
      if (response.status === 200) {
        this.dialog.konfirmasi = false;
        this.text = 'Data dokumen berhasil diupdate';
        this.snackbar = true;
        this.process.run = false
        this.reset()
        this.toPage()
      }
    },

    reset() {
      this.$refs.observer.reset()
      this.errorVisible = [];
      this.data_survei = [],
      this.save_type = ''
    },

    toPage() {
      this.$router.push(`/pendaftaran`);
    }
  }

}
</script>