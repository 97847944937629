<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <div>
      <!-- snackbar -->
      <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
      <!-- end snackbar -->

      <!-- dialog konfirmasi -->
      <v-dialog v-model="dialog.konfirmasi" persistent width="450">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Konfirmasi</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.konfirmasi = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5 text-center mt-2">
            <span class="font-weight-bold title" v-if="type !== 'Hapus'">
              Simpan data yang diinputkan ?
            </span>
            <span class="font-weight-bold title" v-else>
              Apakah anda yakin ingin menghapus data ini ?
            </span>
            <v-card-subtitle class="pt-0">
              <span class="body-2">Pastikan data sudah sesuai</span>
            </v-card-subtitle>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-text class="pt-0 pb-0">
          </v-card-text>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.konfirmasi = false;">
              Batal
            </v-btn>
            <v-btn
              :color="type === 'Hapus' ? 'red' : '#0299B7'"
              elevation="0"
              class="text-capitalize white--text mx-1"
              :loading="process.form"
              :disabled="process.form"
              @click="save">
              Ya, 
              <span v-if="type === 'Hapus'"> hapus</span>
              <span v-else>simpan</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog konfirmasi -->

      <!-- dialog tambah -->
      <v-dialog v-model="dialog.tambah" persistent width="900">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>{{type}} 
                <span v-if="tab === 0">bahan</span>
                <span v-else>satuan</span>
            </span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="reset(); dialog.tambah = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text v-if="tab === 0">
            <ValidationObserver ref="observer">
            <v-form>
              <v-row class="mx-1">
                <v-col cols="12" class="background-ul">
                  <ul class="pl-1">
                    <li>(1.......) Farmasi Praktis</li>
                    <li>(2.......) Biologi Farmasi</li>
                    <li>(3.......) Farmakologi</li>
                    <li>(4.......) Kimia Farmasi</li>
                    <li>(5.......) Mikrobiologi Farmasi</li>
                    <li>(6.......) Teknologi Farmasi</li>
                    <li>(7.......) LPOMK</li>
                  </ul>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="12" md="12" class="pb-0">
                  <ValidationProvider
                    name="Laboratorium"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="form.dialog.id_laboratorium"
                      :items="list.lab_select"
                      label="Laboratorium"
                      color="#0299B7"
                      item-text="nama_laboratorium"
                      item-value="id"
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Kode bahan"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.dialog.kode_bahan"
                      label="Kode bahan"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="3" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Cas number">
                    <v-text-field
                      v-model="form.dialog.cas_number"
                      label="Cas number (Optional)"
                      outlined
                      dense
                      color="#0299B7">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Nama bahan"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.dialog.nama_bahan"
                      label="Nama bahan"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Satuan"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="form.dialog.satuan"
                      :items="list.unit"
                      label="Satuan"
                      color="#0299B7"
                      item-text="label"
                      item-value="value"
                      v-on:change="onSelectSatuan(form.dialog.satuan)"
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Limit"
                    rules="required|numeric"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.dialog.limit"
                      label="Limit"
                      outlined
                      dense
                      color="#0299B7"
                      :suffix="form.dialog.satuan"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Harga internal (Rp)"
                    rules="required"
                    v-slot="{ errors }">
                    <v-currency-field
                      dense
                      color="#0299B7"
                      label="Harga internal (Rp)"
                      outlined
                      require
                      v-model="form.dialog.harga_internal"
                      :error-messages="errors">
                    </v-currency-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Harga eksternal (Rp)"
                    rules="required"
                    v-slot="{ errors }">
                    <v-currency-field
                      dense
                      color="#0299B7"
                      label="Harga eksternal (Rp)"
                      outlined
                      require
                      v-model="form.dialog.harga_external"
                      :error-messages="errors">
                    </v-currency-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pt-0">
                  <v-menu
                    ref="tanggalexpired"
                    v-model="form.dialog.item_start"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    class="z-my"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <ValidationProvider
                        name="Tanggal expired"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          :value="formatDateStart()"
                          label="Tanggal expired"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form.dialog.start_date"
                      v-on:change="startdate()" 
                      color="#0299B7"
                      format="D MMMM YYYY"
                      @input="form.dialog.item_start = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <ValidationProvider
                    name="Link MSDS">
                    <v-text-field
                      v-model="form.dialog.link_msds"
                      label="Link MSDS (Optional)"
                      outlined
                      dense
                      color="#0299B7">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <ValidationProvider
                    name="Keterangan">
                    <v-text-field
                      v-model="form.dialog.keterangan"
                      label="Keterangan (Optional)"
                      outlined
                      dense
                      color="#0299B7">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="type === 'Ubah'">
                  <div>
                    <span>Status   
                      <span v-if="tab === 0">bahan</span>
                      <span v-else>satuan</span>
                    </span>
                  </div>
                  <v-switch
                    class="mt-0 switch"
                    v-model="switch_status"
                    color="#0499b7"
                    value = "true"
                    :label="switch_status === 'true' ? 'Aktif' : 'Tidak aktif'"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          </v-card-text>
          <v-card-text v-if="tab === 1">
            <ValidationObserver ref="observer">
            <v-form>
              <v-row class="mt-5">
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Nama satuan"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.dialog.nama_satuan"
                      label="Nama satuan"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="type === 'Ubah'">
                  <div>
                    <span>Status   
                      <span v-if="tab === 0">bahan</span>
                      <span v-else>satuan</span>
                    </span>
                  </div>
                  <v-switch
                    class="mt-0 switch"
                    v-model="switch_status"
                    color="#0499b7"
                    value = "true"
                    :label="switch_status === 'true' ? 'Aktif' : 'Tidak aktif'"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="reset(), dialog.tambah = false;">
            Tutup
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :disabled="process.form"
            @click="cekForm()">
            Simpan
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog tambah -->

      <!-- dialog detail -->
      <v-dialog v-model="dialog.detail" persistent width="650">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Detail</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.detail = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-row>
              <v-col cols="12" v-if="tab === 0">
                <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                  <tr>
                    <td width="35%">Lab</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.laboratorium }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Kode bahan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.kode_bahan }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Cas number</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.cas_number || '-' }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Nama bahan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.nama_bahan }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Tanggal expired</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.tgl_expired ?? '-' | date }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Satuan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.satuan }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Limit</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.limit }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Harga internal (Rp)</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.harga_internal }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Harga external (Rp)</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.harga_external }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Link MSDS</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.link_msds || '-' }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Keterangan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.keterangan || '-' }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Status bahan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.flag_aktif === 1 ? 'Aktif' : 'Tidak aktif'}}</td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="12" v-else>
                <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                  <!-- <tr>
                    <td width="35%">Lab</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.laboratorium }}</td>
                  </tr> -->
                  <tr>
                    <td width="35%">Nama satuan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.nama_satuan }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Status satuan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.flag_aktif === 1 ? 'Aktif' : 'Tidak aktif'}}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.detail = false;">
              Kembali
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <LoadingComponent v-if="process.loading"></LoadingComponent>

      <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">   
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div class="color-first title font-weight-bold title mt-3">
                    Bahan
                  </div>
                  <!-- row filter -->
                  <v-row class="pt-5">
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.lab"
                        item-text="nama_laboratorium"
                        item-value="id"
                        v-model="filter.lab"
                        v-on:change="onSelectLab()"
                        label="Laboratorium"
                        color="#0299B7"
                        hide-details>
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <!-- end row filter -->
                  <v-row>
                    <v-col cols="12">
                      <v-tabs
                        class="text-capitalize"
                        color="#0299B7"
                        background-color="transparent"
                        style="border-bottom: 1px solid lightgray"
                        light
                        v-model="tab">
                        <v-tab v-for="item in items" :key="item">
                          {{ item }}
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                
                  <!-- row  button-->
                  <v-row class="mt-7 justify-space-between">
                    <v-col cols="12" md="3">
                      <v-btn
                        @click="addForm(tab)"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize white--text">
                          <v-icon size="20" class="mr-3">mdi-plus</v-icon>
                          <div>
                            Tambah 
                            <span class="text-lowercase" v-if="tab === 0">bahan</span>
                            <span class="text-lowercase" v-else>satuan</span>
                          </div>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :disabled="process.run"
                        outlined
                        dense
                        :hide-details="true"
                        placeholder="Cari . . ."
                        clearable
                        color="#0299B7"
                        class="mx-3"
                        v-model="form.cari"
                        @click:clear="form.cari = '', performSearch()"
                        v-on:keyup.enter="performSearch();"
                        prepend-inner-icon="mdi-magnify">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- end row button -->
                  
                  <!-- bahan -->
                  <v-card-text v-if="tab === 0" class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="row"
                        :items-per-page="10"
                        class="elevation-1 required">
                          <template v-slot:[`item.status`]="{ item }">
                            <td>
                              <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                            </td>
                          </template>
                          <template v-slot:[`item.aksi`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="detailForm(item, 'bahan')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Detail</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="30"
                                  color="orange"
                                  :disabled="process.edit"
                                  :loading="process.edit"
                                  @click="editForm(item, 'bahan')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip> 
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="item.flag_dapat_hapus === true"
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="red"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="deleteForm(item, 'bahan')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>
                  <!-- end bahan -->

                  <!-- satuan -->
                  <v-card-text v-if="tab === 1" class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers_satuan"
                        :items="row_satuan"
                        :items-per-page="10"
                        class="elevation-1 required">
                          <template v-slot:[`item.status`]="{ item }">
                            <td>
                              <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                            </td>
                          </template>
                          <template v-slot:[`item.aksi`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="detailForm(item)"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Detail</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="30"
                                  color="orange"
                                  :disabled="process.edit"
                                  :loading="process.edit"
                                  @click="editForm(item)"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip> 
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="item.flag_dapat_hapus === true"
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="red"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="deleteForm(item, 'satuan')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>
                  <!-- end satuan -->
                </v-card-text>      
              </v-col>
            </v-row>
          </v-card>     
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import { get, put, destroy } from "@/service/Axios";
import { serialize } from 'object-to-formdata';
import statusMixin from '@/mixin';
import moment from 'moment';
import 'moment/locale/id';
import Snackbar from '@/components/Snackbar.vue';
import LoadingComponent from '@/components/Loading.vue'
export default {
  mixins: [statusMixin],  
  data() {
    return {
      detail: {},
      dialog: {
        tambah: false,
        detail: false,
        konfirmasi: false
      },
      form : { 
        lab: '',
        cari: '',
        dialog: {
          id_laboratorium: '',
          kode_instrumen: '',
          nama_instrumen: '',
          satuan: '',
          limit: '',
          harga_internal: '',
          harga_external: '',
          start_date: '',
          item_start: false,
          link_msds: '',
          keterangan: '',
          uuid: '',
          nama_satuan: ''
        },
      },
      filter: {
        lab: ''
      },
      id: '',
      process: {
        run: false,
        detail: false,
        edit: false,
        form: false,
        loading: false
      },
      list: {
        lab: [],
        lab_select: [],
        unit: []
      },
      headers: [
          {
            text: 'No.',
            value: 'no',
            sortable: false,
          },
          { text: 'Kode bahan', value: 'kode_bahan', sortable: false, },
          { text: 'Nama bahan', value: 'nama_bahan', sortable: false, },
          { text: 'Tanggal expired', value: 'tgl_expired', sortable: false, },
          { text: 'Limit', value: 'limit', align: 'right', sortable: false, },
          { text: 'Satuan', value: 'satuan', sortable: false, },
          { text: 'Harga internal (Rp)', value: 'harga_internal', align: 'right', sortable: false, },
          { text: 'Harga eksternal (Rp)', value: 'harga_external', align: 'right', sortable: false, },
          { 
            text: 'Aksi', 
            value: 'aksi',
            align: 'center',
            width: '14%'
          },
      ],
      headers_satuan: [
          {
            text: 'No.',
            value: 'no',
            sortable: false,
          },
          { text: 'Nama satuan', value: 'value', sortable: false, },
          { 
            text: 'Aksi', 
            value: 'aksi',
            align: 'center',
            width: '14%'
          },
      ],
      switch_status: null,
      save_type: '',
      type: '',
      row: [],
      row_satuan: [],
      snackbar: false,
      text: '',
      timeout: 3000,
      tab: null,
      items: ['Bahan', 'Satuan'],
    }
  },
  components: {
    Snackbar,
    LoadingComponent,
  },

  watch: {
    tab(newTab) {
      this.form.cari = '';
      if (newTab === 0) {
        this.getList();
      } else if (newTab === 1) {
        this.getUnit();
      }
    },
  },

  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
    
    token () {
      return this.$store.state.access_token
    },
  },

  mounted () {
    this.getLaboratory()
  },

  methods: {
    async getLaboratory() {
      try {
        this.process.run = true
        this.process.loading = true
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory-master', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          const labListSelect = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          labList.unshift({
            id: 'all',
            kd_laboratorium: 'all',
            nama_laboratorium: 'Semua',
          })
          this.list.lab = labList
          this.list.lab_select = labListSelect
          this.filter.lab = labList[0].id
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.loading = false
        this.process.run = false;
        this.getList()
        this.getUnit()

      }
    },

    async getList() {
      try {
        this.process.run = true;
        const params = {
          id_laboratorium: this.filter.lab,
          search: this.form.cari,
        };
        const { data, status } = await get('item-master', { params });
        if (status === 200 && data.data) {
          const row = data.data.map((item, index) => {
            const tarifInternal = item.tarifInternal.toLocaleString('id-ID')
            const tarifEksternal = item.tarifEksternal.toLocaleString('id-ID')
            return {
              no: index + 1,
              kode_bahan: item.kdBahan,
              nama_bahan: item.namaBahan,
              tgl_expired: item.tglExpired || '-',
              limit: item.minStok,
              satuan: item.satuan,
              harga_internal: tarifInternal,
              harga_external: tarifEksternal,
              flag_dapat_hapus: item.flagDapatHapus,
              uuid: item.uuid
            }
          });
          this.process.run = false;
          this.row = row;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
      }
    },

    async getUnit() {
      this.process.run = true;
      try {
        const params = {
          id_laboratorium: this.filter.lab,
          search: this.form.cari,
        }
        const { data, status } = await get(`unit-master`, {params});
        if (status === 200 && data.data) {
          const unit = data.data.map((item, index) => {
            return {
              no: index + 1,
              value: item.namaSatuan,
              label: item.namaSatuan,
              flag_dapat_hapus: item.flagDapatHapus,
              uuid: item.uuid
            }
          });
          this.list.unit = unit
          this.row_satuan = unit
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.process.run = false;
      }
    },

    async getDetail() {
      try {
        const params = {
          id_laboratorium: this.filter.lab,
          search: this.form.cari,
          uuid: this.id
        };
        const { data, status } = await get('item-master', { params });
        if (status === 200 && data.data) {
            const row = data.data.map((item, index) => {
            const tarifInternal = item.tarifInternal.toLocaleString('id-ID')
            const tarifEksternal = item.tarifEksternal.toLocaleString('id-ID')
            return {
              no: index + 1,
              id_laboratorium: item.idLaboratorium,
              kode_bahan: item.kdBahan,
              nama_bahan: item.namaBahan,
              tgl_expired: item.tglExpired,
              cas_number: item.casNumber,
              laboratorium: item.lab.namaLaboratorium,
              limit: item.minStok,
              satuan: item.satuan,
              link_msds: item.linkMsds,
              harga_internal: tarifInternal,
              harga_external: tarifEksternal,
              keterangan: item.keterangan,
              flag_aktif: item.flagAktif,
              uuid: item.uuid
            }
          });
          this.detail = row[0];
          this.form.dialog =  {
            id_laboratorium: this.detail.id_laboratorium,
            kode_bahan: this.detail.kode_bahan,
            nama_bahan: this.detail.nama_bahan,
            cas_number: this.detail.cas_number,
            limit: this.detail.limit,
            satuan: this.detail.satuan,
            harga_internal: parseInt(this.detail.harga_internal.replace(/\./g, ''), 10),
            harga_external: parseInt(this.detail.harga_external.replace(/\./g, ''), 10),
            start_date: this.detail.tgl_expired,
            laboratorium: this.detail.laboratorium,
            keterangan: this.detail.keterangan,
            link_msds: this.detail.link_msds,
            flag_aktif: this.detail.flagAktif === 1 ? 'Aktif' : 'Tidak aktif',
            uuid: this.detail.uuid
          }
          this.switch_status = this.detail.flag_aktif === 1 ? 'true' : false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getDetailUnit() {
      try {
        const params = {
          id_laboratorium: this.filter.lab,
          search: this.form.cari,
          uuid: this.id
        };
        const { data, status } = await get('unit-master', { params });
        if (status === 200 && data.data) {
            const row = data.data.map((item, index) => {
            return {
              no: index + 1,
              id_laboratorium: item.idLaboratorium,
              // laboratorium: item.lab.namaLaboratorium,
              nama_satuan: item.namaSatuan,
              kd_satuan: item.namaSatuan,
              flag_aktif: item.flagAktif,
              uuid: item.uuid
            }
          });
          this.detail = row[0];
          this.form.dialog =  {
            id_laboratorium: this.detail.id_laboratorium,
            nama_satuan: this.detail.nama_satuan,
            // laboratorium: this.detail.laboratorium,
            uuid: this.detail.uuid
          }
          this.switch_status = this.detail.flag_aktif === 1 ? 'true' : false
        }
      } catch (error) {
        console.error(error);
      }
    },

    onSelectSatuan(item) {
      this.form.dialog.durasi = item === 'menit' ? 30 : 1;
    },

    startdate(){
      let today = new Date()
      let time = today.getHours()+ ":" + today.getMinutes()+ ":" + today.getSeconds();
      if (this.form.dialog.start_date === this.reminder) {
        this.form.dialog.start_time_min = time
      } else {
        this.form.dialog.start_time_min = ''
      }
      this.form.dialog.end_date = ''
      this.formatDateStart()
    },

    formatDateStart() {
      return this.form.dialog.start_date ? moment(this.form.dialog.start_date ).format('DD MMMM YYYY') : ''
    },

    // onSelectLab() {
    //   this.getList()
    // },

    addForm(type) {
      this.type = 'Tambah'
      this.dialog.tambah = true
      if (type === 0) {
        this.save_type = 'creates'
      } else {
        this.save_type = 'creates_satuan'
      }
    },

    editForm(item, type) {
      this.type = 'Ubah'
      this.dialog.tambah = true
      if (type === 'bahan') {
        this.save_type = 'updates'
        this.id = item.uuid
        this.getDetail()
      } else {
        this.save_type = 'updates_satuan'
        this.id = item.uuid
        this.getDetailUnit()
      }
    },

    deleteForm(item, type) {
      this.type = 'Hapus'
      this.dialog.konfirmasi = true
      this.id = item.uuid
      if (type === 'bahan') {
        this.save_type = 'delete_bahan'
      } else {
        this.save_type = 'delete_satuan'
      }
    },

    detailForm(item, type) {
      this.dialog.detail = true
      if (type === 'bahan') {
        this.id = item.uuid
        this.getDetail()
      } else {
        this.id = item.uuid
        this.getDetailUnit()
      }
    },

    async performSearch() {
      if (this.tab === 0) {
        await this.getList();
      } else {
        await this.getUnit();
      }
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      this.dialog.konfirmasi = isValid 
    },

    save() {
      switch(this.save_type) {
        case 'creates':
          this.creates();
          break;
        case 'creates_satuan':
          this.creates_satuan();
          break;
        case 'updates':
          this.updates();
          break;
        case 'updates_satuan':
          this.updates_satuan();
          break;
        case 'delete_bahan':
          this.delete_bahan();
          break;
        case 'delete_satuan':
          this.delete_satuan();
          break;
        default:
      }
    },

    async creates() {
      this.process.form = true;
      this.process.run = true
      const { form, token } = this
      const value = {
        id_laboratorium: form.dialog.id_laboratorium,
        kd_bahan: form.dialog.kode_bahan,
        nama_bahan: form.dialog.nama_bahan,
        satuan: form.dialog.satuan,
        tarif_internal: form.dialog.harga_internal,
        tarif_eksternal: form.dialog.harga_external,
        min_stok: form.dialog.limit,
        cas_number: form.dialog.cas_number,
        tgl_expired: form.dialog.start_date,
        link_msds: form.dialog.link_msds,
        keterangan: form.dialog.keterangan
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}item`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
        this.text = 'Data bahan berhasil disimpan';
        this.snackbar = true;
        this.process.form = false;
        this.process.run = false
        this.reset()
        this.getList();
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
      }
    },

    async creates_satuan() {
      this.process.form = true;
      this.process.run = true
      const { form, token } = this
      const value = {
        id_laboratorium: form.dialog.id_laboratorium,
        kd_satuan: form.dialog.nama_satuan,
        nama_satuan: form.dialog.nama_satuan,
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}unit`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
        this.text = 'Data satuan berhasil disimpan';
        this.snackbar = true;
        this.process.form = false;
        this.process.run = false
        this.tab = 1
        this.reset()
        this.getUnit();
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
      }
    },

    async updates() {
      this.process.form = true;
      this.process.run = true
      const { form, switch_status } = this
      const params = {
        id_laboratorium: form.dialog.id_laboratorium,
        kd_bahan: form.dialog.nama_satuan,
        nama_bahan: form.dialog.nama_bahan,
        satuan: form.dialog.satuan,
        tarif_internal: form.dialog.harga_internal,
        tarif_eksternal: form.dialog.harga_external,
        min_stok: form.dialog.limit,
        cas_number: form.dialog.cas_number,
        tgl_expired: form.dialog.start_date,
        link_msds: form.dialog.link_msds,
        keterangan: form.dialog.keterangan,
        flag_aktif: switch_status !== null ? 1 : 0,
        uuid: form.dialog.uuid
      }
      const response = await put(`item`, { params });
      if (response.status === 200) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
        this.text = 'Data bahan berhasil diupdate';
        this.snackbar = true;
        this.process.form = false;
        this.process.run = false
        this.reset()
        this.getList();
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
      }
    },

    async updates_satuan() {
      this.process.form = true;
      this.process.run = true
      const { form, switch_status } = this
      const params = {
        id_laboratorium: form.dialog.id_laboratorium,
        kd_satuan: form.dialog.nama_satuan,
        nama_satuan: form.dialog.nama_satuan,
        flag_aktif: switch_status !== null ? 1 : 0,
        uuid: form.dialog.uuid
      }
      const response = await put(`unit`, { params });
      if (response.status === 200) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
        this.text = 'Data satuan berhasil diupdate';
        this.snackbar = true;
        this.process.form = false;
        this.process.run = false
        this.tab = 1
        this.reset()
        this.getUnit();
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
      }
    },

    async delete_bahan() {
      try {
        this.process.run = true;
        this.process.form = true;
        const params = {
          uuid: this.id
        }
        const { data, status } = await destroy('item', { params });
        if (status === 200 && data.data) {
          this.dialog.konfirmasi = false;
          this.text = 'Data bahan berhasil dihapus';
          this.snackbar = true;
          this.process.run = false
          this.process.form = false;
          this.reset()
          this.getList()
        } else {
          this.dialog.konfirmasi = false;
        }
      } catch (error) {
        console.error(error);
      }     
    },

    async delete_satuan() {
      try {
        this.process.run = true;
        this.process.form = true;
        const params = {
          uuid: this.id
        }
        const { data, status } = await destroy('unit', { params });
        if (status === 200 && data.data) {
          this.dialog.konfirmasi = false;
          this.text = 'Data satuan berhasil dihapus';
          this.snackbar = true;
          this.process.run = false
          this.process.form = false;
          this.tab = 1
          this.reset()
          this.getUnit()
        } else {
          this.dialog.konfirmasi = false;
        }
      } catch (error) {
        console.error(error);
      }     
    },

    reset() {
      this.$refs.observer.reset()
      this.form = { 
        lab: '',
        cari: '',
        dialog: {
          id_laboratorium: '',
          kode_instrumen: '',
          nama_instrumen: '',
          satuan: '',
          limit: '',
          start_date: '',
          item_start: false,
          harga_internal: '',
          harga_external: '',
          link_msds: '',
          keterangan: '',
          uuid: '',
          nama_satuan: ''
        },
      };
      this.id = '';
      this.save_type =  '',
      this.type = ''
    },
  },
}
</script>

<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.background-ul {
  background: #F3F6F9;
  margin-top: 25px;
  border-radius: 10px;
}
</style>