<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.run"
            :disabled="process.run"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <ValidationObserver ref="observer2">
            <v-form>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-card-text>
                    <div>
                      <v-row class="mt-5">
                        <v-col cols="12" class="pb-0">
                          <div class="color-first title font-weight-bold mt-2 pb-1">
                            <span>Permintaan dokumen tambahan</span>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                            <tr>
                              <td width="20%">Tanggal permintaan</td>
                              <td width="1%">:</td>
                              <td>10 September 2023 08.00 WIB</td>
                            </tr>
                            <tr>
                              <td width="20%">Nama dokumen</td>
                              <td width="1%">:</td>
                              <td>GAMBARAN METODE UJI dan HASIL PENGAMATAN DAN ANALISIS HASIL UJI</td>
                            </tr>
                          </table>
                        </v-col>
                        <v-col cols="12">
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header color="#0299B7">
                                <template v-slot:actions>
                                  <v-icon color="white">
                                    $expand
                                  </v-icon>
                                </template>
                                <span class="white--text">Contoh berkas</span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <iframe 
                                  width="100%" 
                                  :height="this.file === null ? '110px' : '500px'" 
                                  :src="this.file" 
                                  :lazy-src="this.file"
                                  frameborder="0"
                                  allowfullscreen>
                                  Oops! an error has occurred.
                                </iframe>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                        <v-col cols="12" class="pt-0" v-if="isDokumenTambahan !== 16">
                          <div class="mt-4">
                            <ValidationProvider
                              name="Unggah dokumen"
                              rules="required"
                              v-slot="{ errors }">
                              <v-file-input
                                dense
                                outlined
                                label="Unggah dokumen"
                                color="#0299B7"
                                hide-details
                                :rules="[fileSizeRule]"
                                accept=".pdf"
                                v-model="form.unggah_dokumen"
                                :error-messages="errors">
                              </v-file-input>
                              <i class="mt-1 caption color-second">
                                Maks 5MB dengan format file .pdf
                              </i>
                            </ValidationProvider>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <v-divider></v-divider>
          <!-- row simpan -->
          <v-card-actions class="mt-4 justify-center">
            <v-btn
              large
              width="120"
              elevation="0"
              :disabled="process.run"
              class="text-capitalize"
              @click="toPage()">
              <span v-if="isDokumenTambahan !== 16">Batal</span>
              <span v-else>Kembali</span>
            </v-btn>
            <v-btn
              v-if="isDokumenTambahan !== 16"
              large
              width="120"
              color="#0299B7"
              elevation="0"
              :loading="process.run"
              @click="save_type = 'ajukan'; cekForm();"
              class="white--text text-capitalize">
              Simapn
            </v-btn>
          </v-card-actions>
          <!-- end row -->
        </v-card>
      </v-col>
    </v-row>
     <!-- pdf -->
     <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1400" filename="Lhu" :pdf-quality="2" :manual-pagination="false"
        pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf">
        <section slot="pdf-content">
          <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files" />
        </section>
      </vue-html2pdf>
    </div>
    <!-- end pdf -->
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import Snackbar from '@/components/Snackbar.vue';
import LoadingComponent from '@/components/Loading.vue'
import { get } from "@/service/Axios";
import Pdf from "@/components/Pdf.vue"
import { TokenService } from "@/service/Storage.Service";
import { serialize } from 'object-to-formdata';
export default {
  data() {
    return {
      dialog: {
        survei: false,
        konfirmasi: false
      },
      detail: {},
      data_survei: [],
      files: null,
      errorVisible: [],
      form: {
        unggah_dokumen: '',
        dialog: {
          now_date: '',
          item_now: false,
          jawaban_pg: [],
          komentar: [],
          isian: [],
        },
      },
      fileSizeRule: (value) => {
        if (!value) {
          return true;
        }
        const size = value.size / 1024 / 1024; // konversi byte ke megabyte
        return size <= 5 ? true : 'Ukuran file tidak boleh lebih dari 5MB';
      },
      file: '',
      process: {
        run: false,
        loading: false,
        loding_question: false
      },
      isDokumenTambahan: '',
      save_type: '',
      switch_status: null,
      snackbar: false,
      text: '',
      timeout: 3000,
      nilai_parameter: 'lhu',
    }
  },

  components: {
    Pdf,
    LoadingComponent,
    Snackbar
  },
  
  computed: {
    token () {
      return this.$store.state.access_token
    }
  },

  mounted () {
    if (TokenService.getUuid() === null) {
      this.$router.push(`persetujuan-pengujian`)
    } else {
      const data = JSON.parse(localStorage.getItem('filter'));
      this.isDokumenTambahan = data.kd_status_pengajuan
      this.printReport()
    }
  },

  methods: {
    async printReport() {
      this.process.loading = true
      let data
      try {
        const params = {
          uuid: TokenService.getUuid()
        }
        get(`test-report/print`, { params }).then((response) => {
          if (response.status === 200) {
            data = response.data.data
            this.$store.dispatch('getDataPdf', data);
            this.process.loading = false
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.$refs.pdf.downloadPdf(data, this.$refs.html2Pdf);
        this.fetch()
      }
    },

    async fetch() {
      try {
        this.process.run = true;
        const uuid = TokenService.getUuid();
        const flag_officer = '1';
        const params = { uuid, flag_officer };
        const response = await get(`test`, { params });
        if (response.status === 200) {
          this.detail = response.data.data[0];
          if (this.isDokumenTambahan === 16) {
            this.getFile()
          }
          this.process.run = false;
          this.process.loading = false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getFile() {
      try {
        const params = {
          url: this.detail.fileDokumenTambahan
        }
        const response = await get(`file-test`, { params });
        if (response.status === 200) {
          this.file = response.data.data;
          this.process.loading = false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async cekForm() {
      const isValid2 = await this.$refs.observer2.validate();
      const { save_type } = this
      if (save_type === 'ajukan') {
        this.dialog.konfirmasi = isValid2
      }
     
    },

    save() {
      switch (this.save_type) {
        case 'ajukan':
          this.ajukan()
          break;
        default:
          break;
      }
    },

    async ajukan() {
      this.process.run = true
      const { form, token } = this

      const value = {
        file_dokumen_tambahan: form.unggah_dokumen,
        uuid: TokenService.getUuid()
      }
      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(
        value,
        options,
      );

      try {
        const authHeader = new Headers();
        authHeader.append('Authorization', 'Bearer ' + token);
        const config = {
          method: 'POST',
          headers: authHeader,
          body: formData
        };
        this.process.form = true;
        const response = await fetch(`${VUE_APP_API_URL}test-report/upload-document`, config);
        if (response.status === 200) {
          this.dialog.konfirmasi = false;
          this.text = 'Data pengujian LHU berhasil diubah';
          this.snackbar = true;
          this.process.form = false;
          this.reset()
          this.toPage();
        }

      } catch (error) {
        console.log(error)
      } finally {
        this.dialog.konfirmasi = false;
      }
    },

    reset() {
      this.errorVisible = [];
      this.data_survei = [],
      this.save_type = ''
      this.form.unggah_dokumen = ''
    },

    toPage() {
      this.$router.push(`/persetujuan-pengujian`);
    }
  },
   
  }
</script>