<template>
  <div>
    <LoadingComponent v-if="process.loading"></LoadingComponent>
    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <!-- data diri -->
                <v-row>
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold title mt-3 pb-2">
                      Data diri
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <table width="100%" class="body-2">
                      <tr>
                        <td width="30%">ID registrasi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.idRegistrasi || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Nama Lengkap</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.nama || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Nomor Mahasiswa/Kepegawaian</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.noInduk || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Email</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.email || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">No. WhatsApp aktif</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.noHp || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Instansi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaInstitusi || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Perguruan tinggi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaPerguruanTinggi || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Fakultas</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaFakultas || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Program studi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaProgramStudi || '-' }}</td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <!-- end data diri -->

                <!-- jenis penggunaan -->
                <v-row>
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold title mt-3 pb-2">
                     Jenis penggunaan
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <table width="100%" class="body-2">
                      <tr>
                        <td width="30%">Jenis</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.usageType?.jenisPenggunaan || '-' }}</td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <!-- end jenis penggunaan -->

                <!-- sampel -->
                <ValidationObserver ref="observer">
                  <v-form>  
                    <v-row v-for="(item, i) in detail.sample" :key="i">
                      <v-col cols="12">
                        <div class="color-first title font-weight-bold title mt-3 pb-2">
                          Sampel {{ i + 1 }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Kode sampel</td>
                            <td width="1%">:</td>
                            <td>{{ item.kodeSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Nama</td>
                            <td width="1%">:</td>
                            <td>{{ item.nama || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Jumlah</td>
                            <td width="1%">:</td>
                            <td>{{ item.jumlah || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Bentuk </td>
                            <td width="1%">:</td>
                            <td>{{ item.phase.namaBentukSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Penyimpanan</td>
                            <td width="1%">:</td>
                            <td>{{ item.storage.namaPenyimpananSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Kondisi</td>
                            <td width="1%">:</td>
                            <td>{{ item.condition.namaKondisiSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Kemasan</td>
                            <td width="1%">:</td>
                            <td>{{ item.kemasan || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%" class="td-flex">Gambar</td>
                            <td width="1%">:</td>
                            <td>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header color="#0299B7">
                                    <template v-slot:actions>
                                      <v-icon color="white">
                                        $expand
                                      </v-icon>
                                    </template>
                                    <span class="white--text"></span>
                                  </v-expansion-panel-header>     
                                  <v-expansion-panel-content>
                                    <div>
                                      <iframe
                                        v-if="item.url && item.url?.startsWith('data:application/pdf') || item.url?.startsWith('blob:http://')" 
                                        width="100%" 
                                        :height="item.url === null ? '110px' : '500px'" 
                                        :src="item.url" 
                                        :lazy-src="item.url"
                                        frameborder="0"
                                        allowfullscreen>
                                        Oops! an error has occurred.
                                      </iframe>
                                      <v-img 
                                        v-else-if="item.url && item.url?.startsWith('data:image')"
                                        :src="item.url" 
                                        :lazy-src="item.url" 
                                        width="100%" 
                                        aspect-ratio="2">
                                      </v-img>
                                    </div>            
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                   
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Status penerimaan sampel</td>
                            <td width="1%">:</td>
                            <td>
                              {{ item.status.statusSampel || '-'}}
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Tanggal penerimaan sampel</td>
                            <td width="1%">:</td>
                            <td>
                              {{ item.tglPenerimaanSampel | date}}
                            </td>
                          </tr>
                          <tr v-for="(data, j) in item.parameter" :key="j">
                            <td width="35%" style="display: flex;">Parameter uj {{ j + 1 }}</td>
                            <td width="1%">:</td>
                            <td>
                              {{ data.parameterTest.namaParameterUji || '-'}} 
                              | <span class="caption" :class="getColor(data.status.kdStatusSampelParameter)"> {{ data.status.statusSampelParameter || '-'}} </span>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-form>
                </ValidationObserver>
                <!-- sampel -->

                <!-- riwayat -->
                <v-row>
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold py-2 mt-5">
                      Riwayat
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-timeline 
                      align-top
                      dense>
                      <v-timeline-item
                        small 
                        color="#0299B7"
                        v-for="(item, j) in detail.history" :key="j">
                        <v-row>
                          <v-col>
                            <span class="color-first body-2">{{item.createdAt | datetime}} WIB</span>
                            <div class="caption font-weight-bold"> {{item.statusSubmission.statusPengajuan}} </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
                <!-- end riwayat -->                
              </v-card-text>
              <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
              <v-card-actions class="mt-2 pb-5 justify-center">
                <v-btn
                  outlined
                  class="text-capitalize color-first mx-1"
                  :disabled="process.form"
                  @click="toPage()">
                  Kembali
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>        
    </v-row>
  </div>
</template>

<script>
import LoadingComponent from '@/components/Loading.vue'
import { TokenService } from "@/service/Storage.Service";
import { get } from "@/service/Axios";
export default {
  data() {
    return {
      detail: {},
      form: {
        sampel: []
      },
      file: [],
      process: {
        run: false,
        loading: false,
        form: false
      },
      isUii: false,
    }
  },

  components: {
    LoadingComponent,
  },

  mounted () {
    if (TokenService.getUuid() === null) {
        this.$router.push(`pendaftaran`)
      } else {
        this.fetch();
      }
  },
  methods: {
    async fetch() {
      this.process.loading = true
      try {
        this.process.run = true;
        const uuid = TokenService.getUuid();
        const params = { uuid };
        const response = await get(`test`, { params });
        if (response.status === 200) {
          this.detail = response.data.data[0];
          const email = this.detail?.user.email
          if (email.endsWith("uii.ac.id")) {
            this.isUii = true
          } else {
            this.isUii = false
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.getFile();
      }
    },

    async getFile() {
      try {
        let params = [];
        this.detail.sample.forEach(element => {
          params.push({ url: element.fileGambar });
        });
        for (let index = 0; index < params.length; index++) {
          const response = await get(`file-test`, { params: params[index] });
          if (response.status === 200) {
            let res = response.data;
            this.detail.sample[index].url = res.data || null;
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.process.loading = false
        this.process.run = false
      }
    },

    getColor(status) {
      switch (status) {
        case 1:
          return 'grey--text'
        case 3:
          return 'green--text';
        case 4:
          return 'red--text';
        default:
          return '';
      }
    },

    toPage() {
      this.$router.push(`/pendaftaran`);
    },

  },
}
</script>

<style lang="scss" scoped>

</style>