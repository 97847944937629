import Form from "@/views/private/Form.vue"

// --- ADMIN ---
// pengujian
import IndexPersetujuanPengujian from "@/views/private/admin/pengujian/persetujuan/Index.vue"
import EditPersetujuanPengujian from "@/views/private/admin/pengujian/persetujuan/EditPersetujuan.vue"
import CetakLhuAdmin from "@/views/private/admin/pengujian/persetujuan/CetakLhuAdmin.vue"
import IndexPembayaranPengujian from "@/views/private/admin/pengujian/pembayaran/Index.vue"
import IndexPengembalianDanaPengujian from "@/views/private/admin/pengujian/pengembalian-dana/IndexPengembalianDana.vue"
// end pengujian

import IndexAlat from "@/views/private/admin/manajemen_lab/alat/Index.vue"
import IndexBahan from "@/views/private/admin/manajemen_lab/bahan/Index.vue"
import IndexInstrument from "@/views/private/admin/manajemen_lab/instrument/Index.vue"
import IndexParameter from '@/views/private/admin/manajemen_lab/parameter_uji/Index.vue'; 
import DetailParameter from '@/views/private/admin/manajemen_lab/parameter_uji/Detail.vue'

import IndexPetugas from "@/views/private/admin/manajemen_user/petugas/Index.vue"
import TambahPetugas from "@/views/private/admin/manajemen_user/petugas/Tambah.vue"
// --- END ADMIN ---

// --- PENDAFTAR ---
import IndexPendaftaran from "@/views/private/pendaftar/pendaftar_uii/pendaftaran/Index.vue"
import TambahPendaftaran from "@/views/private/pendaftar/pendaftar_uii/pendaftaran/Tambah.vue"
import DetailPendaftaran from "@/views/private/pendaftar/pendaftar_uii/pendaftaran/Detail.vue"
import DetailPendaftaranPengujian from "@/views/private/pendaftar/pendaftar_uii/pendaftaran/DetailPengujian.vue"
import CetakLhu from "@/views/private/pendaftar/pendaftar_uii/pendaftaran/CetakLhu.vue"
import EditPendaftaran from "@/views/private/pendaftar/pendaftar_uii/pendaftaran/Edit.vue"
import IndexPembayaran from "@/views/private/pendaftar/pendaftar_uii/pembayaran/Index.vue"
import DetailPembayaran from "@/views/private/pendaftar/pendaftar_uii/pembayaran/Detail.vue"
import DetailLhu from "@/views/private/pendaftar/pendaftar_uii/pendaftaran/Lhu.vue"
import IndexSurat from "@/views/private/pendaftar/pendaftar_uii/surat/Index.vue"
import AjukanSurat from "@/views/private/pendaftar/pendaftar_uii/surat/Ajukan.vue"

// --- END PENDAFTAR ---

// --- PETUGAS KALAB ---
import IndexPersetujuanKalab from "@/views/private/petugas/kalab/persetujuan/Index.vue"
import DetailPersetujuanKalab from "@/views/private/petugas/kalab/persetujuan/Detail.vue"
import IndexRiwayat from "@/views/private/petugas/kalab/riwayat/Index.vue"
import DetailRiwayat from "@/views/private/petugas/kalab/riwayat/Detail.vue"
// --- END PETUGAS KALAB ---

// --- PETUGAS LABORAN ---
import IndexPersetujuanLaboran from "@/views/private/petugas/laboran/persetujuan/Index.vue"
import DetailPersetujuanLaboran from "@/views/private/petugas/laboran/persetujuan/Detail.vue"
import IndexPenggunaanLaboran from "@/views/private/petugas/laboran/penggunaan/Index.vue"
import DetailPenggunaanLaboran from "@/views/private/petugas/laboran/penggunaan/Detail.vue"
import IndexRiwayatLaboran from "@/views/private/petugas/laboran/riwayat/Index.vue"
import DetailRiwayatLaboran from "@/views/private/petugas/laboran/riwayat/Detail.vue"
import IndexSuratLaboran from "@/views/private/petugas/laboran/surat/Index.vue"
import AjukanSuratLaboran from "@/views/private/petugas/laboran/surat/Ajukan.vue"
// --- END PETUGAS LABORAN ---

// --- PETUGAS PEMBAYARAN ---
import IndexPetugasPembayaran from "@/views/private/petugas/petugas_pembayaran/pembayaran/Index.vue"
import DetailPetugasPembayaran from "@/views/private/petugas/petugas_pembayaran/pembayaran/Detail.vue"
import IndexRiwayatPembayaran from "@/views/private/petugas/petugas_pembayaran/riwayat/Index.vue"
import IndexPengembalianDeposit from "@/views/private/petugas/petugas_pembayaran/pengembalian/Index.vue"
import IndexSuratPetugasPembayaran from "@/views/private/petugas/petugas_pembayaran/surat/Index.vue"
import AjukanSuratPetugasPembayaran from "@/views/private/petugas/petugas_pembayaran/surat/Ajukan.vue"
// --- END PETUGAS PEMBAYARAN ---

// --- ALL ---
// kalab lpmok
import IndexKalabLpmok from "@/views/private/all/lpmok/IndexLpmok.vue"
import EditPersetujuanKalabLpmok from "@/views/private/all/lpmok/EditPersetujuanLpmok.vue"
// end kalab lpmok

// analisis sampel penyelia
import IndexAnalisisPenyelia from "@/views/private/all/analisis-penyelia/IndexAnalisisPenyelia.vue"
import EditAnalisisPenyelia from '@/views/private/all/analisis-penyelia/EditAnalisisPenyelia.vue';
// end analisis sampel penyelia

// analisis sampel
import IndexAnalisisSampel from "@/views/private/all/analisis/IndexAnalisisSampel.vue"
import EditAnalisisSampel from "@/views/private/all/analisis/EditAnalisisSampel.vue"
// end analisis sampel

// lhu lpmok
import IndexLhu from "@/views/private/all/lhu/IndexLhu.vue"
import DetailLhuKalab from "@/views/private/all/lhu/DetailLhu.vue"

// end lhu lpmok

// --- END ALL ---


const routes = [
  // form data diri
  {
    path: "/form",
    name: "form",
    component: Form,
    meta: {
      title: "Form data diri"
    }
  },

  //  --- ADMIN ---
  // pengujian
  {
    path: "/persetujuan-pengujian",
    name: "persetujuan",
    component: IndexPersetujuanPengujian,
    meta: {
      title: "Persetujuan",
      roles: ["Admin"]
    },
  },
  {
    path: "/persetujuan-pengujian/edit",
    name: "persetujuan",
    component: EditPersetujuanPengujian,
    meta: {
      title: "Edit",
      roles: ["Admin"]
    },
  },
  {
    path: "/persetujuan-pengujian/cetak",
    name: "persetujuan",
    component: CetakLhuAdmin,
    meta: {
      title: "Cetak LHU",
      roles: ["Admin"]
    },
  },
  {
    path: "/pembayaran-pengujian",
    name: "pembayaran",
    component: IndexPembayaranPengujian,
    meta: {
      title: "Pembayaran",
      roles: ["Admin"]
    },
  },
  {
    path: "/pengembalian-dana-pengujian",
    name: "pengembalian dana",
    component: IndexPengembalianDanaPengujian,
    meta: {
      title: "Pengembalian dana",
      roles: ["Admin"]
    },
  },

  // manajemen lab
  {
    path: "/alat",
    name: "alat",
    component: IndexAlat,
    meta: {
      title: "Alat",
      roles: ["Admin", "Laboran"]
    },
  },
  {
    path: "/bahan",
    name: "bahan",
    component: IndexBahan,
    meta: {
      title: "Bahan",
      roles: ["Admin", "Laboran"]
    },
  },
  {
    path: "/instrumen",
    name: "instrument",
    component: IndexInstrument,
    meta: {
      title: "Instrument",
      roles: ["Admin", "Laboran"]
    },
  },
  {
    path: "/parameter-uji",
    name: "parameter",
    component: IndexParameter,
    meta: {
      title: "Parameter Uji",
      roles: ["Admin"]
    },
  },
  {
    path: "/parameter-uji/:page",
    name: "parameter",
    component: DetailParameter,
    meta: {
      title: "Parameter Uji",
      roles: ["Admin"]
    },
  },

  // manajemen user
  {
    path: "/petugas",
    name: "petugas",
    component: IndexPetugas,
    meta: {
      title: "Petugas",
      roles: ["Admin"]
    },
  },
  {
    path: "/petugas/tambah",
    name: "tambah",
    component: TambahPetugas,
    meta: {
      title: "Tambah petugas",
      roles: ["Admin"]
    },
  },

  // analisis sampel
  {
    path: "/analisis-sampel",
    name: "analisis",
    component: IndexAnalisisSampel,
    meta: {
      title: "Analisis sampel",
      roles: ["Kalab"]
    },
  },
  {
    path: "/analisis-sampel/:page",
    name: "analisis",
    component: EditAnalisisSampel,
    meta: {
      title: "Analisis sampel",
      roles: ["Kalab"]
    },
  },
  // --- END ADMIN ---

  // --- PENDAFTAR ---
  {
    path: "/pendaftaran",
    name: "pendaftaran",
    component: IndexPendaftaran,
    meta: {
      title: "Penggunaan lab",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/pendaftaran/tambah",
    name: "tambah",
    component: TambahPendaftaran,
    meta: {
      title: "Formulir pendaftaran",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/pendaftaran/detail",
    name: "detail",
    component: DetailPendaftaran,
    meta: {
      title: "Detail pendaftaran",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/pendaftaran/detail-pengujian",
    name: "detail",
    component: DetailPendaftaranPengujian,
    meta: {
      title: "Detail pendaftaran",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/pendaftaran/cetak",
    name: "cetak",
    component: CetakLhu,
    meta: {
      title: "Cetak LHU",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/pendaftaran/edit",
    name: "edit",
    component: EditPendaftaran,
    meta: {
      title: "Edit pendaftaran",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/pendaftaran/lhu",
    name: "detail pembayaran",
    component: DetailLhu,
    meta: {
      title: "Cetak LHU",
      roles: ["Pendaftar"]
    },
  },
  {
    path: "/pembayaran",
    name: "pembayaran",
    component: IndexPembayaran,
    meta: {
      title: "Pembayaran",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/pembayaran/detail",
    name: "detail pembayaran",
    component: DetailPembayaran,
    meta: {
      title: "Detail pembayaran",
      roles: ["Pendaftar", "Kalab", "Laboran"]
    },
  },
  {
    path: "/surat",
    name: "surat bebas",
    component: IndexSurat,
    meta: {
      title: "Surat bebas lab",
      roles: ["Pendaftar"]
    },
  },
  {
    path: "/surat/ajukan",
    name: "ajukan surat bebas lab",
    component: AjukanSurat,
    meta: {
      title: "Pengajuan surat bebas lab",
      roles: ["Pendaftar"]
    },
  },
  // --- END PENDAFTAR --- 

  // --- PETUGAS KALAB ---
  {
    path: "/persetujuan-kalab",
    name: "persetujuan kalab",
    component: IndexPersetujuanKalab,
    meta: {
      title: "Persetujuan kalab",
      roles: ["Kalab"]
    },
  },
  {
    path: "/persetujuan-kalab/:page",
    name: "detail kalab",
    component: DetailPersetujuanKalab,
    meta: {
      title: "Detail persetujuan kalab",
      roles: ["Kalab"]
    },
  },
  {
    path: "/riwayat",
    name: "riwayat kalab",
    component: IndexRiwayat,
    meta: {
      title: "Riwayat",
      roles: ["Kalab"]
    },
  },
  {
    path: "/riwayat/detail",
    name: "riwayat detail kalab",
    component: DetailRiwayat,
    meta: {
      title: "Detail riwayat",
      roles: ["Kalab"]
    },
  },
  // --- END PETUGAS KALAB ---

  // --- PETUGAS LABORAN ---
  {
    path: "/persetujuan-laboran",
    name: "persetujuan laboran",
    component: IndexPersetujuanLaboran,
    meta: {
      title: "Persetujuan laboran",
      roles: ["Laboran", "Petugas pembayaran"],
    },
  },
  {
    path: "/persetujuan-laboran/:page",
    name: "detail laboran",
    component: DetailPersetujuanLaboran,
    meta: {
      title: "Detail persetujuan laboran",
      roles: ["Laboran", "Petugas pembayaran"],
    },
  },
  {
    path: "/penggunaan-laboran",
    name: "penggunaan laboran",
    component: IndexPenggunaanLaboran,
    meta: {
      title: "Penggunaan laboran",
      roles: ["Laboran", "Petugas pembayaran"],
    },
  },
  {
    path: "/penggunaan-laboran/:page",
    name: "Detail penggunaan laboran",
    component: DetailPenggunaanLaboran,
    meta: {
      title: "Detail laboran",
      roles: ["Laboran", "Petugas pembayaran"],
    },
  },
  {
    path: "/riwayat-laboran",
    name: "riwayat laboran",
    component: IndexRiwayatLaboran,
    meta: {
      title: "Riwayat",
      roles: ["Laboran", "Petugas pembayaran"],
    },
  },
  {
    path: "/riwayat-laboran/detail",
    name: "detail riwayat laboran",
    component: DetailRiwayatLaboran,
    meta: {
      title: "Detail riwayat",
      roles: ["Laboran", "Petugas pembayaran"],
    },
  },
  {
    path: "/surat-laboran",
    name: "surat bebas",
    component: IndexSuratLaboran,
    meta: {
      title: "Surat bebas lab",
      roles: ["Laboran"]
    },
  },
  {
    path: "/surat-laboran/ajukan/:page",
    name: "ajukan surat bebas lab",
    component: AjukanSuratLaboran,
    meta: {
      title: "Pengajuan surat bebas lab",
      roles: ["Laboran"]
    },
  },
  // --- END PETUGAS LABORAN ---

  // --- PETUGAS PEMBAYARAN ---
  {
    path: "/petugas-pembayaran-pembayaran",
    name: "pembayaran petugas pembayaran",
    component: IndexPetugasPembayaran,
    meta: {
      title: "Pembayaran",
      roles: ["Petugas pembayaran"],
    },
  },
  {
    path: "/petugas-pembayaran-pembayaran/detail",
    name: "detail pembayaran petugas pembayaran",
    component: DetailPetugasPembayaran,
    meta: {
      title: "Detail pembayaran",
      roles: ["Petugas pembayaran"]
    },
  },
  {
    path: "/riwayat-pembayaran-petugas-pembayaran",
    name: "riwayat pembayaran",
    component: IndexRiwayatPembayaran,
    meta: {
      title: "riwayat pembayaran",
      roles: ["Petugas pembayaran"],
    },
  },
  {
    path: "/pengembalian-deposit-petugas-pembayaran",
    name: "pengembalian deposit",
    component: IndexPengembalianDeposit,
    meta: {
      title: "pengembalian deposit",
      roles: ["Petugas pembayaran"]
    },
  },
  {
    path: "/surat-petugas-pembayaran",
    name: "surat bebas",
    component: IndexSuratPetugasPembayaran,
    meta: {
      title: "Surat bebas lab",
      roles: ["Petugas pembayaran"]
    },
  },
  {
    path: "/surat-petugas-pembayaran/ajukan/:page",
    name: "ajukan surat bebas lab",
    component: AjukanSuratPetugasPembayaran,
    meta: {
      title: "Pengajuan surat bebas lab",
      roles: ["Petugas pembayaran"]
    },
  },
  // --- END PETUGAS PEMBAYARAN ---

  // --- ALL ROLE ---
  // KALAB
  {
    path: "/persetujuan-kalab-lpmok",
    name: "persetujuan",
    component: IndexKalabLpmok,
    meta: {
      title: "Persetujuan",
      roles: ["Kalab", "Admin", 'Laboran', 'Petugas pembayaran']
    },
  },
  {
    path: "/persetujuan-kalab-lpmok/:page",
    name: "persetujuan",
    component: EditPersetujuanKalabLpmok,
    meta: {
      title: "Edit",
      roles: ["Kalab", "Admin", 'Laboran', 'Petugas pembayaran']
    },
  },
  {
    path: "/review-sampel-lpmok",
    name: "analisis sampel",
    component: IndexAnalisisPenyelia,
    meta: {
      title: "Analisis sampel",
      roles: ["Kalab", "Admin", 'Laboran', 'Petugas pembayaran']
    },
  },
  {
    path: "/review-sampel-lpmok/:page",
    name: "analisis sampel",
    component: EditAnalisisPenyelia,
    meta: {
      title: "Edit",
      roles: ["Kalab", "Admin", 'Laboran', 'Petugas pembayaran']
    },
  },
  {
    path: "/lhu-kalab-lpmok",
    name: "LHU",
    component: IndexLhu,
    meta: {
      title: "LHU",
      roles: ["Kalab"]
    },
  },
  {
    path: "/lhu-kalab-lpmok/:page",
    name: "LHU",
    component: DetailLhuKalab,
    meta: {
      title: "LHU",
      roles: ["Kalab"]
    },
  },
  // END KALAB
  // --- END ALL ROLE ---
]

export default routes.map(route => {
  const meta = {
    public: false,
    title: route.meta.title,
    roles: route.meta.roles,
  }
  return { ...route, meta }
})