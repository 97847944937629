<template>
  <div>
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog pengembalian alat -->
    <v-dialog
      v-model="dialog.pengembalian_alat"
      scrollable
      persistent
      width="800">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Penambahan alat/bahan/instrumen</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="reset(), dialog.pengembalian_alat = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="color-first title font-weight-bold title mt-4 mb-8">
           <!-- Pengembalian alat -->
          </div>
          <ValidationObserver ref="observer">
            <v-form>
              <div v-if="form.pengembalian_alat.length > 0">
                <v-row v-for="(item, i) in form.pengembalian_alat" :key="i" class="align-center background-row-perubahan">
                  <v-col cols="6">
                    <table width="100%" class="body-2">
                      <tr>
                        <td width="25%"><b> Jenis </b></td>
                        <td width="3%">:</td>
                        <td>{{ item.jenis }}</td>
                      </tr>
                      <tr>
                        <td width="25%"><b> Kode </b></td>
                        <td width="3%">:</td>
                        <td>{{ item.kode }}</td>
                      </tr>
                      <tr>
                        <td width="25%"><b> Nama </b> </td>
                        <td width="3%">:</td>
                        <td>{{ item.nama }}</td>
                      </tr>
                      <tr>
                        <td width="25%"><b> Total </b></td>
                        <td width="3%">:</td>
                        <td>{{ item.total }}</td>
                      </tr>
                    </table>
                  </v-col>
                  <v-col cols="2" class="pb-0">
                    <ValidationProvider
                      name="Kembali"
                      rules="required"
                      v-slot="{ errors }">
                      <v-text-field
                        v-model="item.kembali"
                        label="Kembali"
                        outlined
                        dense
                        hide-details
                        color="#0299B7"
                        :error-messages="errors">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="2" class="pb-0">
                    <ValidationProvider
                      name="Rusak"
                      rules="required"
                      v-slot="{ errors }">
                      <v-text-field
                        v-model="item.rusak"
                        label="Rusak"
                        outlined
                        dense
                        hide-details
                        color="#0299B7"
                        :error-messages="errors">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="2" class="pb-0">
                    <ValidationProvider
                      name="Diganti"
                      rules="required"
                      v-slot="{ errors }">
                      <v-text-field
                        v-model="item.diganti"
                        label="Diganti"
                        outlined
                        dense
                        hide-details
                        color="#0299B7"
                        :error-messages="errors">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </div>
              <div v-else class="text-center">
                Tidak ada penggunaan alat
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="reset(), dialog.pengembalian_alat = false;">
            Tutup
          </v-btn>
          <v-btn
            v-if="form.pengembalian_alat.length > 0"
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :disabled="process.run"
            @click="cekForm()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog pengembalian alat -->

    <!-- dialog tambah alat -->
    <v-dialog
      v-model="dialog.tambah_alat"
      scrollable
      persistent
      width="800">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Penambahan alat/bahan/instrumen</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="reset(), dialog.tambah_alat = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-8">
          <div class="color-first title font-weight-bold title mt-4 mb-8">
           Tambah alat/bahan/instrumen
          </div>
          <ValidationObserver ref="observer">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-menu
                    ref="menunow"
                    v-model="form.dialog.item_now"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    class="z-my"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <ValidationProvider
                        name="Tanggal"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          :value="formatDateNow()"
                          label="Tanggal"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form.dialog.now_date"
                      :min="reminder" 
                      v-on:change="changedate()" 
                      color="#0299B7"
                      format="D MMMM YYYY"
                      @input="form.dialog.item_now = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-for="(item, i) in form.abi" :key="i" class="background-row">
                <v-col cols="6" class="pb-0">
                  <ValidationProvider
                    name="Jenis"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="item.jenis"
                      :items="list.jenis"
                      label="Jenis"
                      color="#0299B7"
                      item-text="nama_jenis_barang"
                      item-value="id"
                      v-on:change='selectJenis(item, i)'
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="pb-0" v-if="item.jenis === 1">
                  <ValidationProvider
                    name="Alat"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="item.nama"
                      label="Nama"
                      color="#0299B7"
                      :items="list.alat"
                      item-text="nama_alat"
                      item-value="id"
                      v-on:change='selectName(i)'
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="pb-0" v-else-if="item.jenis === 2">
                  <ValidationProvider
                    name="Bahan"
                    rules="required|min:1"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="item.nama"
                      label="Nama"
                      color="#0299B7"
                      :items="list.bahan"
                      item-text="nama_bahan"
                      item-value="id"
                      v-on:change='selectName(i)'
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="pb-0" v-else-if="item.jenis === 3">
                  <ValidationProvider
                    name="Instrumen"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="item.nama"
                      label="Nama"
                      color="#0299B7"
                      :items="list.instrumen"
                      v-on:change='selectInstument(item, i)'    
                      item-text="nama_instrumen"
                      item-value="id"
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="pb-0" v-else>
                  <ValidationProvider
                    name="Nama"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      :disabled="isDisabledLab[i]"
                      :filled="isDisabledLab[i]"
                      v-model="item.nama"
                      label="Nama"
                      color="#0299B7"
                      :items="list.nama"
                      item-text="nama"
                      item-value="nama"
                      v-on:change='selectName(i)'
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="item.jenis !== 3 && isName[i]">
                  <ValidationProvider
                    name="Pemakaian"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      label="Pemakaian"
                      color="#0299B7"
                      :suffix="item.jenis === 2 ? item.satuan : ''"
                      :error-messages="errors"
                      v-model="item.pemakaian">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" class="pt-0" v-if="isName[i]" :class="{ 
                  'd-block': isFormulaInstumen[i] && item.jenis === 3, 
                  'd-none': !(isFormulaInstumen[i] && item.jenis === 3)}">
                  <ValidationProvider
                    name="Pemakaian">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      label="Pemakaian"
                      color="#0299B7"
                      :suffix="item.jenis === 3 ? 'formula' : ''"
                      v-model="item.pemakaian">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" md="6" class="pt-0" v-if="isName[i]" :class="{ 
                  'd-block': item.jenis === 3 && !isFormulaInstumen[i], 
                  'd-none': item.jenis !== 3 || isFormulaInstumen[i] }">
                  <ValidationProvider
                    name="Pemakaian"
                    :rules="'required'"
                    v-slot="{ errors }">
                    <v-menu
                      ref="menuTimeStart"
                      v-model="item.start_time"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.time_start"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.time_start"
                          label="Jam mulai"
                          prepend-inner-icon="mdi-clock"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          hide-details
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </template>
                      <v-time-picker
                        v-if="item.start_time"
                        v-model="item.time_start"
                        full-width
                        format="24hr"
                        color="#0299B7"
                        @click:minute="$refs.menuTimeStart && $refs.menuTimeStart[i].save(item.time_start)"
                      ></v-time-picker>
                    </v-menu>
                </ValidationProvider>
                </v-col>
                
                <v-col cols="12" md="6" class="pb-0 pt-0" v-if="isName[i]" :class="{ 
                  'd-block': item.jenis === 3 && !isFormulaInstumen[i], 
                  'd-none': item.jenis !== 3 || isFormulaInstumen[i] }">
                  <ValidationProvider
                    name="Pemakaian"
                    :rules="'required'"
                    v-slot="{ errors }">
                    <v-menu
                      ref="menuTimeEnd"
                      v-model="item.end_time"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="item.time_end"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.time_end"
                          label="Jam selesai"
                          prepend-inner-icon="mdi-clock"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          hide-details
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </template>
                      <v-time-picker
                        v-if="item.end_time"
                        v-model="item.time_end"
                        full-width
                        format="24hr"
                        color="#0299B7"
                        @click:minute="$refs.menuTimeEnd[i].save(item.time_end)"
                      ></v-time-picker>
                    </v-menu>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <div class="d-flex align-center">
                    <v-btn
                      v-if="i === (form.abi.length) - 1"
                      large
                      :disabled="process.run"
                      elevation="3"
                      color="#099a55"
                      @click="addForm()"
                      class="text-capitalize white--text mr-2">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="i > 0 || form.abi > 1"
                      large
                      elevation="3"
                      color="red"
                      @click="removeForm(i)"
                      class="text-capitalize white--text">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-5" v-if="isUpdate">
                <v-col cols="12" class="pb-0">
                  <div class="color-first title font-weight-bold">
                   Verifikasi penambahan
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-radio-group v-model="sort.selected_verification">
                    <v-radio
                      v-for="(status, index) in sort.status_verification"
                      :key="index" 
                      :label="status.text"
                      :value="status.id"
                      color="#0499b7"
                      class="py-0">
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="reset(), dialog.tambah_alat = false;">
            Tutup
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :disabled="process.run"
            @click="cekForm()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog tambah alat -->

    <!-- dialog detail -->
    <v-dialog
      v-model="dialog.detail"
      scrollable
      persistent
      width="850">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Detail tambah alat/bahan/instrumen</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="dialog.detail = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <v-row class="mb-2 align-center">
            <v-col cols="12" md="8">
             <div class="text-left font-weight-bold body-1">
              Penggunaan
             </div>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                :disabled="process.run"
                :hide-details="true"
                placeholder="Cari . . ."
                clearable
                color="#0299B7"
                v-model="filter.history_detail"
                @click:clear="filter.history_detail = ''; getDetailHistoryAddition();"
                v-on:keyup.enter="getDetailHistoryAddition();"
                prepend-inner-icon="mdi-magnify">
              </v-text-field>
            </v-col>
          </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
              :loading="process.detail"
              transition="scale-transition"
              :tile="false"
              :style="process.detail ? 'height: calc(50vh - 305px)' : ''"
              type="table-tbody">
              <v-data-table
                :headers="headers_detail"
                :items="row_detail"
                :items-per-page="10"
                class="elevation-1 required">
                <template slot="body.append">              
                  <tr>
                    <td colspan="8" class="text-left pl-4 font-weight-bold">Total tagihan</td>
                    <td class="font-weight-bold text-right">{{totalAmount | price}}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-skeleton-loader>
          </v-col>
        </v-row>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            color="#0299B7"
            elevation="0"
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.detail = false;">
            Kembali
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog detail -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.run"
            :disabled="process.run"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog edit alat-->
    <v-dialog
      v-model="dialog.edit_alat"
      scrollable
      persistent
      width="790">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Edit data kembali/rusak/diganti</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="dialog.edit_alat = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                dense
                outlined
                label="Kembali"
                color="#0299B7"
                v-model="form.dialog_edit_kembali">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                dense
                outlined
                label="Rusak"
                color="#0299B7"
                v-model="form.dialog_edit_rusak">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                dense
                outlined
                label="Diganti"
                color="#0299B7"
                v-model="form.dialog_edit_diganti">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            color="#0299B7"
            elevation="0"
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.edit_alat = false">
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit alat -->
    
    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div>
                  <div class="color-first title font-weight-bold title mt-3 pb-2">
                    Penggunaan disetujui
                  </div>
                  <!-- row table -->
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Judul</td>
                          <td width="1%">:</td>
                          <td>{{ detail.judul }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Lab</td>
                          <td width="1%">:</td>
                          <td>{{ detail.lab?.namaLaboratorium || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Waktu</td>
                          <td width="1%">:</td>
                          <td>{{ detail.tglMulai | date }} - {{ detail.tglSelesai | date }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Jam (setiap hari)</td>
                          <td width="1%">:</td>
                          <td>{{ detail.jamMulai || '-' }} - {{ detail.jamSelesai || '-' }} WIB </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <!-- end row table -->
                </div>
                <!-- row tabs -->
                <v-row>
                  <v-col cols="12">
                    <v-tabs 
                      :disabled="process.run"
                      color="#0299B7"
                      background-color="transparent"
                      style="border-bottom: 1px solid lightgray"
                      light
                      v-model="tab">
                      <v-tab v-for="item in items" :key="item">
                        {{ item }}
                      </v-tab>
                    </v-tabs>
                    <div v-if="tab === 0">
                      <v-row class="mt-5 justify-end">
                        <v-col cols="12" md="4" class="text-right">
                          <v-btn
                            :block="$vuetify.breakpoint.name === 'xs'"
                            large
                            :disabled="process.run"
                            color="#0299B7"
                            elevation="0"
                            class="white--text text-capitalize"
                            :class="$vuetify.breakpoint.name === 'xs' ? '' : 'mx-3'"
                            @click="openDialogAction()">
                            <v-icon>mdi-plus</v-icon>
                            Tambah alat/bahan/instrumen
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="mb-2 justify-end">
                        <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                          <div class="text-left font-weight-600 title ml-3">
                            Riwayat penambahan
                          </div>
                         </v-col>
                        <v-col cols="12" md="4" sm="6">
                          <v-text-field
                            outlined
                            dense
                            :disabled="process.run"
                            :hide-details="true"
                            placeholder="Cari . . ."
                            clearable
                            color="#0299B7"
                            class="mx-3"
                            v-model="filter.history"
                            @click:clear="filter.history = ''; getHistoryAddition();"
                            v-on:keyup.enter="getHistoryAddition();"
                            prepend-inner-icon="mdi-magnify">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card-text class="pa-2">
                            <v-skeleton-loader
                              :loading="process.row_riwayat"
                              transition="scale-transition"
                              :tile="false"
                              :style="process.row_riwayat ? 'height: calc(60vh - 305px)' : ''"
                              type="table-tbody">
                              <v-data-table
                                :headers="headers_riwayat"
                                :items="row_riwayat"
                                :items-per-page="10"
                                class="elevation-1 required">
                                  <template v-slot:[`item.status`]="{ item }">
                                    <td>
                                      <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                                    </td>
                                  </template>
                                  <template v-slot:[`item.aksi`]="{ item }">
                                    <v-btn
                                      x-small
                                      fab
                                      icon
                                      min-width="25"
                                      color="#0299B7"
                                      @click="detailDialog(item, 'detail')"
                                      class="text-capitalize color-first pa-0 mx-1">
                                      <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                    <v-btn
                                      v-if="item.kd_status_pengajuan === 10 || item.kd_status_pengajuan === 11"
                                      x-small
                                      fab
                                      icon
                                      min-width="30"
                                      color="orange"
                                      @click="editDialog(item, 'edit')"
                                      class="text-capitalize color-first pa-0 mx-1">
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                              </v-data-table>
                            </v-skeleton-loader>
                          </v-card-text>           
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="tab === 1">
                      <v-row class="mt-5 justify-end">
                        <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                        <div class="text-left font-weight-600 title ml-3">
                          Total penggunaan
                        </div>
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                          <v-text-field
                            outlined
                            dense
                            :disabled="process.run"
                            :hide-details="true"
                            placeholder="Cari . . ."
                            clearable
                            color="#0299B7"
                            class="mx-3"
                            v-model="filter.total_usage"
                            @click:clear="filter.total_usage = ''; getTotalUsage();"
                            v-on:keyup.enter="getTotalUsage();"
                            prepend-inner-icon="mdi-magnify">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card-text class="pa-2">
                            <v-skeleton-loader
                              :loading="process.row_penggunaan"
                              transition="scale-transition"
                              :tile="false"
                              :style="process.row_penggunaan ? 'height: calc(60vh - 305px)' : ''"
                              type="table-tbody">
                              <v-data-table
                                :headers="headers_penggunaan"
                                :items="row_penggunaan"
                                :items-per-page="10"
                                class="elevation-1 required">
                                <template slot="body.append">              
                                  <tr>
                                    <td colspan="7" class="text-left pl-4 font-weight-bold"></td>
                                    <td class="font-weight-bold text-right">{{totalAmountUsage | price}}</td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-skeleton-loader>
                          </v-card-text>           
                        </v-col>
                      </v-row>
                    </div> 
                    <div v-if="tab === 2">
                      <v-row class="mt-5 justify-end">
                        <v-col cols="12" md="4" class="text-right">
                          <v-btn
                            :block="$vuetify.breakpoint.name === 'xs'"
                            large
                            :disabled="process.run"
                            color="#0299B7"
                            elevation="0"
                            class="white--text text-capitalize"
                            :class="$vuetify.breakpoint.name === 'xs' ? '' : 'mx-3'"
                            @click="openDialogReturnTools()">
                            <v-icon class="mr-2" size="20">mdi-flask</v-icon>
                            Ubah status pengembalian alat
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="mt-5 justify-end">
                        <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                          <div class="text-left font-weight-600 title ml-3">
                           Pengembalian alat
                          </div>
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                          <v-text-field
                            outlined
                            dense
                            :disabled="process.run"
                            :hide-details="true"
                            placeholder="Cari . . ."
                            clearable
                            color="#0299B7"
                            class="mx-3"
                            v-model="filter.return_tool"
                            @click:clear="filter.return_tool = ''; getToolReturn();"
                            v-on:keyup.enter="getToolReturn();"
                            prepend-inner-icon="mdi-magnify">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card-text class="pa-2">
                            <v-skeleton-loader
                              :loading="process.row_pengembalian"
                              transition="scale-transition"
                              :tile="false"
                              :style="process.row_pengembalian ? 'height: calc(60vh - 305px)' : ''"
                              type="table-tbody">
                              <v-data-table
                                :headers="header_pengembalian"
                                :items="row_pengembalian"
                                :items-per-page="10"
                                class="elevation-1 required">
                              </v-data-table>
                            </v-skeleton-loader>
                          </v-card-text>           
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <!-- end row tabs -->

                <!-- row selesai penggunaan -->
                <v-row class="mb-7">
                  <v-col cols="12" class="pb-0">
                    <div class="color-first title font-weight-bold title mt-3">
                      Persetujuan selesai penggunaan
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="isUii">
                    <div class="background-blue">
                      <div>
                        <v-icon color="#0077BB" class="mr-2">mdi-information</v-icon>
                      </div>
                      <div>
                        <span>Harap <b>ubah status pengembalian alat</b> pada tab Pengembalian alat terlebih dahulu sebelum selesai penggunaan lab.</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      class="mt-0"
                      v-model="switch_pengggunaan"
                      :disabled="isDisabledSwitch"
                      label="Selesai penggunaan lab"
                      color="#0499b7"
                      value = "true"
                      hide-details
                    ></v-switch>
                    <div class="mt-8" v-if="switch_pengggunaan === 'true'">
                      <v-text-field
                        dense
                        outlined
                        label="Catatan (Optional)"
                        color="#0299B7"
                        v-model="form.catatan">
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <!-- end row -->

                <!-- row simpan -->
                <v-card-actions class="mt-4 justify-center">
                  <v-btn
                    large
                    width="120"
                    elevation="0"
                    :disabled="process.run"
                    class="text-capitalize"
                    @click="toHome()">
                    Batal
                  </v-btn>
                  <v-btn
                    v-if="switch_pengggunaan === 'true'"
                    large
                    width="120"
                    color="#0299B7"
                    elevation="0"
                    :loading="process.run"
                    @click="cekForm()"
                    class="white--text text-capitalize">
                    simpan
                  </v-btn>
                </v-card-actions>
                <!-- end row -->
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import { TokenService } from '@/service/Storage.Service';
import { get, put } from "@/service/Axios";
import { serialize } from 'object-to-formdata';
import statusMixin from '@/mixin';
import moment from 'moment';
import 'moment/locale/id';
import LoadingComponent from '@/components/Loading.vue'
import Snackbar from '@/components/Snackbar.vue';
export default {
  mixins: [statusMixin],
  data() {
    return {
      dialog: {
        tambah_alat: false,
        detail: false,
        konfirmasi: false,
        edit_alat: false,
        pengembalian_alat: false,
      },
      detailUuid: null,
      error: {
        jam_mulai: '',
        jamk_selesai: ''
      },
      tab: null,
      filter: {
        history: '',
        history_detail: '',
        total_usage: '',
        return_tool: ''
      },
      detail: {},
      url: '',
      totalAmount: 0,
      totalAmountUsage: 0,
      process: {
        run: false,
        detail: false,
        row_riwayat: false,
        row_penggunaan: false,
        row_pengembalian: false
      },
      isDisabledLab: [true],
      isFormulaInstumen: [false],
      isUpdate: false,
      save_type: '',
      isVerification: false,
      isReturnTools: false,
      isDisabledSwitch: false,
      isName: [false],
      form: {
        dialog: {
          now_date: '',
          item_now: false,
          jawaban_pg: [],
          komentar: [],
          isian: [],
        },
        abi: [
         {
            jenis: '',
            nama: '',
            pemakaian: '',
            time_start: '',
            time_end: '',
            start_time:false,
            end_time:false,
            end_time_min: '',
            start_time_min: '',
            satuan: ''
          }
        ],
        pengembalian_alat: [
          {
            uuid: '',
            kembali: '',
            rusak: '',
            diganti: '',
            jenis: '',
            kode: '',
            nama: '',
            total: ''
          }
        ],
        catatan :'',
        dialog_edit_kembali: '',
        dialog_edit_rusak: '',
        dialog_edit_diganti: '',
      },
      list: {
        jenis: [],
        alat: [],
        bahan: [],
        instrumen: [],
      },
      switch_pengggunaan: null,
      snackbar: false,
      text: '',
      timeout: 3000,
      headers_riwayat: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'Tanggal', value: 'tanggal', sortable: false, },
        { text: 'Status', value: 'status', sortable: false, },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya',sortable: false, },
        {  text: 'Aksi', value: 'aksi', align: 'center', width: '12%' },
      ],
      headers_detail: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'Tanggal', value: 'tanggal', sortable: false, },
        { text: 'Jenis', value: 'jenis', sortable: false, },
        { text: 'Kode', value: 'kode',sortable: false, },
        { text: 'Nama', value: 'nama_barang',sortable: false, },
        { text: 'Pemakaian', value: 'pemakaian', sortable: false, align: 'right'},
        { text: 'Satuan', value: 'satuan',sortable: false, },
        { text: 'Harga satuan (Rp)', value: 'harga_satuan', sortable: false, align: 'right', width: '13%'},
        { text: 'Harga total (Rp)', value: 'harga_total', sortable: false, align: 'right'},
      ],
      headers_penggunaan: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'Jenis', value: 'jenis_barang', sortable: false, },
        { text: 'Kode', value: 'kode_barang', sortable: false, },
        { text: 'Nama', value: 'nama_barang', sortable: false, },
        { text: 'Pemakaian', value: 'pemakaian', sortable: false, align: 'right'},
        { text: 'Satuan', value: 'satuan', sortable: false},
        { text: 'Harga satuan (Rp)', value: 'harga_satuan', sortable: false, align: 'right'},
        { text: 'Harga total (Rp)', value: 'harga_total', sortable: false, align: 'right'},
      ],
      header_pengembalian: [
      { text: 'No.', value: 'no', sortable: false, },
      { text: 'Jenis', value: 'jenis_barang', sortable: false, },
      { text: 'Kode', value: 'kode_barang', sortable: false, },
      { text: 'Nama', value: 'nama_barang', sortable: false, },
      { text: 'Total', value: 'total', sortable: false, align: 'right'},
      { text: 'Kembali', value: 'kembali', sortable: false, align: 'right'},
      { text: 'Rusak', value: 'rusak', sortable: false, align: 'right'},
      { text: 'Diganti', value: 'diganti', sortable: false, align: 'right'},
      ],
      isUii: false,
      row_detail: [],
      row_riwayat: [],
      row_penggunaan: [],
      row_pengembalian: [],
      items: ['Riwayat penambahan', 'Total penggunaan', 'Pengembalian alat'],
      sort: {
        selected_verification: '1',
        status_verification: [
          {
            id: '1',
            text: 'Disetujui'
          },
          {
            id: '2',
            text: 'Ditolak'
          },
        ],
      },
    }
  },
  components: {
    LoadingComponent,
    Snackbar
  },
  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },

  mounted () {
    this.fetch();
  },

  methods: {
    fetch() {
      this.getReserch();
    },

    async getObjectType() {
      try {
        const response = await get(`object-type`);
        if (response.status === 200) {
          const res = response.data.data;
          const jenis = res.map(item => ({
            id: item.id,
            kd_jenis_barang: item.kdJenisBarang,
            nama_jenis_barang: item.namaJenisBarang
          }));
          this.list.jenis = jenis
          this.form.dialog.jenis = jenis;
         
        } else {
          throw new Error(`Failed to fetch data: ${response.info}`);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getAbi() {
      await Promise.all([
        this.getObjectType(),
        this.getAlat(),
        this.getBahan(),
        this.getInstrumen()
      ]);
    },

    async getAlat() {
      try {
        const params = { id_laboratorium: this.detail?.idLaboratorium }
        const { data, status } = await get(`tool`, {params});
        if (status === 200 && data.data) {
          const alat = data.data.map((item) => ({
            id: item.id,
            nama_alat: item.namaAlat,
          }));
          this.list.alat = alat
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getBahan() {
      try {
        const params = { id_laboratorium: this.detail?.idLaboratorium }
        const { data, status } = await get(`item`, {params});
        if (status === 200 && data.data) {
          console.log('haiaaa', data.data);
          const bahan = data.data.map((item) => ({
            id: item.id,
            kd_bahan: item.kdBahan,
            nama_bahan: item.namaBahan,
            satuan: item.satuan
          }));
          this.list.bahan = bahan
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getInstrumen() {
      try {
        const params = { id_laboratorium: this.detail?.idLaboratorium }
        const { data, status } = await get(`instrument`, {params});
        if (status === 200 && data.data) {
          const instrumen = data.data.map((item) => ({
            id: item.id,
            kd_instrumen: item.kdInstrumen,
            nama_instrumen: item.namaInstrumen,
            id_laboratorium: item.idLaboratorium,
            satuan: item.satuan
          }));
          this.list.instrumen = instrumen
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getReserch() {
      const email = this.user.email;
      this.isUII = email.endsWith("uii.ac.id") ? false : true;
      this.process.run = true;
      this.process.loading = true;
      const uuid = TokenService.getUuid();
      const flag_officer = 1
      const params = { uuid, flag_officer };
      try {
        const response = await get(`research`, {params});
        if (response.status === 200) {
          const res = response.data.data;
          this.detail = res[0];
          if (this.detail.flagDapatMengajukanSelesai === false) {
            this.isDisabledSwitch = true
          } else {
            this.isDisabledSwitch = false
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
        this.process.loading = false;
        this.getHistoryAddition();
        this.getTotalUsage();
        this.getToolReturn();
      }
    },

    async getHistoryAddition() {
      this.process.row_riwayat = true
      const params = {
        uuid: this.detail?.uuid,
        search: this.filter.history
      }
      try {
        const response = await get(`research-usage/history`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.row_riwayat = res.map((data, index) => {
            data.createdAt = this.$callbackdatetime(data.createdAt)
            return {
              no: index + 1,
              tanggal: data.createdAt + ' WIB',
              status: data.statusPengajuan,
              kd_status_pengajuan: data.kdStatusPengajuan,
              proses_selanjutnya: data.prosesSelanjutnya,
              uuid: data.uuid
            }
          });
          this.process.row_riwayat = false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getTotalUsage() {
      this.process.row_penggunaan = true
      const params = {
        uuid: this.detail?.uuid,
        search: this.filter.total_usage
      }
      try {
        const response = await get(`research-usage/usage`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.row_penggunaan = res.map((data, index) => {
            const hargaSatuan = data.hargaSatuan.toLocaleString('id-ID');
            const hargaTotal = data.hargaTotal.toLocaleString('id-ID');
            return {
              no: index + 1,
              jenis_barang: data.namaJenisBarang,
              kode_barang: data.kdBarang,
              nama_barang: data.namaBarang,
              pemakaian: data.jlhPenggunaan,
              satuan: data.satuan,
              harga_satuan: hargaSatuan,
              harga_total: hargaTotal,
            }
          });
          this.totalAmountUsage = response.data.total
          this.process.row_penggunaan = false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getToolReturn() {
      this.process.row_pengembalian = true
      const params = {
        uuid: this.detail?.uuid,
        search: this.filter.return_tool
      }
      try {
        const response = await get(`research-usage/return-tool`, {params});
        if (response.status === 200) {
          const res = response.data.data;
          const pengembalian = await res;
          this.row_pengembalian = res.map((data, index) => {
            return {
              no: index + 1,
              jenis_barang: data.namaJenisBarang,
              kode_barang: data.kdBarang,
              nama_barang: data.namaBarang,
              total: data.jlhPenggunaan,
              kembali: data.jlhKembali,
              rusak: data.jlhRusak,
              diganti: data.jlhDiganti,
            }
          });
          const newData = pengembalian.map((item) => ({
            uuid: item.uuid,
            jenis: item.namaJenisBarang,
            kode: item.kdBarang,
            nama: item.namaBarang,
            total: item.jlhPenggunaan,
            kembali: item.jlhKembali,
            rusak: item.jlhRusak,
            diganti: item.jlhDiganti,
          }));
          this.form.pengembalian_alat = newData;
          this.process.row_pengembalian = false
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getDetailHistoryAddition(type) {
      this.process.detail = true;
      const params = {
        uuid: this.detailUuid,
        search: this.filter.history_detail
      }
      try {
        const response = await get(`research-usage/history-detail`, {params});
        if (response.status === 200) {
          const res = response.data.data
          const riwayat = await res
          if (type === 'detail') {
            this.row_detail = res.map((data, index) => {
              data.createdAt = this.$callbackdatetime(data.createdAt);
              const hargaSatuan = data.hargaSatuan.toLocaleString('id-ID');
              const hargaTotal = data.hargaTotal.toLocaleString('id-ID');
              return {
                no: index + 1,
                tanggal: data.createdAt + ' WIB',
                jenis: data.namaJenisBarang,
                kode: data.kdBarang,
                nama_barang: data.namaBarang,
                pemakaian: data.jlhPenggunaan,
                satuan: data.satuan,
                harga_satuan: hargaSatuan,
                harga_total: hargaTotal,
                uuid: data.uuid
              }
            });
            const total = response.data.total
            this.totalAmount = total
          } else {
            const newData = riwayat.map((item) => ({
              jenis: item.idJenisBarang,
              nama: item.idBarang,
              pemakaian: item.jlhPenggunaan,
              time_start: item.jamMulai,
              time_end: item.jamSelesai,
              start_time: false,
              end_time: false,
              end_time_min: '',
              start_time_min: '',
            }));
            this.form.dialog.now_date = riwayat[0].tglPenggunaan;
            const formulaInstumen = riwayat.map((item) => item.satuan === 'formula');
            this.isFormulaInstumen = formulaInstumen;
            this.form.abi = newData;
            this.isName = this.form.abi.map(item => item.nama !== '');
            const total = response.data.total
            this.totalAmount = total
          }
          this.process.detail = false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    selectName(i) {
      const value = this.form.abi[i].nama === ''
      const satuan = this.list.bahan.find(x => x.id === this.form.abi[i].nama)
      this.form.abi[i].satuan = satuan?.satuan
      if (value) {
        this.$set(this.isName, i, false);
      } else {
        this.$set(this.isName, i, true);
      }
    },

    selectInstument(data, i) {
      const val = this.form.abi[i].nama === ''
      if (val) {
        this.$set(this.isName, i, false);
      } else {
        this.$set(this.isName, i, true);
      }
      const value = this.list.instrumen.find(x => x.id === data.nama)
      this.$set(this.isFormulaInstumen, i, (value.satuan === 'formula') ? true : false);
    },

    async selectJenis(data, i) {
      this.dialog.konfirmasi = false
      this.error.field = ''
      this.isDisabledLab[i] = false
      this.isName[i] = false
      const params = {
        id_laboratorium: this.detail.idLaboratorium
      }
      let url = '';
      let targetList = null;
      switch (data.jenis) {
        case 1:
          url = 'tool';
          targetList = this.list.alat;
          break;
        case 2:
          url = 'item';
          targetList = this.list.bahan
          break;         
        default:
          url = 'instrument';
          targetList = this.list.instrumen
          break;
      }

      await get(url, {params}).then((response) => {
        if (response.status === 200) {
          targetList.splice(0, targetList.length);
          const res = response.data.data;
          for (const i in res) {
            if (data.jenis === 1) {
              targetList.push({
                id: res[i].id,
                nama_alat: res[i].namaAlat
              });
            } else if (data.jenis === 2) {
              targetList.push({
                id: res[i].id,
                kd_bahan: res[i].kdBahan,
                nama_bahan: res[i].namaBahan,
                satuan: res[i].satuan
              });
            } else {
              targetList.push({
                id: res[i].id,
                kd_instrumen: res[i].kdInstrumen,
                nama_instrumen: res[i].namaInstrumen,
                id_laboratorium: res[i].idLaboratorium,
                satuan: res[i].satuan
              });
            }
          }
        }
      });
    },
    
    addForm() {
      let data =  {
        jenis: '',
        nama: '',
        pemakaian: '',
        time_start: '',
        time_end: '',
        start_time:false,
        end_time:false,
        end_time_min: '',
        start_time_min: '',
        satuan: ''
      }
      this.isDisabledLab.push(true);
      this.isFormulaInstumen.push(false);
      this.isName.push(false);
      this.form.abi.push(data);
    },

    removeForm(index) {
       this.form.abi.splice(index, 1)
    },

    changedate() {
      this.form.dialog.now_date = this.reminder
      this.formatDateNow()
    },

    formatDateNow() {
      return this.form.dialog.now_date ? moment(this.form.dialog.now_date ).format('DD MMMM YYYY') : '' 
    },

    async openDialogAction() {
      this.dialog.tambah_alat = true
      this.isUpdate = false
      this.isVerification = false
      this.save_type = 'creates'
      await this.getObjectType();
    },

    openDialogEdit(data) {
      const { kembali, rusak, diganti } = data;
      this.dialog.edit_alat = true;
      this.form.dialog_edit_kembali = kembali;
      this.form.dialog_edit_rusak = rusak;
      this.form.dialog_edit_diganti = diganti;
    },

    async editDialog(data, type) {
      const { uuid } = data
      this.detailUuid = uuid
      this.getDetailHistoryAddition(type).then(() => {
        this.dialog.tambah_alat = true
        this.getAbi();
      });
      this.isUpdate = data.kd_status_pengajuan === 11 ? false : true
      this.isVerification = data.kd_status_pengajuan === 10 ? true : false
      this.isName = true
      if (data.kd_status_pengajuan === 10) {
        this.save_type = 'verif'
      } else if (data.kd_status_pengajuan === 11) {
        this.save_type = 'updates'
      }
    },

    openDialogReturnTools() {
      this.dialog.pengembalian_alat = true;
      this.save_type = 'return';
      this.getToolReturn();
    },

    detailDialog(data, type) {
      const { uuid } = data
      this.dialog.detail = true
      this.detailUuid = uuid
      this.getDetailHistoryAddition(type)
    },

    reset() {
      this.$refs.observer.reset()
      this.isDisabledLab = [true];
      this.isFormulaInstumen = [false],
      this.isName = [false];
      this.isUpdate = false
      this.save_type = ''
      this.form.abi = [
        {
          jenis: '',
          nama: '',
          pemakaian: '',
          time_start: '',
          time_end: '',
          start_time: false,
          end_time: false,
          end_time_min: '',
          start_time_min: '',
          satuan: '',
        }
      ]
      this.form.pengembalian_alat = [
        {
          uuid: '',
          kembali: '',
          rusak: '',
          diganti: '',
          jenis: '',
          kode: '',
          nama: '',
          total: ''
        }
      ]
      this.form.dialog.jawaban_pg = [],
      this.form.dialog.komentar = [],
      this.form.dialog.isian = []
    },

    async cekForm() {
      if (this.isDisabledSwitch) {
        const isValid = await this.$refs.observer.validate()
        let isValidText = true;
        for (let index = 0; index < this.form.abi.length; index++) {
          const element = this.form.abi[index];
          if (element.pemakaian === '') {
            isValidText = false;
            this.error.field = 'Kolom tidak boleh kosong';
            break;
          } else {
            this.error.field = '';
          }
        }
        console.log('isValid', isValid);
        console.log('isValidText', isValidText);
        this.dialog.konfirmasi = this.save_type === 'return' ? (isValid) : (isValid && isValidText)
      } else {
        this.dialog.konfirmasi = true 
      }
    },

    async save() {
      switch(this.save_type) {
        case 'creates':
          this.creates();
          break;
        case 'updates':
          this.updates();
          break;
        case 'verif':
          this.updates_verification();
          break;
        case 'return':
          this.updates_return_tools();
          break;
        default:
          this.updates_done_usage();
      }
    },

    async creates() {
      this.process.run = true;
      this.process.row_riwayat = true;
      const { token } = this
      const penggunaan = this.form.abi.map((element) => ({
        jam_mulai: element.time_start,
        jam_selesai: element.time_end,
        id_jenis_barang: element.jenis,
        id_barang: element.nama,
        jlh_penggunaan: element.pemakaian
      }));

      const value = {
        uuid_penelitian: this.detail?.uuid,
        tgl_penggunaan: this.form.dialog.now_date,
        penggunaan
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}research-usage`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah_alat = false;
        this.text = "Data penggunaan berhasil disimpan";
        this.snackbar = true;
        this.process.run = false;
        this.process.row_riwayat = false;
        this.getHistoryAddition();
        this.reset();
        // this.$refs.observer.reset()
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah_alat = false;
      }
    },

    async updates() {
      this.process.run = true;
      this.process.row_riwayat = true;
      const penggunaan = this.form.abi.map((element) => ({
        jam_mulai: element.time_start,
        jam_selesai: element.time_end,
        id_jenis_barang: element.jenis,
        id_barang: element.nama,
        jlh_penggunaan: element.pemakaian
      }));

      const params = {
        uuid: this.detailUuid,
        tgl_penggunaan: this.form.dialog.now_date,
        penggunaan
      };

      const response = await put(`research-usage`, { params });
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah_alat = false;
        this.text = response.data.info;
        this.snackbar = true;
        this.process.run = false;
        this.process.row_riwayat = false;
        this.getHistoryAddition();
        this.reset();
        // this.$refs.observer.reset()
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah_alat = false;
      }
    },

    async updates_verification() {
      this.process.run = true;
      this.process.row_riwayat = true;
      const penggunaan = this.form.abi.map((element) => ({
        jam_mulai: element.time_start,
        jam_selesai: element.time_end,
        id_jenis_barang: element.jenis,
        id_barang: element.nama,
        jlh_penggunaan: element.pemakaian
      }));

      const params = {
        uuid: this.detailUuid,
        tgl_penggunaan: this.form.dialog.now_date,
        flag_persetujuan: this.sort.selected_verification,
        penggunaan
      };

      const response = await put(`research-usage/verification`, { params });

      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah_alat = false;
        this.text = response.data.info;
        this.snackbar = true;
        this.process.run = false;
        this.process.row_riwayat = false;
        this.getReserch();
        this.reset();
        // this.$refs.observer.reset()
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah_alat = false;
      }
    },

    async updates_return_tools() {
      this.process.run = true;
      this.process.row_pengembalian = true;
      const pengembalian = this.form.pengembalian_alat.map((element) => ({
        uuid: element.uuid,
        jlh_kembali: element.kembali,
        jlh_rusak: element.rusak,
        jlh_diganti: element.diganti,
      }));

      const params = {
        pengembalian
      };

      const response = await put(`research-usage/return-tool`, { params });

      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.pengembalian_alat = false;
        this.text = response.data.info;
        this.snackbar = true;
        this.process.run = false;
        this.process.row_pengembalian = false;
        this.getToolReturn();
        this.reset();
        // this.$refs.observer.reset()
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.pengembalian_alat = false;
      }
    },

    async updates_done_usage() {
      this.process.run = true;

      const params = {
        uuid_penelitian: this.detail?.uuid,
        catatan: this.form.catatan
      };

      const response = await put(`research/done-usage`, { params });

      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.text = response.data.info;
        this.snackbar = true;
        this.process.run = false;
        this.process.row_pengembalian = false;
        this.toHome();
        this.reset();
        // this.$refs.observer.reset()
      } else {
        this.dialog.konfirmasi = false;
      }
    },

  

    toHome() {
      TokenService.removeUUID(TokenService.getUuid())
      history.back()
      // this.$router.push(`/penggunaan-laboran`)
    }
  },
}
</script>

<style scoped>
  .background-row {
    padding-top: 12px;
    margin-bottom: 13px;
    /* border-bottom: 1px solid grey; */
    /* background: #EBF0F5; */
    border-radius: 8px;
  }
  .background-row-perubahan {
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
    margin-bottom: 13px;
    /* background: #EBF0F5; */
    /* border-radius: 8px; */
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    background-color: #ffffff;
  }
</style>