<template>
  <div>
    <LoadingComponent v-if="process.loading"></LoadingComponent>
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
          <v-spacer />
          <v-btn color="#0299B7" small elevation="0" fab class="text-capitalize color-first"
            @click="dialog.konfirmasi = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            <span>Simpan data yang diinputkan ?</span>
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn outlined class="text-capitalize color-first mx-1" :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
          <v-btn color='#0299B7' elevation="0" class="text-capitalize white--text mx-1" :loading="process.form"
            :disabled="process.form" @click="save()">
            Ya,
            <span>simpan</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog edit -->
    <v-dialog v-model="dialog.edit" persistent width="700">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Edit metode uji</span>
          <v-spacer />
          <v-btn color="#0299B7" small elevation="0" fab class="text-capitalize color-first"
            @click="dialog.edit = false, reset()">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 px-2 text-center mt-2">
          <v-card-subtitle class="pt-0">
            <ValidationObserver ref="observer">
              <v-form>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-text-field v-model="form.hasil_uji" outlined label="Metode uji" dense hide-details color="#0299B7">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn outlined class="text-capitalize color-first mx-1" :disabled="process.form" @click="dialog.edit = false;">
            Batal
          </v-btn>
          <v-btn color="#0299B7" elevation="0" class="text-capitalize white--text mx-1" :disabled="process.form"
            @click="save_type = 'edit', cekForm()">
            <span>Simpan</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit -->

    <!-- dialog download -->
    <v-dialog v-model="dialog.download" scrollable persistent width="400">
      <v-card flat>
        <v-card-text class="text-center">
          <p class="mb-0 mt-3">Proses download pdf....</p>
          <div class="mt-2">
            <v-progress-linear color="#0299B7" indeterminate rounded height="6">
            </v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog download -->

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <!-- sampel -->
                <ValidationObserver ref="observer">
                  <v-form>
                    <v-row v-for="(item, i) in detail.sample" :key="i">
                      <v-col cols="12">
                        <div class="color-first title font-weight-bold title mt-3 pb-2">
                          Sampel {{ i + 1 }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Kode sampel</td>
                            <td width="1%">:</td>
                            <td>{{ item.kodeSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Nama</td>
                            <td width="1%">:</td>
                            <td>{{ item.nama || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Jumlah</td>
                            <td width="1%">:</td>
                            <td>{{ item.jumlah || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Bentuk </td>
                            <td width="1%">:</td>
                            <td>{{ item.phase.namaBentukSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Penyimpanan</td>
                            <td width="1%">:</td>
                            <td>{{ item.storage.namaPenyimpananSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Kondisi</td>
                            <td width="1%">:</td>
                            <td>{{ item.condition.namaKondisiSampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Kemasan</td>
                            <td width="1%">:</td>
                            <td>{{ item.kemasan || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%" class="td-flex">Gambar</td>
                            <td width="1%">:</td>
                            <td>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header color="#0299B7">
                                    <template v-slot:actions>
                                      <v-icon color="white">
                                        $expand
                                      </v-icon>
                                    </template>
                                    <span class="white--text"></span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <div>
                                      <iframe
                                        v-if="item.url && item.url?.startsWith('data:application/pdf') || item.url?.startsWith('blob:http://')"
                                        width="100%" :height="item.url === null ? '110px' : '500px'" :src="item.url"
                                        :lazy-src="item.url" frameborder="0" allowfullscreen>
                                        Oops! an error has occurred.
                                      </iframe>
                                      <v-img v-else-if="item.url && item.url?.startsWith('data:image')" :src="item.url"
                                        :lazy-src="item.url" width="100%" aspect-ratio="2">
                                      </v-img>
                                    </div>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </td>
                          </tr>
                        </table>
                      </v-col>

                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Status penerimaan sampel</td>
                            <td width="1%">:</td>
                            <td>
                              {{ item.status.statusSampel || '-' }}
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Tanggal penerimaan sampel</td>
                            <td width="1%">:</td>
                            <td>
                              {{ item.tglPenerimaanSampel | date }}
                            </td>
                          </tr>
                          <tr v-for="(data, j) in item.parameter" :key="j + 'a'">
                            <td width="35%" style="display: flex;">Parameter uj {{ j + 1 }}</td>
                            <td width="1%">:</td>
                            <td>
                              {{ data.parameterTest.namaParameterUji || '-' }}
                              | <span class="caption" :class="getColor(data.status.kdStatusSampelParameter)"> {{
                                data.status.statusSampelParameter || '-' }} </span>
                            </td>
                          </tr>
                          <tr v-for="(data, x) in item.parameter" :key="x + 'b'">
                            <td width="30%">Metode uji {{ x + 1 }}</td>
                            <td width="1%">:</td>
                            <td>
                              {{ data.parameter?.metodeUji || data?.parameterTest?.metodeUji }}
                              <v-btn x-small fab icon min-width="25" color="#0299B7" :disabled="process.run"
                                @click="openDialogEdit(data.parameter?.metodeUji || data?.parameterTest?.metodeUji)"
                                class="text-capitalize color-first pa-0">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <v-row v-if="isEdit">
                      <v-col cols="12" class="pb-0">
                        <ValidationProvider
                          name="Nomor"
                          rules="required"
                          v-slot="{ errors }">
                          <v-text-field v-model="form.nomor" outlined label="Nomor" dense color="#0299B7" :error-messages="errors"
                          require>
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field v-model="form.keterangan" outlined label="Keterangan (Optional)" dense
                          color="#0299B7">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field v-model="form.catatan" outlined label="Catatan  (Optional)" dense
                          color="#0299B7">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </ValidationObserver>
                <!-- sampel -->


              </v-card-text>
              <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
              <v-card-actions class="mt-2 pb-5 justify-center">
                <v-btn outlined class="text-capitalize color-first mx-1" :disabled="process.form" @click="toPage()">
                  Kembali
                </v-btn>
                <v-btn v-if="isEdit" color="#0299B7" elevation="0" class="text-capitalize white--text mx-1"
                  :loading="process.save" :disabled="process.save" @click="cekForm()">
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- pdf -->
    <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1400" filename="Lhu" :pdf-quality="2" :manual-pagination="false"
        pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf">
        <section slot="pdf-content">
          <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files" />
        </section>
      </vue-html2pdf>
    </div>
    <!-- end pdf -->
  </div>
</template>
  
<script>
import LoadingComponent from '@/components/Loading.vue'
import { TokenService } from "@/service/Storage.Service";
import { get, put } from "@/service/Axios";
import Pdf from "@/components/Pdf.vue"
import Snackbar from '@/components/Snackbar.vue';
export default {
  data() {
    return {
      detail: {},
      dialog: {
        edit: false,
        konfirmasi: false,
        download: false
      },
      form: {
        sampel: [],
        hasil_uji: '',
        nomor: '',
        keterangan: '',
        catatan: ''
      },
      files: null,
      file: [],
      filePrint: '',
      isEdit: false,
      process: {
        run: false,
        loading: false,
        form: false
      },
      snackbar: false,
      text: '',
      timeout: 4000,
      isUii: false,
      nilai_parameter: 'lhu',
    }
  },

  components: {
    LoadingComponent,
    Pdf,
    Snackbar
  },

  mounted() {
    if (TokenService.getUuid() === null) {
      this.$router.push(`lhu-kalab-lpmok`)
    } else {
      const params = this.$route.params.page
      if (params.endsWith("edit")) {
        this.isEdit = true
      } else { 
        this.isEdit = false
      }
      this.printReport()
    }
  },
  computed: {
    pdf () {
      return this.$store.state.data_pdf
    }
  },
  methods: {
    async printReport() {
      let data
      try {
        const params = {
          uuid: TokenService.getUuid()
        }
        get(`test-report/print`, { params }).then((response) => {
          if (response.status === 200) {
            data = response.data.data
            this.$store.dispatch('getDataPdf', data);
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.$refs.pdf.downloadPdf(data, this.$refs.html2Pdf);
        this.fetch()
      }
    },

    async fetch() {
      this.process.loading = true
      try {
        this.process.run = true;
        const uuid = TokenService.getUuid();
        const params = {
          uuid,
          flag_officer: '1'
        };
        const response = await get(`test-report`, { params });
        if (response.status === 200) {
          this.detail = response.data.data[0];
          const email = this.detail?.user.email
          if (email.endsWith("uii.ac.id")) {
            this.isUii = true
          } else {
            this.isUii = false
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.getFile();
      }
    },

    async getFile() {
      try {
        let params = [];
        this.detail.sample.forEach(element => {
          params.push({ url: element.fileGambar });
        });
        for (let index = 0; index < params.length; index++) {
          const response = await get(`file-test`, { params: params[index] });
          if (response.status === 200) {
            let res = response.data;
            this.detail.sample[index].url = res.data || null;
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.process.loading = false
        this.process.run = false
      }
    },

    getColor(status) {
      switch (status) {
        case 1:
          return 'grey--text'
        case 3:
          return 'green--text';
        case 4:
          return 'red--text';
        default:
          return '';
      }
    },

    async openDialogEdit(item) {
      this.form.hasil_uji = item
      this.dialog.edit = true
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.dialog.konfirmasi = isValid 
      }
    },

    async save() {
      this.updates();
    },

    async updates() {
      this.process.save = true
      const { form } = this
  
      const params = {
        no_surat: form.nomor,
        catatan_surat: form.catatan,
        keterangan: form.keterangan,
        uuid: TokenService.getUuid(),
      };
      const response = await put(`test-report/report-available`, { params });
      if (response.status === 200) {
        this.dialog.konfirmasi = false;
        this.text = 'Data parameter berhasil diubah';
        this.snackbar = true;
        this.process.save = false
        this.reset()
        this.toPage();

      } else {
        this.dialog.konfirmasi = false;
      }

    },

    toPage() {
      this.$router.push(`/lhu-kalab-lpmok`);
    },

    reset() {
      this.form.sampel = [],
      this.form.hasil_uji = '',
      this.form.nomor = '',
      this.form.keterangan = '',
      this.form.catatan = ''
    }
  },
}
</script>
  
<style lang="scss" scoped></style>