<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
       <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
          <!-- <v-alert type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert> -->
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.run"
            :disabled="process.run"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <div  v-if="!process.loading">
      <v-row class="mt-3 px-3">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div class="color-first title font-weight-bold title mt-3 pb-2">
                    Data diri
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Nama lengkap</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.nama }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Nomor Mahasiswa/Kepegawaian</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.noInduk}}</td>
                        </tr>
                        <tr>
                          <td width="30%">No. Whatsapp aktif</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.noHp }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Instansi</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaInstitusi }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Perguruan tinggi</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaPerguruanTinggi }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Fakultas</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaFakultas }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Program studi</td>
                          <td width="1%">:</td>
                          <td>{{ detail.user?.namaProgramStudi }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Jenis penggunaan
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Jenis</td>
                          <td width="1%">:</td>
                          <td>{{ detail.researchType?.jenisPenelitian || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Keterangan</td>
                          <td width="1%">:</td>
                          <td>{{ detail.keteranganJenisPenelitian || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Judul
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Judul</td>
                          <td width="1%">:</td>
                          <td>{{detail.judul}}</td>
                        </tr>
                        <tr>
                          <td width="30%">Dosen pembimbing/Ketua peneliti</td>
                          <td width="1%">:</td>
                          <td>{{ detail.namaDosen }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Judul payung</td>
                          <td width="1%">:</td>
                          <td> {{ detail.judulPayung ? detail.judulPayung : '-'}} </td>
                        </tr>
                        <tr v-if="detail.user?.kdProgramStudi === '613'">
                          <td width="30%">Peta penelitian</td>
                          <td width="1%">:</td>
                          <td>{{ detail.map?.petaPenelitian || '-' }}</td>
                        </tr>
                        <tr v-if="isUii && detail.researchType?.kdJenisPenelitian !== '2'">
                          <td width="30%" class="td-flex">Surat persetujuan Dosen pembimbing/Ketua peneliti</td>
                          <td width="1%">:</td>
                          <td>
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header color="#0299B7">
                                  <template v-slot:actions>
                                    <v-icon color="white">
                                      $expand
                                    </v-icon>
                                  </template>
                                  <span class="white--text">Surat persetujuan Dosen pembimbing/Ketua peneliti</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <iframe 
                                    width="100%" 
                                    :height="this.file === null ? '110px' : '500px'" 
                                    :src="this.file" 
                                    :lazy-src="this.file"
                                    frameborder="0"
                                    allowfullscreen>
                                    Oops! an error has occurred.
                                  </iframe>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </td>
                        </tr>

                        <tr v-if="!isUii && detail.researchType?.kdJenisPenelitian !== '2'">
                          <td width="30%" class="td-flex">Surat proposal</td>
                          <td width="1%">:</td>
                          <td>
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header color="#0299B7">
                                  <template v-slot:actions>
                                    <v-icon color="white">
                                      $expand
                                    </v-icon>
                                  </template>
                                  <span class="white--text">Surat proposal</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <iframe 
                                    width="100%" 
                                    :height="this.fileProposal === null ? '110px' : '500px'" 
                                    :src="this.fileProposal" 
                                    :lazy-src="this.fileProposal"
                                    frameborder="0"
                                    allowfullscreen>
                                    Oops! an error has occurred.
                                  </iframe>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div v-if="detail.researchType?.kdJenisPenelitian !== '2'">
                    <div class="color-first title font-weight-bold py-2 mt-5">
                      Sertifikat pelatihan safety lab
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%"></td>
                            <td width="1%"></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td width="30%" class="td-flex">Sertifikat pelatihan safety lab</td>
                            <td width="1%">:</td>
                            <td>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header color="#0299B7">
                                    <template v-slot:actions>
                                      <v-icon color="white">
                                        $expand
                                      </v-icon>
                                    </template>
                                    <span class="white--text">Sertifikat pelatihan safety lab</span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <iframe 
                                      width="100%" 
                                      :height="this.fileSafety === null ? '110px' : '500px'" 
                                      :src="this.fileSafety" 
                                      :lazy-src="this.fileSafety"
                                      frameborder="0"
                                      allowfullscreen>
                                      Oops! an error has occurred.
                                    </iframe>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="color-first title font-weight-bold py-2 mt-5">
                    <span> {{ detail.researchType?.kdJenisPenelitian !== '2' ? 'Rencana penggunaan' : 'Tempat penelitian' }}</span>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Lab</td>
                          <td width="1%">:</td>
                          <td>{{ detail.lab?.namaLaboratorium || '-' }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Waktu</td>
                          <td width="1%">:</td>
                          <td>{{ detail.tglMulai | date }} - {{ detail.tglSelesai | date }}</td>
                        </tr>
                        <tr>
                          <td width="30%">Jam (setiap hari)</td>
                          <td width="1%">:</td>
                          <td>{{ detail.jamMulai || '-' }} - {{ detail.jamSelesai || '-' }} WIB </td>
                        </tr>
                        <tr>
                          <td width="30%">Alat</td>
                          <td width="1%">:</td>
                          <td>
                            <span v-if="detail.researchLabTool && detail.researchLabTool.length > 0">
                              <span v-for="(alat, i) in detail.researchLabTool" :key="i">
                                <span v-if="i !== alat.length - 1">{{ alat.tool?.namaAlat }}, </span>
                              </span>
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">Bahan</td>
                          <td width="1%">:</td>
                          <td>
                            <span v-if="detail.researchLabItem && detail.researchLabItem.length > 0">
                              <span v-for="(bahan, i) in detail.researchLabItem" :key="'A'+i">
                                <span v-if="i !== bahan.length - 1">{{ bahan.item?.namaBahan }}, </span>
                              </span>
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">Instrumen</td>
                          <td width="1%">:</td>
                          <td>
                            <span v-if="detail.researchLabInstrument && detail.researchLabInstrument.length > 0">
                              <span v-for="(instrument, i) in detail.researchLabInstrument" :key="'B'+i">
                                {{ instrument.instrument?.namaInstrumen || '-' }}
                                <span v-if="i !== detail.researchLabInstrument.length - 1">, </span>
                              </span>  
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Pembiayaan
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <table width="100%" class="body-2">
                        <tr>
                          <td width="30%">Oleh</td>
                          <td width="1%">:</td>
                          <td>{{ detail.paymentType?.jenisPembiayaan || '-' }}</td>
                        </tr>
                        <tr v-if="detail.paymentType?.kdJenisPembiayaan !== '1'">
                          <td width="30%">Sumber pendanaan</td>
                          <td width="1%">:</td>
                          <td>{{ detail.namaDosenPembiayaan || '-' }}</td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <!-- history -->
                  <div class="color-first title font-weight-bold py-2 mt-5">
                    Riwayat
                  </div>
                  <v-timeline 
                    align-top
                    dense>
                    <v-timeline-item
                      small 
                      color="#0299B7"
                      v-for="(item, i) in detail.researchHistory" :key="i">
                      <v-row>
                        <v-col>
                          <span class="color-first body-2">{{item.createdAt | datetime}} WIB</span>
                          <div class="caption font-weight-bold"> {{item.statusSubmission.statusPengajuan}} </div>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                  <!-- end history -->

                  <!-- persetujuan -->
                  <div class="color-first title font-weight-bold py-2 mt-5" v-if="isEdit">
                    Persetujuan
                  </div>
                  <v-row v-if="isEdit">
                    <v-col cols="12" md="12" class="pt-0">
                      <v-radio-group v-model="sort.selected_persetujuan">
                        <v-radio
                          v-for="(status, index) in sort.list_persetujuan"
                          :key="index" 
                          :label="status.text"
                          :value="status.id"
                          color="#0499b7"
                          class="py-0">
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="sort.selected_persetujuan == '2'">
                    <v-text-field
                      dense
                      outlined
                      label="Catatan (Optional)"
                      color="#0299B7"
                      v-model="form.persetujuan.catatan">
                    </v-text-field>
                  </v-row>
                  <!-- end persetujuan -->
                </v-card-text>
                <!-- row button -->
                <v-divider class="mt-8 mb-3"></v-divider>
                <v-card-actions class="mt-8 pb-8 justify-center">
                  <v-btn
                    large
                    width="120"
                    elevation="0"
                    :disabled="process.run"
                    class="text-capitalize"
                    @click="toHome()">
                    Batal
                  </v-btn>
                  <v-btn
                    v-if="isEdit"
                    large
                    width="120"
                    color="#0299B7"
                    elevation="0"
                    :disabled="process.run"
                    @click="dialog.konfirmasi = true"
                    class="white--text text-capitalize">
                    Simpan
                  </v-btn>
                </v-card-actions>
                <!-- end row button  -->
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { get, put } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import LoadingComponent from '@/components/Loading.vue'
import Snackbar from '@/components/Snackbar.vue';
export default {
  data() {
    return {
      detail: {},
      dialog: {
        konfirmasi: false
      },
      file: '',
      fileSafety: '',
      fileProposal: '',
      form: {
        persetujuan: {
          catatan: ''
        }
      },
      process: {
        run: false,
        loading: false
      },
      isEdit: false,
      isUii: false,
      sort: {
        selected_persetujuan: '1',
        list_persetujuan: [
          {
            id: '1',
            text: 'Disetujui'
          },
          {
            id: '2',
            text: 'Ditolak'
          },
        ]
      },
      snackbar: false,
      text: '',
      timeout: 4000,
    }
  },

  components: {
    LoadingComponent,
    Snackbar
  },

  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    },
    roles () {
      return this.$store.state.roles
    }
  },
  mounted () {
    if (TokenService.getUuid() === null) {
      this.$router.push(`/persetujuan-kalab`)
    } else {
      const params = this.$route.params.page
      if (params.endsWith("edit")) {
        this.isEdit = true
      } else { 
        this.isEdit = false
      }
      this.fetch();
    }
  },
  methods: {
    async fetch() {
      this.process.run = true
      this.process.loading = true
      const uuid = TokenService.getUuid()
      const params = {
        uuid: uuid,
        flag_officer: '1'
      }
      await get(`research`, {params}).then((response) => {
        if (response.status === 200) {
          let  res = response.data.data
          this.detail = res[0];
          const email = this.detail.user.email
          if (email.endsWith("uii.ac.id")) {
            this.isUii = true
          } else {
            this.isUii = false
          }
          this.process.run = false
          this.process.loading = false

        } else {
          this.process.run = false
          this.process.loading = false

        }
      });
      this.getFile()
    },

    async getFile() {
      try {
        const params = {
          url: this.detail.filePersetujuanDosen
        }
        const response = await get(`file`, { params });
        if (response.status === 200) {
          this.file = response.data.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        // this.getHistoryAddition()
        // this.getTotalUsage()
        // this.getToolReturn()
      }
    },

    async getFileSafety() {
      try {
        const params = {
          url: this.detail.fileSertifikatPelatihan
        }
        const response = await get(`file`, { params });
        if (response.status === 200) {
          this.fileSafety = response.data.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.getFileProposal()
      }
    },

    async getFileProposal() {
      try {
        const params = {
          url: this.detail.fileProposal
        }
        const response = await get(`file`, { params });
        if (response.status === 200) {
          this.fileProposal = response.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async save() {
      try {
        this.process.run = true;
        const params = {
          uuid_penelitian: this.detail.uuid,
          role: this.roles,
          flag_persetujuan: this.sort.selected_persetujuan,
          catatan: this.form.persetujuan.catatan,
        };
        const response = await put(`research/verification`, { params });
        if (response.status === 201) {
          this.process.run = false;
          this.text = "Data berhasil diubah";
          this.snackbar = true;
          this.$router.push(`/persetujuan-kalab`);
        }
      } catch (error) {
        console.error(error);
      }
    },

    toHome() {
      this.$router.push(`/persetujuan-kalab`)
    }
  },
}
</script>