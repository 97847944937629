<template>
  <div>
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
          <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
          <v-btn
            color='#0299B7'
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            Ya, 
            <span>simpan</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <div v-if="!process.loading">
      <v-row class="mt-3 px-3">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div v-if="!isDetail">
                    <div class="color-first title font-weight-bold title mt-3 pb-5">
                      Parameter uji
                    </div>
                    <ValidationObserver ref="observer">
                      <v-form>
                        <v-row>
                          <v-col cols="12" md="6" class="pb-0">
                            <ValidationProvider
                              name="Laboratorium"
                              rules="required"
                              v-slot="{ errors }">
                              <v-select
                                dense
                                v-model="form.laboratorium"
                                :items="list.lab"
                                label="Laboratorium"
                                color="#0299B7"
                                item-text="nama_laboratorium"
                                item-value="kd_laboratorium"
                                :error-messages="errors"
                                outlined>
                              </v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <ValidationProvider
                              name="Nama parameter uji"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Nama parameter uji"
                                color="#0299B7"
                                v-model="form.nama_parameter_uji"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Kode parameter uji"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Kode parameter uji"
                                color="#0299B7"
                                v-model="form.kode_parameter"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Metode uji"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Metode uji"
                                color="#0299B7"
                                v-model="form.metode_uji"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" md="6" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Harga internal (Rp)"
                              rules="required"
                              v-slot="{ errors }">
                              <v-currency-field
                                dense
                                outlined
                                label="Harga internal (Rp)"
                                color="#0299B7"
                                v-model="form.harga_internal"
                                :error-messages="errors"
                                require>
                              </v-currency-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Harga external (Rp)"
                              rules="required"
                              v-slot="{ errors }">
                              <v-currency-field
                                dense
                                outlined
                                label="Harga external (Rp)"
                                color="#0299B7"
                                v-model="form.harga_external"
                                :error-messages="errors"
                                require>
                              </v-currency-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Satuan"
                              rules="required"
                              v-slot="{ errors }">
                              <v-select
                                dense
                                disabled
                                filled
                                v-model="form.satuan"
                                :items="list.satuan"
                                label="Satuan"
                                color="#0299B7"
                                item-text="nama_satuan"
                                item-value="id"
                                :error-messages="errors"
                                outlined>
                              </v-select>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-form>
                    </ValidationObserver>
                    <v-row>
                      <v-col cols="3">
                        <div class="color-first title font-weight-bold mt-2 pb-1">
                          <span>Replikasi</span>
                        </div>
                        <v-switch
                          class="mt-0 switch"
                          v-model="switch_status"
                          color="#0499b7"
                          value = "true"
                          :label="switch_status === 'true' ? 'Terdapat replikasi' : 'Terdapat replikasi'"
                          hide-details>
                        </v-switch>
                      </v-col>
                    </v-row>
                    <v-row v-if="switch_status">
                      <v-col cols="12">
                        <v-icon color="#0077bb" class="mr-2">mdi-information</v-icon>
                        <span>Harga berlaku untuk replikasi kedua dan seterusnya.</span>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <ValidationProvider
                          name="Harga replikasi internal (Rp)"
                          rules="required"
                          v-slot="{ errors }">
                          <v-currency-field
                            id="text-information"
                            dense
                            outlined
                            label="Harga replikasi internal (Rp)"
                            color="#0299B7"
                            v-model="form.harga_replikasi_internal"
                            :error-messages="errors"
                            require>
                          </v-currency-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <ValidationProvider
                          name="Harga replikasi external (Rp)"
                          rules="required"
                          v-slot="{ errors }">
                          <v-currency-field
                            id="text-information"
                            dense
                            outlined
                            label="Harga replikasi external (Rp)"
                            color="#0299B7"
                            v-model="form.harga_replikasi_external"
                            :error-messages="errors"
                            require>
                          </v-currency-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="isDetail">
                    <div class="color-first title font-weight-bold title mt-3 pb-2">
                      Parameter uji
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2 line-text-second">
                          <tr>
                            <td width="30%">Laboratorium</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.lab?.namaLaboratorium || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Nama parameter uji</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.namaParameterUji || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Kode parameter uji</td>
                            <td width="1%">:</td> 
                            <td>{{ detail[0]?.kdParameterUji || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Metode uji</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.metodeUji || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Harga internal (Rp)</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.hargaInternal | price}}</td>
                          </tr>
                          <tr>
                            <td width="30%">Harga external (Rp)</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.hargaEksternal | price}}</td>
                          </tr>
                          <tr>
                            <td width="30%">Satuan</td>
                            <td width="1%">:</td>
                            <td>Sampel</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <div class="color-first title font-weight-bold mt-2 pb-1">
                          <span>Replikasi</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2 line-text-second">
                          <tr>
                            <td width="30%">Terdapat replikasi</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.flagReplikasi === 0 ? 'Tidak' : 'Ya' || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Harga internal (Rp)</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.hargaReplikasiInternal | price }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Harga external (Rp)</td>
                            <td width="1%">:</td>
                            <td>{{ detail[0]?.hargaReplikasiEksternal | price }}</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- <div v-if="(form.harga_external && form.harga_internal) !== ''"> -->
                  <div>
                    <v-row>
                      <v-col cols="12">
                        <div class="color-first title font-weight-bold mt-2 pb-1">
                          <span>Pembagian</span>
                        </div>
                      </v-col>
                      <v-col cols="12">
                      <v-skeleton-loader
                        :loading="process.run"
                        transition="scale-transition"
                        :tile="false"
                        :style="process.run ? 'height: calc(60vh - 305px)' : ''"
                        type="table-tbody">
                        <v-data-table
                          :headers="headers"
                          :items="row"
                          :items-per-page="10"
                          class="elevation-1 required"
                          hide-default-header>
                          <template v-slot:header>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Peran</th>
                                <th class="text-right">Peran pembagian (%)</th>
                                <th class="text-right">Pembagian harga internal (Rp)</th>
                                <th class="text-right">Pembagian harga external (Rp)</th>
                              </tr>
                            </thead>
                          </template>
                          <template v-slot:body>
                            <tbody>
                              <tr v-for="(item, index) in row" :key="item.uuid">
                                <td>{{ index+1 }}</td>
                                <td>{{ item.nama_peran }} </td>
                                <td class="text-right" v-if="isDetail">{{ item.persen_pembagian }}</td>
                                <td v-if="!isDetail">
                                  <ValidationProvider
                                    name="Persen pembagian (%)"
                                    rules="numeric"
                                    v-slot="{ errors }">
                                    <v-currency-field
                                      dense
                                      outlined
                                      color="#0299B7"
                                      v-model="item.persen_pembagian"
                                      @input="calculateAndStoreResult()"
                                      :error-messages="errors"
                                      require>
                                    </v-currency-field>
                                  </ValidationProvider>
                                </td>
                                <td class="text-right">{{ item.harga_internal }}</td>
                                <td class="text-right">{{ item.harga_external }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-data-table>
                      </v-skeleton-loader>
                    </v-col>
                    </v-row>
                  </div>

                  <v-divider class="mt-8 mb-3"></v-divider>
                  <div>
                    <v-card-actions class="pb-8 justify-center">
                      <v-btn
                        large
                        width="120"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize"
                        @click="toPage()">
                        Batal
                      </v-btn>
                      <v-btn
                        v-if="!isDetail"
                        large
                        width="120"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.run"
                        @click="cekForm"
                        class="white--text text-capitalize">
                        Simpan
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-card-text>
               
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/Loading.vue'
import Snackbar from '@/components/Snackbar.vue';
import { serialize } from 'object-to-formdata';
import { TokenService } from "@/service/Storage.Service";
const { VUE_APP_API_URL } = process.env;
import { get, put } from "@/service/Axios";
export default {
  data() {
    return {
      dialog: {
        konfirmasi: false,
      },
      detail: {},
      form : {
        laboratorium : '',
        nama_parameter_uji : '',
        kode_parameter: '',
        metode_uji: '',
        harga_internal : '',
        harga_external : '',
        satuan : '',
        harga_replikasi_internal : '',
        harga_replikasi_external : '',
      },
      filter: {
        unit: '',
        lab: ''
      },
      isDetail: false,
      isEdit: false,
      process: {
        run: false,
        loading: false
      },
      list: {
        lab: [],
        satuan: []
      },
      headers: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'Persen pembagian', value: 'persen_pembagian', sortable: false, align: 'right'},
        { text: 'Pembagian harga internal (Rp)', value: 'harga_external', sortable: false, align: 'right' },
        { text: 'Pembagian harga external (Rp)', value: 'harga_internal', sortable: false, align: 'right' },
      ],
      row: [],
      switch_status: null,
      snackbar: false,
      text: '',
      timeout: 4000,
      save_type: ''
    }
  },
  watch: {
    'form.harga_internal': 'calculateAndStoreResult',
    'form.harga_external': 'calculateAndStoreResult',
  },
  components: {
    LoadingComponent,
    Snackbar
  },

  computed: {
    token () {
      return this.$store.state.access_token
    }
   },

  mounted () {
    if (TokenService.getUuid() === null) {
      this.$router.push(`/parameter-uji`)
    } else {
      this.cekParams()
      this.getLaboratory();
    }
  },

  methods: {
    cekParams () {
      const params = this.$route.params.page
      this.save_type = params
      if (params.endsWith("detail")) {
        this.isDetail = true
      } else {
        this.isDetail = false
      }

      if (params.endsWith("edit")) {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
    },

    async fetch() {
      try {
        const uuid = TokenService.getUuid()
        const params = {
          uuid: uuid
        }
        const response = await get(`test-parameter`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          const {
            hargaEksternal,
            hargaInternal,
            hargaReplikasiEksternal,
            hargaReplikasiInternal,
            kdParameterUji,
            idSatuan,
            metodeUji,
            namaParameterUji,
            flagReplikasi,
            distribution,
            lab: { kdLaboratorium },
          } = res[0]
          this.form = {
            harga_external: hargaEksternal,
            harga_internal: hargaInternal,
            harga_replikasi_external: hargaReplikasiEksternal,
            harga_replikasi_internal: hargaReplikasiInternal,
            kode_parameter: kdParameterUji,
            laboratorium: kdLaboratorium,
            metode_uji: metodeUji,
            satuan: idSatuan,
            nama_parameter_uji: namaParameterUji,
          };
          this.switch_status = flagReplikasi === 1 ? 'true' : false
          this.row.forEach((item) => {
            const matchingRow = distribution.find(x => x.role.uuid === item.uuid);
            if (matchingRow) {
              item.persen_pembagian = matchingRow.persenPembagian.toString()
            } 
            // else {
            //   this.row.push({
            //     harga_external: "0",
            //     harga_internal: "0",
            //     id: item.role.id,
            //     kd_peran: item.role.kdPeran,
            //     nama_peran: item.role.namaPeran,
            //     no: this.row.length + 1,
            //     persen_pembagian: item.persenPembagian,
            //     uuid: item.role.uuid,
            //   });
            // }
          });
          this.detail = res
        }
      } catch (error) {
        console.log(error)
        this.process.run = false
      }
    },
    
    async getLaboratory() {
      try {
        this.process.loading = true
        this.process.run = true
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory-master', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          this.list.lab = labList
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.loading = false
        this.process.run = false;
        this.getUnit()
      }
    },

    async getUnit() {
      try {
        this.process.run = true
        const { data, status } =  await get('test-unit')
        if (status === 200 && data.data) {
          const unitList = data.data.map((data) => ({
            id: data.id,
            // kd_satuan: data.kdSatuan,
            nama_satuan: data.namaSatuan,
            // uuid: data.uuid
          }))
          this.list.satuan = unitList
          this.form.satuan = unitList[0].id
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
        this.getList()
      }
    },

    async getList() {
      try {
        this.process.run = true;
        const { data, status } = await get('test-role');
        if (status === 200 && data.data) {
          const row = data.data.map((item, index) => {
            return {
              no: index + 1,
              id: item.id,
              kd_peran: item.kdPeran,
              nama_peran: item.namaPeran,
              harga_internal: '0',
              harga_external: '0',
              persen_pembagian: '0',
              uuid: item.uuid
            }
          });
          this.row = row;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
        if (this.isEdit || this.isDetail) {
          this.fetch()
        }
      }
    },

    calculateAndStoreResult() {
      const { form } = this
      this.row.forEach((item) => {
        const harga_internal = (item.persen_pembagian * form.harga_internal) / 100;
        const harga_external = (item.persen_pembagian * form.harga_external) / 100;
        item.harga_internal = harga_internal.toLocaleString('id-ID');
        item.harga_external = harga_external.toLocaleString('id-ID');
      });
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      this.dialog.konfirmasi = isValid 
    },

    save() {
      switch (this.save_type) {
        case 'tambah':
          this.creates()
          break;
        case 'edit':
          this.updates();
          break;
        default:
          break;
      }
    },

    async creates() {
      this.process.run = true
      const { form, token, row, list, switch_status } = this
      const laboratorium = list.lab.find(x => x.kd_laboratorium === form.laboratorium)
      const pembagian = row.map((val) => ({
        id_peran: val.id,
        persen_pembagian: val.persen_pembagian
      }));
      const value = {
        id_laboratorium: laboratorium.id,
        kd_parameter_uji: form.kode_parameter,
        nama_parameter_uji: form.nama_parameter_uji,
        metode_uji: form.metode_uji,
        harga_internal: form.harga_internal,
        harga_eksternal: form.harga_external,
        id_satuan: form.satuan,
        flag_replikasi: switch_status !== null ? 1 : 0,
        harga_replikasi_internal: form.harga_replikasi_internal,
        harga_replikasi_eksternal: form.harga_replikasi_external,
        pembagian
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}test-parameter`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.text = 'Data parameter berhasil disimpan';
        this.snackbar = true;
        this.process.run = false
        this.reset()
        this.toPage();
      } else {
        this.dialog.konfirmasi = false;
      }
    },

    async updates() {
      this.process.run = true
      const { form, row, list, switch_status, detail } = this
      const laboratorium = list.lab.find(x => x.kd_laboratorium === form.laboratorium)
      const pembagian = row.map((val) => ({
        id_peran: val.id,
        persen_pembagian: val.persen_pembagian
      }));

      const params = {
        id_laboratorium: laboratorium.id,
        kd_parameter_uji: form.kode_parameter,
        nama_parameter_uji: form.nama_parameter_uji,
        metode_uji: form.metode_uji,
        harga_internal: form.harga_internal,
        harga_eksternal: form.harga_external,
        id_satuan: form.satuan,
        flag_replikasi: switch_status !== null ? 1 : 0,
        harga_replikasi_internal: form.harga_replikasi_internal,
        harga_replikasi_eksternal: form.harga_replikasi_external,
        pembagian,
        uuid: detail[0].uuid,
      };
      const response = await put(`test-parameter`, { params });
      if (response.status === 200) {
        this.dialog.konfirmasi = false;
        this.text = 'Data parameter berhasil diubah';
        this.snackbar = true;
        this.process.run = false
        this.reset()
        this.toPage();

      } else {
        this.dialog.konfirmasi = false;
      }

    },

    reset() {
      this.$refs.observer.reset()
      this.form = { 
        laboratorium : '',
        nama_parameter_uji : '',
        kode_parameter: '',
        metode_uji: '',
        harga_internal : '',
        harga_external : '',
        satuan : '',
        harga_replikasi_internal : '',
        harga_replikasi_external : '',
      };
      this.row = []
    },
    
    toPage() {
      this.$router.push(`/parameter-uji`)
    },

   
  },
}
</script>

<style scoped>
.background-replika {
  background: #f3faff;
}
</style>