<template>
  <div class="fill-height bg-login">
    <v-layout row wrap>
      <v-flex>
        <v-img
          width="100%"
          height="auto"
          min-height="100vh">
          <v-img
            width="100%"
            height="auto"
            min-height="100vh"
            style="opacity: 95%;"
            class="d-flex align-center">
            <v-container>
              <v-row justify="center" align="center">
                <v-col cols="12" md="5">
                  <v-card class="box-shadow border-radius px-6 py-10 mx-12 text-center">
                    <v-card-text class="text-center pt-0">
                      <v-img
                        class="mx-auto"
                        width="60%"
                        height="auto"
                        :src="require('@/assets/image/logo.png')">
                      </v-img>
                    </v-card-text>
                    <div class="my-8">
                      <div class="font-weight-bold body-2">Masuk</div>
                      <div class="font-weight-bold title text-uppercase"><span class="color-first">Pharmlab</span> uii</div>
                    </div>
                    <div class="text-center">
                      <!-- <v-alert type="error" text dense prominent 
                        v-show="error.message.length > 0"
                        v-html="error.message">
                      </v-alert> -->
                    <v-btn
                      class="mt-8 mb-2"
                      elevation="0"
                      large
                      @click="google()"
                      :disabled="process.run"
                      :loading="process.run">
                      <div class="d-flex align-center text-capitalize body-2">
                        <img :src="require('@/assets/image/google.png')" class="mr-2" alt="">
                        Masuk dengan akun Google
                      </div>
                    </v-btn>
                    </div>
                    <div class="caption mt-2">
                      <i>Jika Anda berasal dari instansi UII, <b> silahkan masuk PHARMLAB UII dengan </b><span class="red--text font-weight-bold"> email UII (...uii.ac.id)</span></i>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </v-img>
      </v-flex>      
    </v-layout>
  </div>
</template>

<script>
const { VUE_APP_GOOGLE_ID } = process.env;
import { TokenService } from '@/service/Storage.Service';
import { get, post } from "@/service/Axios";
export default {
  data() {
    return {
      error: {
        message: ""
      },
      process: {
        run: false
      }
    }
  },
  components:{},
  computed: {},
  watch: {},
  mounted() {
    
  },
  methods: {
    async google() {
      let client;
      // eslint-disable-next-line no-undef
      client = google.accounts.oauth2.initTokenClient({
      client_id: VUE_APP_GOOGLE_ID,
      scope: 'https://www.googleapis.com/auth/userinfo.profile \ https://www.googleapis.com/auth/userinfo.email',
      callback: (tokenResponse) => {
        this.loginSSO(tokenResponse);
      }, 
    });
    client.requestAccessToken();
    },

    async loginSSO(data) {
      this.process.run = true
      try {
        const response = await post(`auth/login`, {
          data: {
            access_token: data.access_token
          }
        });
        if (response.status === 200) { 
          TokenService.saveToken(
            data.access_token,
            JSON.stringify(response.data)
          );
          await this.cekUserSelf();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      } finally {
        this.process.run = false;

      }
    },

    async cekUserSelf() {
      try {
        const response = await get(`user/self`);
        if (response.status === 200) {
          const res = response.data.data;
          if (res === null || res.flagAktif === 0) {
            window.location = "/form"
          } else {
            const role = res.role.namaRole;
            if (role !== null) {
              TokenService.saveRoles(role);
              window.location = "/"
            } else {
              window.location = "/form"
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  }

}
</script>

<style scoped>
.bg-login {
  background-image: url('../../assets/image/background_login.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 900px;
}
</style>