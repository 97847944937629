import Vue from "vue"
import moment from "moment"

Vue.filter("date", function (value) {
  if (!value) return ""
  return moment(value).locale('id').format('D MMMM YYYY')
})

Vue.prototype.$callbackdate = (value) => {
  if (!value) return ''
  return moment(value).locale('id').format('D MMMM YYYY')
}

Vue.prototype.$callbacktime = (value) => {
  if (!value) return ''
  return moment(value).format('HH:mm', 'id')
}

Vue.prototype.$callbackdatetime = (value) => {
  if (!value) return ''
  return moment(value).locale('id').format('D MMMM YYYY HH:mm')
}

Vue.filter("time", function (value) {
  if (!value) return ""
  return moment(value).format("HH:mm", "id")
})

Vue.filter("datetime", function (value) {
  if (!value) return ""
  return moment(value).format("D MMMM, YYYY HH:mm", "id")
})

Vue.filter("price", (num) => {
  if (isNaN(num)) return "Not a Number"
  let price = ""
  const reverseNumber = num
    .toString()
    .split("")
    .reverse()
    .join("")
  const arrReverseNumber = [...Array(reverseNumber.length).keys()]
  arrReverseNumber.map(index => {
    if (index % 3 === 0) price += reverseNumber.substr(index, 3) + "."
  })

  return `${
    price.split("", price.length - 1)
    .reverse()
    .join("")
  }`
})

export default {
  moment
}