<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <div>
      <!-- snackbar -->
      <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
      <!-- end snackbar -->

      <!-- dialog konfirmasi -->
      <v-dialog v-model="dialog.konfirmasi" persistent width="450">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Konfirmasi</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.konfirmasi = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5 text-center mt-2">
            <span class="font-weight-bold title">
              Simpan data yang diinputkan ?
            </span>
            <v-card-subtitle class="pt-0">
              <span class="body-2">Pastikan data sudah sesuai</span>
            </v-card-subtitle>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-text class="pt-0 pb-0">
          </v-card-text>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.konfirmasi = false;">
              Batal
            </v-btn>
            <v-btn
              :color="type === 'Hapus' ? 'red' : '#0299B7'"
              elevation="0"
              class="text-capitalize white--text mx-1"
              :loading="process.form"
              :disabled="process.form"
              @click="save">
              Ya,
              <span v-if="type === 'Hapus'"> hapus</span>
              <span v-else>simpan</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog konfirmasi -->

      <!-- dialog tambah -->
      <v-dialog v-model="dialog.tambah" persistent width="900">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>{{type}} alat</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="reset(); dialog.tambah = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text>
            <ValidationObserver ref="observer">
            <v-form>
              <v-row class="mt-5">
                <v-col cols="12" md="12" class="pb-0">
                  <ValidationProvider
                    name="Laboratorium"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="form.dialog.id_laboratorium"
                      :items="list.lab_select"
                      label="Laboratorium"
                      color="#0299B7"
                      item-text="nama_laboratorium"
                      item-value="id"
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="4" class="pb-0">
                  <ValidationProvider
                    name="Kode alat"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.dialog.kode_alat"
                      label="Kode alat"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="8" class="pb-0">
                  <ValidationProvider
                    name="Nama alat"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.dialog.nama_alat"
                      label="Nama alat"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <ValidationProvider
                    name="Keterangan">
                    <v-text-field
                      v-model="form.dialog.keterangan"
                      label="Keterangan (Optional)"
                      outlined
                      dense
                      color="#0299B7">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-0" v-if="type === 'Ubah'">
                  <div>
                    <span>Status alat</span>
                  </div>
                  <v-switch
                    class="mt-0 switch"
                    v-model="switch_status"
                    color="#0499b7"
                    value = "true"
                    :label="switch_status === 'true' ? 'Aktif' : 'Tidak aktif'"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="reset(), dialog.tambah = false;">
            Tutup
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :disabled="process.form"
            @click="cekForm()">
            Simpan
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog tambah -->

       <!-- dialog detail -->
      <v-dialog v-model="dialog.detail" persistent width="650">
        <v-card>
          <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
            <span>Detail</span>
            <v-spacer />
              <v-btn
                color="#0299B7"
                small
                elevation="0"
                fab
                class="text-capitalize color-first"
                @click="dialog.detail = false">
                <v-icon small color="#FFFFFF">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-row>
              <v-col cols="12">
                <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                  <tr>
                    <td width="35%">Lab</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.laboratorium }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Kode alat</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.kode_alat }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Nama alat</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.nama_alat }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Keterangan</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.keterangan || '-' }}</td>
                  </tr>
                  <tr>
                    <td width="35%">Status alat</td>
                    <td width="3%">:</td>
                    <td>{{ detail?.flag_aktif === 1 ? 'Aktif' : 'Tidak aktif'}}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
          <v-card-actions class="mt-2 pb-3 justify-center">
            <v-btn
              outlined
              class="text-capitalize color-first mx-1"
              :disabled="process.form"
              @click="dialog.detail = false;">
              Kembali
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <LoadingComponent v-if="process.loading"></LoadingComponent>

      <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">   
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div class="color-first title font-weight-bold title mt-3">
                    Alat
                  </div>
                  <!-- row filter -->
                  <v-row class="pt-5">
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.lab"
                        item-text="nama_laboratorium"
                        item-value="id"
                        v-model="filter.lab"
                        v-on:change="onSelectLab()"
                        label="Laboratorium"
                        color="#0299B7"
                        hide-details>
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <!-- end row filter -->

                  <!-- row  button-->
                  <v-row class="mt-7 justify-space-between">
                    <v-col cols="12" md="3">
                      <v-btn
                        @click="addForm()"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize white--text">
                          <v-icon size="20" class="mr-3">mdi-plus</v-icon>
                          Tambah alat
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :disabled="process.run"
                        outlined
                        dense
                        :hide-details="true"
                        placeholder="Cari . . ."
                        clearable
                        color="#0299B7"
                        class="mx-3"
                        v-model="form.cari"
                        @click:clear="form.cari = ''; getList();"
                        v-on:keyup.enter="getList();"
                        prepend-inner-icon="mdi-magnify">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- end row button -->

                  <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="row"
                        :items-per-page="10"
                        class="elevation-1 required">
                          <template v-slot:[`item.status`]="{ item }">
                            <td>
                              <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                            </td>
                          </template>
                          <template v-slot:[`item.aksi`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="detailForm(item)"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Detail</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="30"
                                  color="orange"
                                  :disabled="process.edit"
                                  :loading="process.edit"
                                  @click="editForm(item)"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip> 
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="item.flag_dapat_hapus === true"
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="red"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="deleteForm(item)"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>        
                </v-card-text>      
              </v-col>
            </v-row>
          </v-card>     
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import { get, put, destroy } from "@/service/Axios";
import { serialize } from 'object-to-formdata';
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
import LoadingComponent from '@/components/Loading.vue'
export default {
  mixins: [statusMixin],  
  data() {
    return {
      detail: {},
      dialog: {
        tambah: false,
        detail: false,
        konfirmasi: false
      },
      form : { 
        lab: '',
        cari: '',
        dialog: {
          kode_alat: '',
          nama_alat: '',
          keterangan: '',
          id_laboratorium: '',
          uuid: ''
        },
      },
      filter: {
        lab: ''
      },
      id: '',
      process: {
        run: false,
        detail: false,
        edit: false,
        form: false,
        loading: false
      },
      list: {
        lab: [],
        lab_select: []
      },
      headers: [
          {
            text: 'No.',
            value: 'no',
            sortable: false,
          },
          { text: 'Kode alat', value: 'kode_alat', sortable: false, },
          { text: 'Nama alat', value: 'nama_alat', sortable: false, },
          { text: 'Keterangan', value: 'keterangan', sortable: false, },
          { 
            text: 'Aksi', 
            value: 'aksi',
            align: 'center',
            width: '14%'
          },
      ],
      switch_status: null,
      save_type: '',
      type: '',
      row: [],
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  components: {
    Snackbar,
    LoadingComponent,
  },

  computed: {
    token () {
      return this.$store.state.access_token
    },
  },

  mounted () {
    this.getLaboratory()
  },

  methods: {
    async getLaboratory() {
      try {
        this.process.run = true
        this.process.loading = true
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory-master', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          const labListSelect = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          labList.unshift({
            id: 'all',
            kd_laboratorium: 'all',
            nama_laboratorium: 'Semua',
          })
          this.list.lab = labList
          this.list.lab_select = labListSelect
          this.filter.lab = labList[0].id
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.loading = false
        this.process.run = false;
        this.getList()
      }
    },

    async getList() {
      try {
        this.process.run = true;
        const params = {
          id_laboratorium: this.filter.lab,
          search: this.form.cari,
        };
        const { data, status } = await get('tool-master', { params });
        if (status === 200 && data.data) {
          const row = data.data.map((item, index) => ({
            no: index + 1,
            kode_alat: item.kdAlat,
            nama_alat: item.namaAlat,
            keterangan: item.keterangan,
            laboratorium: item.lab.namaLaboratorium,
            flag_dapat_hapus: item.flagDapatHapus,
            uuid: item.uuid
          }));
          this.row = row;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
      }
    },

    async getDetail() {
      try {
        const params = {
          id_laboratorium: this.filter.lab,
          search: this.form.cari,
          uuid: this.id
        };
        const { data, status } = await get('tool-master', { params });
        if (status === 200 && data.data) {
          const row = data.data.map((item, index) => ({
            no: index + 1,
            kode_alat: item.kdAlat,
            nama_alat: item.namaAlat,
            keterangan: item.keterangan,
            id_laboratorium: item.idLaboratorium,
            laboratorium: item.lab.namaLaboratorium,
            flag_aktif: item.flagAktif,
            uuid: item.uuid
          }));
          this.detail = row[0];
          this.form.dialog =  { 
            kode_alat: this.detail.kode_alat,
            nama_alat: this.detail.nama_alat,
            keterangan: this.detail.keterangan,
            id_laboratorium: this.detail.id_laboratorium,
            laboratorium: this.detail.laboratorium,
            uuid: this.detail.uuid
          }
          this.switch_status = this.detail.flag_aktif === 1 ? 'true' : false
        }
      } catch (error) {
        console.error(error);
      }

    },

    onSelectLab() {
      this.getList()
    },

    addForm() {
      this.save_type = 'creates'
      this.type = 'Tambah'
      this.dialog.tambah = true
    },

    editForm(item) {
      this.id = item.uuid
      this.save_type = 'updates'
      this.type = 'Ubah'
      this.dialog.tambah = true
      this.getDetail()
    },

    detailForm(item) {
      this.id = item.uuid
      this.dialog.detail = true
      this.getDetail()
    },

    deleteForm(item) {
      this.type = 'Hapus'
      this.dialog.konfirmasi = true
      this.id = item.uuid
      this.save_type = 'deletes'
    },


    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      this.dialog.konfirmasi = isValid 

    },

    save() {
      switch(this.save_type) {
        case 'creates':
          this.creates();
          break;
        case 'updates':
          this.updates();
          break;
        case 'deletes':
          this.deletes();
          break;
        default:
      }
    },

    async creates() {
      this.process.form = true;
      this.process.run = true
      const { form, token } = this
      const value = {
        id_laboratorium: form.dialog.id_laboratorium,
        kd_alat: form.dialog.kode_alat,
        nama_alat: form.dialog.nama_alat,
        keterangan: form.dialog.keterangan
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}tool`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
        this.text = 'Data alat berhasil disimpan';
        this.snackbar = true;
        this.process.form = false;
        this.process.run = false
        this.getList();
        this.reset()
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
      }
    },

    async updates() {
      this.process.form = true;
      this.process.run = true
      const { form, switch_status } = this
      const params = {
        id_laboratorium: form.dialog.id_laboratorium,
        kd_alat: form.dialog.kode_alat,
        nama_alat: form.dialog.nama_alat,
        keterangan: form.dialog.keterangan,
        flag_aktif: switch_status !== null ? 1 : 0,
        uuid: form.dialog.uuid
      }
      const response = await put(`tool`, { params });
      if (response.status === 200) {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
        this.text = 'Data alat berhasil diupdate';
        this.snackbar = true;
        this.process.form = false;
        this.process.run = false
        this.getList();
        this.reset()
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.tambah = false
      }
    },

    async deletes() {
      try {
        this.process.run = true;
        this.process.form = true;
        const params = {
          uuid: this.id
        }
        const { data, status } = await destroy('tool', { params });
        if (status === 200 && data.data) {
          this.dialog.konfirmasi = false;
          this.text = 'Data alat berhasil dihapus';
          this.snackbar = true;
          this.process.run = false
          this.process.form = false;
          this.reset()
          this.getList()
        } else {
          this.dialog.konfirmasi = false;
        }
      } catch (error) {
        console.error(error);
      }     
    },

    reset() {
      this.$refs.observer.reset()
      this.form = { 
        lab: '',
        cari: '',
        dialog: {
          kode_alat: '',
          nama_alat: '',
          keterangan: '',
          id_laboratorium: '',
          uuid: ''
        },
      };
      this.id = '';
      this.switch_status = null,
      this.save_type =  '',
      this.type = ''
    },
  },
}
</script>