<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <div>
      <!-- snackbar -->
      <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
      <!-- end snackbar -->
      <!-- <LoadingComponent v-if="process.loading"></LoadingComponent> -->
      <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">   
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div class="color-first title font-weight-bold title mt-3">
                    Pendaftaran
                  </div>
                  <!-- row filter -->
                  <v-row class="pt-5">
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.lab"
                        item-text="nama_laboratorium"
                        item-value="id"
                        v-model="form.lab"
                        v-on:change="onSelectLab($event)"
                        label="Laboratorium"
                        color="#0299B7"
                        hide-details>
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.usage_type"
                        item-text="jenis_penggunaan"
                        item-value="id"
                        v-model="form.jenis"
                        v-on:change="onSelectJenis($event)"
                        label="Jenis pendaftaran"
                        color="#0299B7"
                        hide-details>
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <!-- end row filter -->

                  <!-- row  button-->
                  <v-row class="mt-7 justify-space-between">
                    <v-col cols="12" md="3">
                      <v-btn
                        @click="toPage('tambah')"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize white--text">
                          <v-icon size="20" class="mr-3">mdi-plus</v-icon>
                          Daftar
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :disabled="process.run"
                        outlined
                        dense
                        :hide-details="true"
                        placeholder="Cari . . ."
                        clearable
                        color="#0299B7"
                        class="mx-3"
                        v-model="form.cari"
                        @click:clear="form.cari = ''; getResearchOrPractice();"
                        v-on:keyup.enter="getResearchOrPractice();"
                        prepend-inner-icon="mdi-magnify">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- end row button -->

                  <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="row"
                        :items-per-page="10"
                        class="elevation-1 required">
                          <template v-slot:[`item.status`]="{ item }">
                            <td>
                              <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                            </td>
                          </template>
                          <template v-slot:[`item.aksi`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }" v-if="form.jenis === 2 && item.kd_status_pengajuan === 14 || item.kd_status_pengajuan === 16">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="toPage(item, 'cetak')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <img :src="require('@/assets/image/download_pdf.png')" alt="">
                                </v-btn>
                              </template>
                              <span>LHU</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="toPage(item, 'detail')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Detail</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="(item.kd_status_pengajuan === 3 || item.kd_status_pengajuan === 4 || item.kd_status_pengajuan === 10 || item.kd_status_pengajuan === 11 || item.kd_status_pengajuan === 12) && item.flag_dapat_mengajukan_penggunaan === true"
                                  x-small
                                  fab
                                  icon
                                  min-width="30"
                                  color="orange"
                                  :disabled="process.edit"
                                  :loading="process.edit"
                                  @click="toPage(item, 'edit')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip> 
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>        
                </v-card-text>      
              </v-col>
            </v-row>
          </v-card>     
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { get} from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
// import LoadingComponent from '@/components/Loading.vue'
export default {
  mixins: [statusMixin],  
  data() {
    return {
      error: {
        message: "",
      },
      form : { 
        lab: '',
        jenis: '',
        cari: '',
      },
      filter: {
        jenis: '',
        lab: '',
      },
      list: {
        lab: [],
        usage_type: [],
      },
      process: {
        run: false,
        detail: false,
        edit: false,
        save: false,
        loading: false
      },
      headers: [
          {
            text: 'No.',
            value: 'no',
            sortable: false,
          },
          { text: 'Jenis penggunaan', value: 'jenis_penelitian', sortable: false, },
          { text: 'Judul', value: 'judul', sortable: false, },
          { text: 'Tanggal penggunaan', value: 'tanggal_penggunaan', sortable: false, },
          { text: 'Status', value: 'status', sortable: false, width: '17%' },
          { text: 'Proses selanjutnya', value: 'proses_selanjutnya',sortable: false, },
          { 
            text: 'Aksi', 
            value: 'aksi',
            align: 'center',
            width: '12%'
          },
      ],
      hasDetail: false,
      row: [],
      snackbar: false,
      text: '',
      timeout: 3000,
      type_filter: [],
      type: 1
    }
  },

  components: {
    Snackbar,
    // LoadingComponent
  },

  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },

  watch: {
    
  },

  mounted() {
    this.fetch();
    TokenService.removeUUID(TokenService.getUuid())
  },

  methods: {
    async fetch() {
      this.process.run = true
      const email = this.user.email;
      this.isFreeText = email.endsWith("uii.ac.id") ? false : true;
      await Promise.all([
        this.getLaboratory(),
      ]);
      const data = JSON.parse(localStorage.getItem('filter'))
      if (data) {
        const { form } = this
        this.hasDetail = true
        form.lab = data.lab
        form.jenis = data.jenis
        this.type = data.type
        localStorage.removeItem("filter");
      }
    },

    async getLaboratory() {
      try {
        this.process.loading = true
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          labList.unshift({
            id: 'all',
            kd_laboratorium: 'all',
            nama_laboratorium: 'Semua',
          })
          this.list.lab = labList
          this.form.lab = this.hasDetail ? this.form.lab : this.form.lab === undefined ? labList[0].id : labList[0].id;
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.getUsageType();
      }
    },

    async getUsageType() {
      try {
        const { data, status } = await  get(`usage-type`)
        if (status === 200 && data.data) {
          const usageType = data.data.map((res) => ({
            id: res.id,
            kd_jenis_penggunaan: res.kdJenisPenggunaan,
            jenis_penggunaan: res.jenisPenggunaan,
          }))
          this.list.usage_type = usageType
          this.form.jenis = this.hasDetail ? this.form.jenis : this.form.jenis === undefined ? usageType[0].id : usageType[0].id;
          this.process.run = false;
        }
      } catch (error) {
        console.log(error);
      }
      this.getResearchOrPractice();
    },

    async fetchData(endpoint) {
      this.process.run = true
      try {
        const params = {
          id_jenis_penelitian: this.form.jenis,
          id_laboratorium: this.form.lab,
          search: this.form.cari
        };
        const { data, status } = await get(endpoint, { params });
        let row;
        if (status === 200 && data.data) {
          if (this.form.jenis === 1) {
              row = data.data.map((item, index) => ({
              no: index + 1,
              jenis_penelitian: item.researchType.jenisPenelitian,
              judul: item.judul,
              tanggal_penggunaan: `${item.lab.namaLaboratorium} ${this.$callbackdate(item.tglMulai)} - ${this.$callbackdate(item.tglSelesai)}`,
              status: item.statusSubmission.statusPengajuan,
              kd_status_pengajuan: item.statusSubmission.kdStatusPengajuan,
              flag_dapat_mengajukan_penggunaan: item.flagDapatMengajukanPenggunaan,
              proses_selanjutnya: item.statusSubmission.prosesSelanjutnya,
              uuid: item.uuid
            }));
          } else if (this.form.jenis === 2) {
              row = data.data.map((item, index) => ({
              no: index + 1,
              jenis_penelitian: item.usageType?.jenisPenggunaan,
              judul: item.namaSampel,
              tanggal_penggunaan: '-',
              status: item.statusSubmission?.statusPengajuan,
              kd_status_pengajuan: item?.statusSubmission?.kdStatusPengajuan || '-',
              proses_selanjutnya: item.statusSubmission?.prosesSelanjutnya,
              uuid: item.uuid
            }));
          }
        
          this.row = row;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.loading = false
        this.process.run = false;
      }
    },

    async getReserch() {
      this.type = 1
      await this.fetchData('research');
    },

    async getPractice() {
      this.type = 2
      await this.fetchData('test');
    },

    getResearchOrPractice() {
      if (this.form.jenis === 1 || this.form.jenis === 2) {
        this.fetchData(this.form.jenis === 1 ? 'research' : 'test');
      } else {
        console.log('belum ada');
      }
    },

    onSelectLab(val) {
      this.form.lab = val
      this.getReserch()
    },

    onSelectJenis(val) {
      this.form.jenis = val
      if (val === 1) {
        this.getReserch()
      } else if (val === 2) {
        this.getPractice()
      } else {
        console.log('belum ada');
      }
    },

    logout() {
      TokenService.removeToken()
      window.location = '/login'
    },

    toPage(val, page) {
      console.log('val', page);
      try {
        const { form, type } = this
        const data = {
          lab: form.lab,
          jenis: form.jenis,
          type: type
        }
        localStorage.setItem('filter', JSON.stringify(data))
        TokenService.setUuid(val.uuid);
        let path;
        if (type === 1 && page === 'detail') {
          path = 'detail';
        } else if (type === 2 && page === 'detail') {
          path = 'detail-pengujian';
        } else if (page === 'cetak') {
          path = 'cetak'
        } else if (page === 'edit') {
          path = 'edit'
        } else {
          path = 'tambah';
        }
        this.$router.push(`/pendaftaran/${path}`);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style>
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
  line-height: 1.5;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th{
  font-size:15px !important;
  font-weight: 700 !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
  font-size:12px !important;
}
</style>