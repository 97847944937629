<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <div v-if="!process.loading">
      <v-row class="mt-3 px-3">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <!-- detail table -->
                  <div>
                    <div class="color-first title font-weight-bold pb-2 mt-1">
                      Data diri
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Nama lengkap</td>
                            <td width="1%">:</td>
                            <td>{{ detail.user?.nama }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Nomor Mahasiswa/Kepegawaian</td>
                            <td width="1%">:</td>
                            <td>{{ detail.user?.noInduk}}</td>
                          </tr>
                          <tr>
                            <td width="30%">No. Whatsapp aktif</td>
                            <td width="1%">:</td>
                            <td>{{ detail.user?.noHp }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Instansi</td>
                            <td width="1%">:</td>
                            <td>{{ detail.user?.namaInstitusi }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Perguruan tinggi</td>
                            <td width="1%">:</td>
                            <td>{{ detail.user?.namaPerguruanTinggi }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Fakultas</td>
                            <td width="1%">:</td>
                            <td>{{ detail.user?.namaFakultas || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Program studi</td>
                            <td width="1%">:</td>
                            <td>{{ detail.user?.namaProgramStudi || '-' }}</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <div class="color-first title font-weight-bold py-2 mt-5">
                      Jenis penggunaan
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Jenis</td>
                            <td width="1%">:</td>
                            <td>{{ detail.researchType?.jenisPenelitian || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Keterangan</td>
                            <td width="1%">:</td>
                            <td>{{ detail.keteranganJenisPenelitian || '-' }}</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <div class="color-first title font-weight-bold py-2 mt-5">
                      Judul
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Judul</td>
                            <td width="1%">:</td>
                            <td>{{detail.judul ||'-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Dosen pembimbing/Ketua peneliti</td>
                            <td width="1%">:</td>
                            <td>{{ detail.namaDosen  ||'-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Judul payung</td>
                            <td width="1%">:</td>
                            <td> {{ detail.judulPayung ? detail.judulPayung : '-'}} </td>
                          </tr>
                          <tr v-if="detail.user?.kdProgramStudi === '613'">
                            <td width="30%">Peta penelitian</td>
                            <td width="1%">:</td>
                            <td>{{ detail.map?.petaPenelitian || '-' }}</td>
                          </tr>
                          <tr v-if="isUii && detail.researchType?.kdJenisPenelitian !== '2'">
                            <td width="30%" class="td-flex">Surat persetujuan Dosen pembimbing/Ketua peneliti</td>
                            <td width="1%">:</td>
                            <td>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header color="#0299B7">
                                    <template v-slot:actions>
                                      <v-icon color="white">
                                        $expand
                                      </v-icon>
                                    </template>
                                    <span class="white--text">Surat persetujuan Dosen pembimbing/Ketua peneliti</span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <iframe 
                                      width="100%" 
                                      :height="this.file === null ? '110px' : '500px'" 
                                      :src="this.file" 
                                      :lazy-src="this.file"
                                      frameborder="0"
                                      allowfullscreen>
                                      Oops! an error has occurred.
                                    </iframe>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </td>
                          </tr>

                          <tr v-if="!isUii && detail.researchType?.kdJenisPenelitian !== '2'">
                            <td width="30%" class="td-flex">Surat proposal</td>
                            <td width="1%">:</td>
                            <td>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header color="#0299B7">
                                    <template v-slot:actions>
                                      <v-icon color="white">
                                        $expand
                                      </v-icon>
                                    </template>
                                    <span class="white--text">Surat proposal</span>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <iframe 
                                      width="100%" 
                                      :height="this.fileProposal === null ? '110px' : '500px'" 
                                      :src="this.fileProposal" 
                                      :lazy-src="this.fileProposal"
                                      frameborder="0"
                                      allowfullscreen>
                                      Oops! an error has occurred.
                                    </iframe>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <div v-if="detail.researchType?.kdJenisPenelitian !== '2'">
                      <div class="color-first title font-weight-bold py-2 mt-5">
                        Sertifikat pelatihan safety lab
                      </div>
                      <v-row>
                        <v-col cols="12">
                          <table width="100%" class="body-2">
                            <tr>
                              <td width="30%"></td>
                              <td width="1%"></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td width="30%" class="td-flex">Sertifikat pelatihan safety lab</td>
                              <td width="1%">:</td>
                              <td>
                                <v-expansion-panels>
                                  <v-expansion-panel>
                                    <v-expansion-panel-header color="#0299B7">
                                      <template v-slot:actions>
                                        <v-icon color="white">
                                          $expand
                                        </v-icon>
                                      </template>
                                      <span class="white--text">Sertifikat pelatihan safety lab</span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <iframe 
                                        width="100%" 
                                        :height="this.fileSafety === null ? '110px' : '500px'" 
                                        :src="this.fileSafety" 
                                        :lazy-src="this.fileSafety"
                                        frameborder="0"
                                        allowfullscreen>
                                        Oops! an error has occurred.
                                      </iframe>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </td>
                            </tr>
                          </table>
                        </v-col>
                      </v-row>
                    </div>
                    
                    <div class="color-first title font-weight-bold py-2 mt-5">
                      <span> {{ detail.researchType?.kdJenisPenelitian !== '2' ? 'Rencana penggunaan' : 'Tempat penelitian' }}</span>
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Lab</td>
                            <td width="1%">:</td>
                          <td>{{ detail.lab?.namaLaboratorium || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Waktu</td>
                            <td width="1%">:</td>
                            <td>{{ detail.tglMulai | date }} - {{ detail.tglSelesai | date }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Jam (setiap hari)</td>
                            <td width="1%">:</td>
                            <td>{{ detail.jamMulai || '-' }} - {{ detail.jamSelesai || '-' }} WIB </td>
                          </tr>
                          <tr>
                            <td width="30%">Alat</td>
                            <td width="1%">:</td>
                            <td>
                              <span v-if="detail.researchLabTool && detail.researchLabTool.length > 0">
                                <span v-for="(alat, i) in detail.researchLabTool" :key="i">
                                  <span v-if="i !== alat.length - 1">{{ alat.tool?.namaAlat }}, </span>
                                </span>
                              </span>
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Bahan</td>
                            <td width="1%">:</td>
                            <td>
                              <span v-if="detail.researchLabItem && detail.researchLabItem.length > 0">
                                <span v-for="(bahan, i) in detail.researchLabItem" :key="'A'+i">
                                  <span v-if="i !== bahan.length - 1">{{ bahan.item?.namaBahan }}, </span>
                                </span>
                              </span>
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <td width="30%">Instrumen</td>
                            <td width="1%">:</td>
                            <td>
                              <span v-if="detail.researchLabInstrument && detail.researchLabInstrument.length > 0">
                                <span v-for="(instrument, i) in detail.researchLabInstrument" :key="'B'+i">
                                  {{ instrument.instrument?.namaInstrumen || '-' }}
                                  <span v-if="i !== detail.researchLabInstrument.length - 1">, </span>
                                </span>  
                              </span>
                              <span v-else>-</span>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <div class="color-first title font-weight-bold py-2 mt-5">
                      Pembiayaan
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Oleh</td>
                            <td width="1%">:</td>
                            <td>{{ detail.paymentType?.jenisPembiayaan || '-' }}</td>
                          </tr>
                          <tr v-if="detail.paymentType?.kdJenisPembiayaan !== '1'">
                            <td width="30%">Sumber pendanaan</td>
                            <td width="1%">:</td>
                            <td>{{ detail.namaDosenPembiayaan || '-' }}</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- end detail table -->

                  <!-- tabs -->
                  <div class="py-2 mt-5" v-if="detail.researchType?.kdJenisPenelitian !== '2'">
                    <v-tabs 
                      color="#0299B7"
                      background-color="transparent"
                      style="border-bottom: 1px solid lightgray"
                      light
                      v-model="tab">
                      <v-tab v-for="item in items" :key="item">
                        {{ item }}
                      </v-tab>
                    </v-tabs>
                    <div v-if="tab === 0">
                      <div class="px-2">
                        <v-row class="mt-5 mb-2 justify-end">
                          <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                            <div class="text-left font-weight-600 title ml-3">
                              Riwayat penambahan
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field
                              outlined
                              dense
                              :disabled="process.run"
                              :hide-details="true"
                              placeholder="Cari . . ."
                              clearable
                              color="#0299B7"
                              class="mx-3"
                              v-model="filter.history"
                              @click:clear="filter.history = ''; getHistoryAddition();"
                              v-on:keyup.enter="getHistoryAddition();"
                              prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-card-text class="pa-2">
                              <v-skeleton-loader
                                :loading="process.row_riwayat"
                                transition="scale-transition"
                                :tile="false"
                                :style="process.row_riwayat ? 'height: calc(60vh - 305px)' : ''"
                                type="table-tbody">
                                <v-data-table
                                  :headers="headers_riwayat"
                                  :items="row_riwayat"
                                  :items-per-page="10"
                                  class="elevation-1 required">
                                  <template v-slot:[`item.status`]="{ item }">
                                    <td>
                                      <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                                    </td>
                                  </template>
                                  <!-- <template v-slot:[`item.aksi`]="{ item }">
                                    <v-btn
                                      x-small
                                      fab
                                      icon
                                      min-width="25"
                                      color="#0299B7"
                                      @click="toDetail(item)"
                                      class="text-capitalize color-first pa-0 mx-1">
                                      <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                  </template> -->
                                </v-data-table>
                              </v-skeleton-loader>
                            </v-card-text>           
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div v-if="tab === 1">
                      <div class="px-2">
                        <v-row class="mt-5 mb-2 justify-end">
                          <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                            <div class="text-left font-weight-600 title ml-3">
                              Total penggunaan
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field
                              outlined
                              dense
                              :disabled="process.run"
                              :hide-details="true"
                              placeholder="Cari . . ."
                              clearable
                              color="#0299B7"
                              class="mx-3"
                              v-model="filter.total_usage"
                              @click:clear="filter.total_usage = ''; getTotalUsage();"
                              v-on:keyup.enter="getTotalUsage();"
                              prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-card-text class="pa-2">
                              <v-skeleton-loader
                                :loading="process.row_penggunaan"
                                transition="scale-transition"
                                :tile="false"
                                :style="process.row_penggunaan ? 'height: calc(60vh - 305px)' : ''"
                                type="table-tbody">
                                <v-data-table
                                  :headers="headers_penggunaan"
                                  :items="row_penggunaan"
                                  :items-per-page="10"
                                  class="elevation-1 required">
                                  <template slot="body.append">              
                                    <tr>
                                      <td colspan="7" class="text-left pl-4 font-weight-bold"></td>
                                      <td class="font-weight-bold text-right">{{totalAmountUsage | price}}</td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-skeleton-loader>
                            </v-card-text>           
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div v-if="tab === 2">
                      <div class="px-2">
                        <v-row class="mt-5 justify-end">
                          <v-col cols="12" md="8" sm="6" v-if="$vuetify.breakpoint.name !== 'xs'">
                            <div class="text-left font-weight-600 title ml-3">
                              Pengembalian alat
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" sm="6">
                            <v-text-field
                              outlined
                              dense
                              :disabled="process.run"
                              :hide-details="true"
                              placeholder="Cari . . ."
                              clearable
                              color="#0299B7"
                              class="mx-3"
                              v-model="filter.return_tool"
                              @click:clear="filter.return_tool = ''; getToolReturn();"
                              v-on:keyup.enter="getToolReturn();"
                              prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-card-text class="pa-2">
                              <v-skeleton-loader
                                :loading="process.row_pengembalian"
                                transition="scale-transition"
                                :tile="false"
                                :style="process.row_pengembalian ? 'height: calc(60vh - 305px)' : ''"
                                type="table-tbody">
                                <v-data-table
                                  :headers="header_pengembalian"
                                  :items="row_pengembalian"
                                  :items-per-page="10"
                                  class="elevation-1 required">
                                </v-data-table>
                              </v-skeleton-loader>
                            </v-card-text>           
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                  <!-- end tabs -->

                  <!-- history -->
                  <div class="py-2 mt-5">
                    <div class="color-first title font-weight-bold pb-2 mt-1 mb-3">
                      Riwayat
                    </div>
                    <v-timeline 
                      align-top
                      dense>
                      <v-timeline-item
                        small 
                        color="#0299B7"
                        v-for="(item, i) in detail.researchHistory" :key="i">
                        <v-row>
                          <v-col>
                            <span class="color-first body-2">{{item.createdAt | datetime}} WIB</span>
                            <div class="caption font-weight-bold"> {{item.statusSubmission.statusPengajuan}} </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </div>
                  <!-- end history -->

                  <v-card-actions class="mt-8 justify-center">
                    <v-btn
                      large
                      width="120"
                      elevation="0"
                      :disabled="process.form"
                      class="text-capitalize"
                      @click="toHome()">
                      Batal
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { get } from "@/service/Axios";
  import { TokenService } from "@/service/Storage.Service";
  import LoadingComponent from '@/components/Loading.vue'
  import statusMixin from '@/mixin';
  export default {
    mixins: [statusMixin],  
    data() {
      return {
        detail: {},
        file: '',
        fileSafety: '',
        fileProposal: '',
        isUii: false,
        row_riwayat: [],
        rows_penggunaan: [],
        rows_pengembalian: [],
        headers_riwayat: [
          { text: 'No.', value: 'no', sortable: false, },
          { text: 'Tanggal', value: 'tanggal', sortable: false, },
          { text: 'Status', value: 'status', sortable: false, },
          { text: 'Proses selanjutnya', value: 'proses_selanjutnya',sortable: false, },
          // {  text: 'Aksi', value: 'aksi', align: 'center', width: '12%' },
        ],

        headers_penggunaan: [
          { text: 'No.', value: 'no', sortable: false, },
          { text: 'Jenis', value: 'jenis_barang', sortable: false, },
          { text: 'Kode', value: 'kode_barang', sortable: false, },
          { text: 'Nama', value: 'nama_barang', sortable: false, },
          { text: 'Pemakaian', value: 'pemakaian', sortable: false, align: 'right'},
          { text: 'Satuan', value: 'satuan', sortable: false},
          { text: 'Harga satuan (Rp)', value: 'harga_satuan', sortable: false, align: 'right'},
          { text: 'Harga total (Rp)', value: 'harga_total', sortable: false, align: 'right'},
        ],

        header_pengembalian: [
          { text: 'No.', value: 'no', sortable: false, },
          { text: 'Jenis', value: 'jenis_barang', sortable: false, },
          { text: 'Kode', value: 'kode_barang', sortable: false, },
          { text: 'Nama', value: 'nama_barang', sortable: false, },
          { text: 'Total', value: 'total', sortable: false, align: 'right'},
          { text: 'Kembali', value: 'kembali', sortable: false, align: 'right'},
          { text: 'Rusak', value: 'rusak', sortable: false, align: 'right'},
          { text: 'Diganti', value: 'diganti', sortable: false, align: 'right'},
        ],
        items: ['Riwayat penambahan', 'Total penggunaan', 'Pengembalian alat'],
        process: {
          run: false,
          row_riwayat: false,
          row_penggunaan: false,
          row_pengembalian: false,
          loading: false
        },
        filter: {
          history: '',
          total_usage: '',
          return_tool: ''
        },
        totalAmountUsage: 0,
        tab: null
      }
    },

    components: {
      LoadingComponent,
    },

    computed: {
      user () {
        return this.$store.state.user
      },
      token () {
        return this.$store.state.access_token
      }
    },

    watch: {},

    mounted() {
      if (TokenService.getUuid() === null) {
        this.$router.push(`pendaftaran`)
      } else {
        this.fetch();
      }
    },

    methods: {
      async fetch() {
        try {
          this.process.run = true;
          this.process.loading = true;
          const uuid = TokenService.getUuid();
          const params = { 
            uuid: uuid,
            flag_officer: '1'
          };
          const response = await get(`research`, { params });
          if (response.status === 200) {
            this.detail = response.data.data[0];
            const email = this.detail.user.email
            if (email.endsWith("uii.ac.id")) {
              this.isUii = true
            } else {
              this.isUii = false
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.process.run = false;
          this.process.loading = false;
          this.getFile();
        }
      },

      async getFile() {
        try {
          const params = {
            url: this.detail.filePersetujuanDosen
          }
          const response = await get(`file`, { params });
          if (response.status === 200) {
            this.file = response.data.data;
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.getFileSafety()
        }
      },

      async getFileSafety() {
        try {
          const params = {
            url: this.detail.fileSertifikatPelatihan
          }
          const response = await get(`file`, { params });
          if (response.status === 200) {
            this.fileSafety = response.data.data;
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.getFileProposal()
        }
      },

      async getFileProposal() {
        try {
          const params = {
            url: this.detail.fileProposal
          }
          const response = await get(`file`, { params });
          if (response.status === 200) {
            this.fileProposal = response.data.data;
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.getHistoryAddition()
          this.getTotalUsage()
          this.getToolReturn()
        }
      },

      async getHistoryAddition() {
        this.process.row_riwayat = true
        const params = {
          uuid: this.detail.uuid,
          search: this.filter.history
        }
        try {
          const response = await get(`research-usage/history`, {params});
          if (response.status === 200) {
            const res = response.data.data
            this.row_riwayat = res.map((data, index) => {
              data.createdAt = this.$callbackdatetime(data.createdAt)
              return {
                no: index + 1,
                tanggal: data.createdAt + ' WIB',
                status: data.statusPengajuan,
                kd_status_pengajuan: data.kdStatusPengajuan,
                proses_selanjutnya: data.prosesSelanjutnya,
                uuid: data.uuid
              }
            });
            this.process.row_riwayat = false
          }
        } catch (error) {
          console.error(error);
        }
      },

      async getTotalUsage() {
        this.process.row_penggunaan = true
        const params = {
          uuid: this.detail.uuid,
          search: this.filter.total_usage
        }
        try {
          const response = await get(`research-usage/usage`, {params});
          if (response.status === 200) {
            const res = response.data.data
            this.row_penggunaan = res.map((data, index) => {
              const hargaSatuan = data.hargaSatuan.toLocaleString('id-ID');
              const hargaTotal = data.hargaTotal.toLocaleString('id-ID');
              return {
                no: index + 1,
                jenis_barang: data.namaJenisBarang,
                kode_barang: data.kdBarang,
                nama_barang: data.namaBarang,
                pemakaian: data.jlhPenggunaan,
                satuan: data.satuan,
                harga_satuan: hargaSatuan,
                harga_total: hargaTotal,
              }
            });
            this.totalAmountUsage = response.data.total
            this.process.row_penggunaan = false
          }
        } catch (error) {
          console.error(error);
        }
      },

      async getToolReturn() {
        this.process.row_pengembalian = true
        const params = {
          uuid: this.detail.uuid,
          search: this.filter.return_tool
        }
        try {
          const response = await get(`research-usage/return-tool`, {params});
          if (response.status === 200) {
            const res = response.data.data;
            const pengembalian = await res;
            this.row_pengembalian = res.map((data, index) => {
              return {
                no: index + 1,
                jenis_barang: data.namaJenisBarang,
                kode_barang: data.kdBarang,
                nama_barang: data.namaBarang,
                total: data.jlhPenggunaan,
                kembali: data.jlhKembali,
                rusak: data.jlhRusak,
                diganti: data.jlhDiganti,
              }
            });
            this.process.row_pengembalian = false
          }
        } catch (error) {
          console.error(error);
        }
      },

      toHome() {
        TokenService.removeUUID(TokenService.getUuid())
        history.back()
      }
    }
  }
</script>

<style scoped>
.td-flex {
  display: flex;
  align-items: start;
  width: 100%;
}

.max-line {
  max-width: 30px;
  min-width: 20px;
  margin-left: 12em;
}

/* .v-tab {
  text-transform: none !important;
} */
</style>