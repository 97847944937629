<template>
  <v-row class="mt-3 px-3 loading">
    <v-col cols="12" class="py-0" >
      <v-card-text class="text-center">
        <v-progress-circular
          :size="150"
          :width="10"
          indeterminate
          color="#0299B7">
        </v-progress-circular>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>

export default({
  name: 'LoadingComponent',
  data() {
    return {

    }
  },
})
</script>
