<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <div>
      <!-- snackbar -->
      <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
      <!-- end snackbar -->

      <v-row class="mt-3 px-3">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div class="color-first title font-weight-bold title mt-3">
                    Lab. Mikrobiologi Farmasi
                  </div>
                  <!-- row filter -->
                  <v-row class="pt-5">
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.lab"
                        item-text="nama_laboratorium"
                        item-value="id"
                        v-model="form.lab"
                        v-on:change="onSelectLab($event)"
                        label="Laboratorium"
                        color="#0299B7"
                        hide-details>                        
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.usage_type"
                        item-text="jenis_penggunaan"
                        item-value="id"
                        v-model="form.jenis"
                        v-on:change="onSelectJenis($event)"
                        label="Jenis penggunaan"
                        color="#0299B7"
                        hide-details>                        
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.status"
                        item-text="status_pengajuan"
                        item-value="id"
                        v-model="form.status"
                        v-on:change="onSelectStatus($event)"
                        label="Status"
                        color="#0299B7"
                        hide-details>                        
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <!-- end row filter -->

                  <!-- row search -->
                  <v-row class="mt-7 justify-end">
                    <v-col cols="12" md="4">
                      <v-text-field
                        :disabled="process.run"
                        outlined
                        dense
                        :hide-details="true"
                        placeholder="Cari . . ."
                        clearable
                        color="#0299B7"
                        class="mx-3"
                        v-model="form.cari"
                        @click:clear="form.cari = ''; getReserch();"
                        v-on:keyup.enter="getReserch();"
                        prepend-inner-icon="mdi-magnify">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- end row search -->

                  <!-- row table -->
                  <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="row"
                        :items-per-page="10"
                        class="elevation-1 required">
                        <template v-slot:[`item.status`]="{ item }">
                          <td>
                            <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                          </td>
                        </template>
                        <template v-slot:[`item.aksi`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="#0299B7"
                              :disabled="process.detail"
                              :loading="process.detail"
                              @click="toPage(item, 'detail')"
                              class="text-capitalize color-first pa-0 mx-1"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Detail</span>
                        </v-tooltip>
                      
                      </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>
                  <!-- end row table -->
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { get } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
export default {
  mixins: [statusMixin],
  data() {
    return {
      roles_type: '',
      form: {
        lab: '',
        jenis: '',
        status: '',
        cari: ''
      },
      process: {
        run: false
      },
      list: {
        lab: [],
        usage_type: [],
        status: [],
      },
      headers: [
          {
            text: 'No.',
            value: 'no',
            sortable: false,
          },
          { text: 'Nama', value: 'nama', sortable: false, },
          { text: 'Jenis', value: 'jenis_penelitian', sortable: false, },
          { text: 'Judul atau nama sampel', value: 'judul', sortable: false, },
          { text: 'Tanggal penggunaan', value: 'tanggal_penggunaan', sortable: false, },
          { text: 'Status', value: 'status',sortable: false, },
          { 
            text: 'Aksi', 
            value: 'aksi',
            align: 'center',
            width: '12%'
          },
      ],
      hasDetail: false,
      row: [],
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },

  components: {
    Snackbar,
  },

  mounted () {
    TokenService.removeUUID(TokenService.getUuid())
    this.fetch();
  },
 
  methods: {
    fetch() {
      this.process.run = true
      const data = JSON.parse(localStorage.getItem('filter'))
      if (data) {
        const { form } = this
        this.hasDetail = true
        form.lab = data.lab
        form.jenis = data.jenis
        form.status = data.status
        localStorage.removeItem("filter");
      }
      this.cekUserSelf();
    },

    async cekUserSelf() {
      await get(`user/self`).then((response) => {
        if (response.status === 200) {
          if (response.data.data === null) {
            this.dialog.form = true
            this.form.email = this.user.email
            this.form.nama = this.user.name
            this.getInstansi();
          } else {
            let res = response.data.data
            let role = res.role.namaRole
            this.roles_type = role
            this.getLab(res);
          }
        }
      })
    },

    getLab(data) {
      try {
        let lab = data.admin
        for (let i = 0; i < lab.length; i++) {
          const element = lab[i];
          this.list.lab.push({
            id: element.lab.id,
            kd_laboratorium: element.lab.kdLaboratorium,
            nama_laboratorium: element.lab.namaLaboratorium,
          });
        }
        // this.list.lab.unshift({
        //   id: 'all',
        //   kd_laboratorium: 'all',
        //   nama_laboratorium: 'Semua',
        // });
        this.form.lab = this.hasDetail ? this.form.lab : this.form.lab === undefined ? this.list.lab[0].id : this.list.lab[0].id;       
      } catch (error) {
        console.log(error);
      } finally {
        this.getUsageType();
      }
    },

    async getUsageType() {
      try {
        await get(`usage-type`).then((response) => {
        if (response.status === 200) {
          let res = response.data.data;
          this.process.run = false
          for (const i in res) {
            this.list.usage_type.push({
              id: res[i].id,
              kd_jenis_penggunaan: res[i].kdJenisPenggunaan,
              jenis_penggunaan: res[i].jenisPenggunaan,
            })
          }
          this.list.usage_type.unshift({
            id: 'all',
            kd_jenis_penggunaan: 'all',
            jenis_penggunaan: 'Semua',
          })
        }
        this.form.jenis = this.hasDetail ? this.form.jenis : this.form.jenis === undefined ? this.list.usage_type[0].id : this.list.usage_type[0].id;
      });
      } catch (error) {
        console.log(error);
      } finally {
        this.getStatusSubmission();
      }
     
    },

    async getStatusSubmission() {
      try {
        await get(`submission-status`).then((response) => {
        if (response.status === 200) {
          let res = response.data.data;
          this.process.run = false
          for (const i in res) {
            this.list.status.push({
              id: res[i].id,
              kd_status_pengajuan: res[i].kdStatusPengajuan,
              status_pengajuan: res[i].statusPengajuan,
            })
          }
          this.list.status.unshift({
            id: 'all',
            kd_status_pengajuan: 'all',
            status_pengajuan: 'Semua',
          })
        }
        this.form.status = this.hasDetail ? this.form.status : this.form.status === undefined ? this.list.status[0].id : this.list.status[0].id;
      });
      } catch (error) {
        console.log(error);
      } finally {
        this.getReserch()
      }
     
    },

    async getReserch() {
      try {
        this.process.run = true
        const { form } = this
        const params = {
          id_jenis_penelitian: form.jenis,
          id_laboratorium: form.lab,
          id_status_pengajuan: form.status,
          search: form.cari,
          flag_officer: '1'
        }
        const response = await get(`research`, {params})
        if (response.status === 200) {
          let res = response.data.data
          this.process.run = false
          this.row = res.map((data, index) => {
            data.tglMulai = this.$callbackdate(data.tglMulai)
            data.tglSelesai = this.$callbackdate(data.tglSelesai)
            return {
              no: index + 1,
              nama: data.user.nama,
              jenis_penelitian: data.researchType.jenisPenelitian,
              judul: data.judul,
              tanggal_penggunaan: data.lab.namaLaboratorium + ' ' + data.tglMulai + ' - ' + data.tglSelesai,
              status: data.statusSubmission.statusPengajuan,
              proses_selanjutnya: data.statusSubmission.prosesSelanjutnya,
              uuid: data.uuid
            }
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.process.run = false
      }
    },

    onSelectLab(val) {
      this.form.lab = val
      this.getReserch()
    },

    onSelectJenis(val) {
      this.form.jenis = val
      this.getReserch()
    },

    onSelectStatus(val) {
      this.form.status = val
      this.getReserch()
    },

    toPage(val) {
      const { form } = this
      const data = {
        lab: form.lab,
        jenis: form.jenis,
        status: form.status
      }
      localStorage.setItem('filter', JSON.stringify(data))
      TokenService.setUuid(val.uuid);
      this.$router.push({ path: '/riwayat/detail'})
    },
  },
}
</script>