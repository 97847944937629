<template>
  <div>
    <v-dialog v-model="dialog.confirmation" persistent max-width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="dialog.confirmation = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Keluar dari sistem Pharmlab UII ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Anda akan keluar dari sistem</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.confirmation = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="logout">
            Ya, keluar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      color="#fff"
      v-model="drawer"
      fixed
      app
      v-if="$route.name !== 'login'">
      <div class="pa-3 mb-4 mt-1" @click="toHome()">
        <div class="font-weight-bold text-uppercase title cursor-pointer"><span class="color-default">Pharmlab</span> uii</div>
      </div>

      <div id="nav">
        <v-list dense nav v-for="(header, index) in menu" :key="index">
          <template v-if="!Array.isArray(header.submenu)">
            <v-list-item link :to="header.link">
              <v-list-item-icon class="mr-3">
                <v-icon :color="header.link.split('/')[1] === $route.path.split('/')[1] ? '#ffffff' : '#B2B2B2'">{{ header.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content :class="header.link.split('/')[1] === $route.path.split('/')[1] ? 'white--text' : 'color-fourth'">
                <v-list-item-title>{{ header.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          
          <template v-else>
            <v-subheader class="caption color-fivefth">{{ header.label }}</v-subheader>
            <v-list-item v-for="(item, itemIndex) in header.submenu"  :key="itemIndex" link :to="item.link">
              <v-list-item-icon class="mr-3">
                <v-icon :color="item.link.split('/')[1] === $route.path.split('/')[1] ? '#ffffff' : '#B2B2B2'">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content :class="item.link.split('/')[1] === $route.path.split('/')[1] ? 'white--text' : 'color-fourth'">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>

      <template v-slot:append>
        <div v-if="$vuetify.breakpoint.mdAndDown" @click="logout" color="#0299B7">
          <v-divider></v-divider> 
          <v-btn class="my-3 red--text text-capitalize"  text @click="logout"><v-icon size="22" left>mdi-logout-variant</v-icon>Keluar</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar  
      :clipped-left="clipped"
      color="#fff"
      fixed
      nav
      elevation="0"
      app>
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer"/>
      <v-toolbar-title class="body-1 pl-5 d-flex align-center">
      <v-divider
        class="mx-3"
        vertical
        style="min-height: 25px !important
        height: 25px;
        min-width: 3px;
        margin-top: auto;
        margin-bottom: auto;
        background: #dadada;">
      </v-divider>
      <span class="text-second">
        {{ $route.meta.title }}
      </span>
      </v-toolbar-title>
      <v-spacer/>
      <!-- profil -->
      <div class="pr-2 hidden-md-and-down">
        <v-menu  
          :close-on-click="true"
          :close-on-content-click="true"
          :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-list class="py-0" color="#0299B7" style="border-radius: 50px;">
              <v-list-item class="pl-1" 
                v-bind="attrs"
                v-on="on"
                style="border-radius: 50px;"
                to="/profile">
                <v-list-item-avatar>
                  <!-- <v-img :src="user.picture !=='' ? user.picture : require('@/assets/image/member-default.jpg')"></v-img> -->
                  <v-img :src="user.picture"></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="py-0 white--text">
                  <v-list-item-title class="caption font-weight-bold pt-0">
                    {{ user.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-light white--text pb-0" style="font-size: 10px;">
                    {{ user.email }} 
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-menu>
      </div>
      <!-- end profil -->

      <!-- logout -->
      <div class="pr-6 cursor-pointer hidden-md-and-down" @click="dialog.confirmation = true">
        <v-card-title class="justify-center pb-1">
          <v-icon color="#ff6161">mdi-logout-variant</v-icon>
        </v-card-title>
        <v-card-text class="text-center pa-0  red--text" style="font-size: 10px;">
          Keluar
        </v-card-text>
      </div>
      <!-- end logout -->
    </v-app-bar> 
  </div>
</template>

<script>
import { TokenService } from "@/service/Storage.Service";
export default {
  data() {
    return {
      drawer: true,
      dialog: {
        confirmation: false
      },
      clipped: false,
      pageAccess: false,
      count: '',
      notification: [],
      process: {
        run: false,
        form: false
      },
       menu: [],
    }
  },
  watch: {
    
  },

  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    },
    roles () {
      return this.$store.state.roles
    }
  },

  created() {
    this.access()
  },

  methods: {
    access() {
      this.menu = [];
      if (this.roles === 'Pendaftar') {
        this.menu = [
          {
            label: 'Pendaftaran',
            icon: 'mdi-file-plus',
            link: '/pendaftaran'
          },
          {
            label: 'Pembayaran',
            icon: 'mdi-cash-multiple',
            link: '/pembayaran'
          },
        ]
      if (this.user.email && this.user.email.endsWith('uii.ac.id')) {
        this.menu.push({
          label: 'Surat bebas lab',
          icon: 'mdi-note-check',
          link: '/surat'
        });
      }
      } else if (this.roles === 'Kalab') {
        this.menu = [
          {
            label: 'Pendaftar',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Pendaftaran',
                icon: 'mdi-file-plus',
                link: '/pendaftaran'
              },
              {
                label: 'Pembayaran',
                icon: 'mdi-file-document',
                link: '/pembayaran'
              },
            ]
          },
          
          {
            label: 'Kalab',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Persetujuan',
                icon: 'mdi-file',
                link: '/persetujuan-kalab'
              },
              {
                label: 'Riwayat',
                icon: 'mdi-zip-box',
                link: '/riwayat'
              },
            ]
          },

        
          // {
          //   label: 'Pengujian',
          //   icon: 'mdi-filter',
          //   submenu: [
          //     {
          //       label: 'Persetujuan',
          //       icon: 'mdi-note-check',
          //       link: '/persetujuan-kalab-lpmok',
          //     },
          //     {
          //       label: 'Analisis sampel',
          //       icon: 'mdi-filter',
          //       link: '/analisis-sampel',
          //     },
          //     {
          //       label: 'Review sampel',
          //       icon: 'mdi-magnify',
          //       link: '/review-sampel-lpmok',
          //     },
          //     {
          //       label: 'LHU',
          //       icon: 'mdi-file-document',
          //       link: '/lhu-kalab-lpmok',
          //     }
          //   ],
          // },
        ]
      } else if (this.roles === 'Laboran') {
        this.menu = [
          {
            label: 'Pendaftar',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Pendaftaran',
                icon: 'mdi-file-plus',
                link: '/pendaftaran'
              },
              {
                label: 'Pembayaran',
                icon: 'mdi-file-document',
                link: '/pembayaran'
              },
            ]
          },

          {
            label: 'Laboran',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Persetujuan',
                icon: 'mdi-file-document',
                link: '/persetujuan-laboran'
              },
              {
                label: 'Penggunaan',
                icon: 'mdi-file-find',
                link: '/penggunaan-laboran'
              },
              {
                label: 'Riwayat',
                icon: 'mdi-zip-box',
                link: '/riwayat-laboran'
              },
            ]
          },
         
          {
            label: 'Manajemen lab',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Alat',
                icon: 'mdi-test-tube',
                link: '/alat',
              },
              {
                label: 'Bahan',
                icon: 'mdi-flask-empty-outline',
                link: '/bahan',
              },
              {
                label: 'Instrumen',
                icon: 'mdi-microscope',
                link: '/instrumen',
              },
            ]
          },
          // {
          //   label: 'Pengujian',
          //   icon: 'mdi-filter',
          //   submenu: [
          //     {
          //       label: 'Persetujuan',
          //       icon: 'mdi-note-check',
          //       link: '/persetujuan-kalab-lpmok',
          //     },
          //     {
          //       label: 'Analisis sampel',
          //       icon: 'mdi-filter',
          //       link: '/analisis-sampel',
          //     },
          //     {
          //       label: 'Review sampel',
          //       icon: 'mdi-magnify',
          //       link: '/review-sampel-lpmok',
          //     }
          //   ],
          // },
        ]
        if (this.user.email && this.user.email.endsWith('@gmail.com')) {
          this.menu[1].submenu.push({
            label: 'Surat Bebas Lab',
            icon: 'mdi-note-check',
            link: '/surat-laboran'
          });
      }
      } else if (this.roles === 'Petugas pembayaran') {
        this.menu = [
          {
            label: 'Petugas pembayaran',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Pembayaran',
                icon: 'mdi-file-document',
                link: '/petugas-pembayaran-pembayaran'
              },
              {
                label: 'Riwayat pembayaran',
                icon: 'mdi-zip-box',
                link: '/riwayat-pembayaran-petugas-pembayaran'
              },
              {
                label: 'Pengembalian deposit',
                icon: 'mdi-file-find',
                link: '/pengembalian-deposit-petugas-pembayaran'
              },
              {
                label: 'Surat bebas lab',
                icon: 'mdi-note-check',
                link: '/surat-petugas-pembayaran'
              },
            ]
          },

          {
            label: 'Laboran',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Persetujuan',
                icon: 'mdi-file-document',
                link: '/persetujuan-laboran'
              },
              {
                label: 'Penggunaan',
                icon: 'mdi-file-find',
                link: '/penggunaan-laboran'
              },
              {
                label: 'Riwayat',
                icon: 'mdi-zip-box',
                link: '/riwayat-laboran'
              },
            ]
          },
         
          // {
          //   label: 'Pengujian',
          //   icon: 'mdi-filter',
          //   submenu: [
          //     {
          //       label: 'Persetujuan',
          //       icon: 'mdi-note-check',
          //       link: '/persetujuan-kalab-lpmok',
          //     },
          //     {
          //       label: 'Analisis sampel',
          //       icon: 'mdi-filter',
          //       link: '/analisis-sampel',
          //     },
          //     {
          //       label: 'Review sampel',
          //       icon: 'mdi-magnify',
          //       link: '/review-sampel-lpmok',
          //     }
          //   ],
          // },
        ]
      } else if (this.roles === 'Admin') {
        this.menu = [
          // {
          //   label: 'Pengujian',
          //   icon: 'mdi-filter',
          //   submenu: [
          //     {
          //       label: 'Persetujuan',
          //       icon: 'mdi-check-all',
          //       link: '/persetujuan-pengujian',
          //     },
          //     {
          //       label: 'Pembayaran',
          //       icon: 'mdi-cash-multiple',
          //       link: '/pembayaran-pengujian',
          //     },
          //     {
          //       label: 'Pengembalian dana',
          //       icon: 'mdi-cash',
          //       link: '/pengembalian-dana-pengujian',
          //     },
          //   ]
          // },
          {
            label: 'Manajemen lab',
            icon: 'mdi-flask-outline',
            submenu: [
              {
                label: 'Alat',
                icon: 'mdi-test-tube',
                link: '/alat',
              },
              {
                label: 'Bahan',
                icon: 'mdi-flask-empty-outline',
                link: '/bahan',
              },
              {
                label: 'Instrumen',
                icon: 'mdi-microscope',
                link: '/instrumen',
              },
              // {
              //   label: 'Parameter uji',
              //   icon: 'mdi-av-timer',
              //   link: '/parameter-uji',
              // }
            ]
          },
          // {
          //   label: 'Pengujian',
          //   icon: 'mdi-filter',
          //   submenu: [
          //     {
          //       label: 'Persetujuan',
          //       icon: 'mdi-note-check',
          //       link: '/persetujuan-kalab-lpmok',
          //     },
          //     {
          //       label: 'Analisis sampel',
          //       icon: 'mdi-filter',
          //       link: '/analisis-sampel',
          //     },
          //     {
          //       label: 'Review sampel',
          //       icon: 'mdi-magnify',
          //       link: '/review-sampel-lpmok',
          //     }
          //   ],
          // },
          {
            label: 'Manajemen user',
            icon: 'mdi-account-group',
            submenu: [
              {
                label: 'Petugas',
                icon: 'mdi-account-switch',
                link: '/petugas',
              }
            ]
          },
        ];
      }
    },
    isActive(link) {
      return this.$route.path.split('/')[1] === (link ? link.split('/')[1] : '');
    },
    logout() {
      this.process.form = true
      TokenService.removeToken()
      window.location = '/login'
      this.process.form = false
    },
    toHome() {
      switch (this.roles) {
        case 'Pendaftar':
            return window.location = '/pendaftaran'
        case 'Kalab':
            return window.location = '/persetujuan-kalab'
        case 'Laboran':
            return window.location = '/persetujuan-laboran'
        case 'Petugas pembayaran':
            return window.location = '/petugas-pembayaran-pembayaran'
        default:
          break;
      }
    }
  }
}
</script>

<style>
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
  opacity: 0;
}

#nav .v-list .v-list-item--active {
  background-color: #0299B7 !important;
}
</style>