import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: '',
    authenticated: false,
    roles: '',
    user: {},
    data_pdf: {}
  },
  getters: {
  },
  mutations: {
    setToken(state, accessToken) {
      console.log('isi acces token', accessToken);
      state.access_token = accessToken
      state.authenticated = true
    },
    setUser(state, accessUser) {
      state.user = accessUser
    },
    setRoles(state, roles) {
      state.roles = roles
    },
    setDataPdf(state, data_pdf) {
      state.data_pdf = data_pdf
    }
  },
  actions: {
    async getDataPdf(state, payload) {
      state.commit('setDataPdf', payload)
    }
  },
  modules: {
  }
})
