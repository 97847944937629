import Login from "@/views/public/Login.vue"

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Masuk"
    }
  },
]
export default routes.map(route => {
  const meta = {
    public: true,
    onlyLoggedOut: true,
    title: route.meta.title
  }
  return { ...route, meta }
})