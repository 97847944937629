import Vue from 'vue'
import Router from "vue-router"
import routes from "@/router/routes/index.js"
import store from "@/store/index.js"
import notFound from "@/views/public/404.vue"
import checkUserRole from "@/middleware/roles.js"


Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      beforeEnter: checkUserRole

    },
    {
      path: "/",
      beforeEnter: checkUserRole
    },
    {
      path: "/404",
      name: "404",
      component: notFound,
      meta: {
        title: "404"
      }
    }
  ].concat(routes)
})

router.beforeEach((to, from, next) => {
  const authenticated = store.state.authenticated
  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some(record => record.meta.public)
  const roles = store.state.roles

  // this route requires auth, check if logged in
  // if not, redirect to login page.
  if (!isPublic && !authenticated) {
    return next({
      path: "/login",
    })
  }


  if (authenticated && onlyLoggedOut) {
    return next("/")
  }

  if (to.meta && to.meta.roles && to.meta.roles.length > 0 && !to.meta.roles.includes(roles)) {
    return next({
      path: "/404",
    })
  }
  
  document.title = `Pharmlab | ${to.meta.title}`
  next()
  
})


export default router