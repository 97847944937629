<template>
  <div>
    <v-snackbar
      v-model="internalSnackbar"
      color="green lighten-2"
      tile
      top
      right
      class="white--text"
      :timeout="timeout">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
   snackbar: Boolean,
   text: String,
   timeout: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      internalSnackbar: false
    };
  },
  watch: {
    snackbar(newSnackbarValue) {
      this.internalSnackbar = newSnackbarValue;
    }
  }
}
</script>