<template>
  <div>
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
          <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
          <v-btn
            color='#0299B7'
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            Ya, 
            <span>simpan</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog detail -->
    <v-dialog v-model="dialog.detail" persistent width="1000">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Detail laporan</span>
          <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.detail = false; reset()">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12" class="text-left pb-0">
              <div class="color-first title font-weight-bold mt-2">
                Jenis laporan
              </div>
            </v-col>
            <v-col cols="12">
              <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                <tr>
                  <td width="35%">Jenis laporan</td>
                  <td width="3%">:</td>
                  <td>{{ form_pengujian.jenis_laporan }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-left pb-0">
              <div class="color-first title font-weight-bold mt-2">
                Isi laporan
              </div>
            </v-col>
            <v-col cols="12">
              <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                <tr>
                  <td width="35%">Tanggal</td>
                  <td width="3%">:</td>
                  <td>{{ form_pengujian?.tanggal_bayar | datetime }}</td>
                </tr>
                <tr>
                  <td width="30%" class="td-flex">Isi laporan</td>
                  <td width="1%">:</td>
                  <td>  
                    <div>
                      <quill-editor 
                        ref="myTextEditor" 
                        v-model="form_pengujian.desc"
                        height= '250px'
                        :options="option">
                      </quill-editor>
                    </div> 
                  </td>
                </tr>
                <tr v-if="form_pengujian.kd_status_progres_parameter === 4">
                  <td width="35%">Parameter</td>
                  <td width="3%">:</td>
                  <td>{{ detail.parameter?.parameterTest.namaParameterUji || '-'}}</td>
                </tr>
                <tr v-if="form_pengujian.kd_status_progres_parameter === 4">
                  <td width="35%">Hasil uji</td>
                  <td width="3%">:</td>
                  <td>{{ form_pengujian?.hasil_uji || '-' }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.detail = false; reset()">
            Kembali
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog detail -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <!-- data diri -->
                <v-row>
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold title mt-3 pb-2">
                      Data diri
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <table width="100%" class="body-2">
                      <tr>
                        <td width="30%">ID registrasi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.idRegistrasi || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Nama Lengkap</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.nama || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Nomor Mahasiswa/Kepegawaian</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.noInduk || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">No. WhatsApp aktif</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.noHp || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Instansi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaInstitusi || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Perguruan tinggi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaPerguruanTinggi || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Fakultas</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaFakultas || '-' }}</td>
                      </tr>
                      <tr>
                        <td width="30%">Program studi</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.user?.namaProgramStudi || '-' }}</td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <!-- end data diri -->

                <!-- jenis penggunaan -->
                <v-row>
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold title mt-3 pb-2">
                     Jenis penggunaan
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <table width="100%" class="body-2">
                      <tr>
                        <td width="30%">Jenis</td>
                        <td width="1%">:</td>
                        <td>{{ detail?.usageType?.jenisPenggunaan || '-' }}</td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <!-- end jenis penggunaan -->

                <!-- sampel -->
                <ValidationObserver ref="observer">
                  <v-form>  
                    <v-row v-for="(item, i) in form.sampel" :key="i">
                      <v-col cols="12">
                        <div class="color-first title font-weight-bold title mt-3 pb-2">
                          Sampel {{ i + 1 }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr>
                            <td width="30%">Kode sampel</td>
                            <td width="1%">:</td>
                            <td>{{ item.kode_sampel || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Nama</td>
                            <td width="1%">:</td>
                            <td>{{ item.nama || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Jumlah</td>
                            <td width="1%">:</td>
                            <td>{{ item.jumlah || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Bentuk </td>
                            <td width="1%">:</td>
                            <td>{{ item.bentuk || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Penyimpanan</td>
                            <td width="1%">:</td>
                            <td>{{ item.penyimpanan || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Kondisi</td>
                            <td width="1%">:</td>
                            <td>{{ item.kondisi || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%">Kemasan</td>
                            <td width="1%">:</td>
                            <td>{{ item.kemasan || '-' }}</td>
                          </tr>
                          <tr>
                            <td width="30%" class="td-flex">Gambar</td>
                            <td width="1%">:</td>
                            <td>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header color="#0299B7">
                                    <template v-slot:actions>
                                      <v-icon color="white">
                                        $expand
                                      </v-icon>
                                    </template>
                                    <span class="white--text"></span>
                                  </v-expansion-panel-header>     
                                  <v-expansion-panel-content>
                                    <div v-if="item.isProgress" class="d-flex justify-center mt-5">
                                      <div>
                                        <v-progress-linear
                                          color="#0299B7"
                                          indeterminate
                                          rounded
                                          height="6"
                                          style="width: 300px;">
                                        </v-progress-linear>
                                      </div>
                                    </div>
                                    <div v-if="!item.isProgress">
                                      <iframe
                                        v-if="item.url && item.url?.startsWith('data:application/pdf') || item.url?.startsWith('blob:http://')" 
                                        width="100%" 
                                        :height="item.url === null ? '110px' : '500px'" 
                                        :src="item.url" 
                                        :lazy-src="item.url"
                                        frameborder="0"
                                        allowfullscreen>
                                        Oops! an error has occurred.
                                      </iframe>
                                      <v-img 
                                        v-else-if="item.url && item.url?.startsWith('data:image')"
                                        :src="item.url" 
                                        :lazy-src="item.url" 
                                        width="100%" 
                                        aspect-ratio="2">
                                      </v-img>
                                    </div>            
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col cols="12" v-if="save_status.kd_status_pengajuan !== 8">
                        <ValidationProvider
                          name="Gambar">
                          <v-file-input
                            v-model="item.file"
                            label="Unggah ulang gambar (Optional)"
                            outlined
                            dense
                            hide-details
                            color="#0299B7"
                            accept=".jpg,.jpeg,.pdf"
                            @change="onFileChange($event, i)"
                            :rules="[fileSizeRule]">
                          </v-file-input>
                          <i class="mt-1 caption color-second">
                            Maks 5MB dengan format file .jpg/.pdf
                          </i>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <table width="100%" class="body-2">
                          <tr v-if="save_status.kd_status_pengajuan !== 8">
                            <td width="30%">Status penerimaan sampel</td>
                            <td width="1%">:</td>
                            <td>
                              <ValidationProvider
                                name="Status"
                                rules="required"
                                v-slot="{ errors }">
                                <v-select
                                  dense
                                  v-model="item.status_sampel"
                                  :items="list.status_sampel"
                                  label="Status sampel"
                                  color="#0299B7"
                                  item-text="label"
                                  item-value="value"
                                  :error-messages="errors"
                                  outlined>
                                </v-select>
                              </ValidationProvider>
                            </td>
                          </tr>
                          <tr v-if="save_status.kd_status_pengajuan !== 8">
                            <td width="30%">Tanggal penerimaan sampel</td>
                            <td width="1%">:</td>
                            <td>
                              <v-menu
                                ref="menustart"
                                v-model="item.item_start"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                class="z-my"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                  <ValidationProvider
                                    name="Tanggal mulai"
                                    :rules="item.status_sampel === 1 ? '' : 'required'"
                                    v-slot="{ errors }">
                                    <v-text-field
                                      :value="formatDateStart(i)"
                                      prepend-inner-icon="mdi-calendar"
                                      :readonly="item.status_sampel === 1"
                                      :filled="item.status_sampel === 1"
                                      :disabled="item.status_sampel === 1"
                                      outlined
                                      dense
                                      color="#0299B7"
                                      :error-messages="errors"
                                      v-on="on">
                                    </v-text-field>
                                  </ValidationProvider>
                                </template>
                                <v-date-picker
                                  v-model="item.start_date"
                                  :min="reminder"
                                  v-on:change="startdate(i)" 
                                  color="#0299B7"
                                  @input="item.item_start = false">
                                </v-date-picker>
                              </v-menu>
                            </td>
                          </tr>
                          <tr v-for="(parameter, parameterIndex) in detail.sample[i].parameter" :key="parameterIndex">
                            <td width="30%" style="display: flex;">Parameter uji {{parameterIndex + 1}}</td>
                            <td width="1%">:</td>
                            <td>
                              <div v-if="parameter.parameterTest">
                                {{ parameter.parameterTest.namaParameterUji }} | <span class="caption" :class="getColor(parameter.status.kdStatusSampelParameter)"> {{ parameter.status.statusSampelParameter }} </span>
                                <v-radio-group v-model="parameter.id_status_sampel_parameter" v-if="parameter.status.kdStatusSampelParameter === 1">
                                  <v-radio
                                    v-for="(status, statusIndex) in sort.status_parameter"
                                    :key="statusIndex" 
                                    :label="status.text"
                                    :value="status.id"
                                    color="#0499b7"
                                    class="py-0 mt-0"
                                    @change="updateParameter(i, parameterIndex, status.id, parameter)">
                                  </v-radio>
                                </v-radio-group>

                                <!-- ketika statusnya pembayaran dp50% terverifikasi  -->
                                <v-row class="mb-2" v-if="save_status.kd_status_pengajuan === 6">
                                  <v-col cols="6" class="pb-0">
                                    <ValidationProvider
                                      name="Analisis"
                                      rules="required"
                                      v-slot="{ errors }">
                                      <v-select
                                        dense
                                        v-model="detail.sample[i].parameter[parameterIndex].analisis"
                                        :items="list.kalab"
                                        label="Analisis"
                                        color="#0299B7"
                                        item-text="label"
                                        item-value="value"
                                        :error-messages="errors"
                                        outlined
                                        @change="updateParameterAnalisis(i, parameterIndex, $event, parameter)">
                                      </v-select>
                                    </ValidationProvider>
                                  </v-col>
                                  <v-col cols="6" class="pb-0">
                                    <ValidationProvider
                                      name="Penyelia"
                                      rules="required"
                                      v-slot="{ errors }">
                                      <v-select
                                        dense
                                        v-model="detail.sample[i].parameter[parameterIndex].penyelia"
                                        :items="list.kalab"
                                        label="Penyelia"
                                        color="#0299B7"
                                        item-text="label"
                                        item-value="value"
                                        :error-messages="errors"
                                        outlined
                                        @change="updateParameterPenyelia(i, parameterIndex, $event, parameter)">
                                      </v-select>
                                    </ValidationProvider>
                                  </v-col>
                                </v-row>
                                <!-- end ketika statusnya pembayaran dp50% terverifikasi  -->
                              </div>
                            </td>
                          </tr>

                          <!-- ketika statusnya selesai pengujian -->
                          <template v-if="save_status.kd_status_pengajuan === 8">
                            <tr>
                              <td width="30%"></td>
                              <td width="1%"></td>
                              <td>
                                Analisis: {{ detail.sample[i].parameter[i].userAnalyst?.nama || '-' }} ({{ detail.sample[i].parameter[i].userAnalyst?.noInduk || '-' }})
                              </td>
                            </tr>
                            <tr>
                              <td width="30%"></td>
                              <td width="1%"></td>
                              <td>
                                Penyelia: {{ detail.sample[i].parameter[i].userSupervisor?.nama || '-' }} ({{ detail.sample[i].parameter[i].userSupervisor?.noInduk || '-' }})
                              </td>
                            </tr>
                            <tr>
                              <td width="30%">Status penerimaan sampel</td>
                              <td width="1%">:</td>
                              <td>
                                {{ detail.sample[i].status.statusSampel || '-'}}
                              </td>
                            </tr>
                            <tr >
                              <td width="30%">Tanggal penerimaan sampel</td>
                              <td width="1%">:</td>
                              <td>
                                {{  detail.sample[i].tglPenerimaanSampel | datetime}}
                              </td>
                            </tr>
                          </template>
                          <!-- end ketika statusnya selesai pengujian -->
                        </table>
                      </v-col>
                      <v-col cols="12" v-if="save_status.kd_status_pengajuan === 8">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left"> No </th>
                                <th class="text-left"> Tanggal </th>
                                <th class="text-left"> Status pengujian </th>
                                <th class="text-left"> Proses selanjutnya </th>
                                <th class="text-left"> Aksi </th>  
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in detail.sample[i].parameter[i].historyProgres" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.statusProgres.updatedAt | datetime }}</td>
                                <td><span :class="getStatusClass(item.statusProgres.statusProgresParameter)">{{ item.statusProgres.statusProgresParameter }}</span></td>
                                <td>{{ item.statusProgres.prosesSelanjutnya }}</td>
                                <td>
                                  <template>
                                    <v-tooltip top v-if="item.statusProgres.kdStatusProgresParameter === 2 || item.statusProgres.kdStatusProgresParameter === 3 || item.statusProgres.kdStatusProgresParameter === 4">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          x-small
                                          fab
                                          icon
                                          min-width="25"
                                          color="#0299B7"
                                          :disabled="process.run"
                                          @click="openDialogDetail(item)"
                                          class="text-capitalize color-first pa-0"
                                          v-bind="attrs"
                                          v-on="on">
                                          <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Detail</span>
                                    </v-tooltip>
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-form>
                </ValidationObserver>
                <!-- sampel -->

                <!-- riwayat -->
                <v-row>
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold py-2 mt-5">
                      Riwayat
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-timeline 
                      align-top
                      dense>
                      <v-timeline-item
                        small 
                        color="#0299B7"
                        v-for="(item, j) in detail.history" :key="j">
                        <v-row>
                          <v-col>
                            <span class="color-first body-2">{{item.createdAt | datetime}} WIB</span>
                            <div class="caption font-weight-bold"> {{item.statusSubmission.statusPengajuan}} </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
                <!-- end riwayat -->

                <!-- persetujuan -->
                <v-row v-if="save_status.kd_status_pengajuan !== 6 && save_status.kd_status_pengajuan !== 8 ">
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold mt-2">
                      Persetujuan
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-radio-group v-model="sort.selected_persetujuan">
                      <v-radio
                        v-for="(status, index) in sort.status_persetujuan"
                        :key="index" 
                        :label="status.text"
                        :value="status.id"
                        color="#0499b7"
                        class="py-0 mt-0">
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" v-if="sort.selected_persetujuan == '0'">
                    <v-text-field
                      dense
                      outlined
                      label="Catatan (Optional)"
                      color="#0299B7"
                      v-model="form.catatan">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="sort.selected_persetujuan == '2'">
                    <v-select
                      dense
                      v-model="form.kalab"
                      :items="list.kalab"
                      label="Kalab"
                      color="#0299B7"
                      item-text="label"
                      item-value="value"
                      outlined>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- end persetujuan -->

                <!-- tagihan -->
                <v-row v-if="save_status.kd_status_pengajuan !== 1 && save_status.kd_status_pengajuan !== 6 && save_status.kd_status_pengajuan !== 8" >
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold mt-2">
                      Tagihan
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(50vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="row"
                        :items-per-page="10"
                        class="elevation-1 required">
                        <template slot="body.append">              
                          <tr>
                            <td colspan="8" class="text-left pl-4 font-weight-bold">DP 50%</td>
                            <!-- <td class="font-weight-bold text-right">{{totalAmount | price}}</td> -->
                          </tr>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <v-row v-if="save_status.kd_status_pengajuan === 8">
                  <v-col cols="12">
                    <div class="color-first title font-weight-bold mt-2">
                      Detail tagihan
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers_pengujian"
                      :items="row_pengujian"
                      :items-per-page="10"
                      class="elevation-1 required"
                      hide-default-header>
                      <template v-slot:header>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Sampel</th>
                            <th>Parameter uji</th>
                            <th class="text-right">Harga per pengujian (Rp)</th>
                            <th class="text-right">Harga replikasi (Rp)</th>
                            <th class="text-right">Harga total (Rp)</th>
                          </tr>
                        </thead>
                      </template>
                      <template v-slot:body>
                        <tbody>
                          <tr v-for="(item, index) in detail.bill" :key="item.uuid">
                            <td>{{ index+1 }}</td>
                            <td>{{ item.namaSampel }} </td>
                            <td>{{ item.namaParameterUji }} </td>
                            <td>
                              <ValidationProvider
                                name="Harga per pengujian (Rp)"
                                rules="numeric"
                                v-slot="{ errors }">
                                <v-currency-field
                                  dense
                                  outlined
                                  color="#0299B7"
                                  v-model="item.hargaPengujian"
                                  @input="calculateAndStoreResult()"
                                  :error-messages="errors"
                                  require>
                                </v-currency-field>
                              </ValidationProvider>
                            </td>
                            <td>
                              <ValidationProvider
                                name="Harga replikasi (Rp)"
                                rules="numeric"
                                v-slot="{ errors }">
                                <v-currency-field
                                  dense
                                  outlined
                                  color="#0299B7"
                                  v-model="item.hargaReplikasi"
                                  @input="calculateAndStoreResult()"
                                  :error-messages="errors"
                                  require>
                                </v-currency-field>
                              </ValidationProvider>
                            </td>
                            <td class="text-right">{{ item.hargaTotal | price}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-left pl-4">Total (Rp)</td>
                            <td class="text-right pl-4">{{ detail.totalHarga | price}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-left pl-4">Sudah terbayar (Rp)</td>
                            <td class="text-right pl-4">{{ detail.totalTerbayar | price}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-left pl-4 font-weight-bold">Nominal akhir (Rp)</td>
                            <td class="text-right pl-4 font-weight-bold">{{ detail.totalHarga - detail.totalTerbayar | price }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Catatan (Optional)"
                      color="#0299B7"
                      v-model="form_pengujian.desc">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- end tagihan -->
                
              </v-card-text>
              <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
              <v-card-actions class="mt-2 pb-5 justify-center">
                <v-btn
                  outlined
                  class="text-capitalize color-first mx-1"
                  :disabled="process.form"
                  @click="toPage()">
                  Kembali
                </v-btn>
                <v-btn
                  color="#0299B7"
                  elevation="0"
                  class="text-capitalize white--text mx-1"
                  :disabled="process.run"
                  @click="cekForm()">
                  <span>Simpan</span>
                  <!-- <span v-if="sort.selected_persetujuan === '1' || save_status.kd_status_pengajuan === 3" class="text-capitalize">Simpan dan generate tagihan</span>
                  <span v-if="sort.selected_persetujuan === '2' || sort.selected_persetujuan === '0'" class="text-capitalize">Simpan</span>
                  <span v-if="save_status.kd_status_pengajuan === 6" class="text-capitalize">Simpan dan generate kode</span>
                  <span v-if="save_status.kd_status_pengajuan === 8" class="text-capitalize">Simpan dan generate tagihan akhir</span> -->
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>        
    </v-row>

  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import LoadingComponent from '@/components/Loading.vue'
import Snackbar from '@/components/Snackbar.vue';
import { TokenService } from "@/service/Storage.Service";
import moment from 'moment';
import 'moment/locale/id';
import { get } from "@/service/Axios";
import { serialize } from 'object-to-formdata';
import statusMixin from '@/mixin';

export default {
  mixins: [statusMixin],  
  data() {
    return {
      detail: {},
      dialog: {
        konfirmasi: false,
        detail: false
      },
      error: {
        analisis: '',
        penyelia: ''
      },
      fileSizeRule: (value) => {
        if (!value) {
          return true;
        }
        const size = value.size / 1024 / 1024; // konversi byte ke megabyte
        return size <= 5 ? true : 'Ukuran file tidak boleh lebih dari 5MB';
      },
      form: {
        sampel: [{
          file: [],
          kode_sampel: '',
          isProgress: false,
          nama: '',
          jumlah: '',
          bentuk: '',
          penyimpanan: '',
          kondisi: '',
          kemasan: '',
          gambar: '',
          status_sampel: 1,
          item_start: '',
          start_date: '',
          uuid: '',
          parameter_uji: [
            {
              uuid: '',
              id_status_sampel_parameter: '',
              penyelia: '',
              analisis: ''
            },
          ]
        }],
        catatan: '',
        kalab: ''
      },
      form_pengujian: {
        jenis_laporan: '',
        tanggal_bayar: '',
        desc: '',
        kd_status_progres_parameter: '',
        hasil_uji: '',
      },
      isImage: '',
      file: [],
      process: {
        run: false,
        loading: false,
        form: false
      },
      list: {
        status_sampel: [],
        parameter: [],
        kalab: [],
      },
      sort: {
        selected_parameter: [],
        selected_persetujuan: '1',
        status_parameter: [],
        status_persetujuan: [
        {
            id: '1',
            text: 'Disetujui'
          },
          {
            id: '2',
            text: 'Perlu verifikasi lab'
          },
          {
            id: '0',
            text: 'Ditolak'
          },
        ],
      },
      option: {
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'align': [] }],
              ['image'],
            ]
          },
        },
      headers: [
        { text: 'No.', value: 'no', sortable: false, },
        { text: 'Sampel', value: 'persen_pembagian', sortable: false,},
        { text: 'Parameter uji', value: 'persen_pembagian', sortable: false,},
        { text: 'Harga per pengujian', value: 'harga_external', sortable: false, align: 'right' },
        { text: 'Harga replikasi', value: 'harga_internal', sortable: false, align: 'right' },
        { text: 'Harga total', value: 'harga_internal', sortable: false, align: 'right' },
      ],
      tagihan: {
        total: '',
        nominal: ''
      },
      headers_pengujian: [],
      row: [],
      row_pengujian: [],
      row_progress: [],
      snackbar: false,
      text: '',
      timeout: 4000,
      save_status: '',
      save_type: ''
    }
  },

  components: {
    LoadingComponent,
    Snackbar
  },

  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },

    roles () {
      return this.$store.state.roles
    },

    token () {
      return this.$store.state.access_token
    }
  },

  watch: {
    'form.sampel'(newSampel, oldSampel) {
      newSampel.forEach((element, index) => {
        if (element.file && element.file !== oldSampel[index].file) {
          this.form.sampel[index].isProgress = true;
          setTimeout(() => {
            this.form.sampel[index].isProgress = false;
            this.$set(this.form.sampel[index], element.file);
          }, 2000); 
        }
      })
    },
    
  },

  mounted () {
    if (TokenService.getUuid() === null) {
      this.$router.push(`/persetujuan-pengujian`)
    } else {
      this.cekParams()
    }  
  },

  methods: {
    cekParams() {
      const data = JSON.parse(localStorage.getItem('filter'));
      this.save_status = data;
      const params = this.$route.params.page
      this.save_type = params
      this.getSubmissionSampleStatus()
    },
    
    async getSubmissionSampleStatus() {
      this.process.loading = true
      try {
        this.process.run = true
        const response = await get(`test-submission-sample-status`)
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          const status = res.map((el) => ({
            id: el.kdStatusSampel,
            value: el.id,
            label: el.statusSampel,
            flag_aktif: el.flagAktif
          }))
          this.list.status_sampel = status
        }
      } catch (error) {
          console.log(error) 
      } finally {
          this.process.run = false;
          this.getTestSampleParameterStatus();
      }
    },

    async getTestSampleParameterStatus() {
      try {
        this.process.run = true
        const params = {
          role: this.roles
        }
        const response = await get(`test-submission-sample-parameter-status`, { params })
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          const parameter = res.map((el) => ({
            id: el.kdStatusSampelParameter,
            text: el.statusSampelParameter,
            uuid: el.uuid
          }))
          this.sort.status_parameter = parameter
        }
      } catch (error) {
        console.log(error) 
      } finally {
        this.process.run = false;
        this.getOfficer();
      }
    },

    async getOfficer() {
      const params = {
        flag_aktif: '1'
      }
      try {
        this.process.run = true
        const response = await get(`officer`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          const data = res.map((el) => ({
            label: el.nama,
            value: el.id,
          }))
          this.list.kalab = data
        }
      } catch (error) {
        console.log(error) 
      } finally {
        this.fetch()
      }
    },

    async fetch() {
      try {
        const uuid = TokenService.getUuid()
        const params = {
          uuid: uuid,
          flag_officer: '1'
        }
        const response = await get(`test`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.detail = res[0];
          const formattedSamples = this.detail.sample.map((item) => ({
            kode_sampel: item.kodeSampel || '',
            nama: item.nama || '',
            jumlah: item.jumlah || '',
            bentuk: item.phase.namaBentukSampel || '',
            penyimpanan: item.storage.namaPenyimpananSampel || '',
            kondisi: item.condition.namaKondisiSampel || '',
            kemasan: item.kemasan || '',
            gambar: item.fileGambar || '',
            uuid: item.uuid || '',
            status_sampel: item.idPengujianStatusSampel || 1,
            isProgress: false,
            item_start: '',
            start_date: item.tglPenerimaanSampel,
            parameter_uji: []
          }));
          this.form.sampel = formattedSamples;
          this.detail.sample.forEach((item) => {
            item.parameter.forEach((sampel) => {
              const statusSampelParameter = sampel.status.statusSampelParameter;
              this.list.parameter.forEach((tmp) => {
                tmp.status = statusSampelParameter
              })
            });
          });
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.getFile()
      }
    },

    async getFile() {
      try {
        let params = [];
        this.detail.sample.forEach(element => {
          params.push({ url: element.fileGambar });
        });
        for (let index = 0; index < params.length; index++) {
          const response = await get(`file-test`, { params: params[index] });
          if (response.status === 200) {
            let res = response.data;
            this.form.sampel[index].url = res.data || null;
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.process.loading = false
        this.process.run = false
      }
    },

    async openDialogDetail(item) {
      try {
        const params = {
          uuid: item.uuid,
        }
        const response = await get(`test-analysis/progress-detail`, {params})
        if (response.status === 200) {
          const res = response.data.data[0];
          this.form_pengujian = {
            jenis_laporan: res.statusProgres.statusProgresParameter,
            tanggal_bayar: res.statusProgres.updatedAt,
            desc: res.catatan,
            kd_status_progres_parameter: res.statusProgres.kdStatusProgresParameter,
            hasil_uji: res.parameter.hasilPengujian
          }
          console.log('this.form', this.form);
          this.dialog.detail = true
        }
      } catch (error) {
        console.log(error)
      }
    },

    onFileChange(data, index) {
      const pdf = data.name.endsWith('pdf');
      if (pdf) {
        this.selectPdf(data, index);
      } else {
        this.selectImage(data, index);
      }
    },

    async selectImage(e, i) {
      this.form.sampel[i].isProgress = true;
      const file = e;
      try {
        const readData = (f) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(f);
          });
        const data = await readData(file);
        this.form.sampel[i].url = data
      } catch (error) {
        console.error('Error reading data:', error);
      } finally {
        setTimeout(() => {
          this.form.sampel[i].isProgress = false;
        }, 2000);
      }
    },

    async selectPdf(e, i) {
      this.form.sampel[i].isProgress = true;
      const pdf = e
      try {
       const data = URL.createObjectURL(pdf)
       this.form.sampel[i].url = data
      } catch (error) {
        console.error('Error reading data:', error);
      } finally {
        setTimeout(() => {
          this.form.sampel[i].isProgress = false;
        }, 2000);
      }
    },

    getColor(status) {
      switch (status) {
        case 1:
          return 'grey--text'
        case 3:
          return 'green--text';
        case 4:
          return 'red--text';
        default:
          return '';
      }
    },

    calculateAndStoreResult() {
      this.detail.totalHarga = 0;
      this.detail.bill.forEach((item) => {
        const harga_total = (item.hargaPengujian + item.hargaReplikasi);
        item.hargaTotal = harga_total
        this.detail.totalHarga += item.hargaTotal;
      });
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.dialog.konfirmasi = true
      } else {
        this.dialog.konfirmasi = false
      }
    },

    updateParameter(sampelIndex, parameterIndex, selectedStatusId, parameter) {
      this.form.sampel[sampelIndex].parameter_uji[parameterIndex] = {
        id_status_sampel_parameter: selectedStatusId,
        uuid: parameter.uuid
      };
    },

    updateParameterAnalisis(sampelIndex, parameterIndex, parameter, data) {
      const existingParameter = { ...this.form.sampel[sampelIndex].parameter_uji[parameterIndex] };
      existingParameter.analisis = parameter;
      existingParameter.uuid = data.uuid
      this.form.sampel[sampelIndex].parameter_uji[parameterIndex] = existingParameter;
    },

    updateParameterPenyelia(sampelIndex, parameterIndex, parameter, data) {
      const existingParameter = { ...this.form.sampel[sampelIndex].parameter_uji[parameterIndex] };
      existingParameter.penyelia = parameter;
      existingParameter.uuid = data.uuid
      this.form.sampel[sampelIndex].parameter_uji[parameterIndex] = existingParameter;
    },
  
    startdate(index){
      let today = new Date()
      let time = today.getHours()+ ":" + today.getMinutes()+ ":" + today.getSeconds();
      if (index !== undefined) {
        if(this.form.sampel[index].start_date === this.reminder){
          this.form.sampel[index].start_time_min = time
        }else{        
          this.form.sampel[index].start_time_min = ''
        }
        this.form.sampel[index].time_start = ''
        this.form.sampel[index].end_date = ''
        this.form.sampel[index].time_end = ''
      }
      this.formatDateStart()
    },

    formatDateStart(index) {
      if (index !== undefined) {
        return this.form.sampel[index].start_date ? moment(this.form.sampel[index].start_date).format('DD MMMM YYYY') : ''
      }
    },

    async save() {
      switch(this.save_status.kd_status_pengajuan) {
        case 1:
          this.createVerification();
          break;
        case 6:
          this.createGenerateKodeSampel();
          break;
        case 8:
          this.createRepayment();
          break;
        default:
          break;
      }
    },

    async createVerification() {
      this.process.form = true
      const { form, token, roles, sort, detail } = this;
      const sampel = form.sampel.map(element => {
      const parameter_uji = element.parameter_uji.map(val => ({
        uuid: val.uuid,
        id_status_sampel_parameter: val.id_status_sampel_parameter
      }));

      const obj = {
        uuid: element.uuid,
        id_pengujian_status_sampel: element.status_sampel,
        tgl_penerimaan_sampel: element.start_date,
        parameter_uji
      };

      if (element.file !== undefined) {
        obj.file_gambar = element.file;
      }

        return obj;
      })

      const value = {
        role: roles,
        flag_persetujuan:  sort.selected_persetujuan,
        uuid: detail.uuid,
        catatan_tambahan: form.catatan,
        id_user_verifikasi_kalab: form.kalab,
        sampel
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(
        value,
        options,
      );

      try {
        const authHeader = new Headers();
        authHeader.append('Authorization', 'Bearer ' + token);
        const config = {
          method: 'POST',
          headers: authHeader,
          body: formData
        };
        this.process.loading = true
        const response = await fetch(`${VUE_APP_API_URL}test/verification`, config);
        if (response.status === 200) {
          this.process.loading = false
          this.process.form = false
          this.dialog.konfirmasi = false;
          this.text = 'Data penelitian berhasil disimpan';
          this.snackbar = true;
          this.toPage();
        }

      } catch (error) {
        console.log(error)
      } finally {
        this.dialog.konfirmasi = false;
      }
    },

    async createGenerateKodeSampel() {
      this.process.form = true
      const { form, token, roles, sort, detail } = this;
      const sampel = form.sampel.map(element => {
        const parameter_uji = element.parameter_uji.map(val => ({
          uuid: val.uuid,
          id_user_analisis: val.analisis,
          id_user_penyelia: val.penyelia
        }));

        const obj = {
          uuid: element.uuid,
          id_pengujian_status_sampel: element.status_sampel,
          tgl_penerimaan_sampel: element.start_date,
          parameter_uji,
        };

        if (element.file !== undefined) {
          obj.file_gambar = element.file;
        }

        return obj;
      })

      const value = {
        role: roles,
        flag_persetujuan:  sort.selected_persetujuan,
        uuid: detail.uuid,
        catatan_tambahan: form.catatan,
        id_user_verifikasi_kalab: form.kalab,
        sampel
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(
        value,
        options,
      );

      try {
        const authHeader = new Headers();
        authHeader.append('Authorization', 'Bearer ' + token);
        const config = {
          method: 'POST',
          headers: authHeader,
          body: formData
        };
        this.process.loading = true
        const response = await fetch(`${VUE_APP_API_URL}test/generate-code`, config);
        if (response.status === 200) {
          this.process.loading = false
          this.process.form = false
          this.dialog.konfirmasi = false;
          this.text = 'Data penelitian berhasil disimpan';
          this.snackbar = true;
          this.toPage();
        }

      } catch (error) {
        console.log(error)
      } finally {
        this.dialog.konfirmasi = false;
      }
    },

    async createRepayment() {
      this.process.form = true;
      this.process.run = true
      const tagihan = this.detail.bill.map(val => ({
        uuid: val.uuidSampelParameter,
        harga_pengujian: val.hargaPengujian,
        harga_replikasi: val.hargaReplikasi,
        harga_total: val.hargaTotal,
      }));
      const value = {
        uuid: this.detail.uuid,
        catatan_tambahan: this.form_pengujian.desc,
        nominal_akhir: this.detail.totalHarga,
        tagihan
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${this.token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      this.process.loading = true
      const response = await fetch(`${VUE_APP_API_URL}test/repayment`, config);
      if (response.status === 200) {
        this.process.loading = false
        this.text = "Data pengujian generate tagihan pelunasan berhasil";
        this.snackbar = true;
        this.dialog.konfirmasi = false;
        this.process.form = false;
        this.process.run = false
        this.fetch()
        this.toPage()
      }
    },

    reset() {
     
    },

    toPage() {
      this.$router.push(`/persetujuan-pengujian`)
    },

  },
}
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 5px;
  padding-top: 4px;
}
</style>