<template>
  <div>
    <div @click="launchFile()">
      <slot name="activator"></slot>
    </div>
    <input 
      accept=".image/jpeg,.image/jpg,application/pdf" 
      type="file"
      ref="file"
      :name="filename"
      v-on:change="changeFile($event.target.files)"
      style="display:none">
      <v-dialog 
        v-model="dialog"
        overlay-opacity="0.7"
        width="500">
        <v-card
          class="pa-0 ma-0"
          flat>
          <v-card
            color="red accent-2">
            <v-list-item>
              <v-list-item-avatar color="red accent-2" size="30">
                <v-icon color="white" large>mdi-alert</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-card-text class="caption white--text pa-0">
                  {{ response }}
                </v-card-text>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'file-input',
  data() {
    return {
      dialog: false,
      blob: null,
      filename: 'file',
      response: '',
      process: {
        run: false
      }

    }
  },
  
  methods: {
    launchFile () {
      this.$refs.file.click()
    },
    async changeFile (file) {
      let pdf = file[0];
      if (file.length > 0) {
        if (pdf.type != 'application/pdf' ) {
          this.dialog = true;
          this.response = 'Silahkan pilih file dengan format .pdf'
        } else if (pdf.size > 5242880) {
          this.dialog = true
          this.response = 'Maksimal file 5MB'
        } else {
          this.process.run = true
          this.$emit("input", {
            add : this.process.run,
          });
          setTimeout(() => {
            this.process.run = false
            this.$emit("input", {
              add : this.process.run,
              url: URL.createObjectURL(pdf),
              files: pdf
            });
          }, 3000);
        }
      }
    }
  }
}
</script>