<template>
  <div id="pdf-content" style="margin: 17px">
    <v-container>
      <!-- pdf pengembalian deposit -->
      <v-row class="body-2" v-if="parameter === 'pengembalian'">
        <v-col cols="12">
          <table width="100%">
            <tr>
              <td style="text-align: center;">
                <img :src="require('@/assets/image/invoice_header.png')" style="width: 100%; object-fit: contain;"
                  alt="header">
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="text-center">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif;">
            <tr>
              <td width="33%" style="text-align: center;"><span><b>Invoice {{ pdf[0]?.noInvoice }}</b></span></td>
            </tr>
            <tr>
              <td width="33%" style="text-align: center;"><span><b>Lab {{ pdf[0]?.namaLaboratorium }}</b></span></td>
            </tr>
          </table>
        </v-col>
        <v-col>
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
            <tr>
              <td width="30%">Total pengempalian deposit (Rp)</td>
              <td width="1%">:</td>
              <td>{{ pdf[0]?.nominalPengembalian | price }}</td>
            </tr>
            <tr>
              <td width="30%">Nomor rekening tujuan</td>
              <td width="1%">:</td>
              <td>{{ pdf[0]?.noRekeningTujuan || '-' }}</td>
            </tr>
            <tr>
              <td width="30%">Bank tujuan</td>
              <td width="1%">:</td>
              <td>{{ pdf[0]?.namaBankTujuan || '-' }}</td>
            </tr>
            <tr>
              <td width="30%">Nama pemilik rekening</td>
              <td width="1%">:</td>
              <td>{{ pdf[0]?.namaPemilikRekeningTujuan || '-' }}</td>
            </tr>
            <tr>
              <td width="30%">Tanggal pengembalian</td>
              <td width="1%">:</td>
              <td>{{ pdf[0]?.tglPengembalian | date }}</td>
            </tr>
            <tr>
              <td width="30%">Keterangan</td>
              <td width="1%">:</td>
              <td>{{ pdf[0]?.catatan || '-' }}</td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <!-- end pdf pengembalian deposit -->

      <!-- pdf pembayaran deposit -->
      <v-row class="body-2" v-if="parameter === 'pembayaran'">
        <v-col cols="12">
          <table width="100%">
            <tr>
              <td style="text-align: center;">
                <img :src="require('@/assets/image/invoice_header.png')" style="width: 100%; object-fit: contain;"
                  alt="header">
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="text-center">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif;">
            <tr>
              <td width="33%" style="text-align: center;"><span><b>Invoice {{ pdf?.noInvoice }}</b></span></td>
            </tr>
            <tr>
              <td width="33%" style="text-align: center;"><span><b>Lab {{ pdf?.namaLaboratorium }}</b></span></td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
            <tr>
              <td width="30%">ID</td>
              <td width="1%">:</td>
              <td>{{ pdf?.idRegistrasi }}</td>
            </tr>
            <tr>
              <td width="30%">NIM/NIK</td>
              <td width="1%">:</td>
              <td>{{ pdf?.noInduk }}</td>
            </tr>
            <tr>
              <td width="30%">Nama</td>
              <td width="1%">:</td>
              <td>{{ pdf?.nama }}</td>
            </tr>
            <tr>
              <td width="30%">Status</td>
              <td width="1%">:</td>
              <td>{{ pdf?.status }}</td>
            </tr>
            <tr>
              <td width="30%">No. invoice</td>
              <td width="1%">:</td>
              <td>{{ pdf?.noInvoice }}</td>
            </tr>
            <tr>
              <td width="30%">Besar invoice (Rp)</td>
              <td width="1%">:</td>
              <td>{{ pdf?.besarInvoice | price }}</td>
            </tr>
            <tr>
              <td width="30%">Sisa deposit (Rp)</td>
              <td width="1%">:</td>
              <td>{{ pdf?.potonganDeposit | price }}</td>
            </tr>
            <tr>
              <td width="30%">Invoice yang harus dibayarkan (Rp)</td>
              <td width="1%">:</td>
              <td><b>{{ pdf?.totalTagihan | price }}</b></td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="background-second">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.5; font-size: 14px;">
            <tr>
              <td>Rekening</td>
              <td>:</td>
              <td><b>Bank Mandiri</b></td>
            </tr>
            <tr>
              <td>No. rekening</td>
              <td>:</td>
              <td><b>137-00-0747021-0</b></td>
            </tr>
            <tr>
              <td>a.n</td>
              <td>:</td>
              <td><b>Universitas Islam Indonesia - Lab farmasi</b></td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="background-third">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.5; font-size: 14px;">
            <tr>
              <td><span><i>Jika sudah melakukan pembayaran, silahkan lakukan verifikasi pembayaran dengan mengunggah bukti
                    bayar. </i></span></td>
            </tr>
          </table>
        </v-col>

        <v-col cols="12" class="custom-border" style="padding-bottom: 0px; padding-top: 0px;">
          <table width="100%"
            style="font-family: Arial, Helvetica, sans-serif; font-size: 16px; margin-top: 15px; margin-bottom: 15px;">
            <tr>
              <td><span><b>Detail tagihan</b></span></td>
            </tr>
          </table>
        </v-col>

        <v-col cols="12" style="padding: 0px;">
          <table id="tagihan-table">
            <thead>
              <tr>
                <th style="text-align: left;">No</th>
                <th style="text-align: left;">Tanggal</th>
                <th style="text-align: left;">Jenis</th>
                <th style="text-align: left;">Kode</th>
                <th style="text-align: left;">Nama</th>
                <th style="text-align: right;">Pemakaian</th>
                <th style="text-align: right;">Satuan</th>
                <th style="text-align: right;">Harga satuan (Rp)</th>
                <th style="text-align: right;">Harga total (Rp)</th>
              </tr>
            </thead>
            <tbody style="font-size: 11px;">
              <tr v-for="(item, index) in pdf.invoiceDetail" :key="item.uuid">
                <td style="text-align: left;">{{ index + 1 }}</td>
                <td style="text-align: left;">{{ item.createdAt | date }}</td>
                <td style="text-align: left;">{{ item.jenisBarang?.namaJenisBarang }}</td>
                <td style="text-align: left;">{{ item.kdBarang }}</td>
                <td style="text-align: left;">{{ item.namaBarang }}</td>
                <td style="text-align: right;">{{ item.jlhPenggunaan }}</td>
                <td style="text-align: right;">{{ item.satuan }}</td>
                <td style="text-align: right;">{{ item.hargaSatuan | price }}</td>
                <td style="text-align: right;">{{ item.hargaTotal | price }}</td>
              </tr>
              <tr style="border: 1px solid #E0E5ED;">
                <td colspan="1" style="font-weight: bold; text-align: center;">Total</td>
                <td colspan="7"></td>
                <td style="font-weight: bold; text-align: right;">{{ pdf?.besarInvoice | price }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <!-- end pdf pembayaran deposit -->

      <!-- pdf pembayaran dp 50% pengujian -->
      <v-row class="body-2" v-if="parameter === 'pengujian'">
        <v-col cols="12">
          <table width="100%">
            <tr>
              <td style="text-align: center;">
                <img :src="require('@/assets/image/invoice_header.png')" style="width: 100%; object-fit: contain;"
                  alt="header">
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="text-center">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif;">
            <tr>
              <td width="33%" style="text-align: center;"><span><b>Invoice {{ pdf?.noInvoice }}</b></span></td>
            </tr>
            <tr>
              <td width="33%" style="text-align: center;"><span><b>Lab {{ pdf?.namaLaboratorium }}</b></span></td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
            <tr>
              <td width="30%">ID</td>
              <td width="1%">:</td>
              <td>{{ pdf?.idRegistrasi }}</td>
            </tr>
            <tr>
              <td width="30%">NIM/NIK</td>
              <td width="1%">:</td>
              <td>{{ pdf?.noInduk }}</td>
            </tr>
            <tr>
              <td width="30%">Nama</td>
              <td width="1%">:</td>
              <td>{{ pdf?.nama }}</td>
            </tr>
            <tr>
              <td width="30%">Status</td>
              <td width="1%">:</td>
              <td>{{ pdf?.status }}</td>
            </tr>
            <tr>
              <td width="30%">No. invoice</td>
              <td width="1%">:</td>
              <td>{{ pdf?.noInvoice }}</td>
            </tr>
            <tr>
              <td width="30%">Invoice yang harus dibayarkan (Rp)</td>
              <td width="1%">:</td>
              <td><b>{{ pdf?.totalTagihan | price }}</b></td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="background-second">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.5; font-size: 14px;">
            <tr>
              <td>Rekening</td>
              <td>:</td>
              <td><b>Bank Mandiri</b></td>
            </tr>
            <tr>
              <td>No. rekening</td>
              <td>:</td>
              <td><b>137-00-0747021-0</b></td>
            </tr>
            <tr>
              <td>a.n</td>
              <td>:</td>
              <td><b>Universitas Islam Indonesia - Lab farmasi</b></td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="background-third">
          <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.5; font-size: 14px;">
            <tr>
              <td><span><i>Jika sudah melakukan pembayaran, silahkan lakukan verifikasi pembayaran dengan mengunggah bukti
                    bayar. </i></span></td>
            </tr>
          </table>
        </v-col>

        <v-col cols="12" class="custom-border" style="padding-bottom: 0px; padding-top: 0px;">
          <table width="100%"
            style="font-family: Arial, Helvetica, sans-serif; font-size: 16px; margin-top: 15px; margin-bottom: 15px;">
            <tr>
              <td><span><b>Detail tagihan</b></span></td>
            </tr>
          </table>
        </v-col>

        <v-col cols="12" style="padding: 0px;">
          <table id="tagihan-table">
            <thead>
              <tr>
                <th style="text-align: left;">No</th>
                <th style="text-align: left;">Sampel</th>
                <th style="text-align: left;"></th>
                <th style="text-align: left;">Parameter uji</th>
                <th style="text-align: left;"></th>
                <th style="text-align: right;">Harga per pengujian (Rp)</th>
                <th style="text-align: right;"></th>
                <th style="text-align: right;">Harga replikasi (Rp)</th>
                <th style="text-align: right;">Harga total (Rp)</th>
              </tr>
            </thead>
            <tbody style="font-size: 11px;">
              <tr v-for="(item, index) in pdf.invoiceDetailTest" :key="item.uuid">
                <td style="text-align: left;">{{ index + 1 }}</td>
                <td style="text-align: left;">{{ item.namaSampel }}</td>
                <td style="text-align: left;"></td>
                <td style="text-align: left;">{{ item.namaParameterUji }} - {{ item.jumlahReplikasi }}</td>
                <td style="text-align: left;"></td>
                <td style="text-align: right;">{{ item.hargaPengujian | price }}</td>
                <td style="text-align: right;"></td>
                <td style="text-align: right;">{{ item.hargaReplikasi | price }}</td>
                <td style="text-align: right;">{{ item.hargaTotal | price }}</td>
              </tr>
              <tr style="border: 1px solid #E0E5ED;">
                <td colspan="1" style="font-weight: bold; text-align: center;">Total</td>
                <td colspan="7"></td>
                <td style="font-weight: bold; text-align: right;">{{ pdf?.totalHarga | price }}</td>
              </tr>
              <tr style="border: 1px solid #E0E5ED;">
                <td colspan="2" style="font-weight: bold; text-align: left;">DP50% (Rp)</td>
                <td colspan="6"></td>
                <td style="font-weight: bold; text-align: right;">{{ pdf?.totalTagihan | price }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <!-- end pdf pembayaran dp 50% pengujian -->


      <!-- sertifikat pengujian -->
      <!-- <v-row>
        <v-col cols="12">
          <v-img :src="require('@/assets/image/invoice_header.png')" contain></v-img>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-card-title class="text-center">SURAT PENGUJIAN</v-card-title>
                </v-col>
              </v-row>
              <v-row>
                
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <!-- end sertifikat pengujian -->

      <!-- pdf bebas laboratorium farmasi -->
      <!-- <v-row>
        <v-col cols="12">
          <v-img :src="require('@/assets/image/invoice_header.png')" contain></v-img>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-card-title class="text-center">SURAT KETERANGAN</v-card-title>
                  <v-card-subtitle class="text-center">BEBAS ADMINISTRASI LABORATORIUM FARMASI</v-card-subtitle>
                  <v-card-subtitle class="text-center">NO : 53 / Lab Klinis / VIII / 2022</v-card-subtitle>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      Nama Mahasiswa :
                    </v-col>
                    <v-col cols="12">
                      NIM :
                    </v-col>
                    <v-col cols="12">
                      Jurusan :
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      Mega Alfiana
                    </v-col>
                    <v-col cols="12">
                      15613041
                    </v-col>
                    <v-col cols="12">
                      Farmasi
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Tidak memiliki tanggungan administrasi di Lingkungan Laboratorium Jurusan Farmasi Fakultas MIPA Universitas Islam Indonesia
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Demikian surat ini dibuat agar dapat digunakan sebagaimana mestinya.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Yogyakarta, {{ tanggal_sekarang }} {{ bulan_sekarang }} {{ tahun_sekarang }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Koordinator Kalab
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-img :src="(image_tanda_tangan)" alt="Tanda Tangan"></v-img>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Dr. apt. Rochmy Istikharah, M.Sc.
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <!-- end pdf bebas laboratorium farmasi -->

      <!-- pdf lhu -->
      <div class="body-2"  v-if="parameter === 'lhu'">
      <!-- halaman 1 -->
        <v-row>
          <v-col cols="12">
            <table width="100%">
              <tr>
                <td style="text-align: center;">
                  <img :src="require('@/assets/image/header_lhu.png')" style="width: 100%; object-fit: contain;"
                    alt="header">
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" class="text-center mt-8">
            <table width="100%" style="font-family: Arial, Helvetica, sans-serif;">
              <tr>
                <td style="text-align: center; font-size: 22px;"><span><b>Sertifikat Pengujian</b></span></td>
              </tr>
              <tr>
                <td style="text-align: center; font-size: 22px;">
                  <hr style="border: none; height: 2px; background-color: black; margin: 10px auto; width: 200px;">
                </td>
              </tr>
              <tr>
                <td style="text-align: center; font-size: 22px;"><span>Test Certified</span></td>
              </tr>
            </table>

            <div style="font-family: Arial, Helvetica, sans-serif; margin-top: 50px; text-align: right;">
              <div style="font-size: 14px; text-align: right;"><u>Nomor</u>: {{pdf?.noSurat}}</div>
              <div style="font-size: 12px; text-align: right;"><i>Number</i></div>

              <div style="margin-top: 20px;"></div>

              <div style="font-size: 14px; text-align: right;"><u>Halaman</u>: 1 dari 2</div>
              <div style="font-size: 12px; text-align: right;"><i>Page</i></div>
            </div>

            <div style="font-family: Arial, Helvetica, sans-serif; margin-top: 50px;">
              <div style="font-size: 16px;"><u>Dibuat untuk</u>: Pt. Bla bla</div>
              <div style="font-size: 16px;"><i>Certified to</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Jenis/Nama Sampel</u>: {{pdf?.namaSampel}}</div>
              <div style="font-size: 16px;"><i>Type/Name of sample</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Asal Sample</u>: Pelanggan</div>
              <div style="font-size: 16px;"><i>Origin of sample</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Jumlah Sampel</u>: {{pdf?.sample.length}} buah</div>
              <div style="font-size: 16px;"><i>Amount of sample</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Kode Sample</u>: Terlampir</div>
              <div style="font-size: 16px;"><i>Sample code</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Parameter</u>: {{ pdf?.namaParameter }}</div>
              <div style="font-size: 16px;"><i>Parameters</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Tanggal Pengembalian Sampel</u>: -</div>
              <div style="font-size: 16px;"><i>Sample taken on</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Tanggal Penerimaan Sampel</u>: {{ pdf?.tglPenerimaanSampel | date }}</div>
              <div style="font-size: 16px;"><i>Sample received on</i></div>

              <div style="margin-bottom: 20px;"></div>

              <div style="font-size: 16px;"><u>Tanggal Pengujian Sampel</u>: {{ pdf?.tglMulaiPengujian | date }} -  {{ pdf?.tglAkhirPengujian | date }}</div>
              <div style="font-size: 16px;"><i>Sample tasted on</i></div>

            </div>
          </v-col>
        </v-row>
        <!-- end halaman 1 -->

        <!-- halaman 2 -->
        <v-row>
          <v-col cols="12">
            <table width="100%" class="mt-20">
              <tr>
                <td style="text-align: center;">
                  <img :src="require('@/assets/image/header_lhu.png')" style="width: 100%; object-fit: contain;"
                    alt="header">
                </td>
              </tr>
            </table>
          </v-col>

          <v-col cols="12" class="text-center mt-8">
            <table width="100%" style="font-family: Arial, Helvetica, sans-serif;">
              <tr>
                <td style="text-align: center; font-size: 22px;"><span><b>Sertifikat Pengujian</b></span></td>
              </tr>
              <tr>
                <td style="text-align: center; font-size: 22px;">
                  <hr style="border: none; height: 2px; background-color: black; margin: 10px auto; width: 200px;">
                </td>
              </tr>
              <tr>
                <td style="text-align: center; font-size: 22px;"><span>Test Certified</span></td>
              </tr>
            </table>

            <div style="font-family: Arial, Helvetica, sans-serif; margin-top: 50px; text-align: right;">
              <div style="font-size: 14px; text-align: right;"><u>Nomor</u>: {{pdf?.noSurat}}</div>
              <div style="font-size: 12px; text-align: right;"><i>Number</i></div>

              <div style="margin-top: 20px;"></div>

              <div style="font-size: 14px; text-align: right;"><u>Halaman</u>: 2 dari 2</div>
              <div style="font-size: 12px; text-align: right;"><i>Page</i></div>
            </div>
          </v-col>

          <v-col cols="12" class="text-center mt-8">
            <table width="100%" style="font-family: Arial, Helvetica, sans-serif;">
              <tr>
                <td style="text-align: center; font-size: 20px;"><span><b>HASIL PENGUJIAN</b></span></td>
              </tr>
              <tr>
                <td style="text-align: center; font-size: 20px;">
                  <hr style="border: none; height: 2px; background-color: black; margin: 10px auto; width: 200px;">
                </td>
              </tr>
              <tr>
                <td style="text-align: center; font-size: 20px;"><span>Test Result</span></td>
              </tr>
            </table>
          </v-col>

          <v-col cols="12" style="padding: 0px;">
            <table id="tagihan-table">
              <thead>
                <tr>
                  <th style="text-align: left;">No</th>
                  <th style="text-align: left;">Nama Sampel</th>
                  <th style="text-align: left;">Kode</th>
                  <th style="text-align: left;">Label</th>
                  <th style="text-align: left;">Parameter</th>
                  <th style="text-align: left;">Hasil Uji</th>
                  <th style="text-align: left;">Metode Uji</th>
                </tr>
              </thead>
              <tbody style="font-size: 11px;">
                <tr v-for="(item, index) in pdf?.sample" :key="item.uuid">
                  <td style="text-align: left;">{{ index + 1 }}</td>
                  <td style="text-align: left;">{{ item.nama }}</td>
                  <td style="text-align: left;">{{ item.kodeSampel }}</td>
                  <td style="text-align: left;">{{ index + 1 }}</td>
                  <td style="text-align: left;">{{ item.parameter[index].parameterTest.namaParameterUji }}</td>
                  <td style="text-align: left;">{{ item.parameter[index].hasilPengujian }}</td>
                  <td style="text-align: left;">{{ item.parameter[index].metodeUji }}</td>
                </tr>
              </tbody>
            </table>
            <div>
              Keterangan *: {{ pdf?.keterangan || '' }}
            </div>
          </v-col>
        </v-row>
        <!-- end halaman 2 -->

        <!-- halaman 3 -->
        <v-row>
          <v-col cols="12" class="text-right">
            <div style="font-family: Arial, Helvetica, sans-serif; font-size: 16px;"  class="mt-10">
              <div>
                Yogyakarta, {{ pdf?.user.createdAt | date }}
              </div>
              <div>
                Manajer Teknis
              </div>
              <div class="my-10"></div>
              <div style="margin-top: 30px;">
                <div>
                <u>{{ pdf?.user.nama }}.</u>
                </div>
                <div>
                  NIP.{{ pdf?.user.noInduk }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- end halaman 3 -->
      </div>
      <!-- end pdf lhu -->
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: { download: false },
      row_detail: [],
      totalAmount: 0,
    }
  },
  props: {
    dialogDownload: {
      type: Boolean,
      default: false
    },
    parameter: {
      type: String,
      required: true
    }
  },
  mounted() { },
  computed: {
    pdf() {
      return this.$store.state.data_pdf
    },
  },
  methods: {
    downloadPdf(data, type) {
      setTimeout(() => {
        type.generatePdf();
        this.dialog.download = false
        this.$emit("input", {
          add: this.dialog.download,
        });
      }, 3000);
    },
  },
};
</script>

<style scoped>
#tagihan-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #E0E5ED;
}

#tagihan-table td,
#tagihan-table th {
  padding: 8px;
}

#tagihan-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  font-size: 12px;
  background-color: #E0E5ED;
}

.custom-border {
  border: 1px solid #E0E5ED;
  color: black;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-top: 15px;
}</style>
