<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
       <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Apakah anda yakin ingin {{ text_confirmed }} ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
          <!-- :color="text_confirmed ==='aktifkan' ? '#0299B7' : 'red'" -->
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="getActivation">
            Ya, {{text_confirmed}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->
    
    <!-- dialog download -->
    <v-dialog
      v-model="dialog.download"
      scrollable
      persistent
      width="400">
      <v-card flat>
        <v-card-text class="text-center">
        <p class="mb-0 mt-3">Proses download pdf....</p>
        <div class="mt-2">
            <v-progress-linear
            color="#0299B7"
            indeterminate
            rounded
            height="6">
            </v-progress-linear>
        </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog download -->

    <v-row class="mt-3 px-3">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                  LHU
                </div>
                <!-- row filter -->
                <v-row class="pt-5">
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.lab"
                      item-text="nama_laboratorium"
                      item-value="id"
                      v-model="form.filter.lab"
                      v-on:change="onSelectLab($event)"
                      label="Lab"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.status"
                      item-text="status_pengajuan"
                      item-value="id"
                      v-model="form.filter.status"
                      v-on:change="onSelectStatus($event)"
                      label="Status"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- end row filter -->

                <!-- row  button-->
                <v-row class="mt-8 justify-space-between">
                  <v-col cols="12" md="3">
                    <div class="title">
                     LHU
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :disabled="process.run"
                      outlined
                      dense
                      :hide-details="true"
                      placeholder="Cari . . ."
                      clearable
                      color="#0299B7"
                      class="mx-3"
                      v-model="filter.title"
                      @click:clear="filter.title = ''; getList();"
                      v-on:keyup.enter="getList();"
                      prepend-inner-icon="mdi-magnify">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- end row button -->

                <v-card-text class="pa-5 px-0" style="height: calc(100vh - 145px);">
                  <v-skeleton-loader
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                    type="table-tbody">
                    <v-data-table
                      :headers="headers"
                      :items="rows"
                      :items-per-page="10"
                      class="elevation-1 required">
                      <template v-slot:[`item.status`]="{ item }">
                        <td>
                          <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                        </td>
                      </template>
                      <template v-slot:[`item.aksi`]="{ item }">
                        <v-tooltip top v-if="item.kd_status_pengajuan === 14">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="#0299B7"
                              :disabled="process.run"
                              @click="handleBeforeDownload(item)"
                              class="text-capitalize color-first pa-0 mx-1"
                              v-bind="attrs"
                              v-on="on">
                              <div class="d-flex align-center text-capitalize body-2">
                                <img :src="require('@/assets/image/download_pdf.png')" alt="">
                              </div>
                            </v-btn>
                          </template>
                          <span>Invoice</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.kd_status_pengajuan !== 14">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="green"
                              :disabled="process.run"
                              :loading="process.run"
                              @click="toPage(item, 'edit')"
                              class="text-capitalize color-first pa-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Pengujian</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="#0299B7"
                              :disabled="process.run"
                              @click="toPage(item, 'detail')"
                              class="text-capitalize color-first pa-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Detail</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-skeleton-loader>
                </v-card-text>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
     <!-- pdf -->
     <div>
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="3500" filename="Lhu" :pdf-quality="2" :manual-pagination="false"
        pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf">
        <section slot="pdf-content">
          <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files" />
        </section>
      </vue-html2pdf>
    </div>
    <!-- end pdf -->
  </div>
</template>

<script>
import { get, put } from '@/service/Axios'
import { TokenService } from "@/service/Storage.Service";
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
import Pdf from "@/components/Pdf.vue"
export default ({
  mixins: [statusMixin],
  data() {
    return {
      detailUuid: '',
      detail: {},
      dialog: {
        konfirmasi: false,
        download: false
      },
      files: null,
      form: {
        dialog: {
          catatan: ''
        },
        filter: {
          lab: '',
          status: '',
        },
      },
      filter: {
        title: ''
      },
      id: '',
      rows: [],
      headers: [
        { text: 'No.', value: 'no', sortable: false },
        { text: 'Nama', value: 'nama', sortable: false, },
        { text: 'Judul atau nama sampel', value: 'nama_sampel', sortable: false, },
        { text: 'Tanggal penggunaan', value: 'tanggal_penggunaan', sortable: false, },
        { text: 'Status', value: 'status', sortable: false, width: '13%' },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya', sortable: false, width: '11%' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      process: {
        run: false,
        form: false
      },
      list: {
        lab: [],
        status: []
      },
      text_confirmed: '',
      save_type: '',
      type: '',
      switch_status: null,
      hasDetail: false,
      snackbar: false,
      text: '',
      timeout: 3000,
      nilai_parameter: 'lhu',
    }
  },
  components: {
    Snackbar,
    Pdf,
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },
  watch: {
    files: {
      handler: function () {
        this.dialog.download = this.files.add
      },
      deep: true
    },
  },
  mounted () {
   this.fetch()
  },
  methods: {
    async fetch() {
      this.process.run = true
      this.cekUserSelf();
    },

    async cekUserSelf() {
      try {
        const response = await get('user/self');
        const { data } = response;
        if (response.status === 200) {
          if (data === null) {
            this.getInstansi();
          } else {
            const roleName = data.data.role.namaRole;
            this.roles_type = roleName;
            this.getLab(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getLab(data) {
      try {
        const lab = data.data.admin;
        const labData = lab.map(element => ({
          id: element.lab.id,
          kd_laboratorium: element.lab.kdLaboratorium,
          nama_laboratorium: element.lab.namaLaboratorium,
        }));
        labData.unshift({
          id: 'all',
          kd_laboratorium: 'all',
          nama_laboratorium: 'Semua',
        })
        this.list.lab = labData
        this.form.filter.lab = labData[0].id
      } catch (error) {
        console.error(error);
      } finally {
        this.getStatus();
      }
    },

    async getStatus() {
     try {
      const params = {
        flag_lhu: '1'
      }
      const response = await get(`test-submission-status`, {params})
      if (response.status === 200) {
        const res = response.data.data;
        const status = res.map((el) => ({
          id: el.id,
          kd_status_pengajuan: el.kdStatusPengajuan,
          status_pengajuan: el.statusPengajuan,
          proses_selanjutnya: el.prosesSelanjutnya
        }))
        status.unshift({
          id: 'all',
          kd_status_pengajuan: 'all',
          status_pengajuan: 'Semua',
        })
        this.list.status = status
        this.form.filter.status = this.hasDetail ?  this.form.filter.status :  this.form.filter.status === undefined ? status[0].id : status[0].id;

      }
     } catch (error) {
        console.log(error) 
     } finally {
        this.getList()
     }
    },

    async getList() {
      try {
        const { filter, form } = this
        const params = {
          id_laboratorium: form.filter.lab,
          id_status_pengajuan: form.filter.status,
          flag_officer: '1',
          search: filter.title,
        }
        const response = await get(`test-report`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          this.rows = res.map((item, index) => {
            return {
              no: index + 1,
              nama: item?.user.nama || '-',
              nama_sampel: item?.namaSampel || '-',
              tanggal_penggunaan: item?.email || '-',
              status: item?.statusSubmission?.statusPengajuan || '-',
              kd_status_pengajuan: item?.statusSubmission?.kdStatusPengajuan || '-',
              proses_selanjutnya: item?.statusSubmission?.prosesSelanjutnya || '-',
              uuid: item?.uuid || '-'
            }
          })
          this.process.run = false
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getActivation() {
      this.process.run = true
      try {
        const flag_aktif = this.switch_status === 'true' ? '1' : '0';
        const params = {
          flag_aktif: flag_aktif,
          uuid: this.detailUuid
        }
        const response = await put(`officer/activate`, {params})
        if (response.status === 201) {
          this.process.run = false
          this.dialog.konfirmasi = false
          this.dialog.detail = false
          this.text = response.data.info;
          this.snackbar = true;
          this.getList()
        } else {
          this.process.run = false
        }
      } catch (error) {
        console.log(error);
        this.process.run = false
      }
    },

    handleBeforeDownload(data) {
      this.dialog.download = true
      this.getInvoicePrint(data, this.$refs.html2Pdf);
    },

    async getInvoicePrint(data, ref) {
      try {
        const params = {
          uuid: data.uuid,
        };
        const response = await get(`test-report/print`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.$store.dispatch('getDataPdf', res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.pdf.downloadPdf(data, ref);
      }
    },

    onSelectOtoritas(val) {
      this.form.filter.otoritas = val
      this.process.run = true
      this.getList()
    },

    onSelectLab(val) {
      this.form.filter.lab = val
      this.process.run = true
      this.getList()
    },

    onSelectStatus(val) {
      this.form.filter.status = val
      this.process.run = true
      this.getList()
    },

    deleteForm(item) {
      this.type = 'Hapus'
      this.dialog.konfirmasi = true
      this.id = item.uuid
      this.save_type = 'deletes'
    },

    toPage(val, page) {
      try {
      const { form } = this
      const data = {
        otoritas: form.filter.otoritas,
        lab: form.filter.lab,
        status: form.filter.status,
        kd_status_pengajuan: val.kd_status_pengajuan,
        uuid: val.uuid
      }
      localStorage.setItem('filter', JSON.stringify(data))
        TokenService.setUuid(val.uuid);
        const path = page === 'edit' ? 'edit' : 'detail';
        this.$router.push(`/lhu-kalab-lpmok/${path}`);
      } catch (error) {
        console.error(error);
      }
    },
  },
})
</script>

<style scoped>
.switch .v-label {
  font-size: 14px;
}
</style>