import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import pdf from './plugins/Vhtml2pdf'
import Convert from "./plugins/Convert"
import VueQuillEditor from './plugins/VueQuillEditor' 
import VeeValidate from "./plugins/VeeValidate"
import VCurrencyField from "./plugins/VCurrencyField"

import '@/assets/css/main.css'

import { TokenService } from "@/service/Storage.Service.js"


if (TokenService.getToken()) {
  store.commit("setToken", TokenService.getToken())
  store.commit("setUser", JSON.parse(TokenService.getUser()))
  store.commit("setRoles", TokenService.getRoles())
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  Convert,
  vuetify,
  pdf,
  VeeValidate,
  VCurrencyField,
  VueQuillEditor,
  render: h => h(App)
}).$mount('#app')
