<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
     <!-- snackbar -->
     <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
       <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="reset(); dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang di inputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="reset(); dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->
    
    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3 px-3" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <ValidationObserver ref="observer">
                  <v-form>
                    <div>
                      <v-row>
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold mt-2">
                            Data diri
                          </div>
                        </v-col>
                      </v-row>    
                      <v-row class="mt-5">
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Email"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              filled
                              disabled
                              outlined
                              label="Email aktif"
                              color="#0299B7"
                              v-model="form.data_diri.email"
                              :error-messages="errors"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Nama lengkap"
                            rules="required"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="Nama lengkap"
                              color="#0299B7"
                              v-model="form.data_diri.nama"
                              :error-messages="errors"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="No. whatsapp aktif"
                            rules="required|min:10|max:14"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="No. whatsapp aktif"
                              color="#0299B7"
                              v-model="form.data_diri.whatsapp"
                              :error-messages="errors"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider 
                            name="Nomor induk mahasiswa"
                            rules="required|min:4|max:16"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              type="number"
                              label="Nomor induk kepegawaian"
                              color="#0299B7"
                              v-model="form.data_diri.nik"
                              :error-messages="errors"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row v-if="detail[0]?.statusVerification.kdStatusVerifikasiUser === 3">
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold mt-2">
                           Persetujuan
                          </div>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                          <v-radio-group v-model="sort.selected_persetujuan">
                            <v-radio
                              v-for="(status, index) in sort.status_persetujuan"
                              :key="index" 
                              :label="status.text"
                              :value="status.id"
                              color="#0499b7"
                              class="py-0 mt-0">
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" class="pt-0" v-if="sort.selected_persetujuan == '2'">
                          <v-text-field
                            dense
                            outlined
                            label="Catatan (Optional)"
                            color="#0299B7"
                            v-model="form.data_diri.catatan_verifikasi">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-3">
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold mt-2">
                            Otoritas laboratorium
                          </div>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <v-radio-group v-model="sort.selected_radio" class="mt-0">
                            <v-radio
                              v-for="(status, index) in list.lab_parent"
                              :key="index" 
                              :label="status.nama_role"
                              :value="status.id"
                              color="#0499b7"
                              class="py-0">
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row class="container-checkbox">
                        <v-col cols="12" md="3">
                          <ul>
                            <li v-for="(item, index) in list.lab_child" :key="item.id">
                              <div v-if="index < halfLength">
                                <v-checkbox
                                  color="#0499b7"
                                  hide-details
                                  v-model="sort.selected_checkbox[index]"
                                  :label="item.nama_laboratorium"
                                  :value="item.id">
                                </v-checkbox>
                              </div>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="12" md="3">
                          <ul>
                            <li v-for="(item, index) in list.lab_child" :key="item.id">
                              <div v-if="index >= halfLength">
                                <v-checkbox
                                  color="#0499b7"
                                  hide-details
                                  v-model="sort.selected_checkbox[index]"
                                  :label="item.nama_laboratorium"
                                  :value="item.id"
                                  @change="saveCheckbox(item.id, item.checked)">
                                </v-checkbox>
                              </div>
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                      <v-row class="mt-5">
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold mt-2">
                           Status akun
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            class="mt-0 switch"
                            v-model="switch_status"
                            color="#0499b7"
                            value = "true"
                            :label="switch_status === 'true' ? 'Aktif' : 'Tidak aktif'"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </div>
                
                    <v-card-actions class="mt-10 justify-center" style="border-top: 1px solid #BAC0C6;">
                      <div class="mt-5">
                        <v-btn
                          large
                          width="120"
                          elevation="0"
                          :disabled="process.form"
                          class="text-capitalize mr-2"
                          @click="reset(); toPage()">
                          Batal
                        </v-btn>
                        <v-btn
                          large
                          width="120"
                          color="#0299B7"
                          elevation="0"
                          :disabled="process.form"
                          @click="cekForm()"
                          class="white--text text-capitalize">
                          Simpan
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TokenService } from '@/service/Storage.Service';
import { get, put } from '@/service/Axios';
import LoadingComponent from '@/components/Loading.vue'
import Snackbar from '@/components/Snackbar.vue';
export default {
  data() {
    return {
      detail: {},
      dialog: {
        konfirmasi: false
      },
      form: {
        data_diri: {
          email: '',
          nama: '',
          whatsapp: '',
          nik: '',
          uuid: '',
          catatan_verifikasi: ''
        }
      },
      list: {
        lab_parent: [],
        lab_child: [],
      },
      halfLength: null,
      process: {
        run: false,
        form: false,
        loading: false
      },
      sort: {
        selected_persetujuan: '1',
        selected_radio: '1',
        selected_checkbox: [],
        status_persetujuan: [
          {
            id: '1',
            text: 'Disetujui'
          },
          {
            id: '2',
            text: 'Ditolak'
          },
        ],
      },
      switch_status: null,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  components: {
    LoadingComponent,
    Snackbar
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },
  mounted () {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.process.run = true
        this.process.loading = true
        const uuid = TokenService.getUuid()
        const params = {
          uuid: uuid
        }
        const response = await get(`officer`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          const {
            email,
            nama,
            noHp,
            noInduk,
            uuid
          } = res[0]
          this.form.data_diri = {
            email: email,
            nama: nama,
            whatsapp: noHp,
            nik: noInduk,
            uuid: uuid
          };
          this.process.run = false
          this.detail = res
        } else {
          this.process.run = false
        }
      } catch (error) {
        console.log(error)
        this.process.run = false
      } finally {
        this.switch_status = this.detail[0].flagAktif === 1 ? 'true' : null
        this.getRole()
      }
    }, 

    async getRole() {
      try {
        this.process.run = true;
        const response = await get(`role`)
          if (response.status === 200) {
            const res = response.data.data
            const lab_parent = res.map(el => ({
              id: el.id,
              nama_role: el.namaRole,
              uuid: el.uuid
            }))
            this.list.lab_parent = lab_parent
          }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
        this.getLaboratory()
      }
    },
    
    async getLaboratory() {
      try {
        this.process.run = true
        const response =  await get('laboratory')
        if (response.status === 200) {
          const res = response.data.data
          const lab_child = res.map(lab => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          this.list.lab_child = lab_child
          this.halfLength = Math.ceil(this.list.lab_child.length / 2);
          this.initializeSelectedCheckboxes()
          this.process.loading = false
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
        this.checkRoles()
      }
    },

    checkRoles() {
      const detail = this.detail[0];
      const data = this.list.lab_parent.find(x => x.id === detail.idRole);
      this.sort.selected_radio = data.id
      this.checkLab()
    },

    saveCheckbox() {},

    checkLab() {
      const detail = this.detail[0].admin;
      this.list.lab_child.forEach((lab, index) => {
        const matchingDetail = detail.find(d => d.idLaboratorium === lab.id);
        if (matchingDetail) {
          this.sort.selected_checkbox[index] = matchingDetail.idLaboratorium;
        }
      });
    },


    initializeSelectedCheckboxes() {
      this.sort.selected_checkbox = new Array(this.list.lab_child.length).fill(null);
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      this.dialog.konfirmasi = isValid
    },

    async save() {
      this.process.form = true;
      const { form, sort, switch_status } = this;
      const otoritas_laboratorium = sort.selected_checkbox.map(obj => obj)
      const params = {
        otoritas_laboratorium,
        uuid: form.data_diri.uuid,
        nama: form.data_diri.nama,
        no_whatsapp: form.data_diri.whatsapp,
        no_induk_kepegawaian: form.data_diri.nik,
        id_role: sort.selected_radio,
        flag_persetujuan: sort.selected_persetujuan,
        catatan_verifikasi: form.catatan_verifikasi,
        flag_aktif: switch_status !== null ? 1 : 0
      }
      const response = await put(`officer`, { params });
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.text = 'Data penelitian berhasil disimpan';
        this.snackbar = true;
        this.process.form = false;
        this.toPage();
        this.reset();
      } else {
        this.dialog.konfirmasi = false;
      }
    },

    reset() {
      this.$refs.observer.reset()
    },


    toPage() {
      this.$router.push(`/petugas`)
    },
  },
}
</script>

<style scoped>
  ul {
    list-style: none;
  }
  .container-checkbox {
    background: #F3F6F9;
    border-radius: 8px;
  }
  .v-messages {
    display: none;
  }
</style>