<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">   
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                  Pembayaran
                </div>
                <!-- row filter -->
                <v-row class="pt-5">
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.lab"
                      item-text="nama_laboratorium"
                      item-value="id"
                      v-model="form.lab"
                      v-on:change="onSelectLab($event)"
                      label="Laboratorium"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.usage_type"
                      item-text="jenis_penggunaan"
                      item-value="id"
                      v-model="form.jenis"
                      v-on:change="onSelectJenis($event)"
                      label="Jenis pendaftaran"
                      color="#0299B7"
                      hide-details>>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- end row filter -->

                <!-- row  button-->
                <v-row class="mt-8 justify-space-between">
                  <v-col cols="12" md="3">
                   <div class="title">
                    Riwayat pendaftaran
                   </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :disabled="process.run"
                      outlined
                      dense
                      :hide-details="true"
                      placeholder="Cari . . ."
                      clearable
                      color="#0299B7"
                      class="mx-3"
                      v-model="form.cari"
                      @click:clear="form.cari = ''; getResearchOrPractice();"
                      v-on:keyup.enter="getResearchOrPractice();"
                      prepend-inner-icon="mdi-magnify">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- end row button -->

                <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                  <v-skeleton-loader
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                    type="table-tbody">
                    <v-data-table
                      :headers="headers"
                      :items="rows"
                      :items-per-page="10"
                      class="elevation-1 required">
                        <template v-slot:[`item.status`]="{ item }">
                          <td>
                            <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                          </td>
                        </template>
                        <template v-slot:[`item.aksi`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                fab
                                icon
                                min-width="25"
                                color="#0299B7"
                                :disabled="process.detail"
                                :loading="process.detail"
                                @click="toDetail(item)"
                                v-bind="attrs"
                                v-on="on"
                                class="text-capitalize color-first pa-0 mx-1">
                                <div class="d-flex align-center text-capitalize body-2">
                                  <img :src="require('@/assets/image/vector.png')" class="mr-2" alt="">
                                </div>
                              </v-btn>
                            </template>
                            <span>Bayar</span>
                          </v-tooltip> 
                      </template>
                    </v-data-table>
                  </v-skeleton-loader>
                </v-card-text>  
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TokenService } from "@/service/Storage.Service";
import { get } from "@/service/Axios";
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
export default {
  mixins: [statusMixin],
  data() {
    return {
      form: {
        lab: '',
        jenis: ''
      },
      list: {
        lab: [],
        usage_type: []
      },
      process: {
        run: false,
        loading: false
      },
      headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Jenis penggunaan', value: 'jenis_penelitian', sortable: false, width: '12%'},
        { text: 'Judul', value: 'judul', sortable: false, width: '22%'},
        { text: 'Tanggal penggunaan', value: 'tanggal_penggunaan', sortable: false, width: '15%'},
        { text: 'Status', value: 'status', sortable: false, },
        { text: 'Proses selanjutnya', value: 'proses_selanjutnya',sortable: false, width: '17%'},
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      hasDetail: false,
      rows: [],
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },
  mounted () {
    this.fetch();
    TokenService.removeUUID(TokenService.getUuid())
  },
  components: {
    Snackbar,
    // LoadingComponent
  },
  methods: {
    async fetch() {
      this.process.run = true
      const data = JSON.parse(localStorage.getItem('filter'))
      if (data) {
        const { form } = this
        this.hasDetail = true
        form.lab = data.lab
        form.jenis = data.jenis
        localStorage.removeItem("filter");
      }
      this.getLaboratory();
    },

    async getLaboratory() {
      try {
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          labList.unshift({
            id: 'all',
            kd_laboratorium: 'all',
            nama_laboratorium: 'Semua',
          })
          this.list.lab = labList
          this.form.lab = this.hasDetail ? this.form.lab : this.form.lab === undefined ? labList[0].id : labList[0].id;
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.getUsageType();
      }
    },

    async getUsageType() {
      try {
        const { data, status } = await  get(`usage-type`)
        if (status === 200 && data.data) {
          const usageType = data.data.map((res) => ({
            id: res.id,
            kd_jenis_penggunaan: res.kdJenisPenggunaan,
            jenis_penggunaan: res.jenisPenggunaan,
          }))
          this.list.usage_type = usageType
          this.form.jenis = this.hasDetail ? this.form.jenis : this.form.jenis === undefined ? usageType[0].id : usageType[0].id;
          this.process.run = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getResearchOrPractice();
      }
    },

    getResearchOrPractice() {
      if (this.form.jenis === 1 || this.form.jenis === 2) {
        this.fetchData(this.form.jenis === 1 ? 'research-payment' : 'test-payment');
      } else {
        console.log('belum ada');
      }
    },

    async fetchData(endpoint) {
      try {
        this.process.run = true
        const params = {
          id_jenis_penelitian: this.form.jenis,
          id_laboratorium: this.form.lab,
          flag_officer: 0,
          search: this.form.cari
        };
        const response = await get(endpoint, { params });
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false;
          this.rows = res.map((item, index) => {
            let tanggal_penggunaan = '-';
            let judul = '-';
            if (this.form.jenis === 1) {
              tanggal_penggunaan = item.namaLaboratorium + ' ' + this.$callbackdate(item.tglMulai) + ' - ' + this.$callbackdate(item.tglSelesai);
              judul = item.judul;
            } else if (this.form.jenis === 2) {
              tanggal_penggunaan = '-';
              judul = item.namaSampel
            }
            return {
              no: index + 1,
              jenis_penelitian: item.jenisPenggunaan || '-',
              judul: judul,
              tanggal_penggunaan: tanggal_penggunaan,
              status: item.statusPembayaran,
              proses_selanjutnya: item.prosesSelanjutnya,
              uuid: item.uuid
            };
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
        this.process.loading = false
      }
    },

    async getReserch() {
      await this.fetchData('research-payment');
    },

    async getPractice() {
      await this.fetchData('test-payment');
    },

    onSelectLab(val) {
      this.form.lab = val
      this.getReserch()
    },

    onSelectJenis(val) {
      this.form.jenis = val
      if (val === 1) {
        this.getReserch()
      } else if (val === 2) {
        this.getPractice()
      } else {
        console.log('belum ada');
      }
    },
    

    toDetail(val) {
      const { form } = this
      const data = {
        lab: form.lab,
        jenis: form.jenis,
      }
      localStorage.setItem('filter', JSON.stringify(data))
      TokenService.setUuid(val.uuid);
      this.$router.push("/pembayaran/detail").catch(()=>{});
    }
  },
}
</script>