<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-5' : 'px-5'">

    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
          <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Ajukan dokumen tambahan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan nama dokumen yang diajukan sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            Ya, ajukan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div>
                  <v-row>
                    <v-col cols="12">
                      <div class="color-first title font-weight-bold title mt-3 pb-2">
                       LHU
                      </div>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header color="#0299B7">
                            <template v-slot:actions>
                              <v-icon color="white">
                                $expand
                              </v-icon>
                            </template>
                            <span class="white--text">Ini ntr nama berkasnya</span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <!-- <iframe 
                              width="100%" 
                              :height="this.file === null ? '110px' : '500px'" 
                              :src="this.file" 
                              :lazy-src="this.file"
                              frameborder="0"
                              allowfullscreen>
                              Oops! an error has occurred.
                            </iframe> -->
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                  <!-- ini nanti di if ketika dokumen tambahan tidak ada maka ini di munculkan -->
                  <v-row class="mt-7">
                    <v-col cols="12" class="py-0">
                      <div class="color-first title font-weight-bold title mt-3 pb-2">
                       Lainnya
                      </div>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-switch
                        class="mt-0 switch"
                        v-model="switch_status"
                        color="#0499b7"
                        value = "true"
                        label="Ajukan dokumen tambahan'"
                        hide-details
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" md="12" v-if="switch_status">
                      <ValidationProvider
                        name="Nama dokumen"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          dense
                          outlined
                          label="Nama dokumen"
                          color="#0299B7"
                          v-model="form.dokumen"
                          :error-messages="errors">
                        </v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- endnya -->

                  <!-- ini elsenya -->
                  <v-row class="mt-7">
                    <v-col cols="12" class="py-0">
                      <div class="color-first title font-weight-bold title mt-3 pb-2">
                       Dokumen tambahan
                      </div>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <table width="100%" class="body-2 line-text-second">
                        <tr>
                          <td width="30%">Tanggal permintaan</td>
                          <td width="1%">:</td>
                          <!-- <td>{{ detail.user?.nama }}</td> -->
                        </tr>
                        <tr>
                          <td width="30%">Nama dokumen</td>
                          <td width="1%">:</td>
                          <!-- <td>{{ detail.user?.noInduk}}</td> -->
                        </tr>
                        <tr>
                          <v-expansion-panels>
                            <v-expansion-panel>
                              <v-expansion-panel-header color="#0299B7">
                                <template v-slot:actions>
                                  <v-icon color="white">
                                    $expand
                                  </v-icon>
                                </template>
                                <span class="white--text">Ini ntr nama berkasnya</span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <!-- <iframe 
                                  width="100%" 
                                  :height="this.file === null ? '110px' : '500px'" 
                                  :src="this.file" 
                                  :lazy-src="this.file"
                                  frameborder="0"
                                  allowfullscreen>
                                  Oops! an error has occurred.
                                </iframe> -->
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <!-- endnya -->
                </div>
              </v-card-text>
              <v-divider class="my-5" style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
              <v-card-actions class="justify-center">
                <v-btn
                  large
                  width="120"
                  elevation="0"
                  :disabled="process.form"
                  class="text-capitalize"
                  @click="reset(); toPage()">
                  Kembali
                </v-btn>
                <v-btn
                  large
                  width="120"
                  color="#0299B7"
                  elevation="0"
                  :disabled="process.form"
                  @click="cekForm()"
                  class="white--text text-capitalize">
                  Ajukan
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoadingComponent from '@/components/Loading.vue'
import Snackbar from '@/components/Snackbar.vue';
export default ({
  data() {
    return {
      dialog: {
        konfirmasi: false
      },
      form: {
        dokumen: ''
      },
      process: {
        form: false,
        run: false,
        start: false,
        loading: false,
      },
      switch_status: null,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  components: {
    LoadingComponent,
    Snackbar,
  },
  mounted () {},
  methods: {
    cekForm() {},
    save() {},
    reset() {},
    toPage() {}
  },
})
</script>

