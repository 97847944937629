import { TokenService } from "@/service/Storage.Service.js"
export default function roles(to, from, next) {
    const roles = TokenService.getRoles()
    switch (roles) {
        case 'Admin':
            // return next({ path: "/persetujuan-pengujian"})
            return next({ path: "/alat"})
        case 'Pendaftar':
            return next({ path: "/pendaftaran"})
        case 'Kalab':
            return next({ path: "/pendaftaran"})
        case 'Laboran':
            return next({ path: "/pendaftaran"})
        case 'Petugas pembayaran':
            return next({ path: "/petugas-pembayaran-pembayaran"})
        default:
            break;
    }
}
