<template>
  <v-app>
    <Navbar v-if="this.$route.name !== 'login'" />
    <v-main 
      min-height="80%"
      class="background-second"
      light>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#content {
  padding: 0px;
}
</style>

