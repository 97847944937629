<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- dialog data diri -->
    <v-dialog
      v-model="dialog.form"
      scrollable
      persistent
      width="650"
      v-if="dialog.form">
      <v-card flat>
        <v-card-title class="body-1 black--text">
          Isi data diri
          <v-spacer />
          <v-btn
            small
            elevation="0"
            icon
            class="text-capitalize white--text"
            @click="dialog.form = false; logout()">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form>
            <ValidationObserver ref="observer">
              <v-row class="mt-3">
                <v-col cols="12" md="12" class="pb-0">
                  <ValidationProvider
                    name="Status"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      v-model="form.status"
                      label="Status"
                      color="#0299B7"
                      :items="list.status"
                      item-text="nama"
                      item-value="kd_status"
                      :disabled="process.save"
                      :error-messages="errors"
                      v-on:change='cekConditionForm()'
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0" v-if="!isNim">
                  <ValidationProvider
                    name="Nomor induk mahasiswa"
                    rules="required|min:6|max:10"
                    v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      :label="form.status == '2' || form.status === '5' ? 'Nomor Induk Kepegawaian' : 'Nomor Induk Mahasiswa'"
                      color="#0299B7"
                      v-model="form.nik"
                      :disabled="process.save"
                      :error-messages="errors"
                      require>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Nama lengkap"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      dense
                      outlined
                      label="Nama lengkap"
                      color="#0299B7"
                      v-model="form.nama"
                      :disabled="process.save"
                      :error-messages="errors"
                      require>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Email aktif"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      dense
                      filled
                      disabled
                      outlined
                      label="Email aktif"
                      color="#0299B7"
                      v-model="form.email"
                      :error-messages="errors"
                      require>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="No. Whatsapp aktif"
                    rules="required|min:10|max:14"
                    v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      label="No. Whatsapp aktif"
                      color="#0299B7"
                      v-model="form.whatsapp"
                      :disabled="process.save"
                      :error-messages="errors"
                      require>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0" v-if="!isPetugas">
                  <ValidationProvider
                    name="Instansi"
                    rules="required"
                    v-slot="{ errors }">
                    <v-select
                      dense
                      :filled="isdisabledForm || isdisabledInstansi"
                      :disabled="isdisabledForm || isdisabledInstansi"
                      :items="list.instansi"
                      v-model="form.instansi"
                      v-on:change="settingInstansi()"
                      label="Instansi"
                      color="#0299B7"
                      item-text="institusi"
                      item-value="kd_institusi"
                      :error-messages="errors"
                      outlined>
                    </v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0" v-if="!isPetugas">
                  <ValidationProvider
                    name="Nama perguruan tinggi"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      dense
                      :filled="isdisabledForm || isDisabledPerguruan"
                      :disabled="isdisabledForm || isDisabledPerguruan"
                      outlined
                      :label="label"
                      color="#0299B7"
                      v-model="form.perguruan_tinggi"
                      :error-messages="errors"
                      require>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0" v-if="!isLainnya">
                  <ValidationProvider
                    name="Fakultas"
                    rules="required"
                    v-slot="{ errors }">
                    <div v-if="!isFreeText">
                      <v-select
                        dense
                        :items="list.fakultas"
                        v-model="form.fakultas"
                        label="Fakultas"
                        color="#0299B7"
                        item-text="nama_organisasi"
                        item-value="kd_organisasi"
                        :disabled="process.save"
                        :error-messages="errors"
                        v-on:change="getProdi(form.fakultas)"
                        outlined>
                      </v-select>
                    </div>

                    <div v-if="isFreeText">
                      <v-text-field
                        dense
                        outlined
                        label="Fakultas"
                        color="#0299B7"
                        v-model="form.fakultas"
                        :disabled="process.save"
                        :error-messages="errors"
                        require>
                      </v-text-field>
                    </div>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0" v-if="!isLainnya">
                  <ValidationProvider
                    name="Program studi"
                    rules="required"
                    v-slot="{ errors }">
                    <div v-if="!isFreeText">
                      <v-select
                        dense
                        v-model="form.program_studi"
                        label="Program studi"
                        color="#0299B7"
                        :items="list.program_studi"
                        item-text="nama_organisasi"
                        item-value="kd_program_studi"
                        :disabled="process.save"
                        :error-messages="errors"
                        outlined>
                      </v-select>
                    </div>

                    <div v-if="isFreeText">
                      <v-text-field
                        dense
                        outlined
                        label="Program studi"
                        color="#0299B7"
                        v-model="form.program_studi"
                        :disabled="process.save"
                        :error-messages="errors"
                        require>
                      </v-text-field>
                    </div>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="my-2 pb-3 justify-center">
          <v-btn
            large
            width="120"
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.save"
            :disabled="process.save"
            @click="cekForm()">
            simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog data diri -->

    <!-- dialog menunggu konfirmasi -->
    <v-dialog
      v-model="dialog.konfirmasi"
      scrollable
      persistent
      width="550"
      v-if="dialog.konfirmasi">
      <v-card flat>
        <v-card-text class="mt-5 text-center">
         <div class="mb-3">
          <v-icon
            style="font-size: 80px;"
            color="#0299B7">
            mdi-check-circle
          </v-icon>
         </div>
         <div v-if="detail.flagAktif === 0">
          <b class="text-h5 font-weight-bold py-3">Status akun Anda nonaktif!</b>
          <p class="mb-0 mt-3 title"></p><br>
          <span class="mb-0 mt-3 body-1"><b>Akses akan diberikan setelah mendapat verifikasi oleh Admin. </b></span>
          <span class="body-2">Untuk mendapat akses kembali, silahkan hubungi 0895332562008.</span>
         </div>
         <div v-else>
          <b class="text-h5 font-weight-bold py-3">Silahkan menunggu beberapa <br> saat !</b>
          <p class="mb-0 mt-3 title"></p><br>
          <span class="mb-0 mt-3 body-1"><b>Akses akan diberikan setelah mendapat verifikasi oleh Admin. </b></span>
          <span class="body-2">Jika status yang dipilih salah (bukan petugas laboratorium), silahkan hubungi 0895332562008</span>
        </div>
        </v-card-text>
        <v-card-actions class="my-2 pb-3 justify-center">
          <v-btn
            large
            width="200"
            outlined
            elevation="0"
            class="text-capitalize color-first mx-1"
            rounded
            @click="logout()">
            Ke halaman login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog menunggu konfirmasi -->
  </div>
</template>

<script>
import { TokenService } from '@/service/Storage.Service'
import { get,post } from "@/service/Axios";
export default {
  data() {
    return {
      detail: {},
      dialog: {
        form: false,
        konfirmasi: false
      },
      form : { 
        status: '',
        nik: '',
        nama: '',
        email: '',
        instansi: '',
        fakultas: '',
        whatsapp: '',
        perguruan_tinggi: '',
      },
      isLainnya: false,
      isUII: false,
      isFreeText: true,
      isOther: false,
      isdisabledForm: false,
      isDisabledPerguruan: false,
      isdisabledInstansi: false,
      isNim: false,
      isPetugas: false,
      label: 'Nama perguruan tinggi',
      list: {
        status: [],
        instansi: [],
        fakultas: [],
        program_studi: [],
      },
      process: {
        run: false,
        detail: false,
        edit: false,
        save: false,
      },
    }
  },
  mounted () {
    this.init();
  },
  computed: {
    user () {
      return this.$store.state.user
    },
  },
  methods: {
    async init() {
      this.fetch();
    },
    
    async fetch() {
      const email = this.user.email;
      this.isFreeText = email.endsWith("uii.ac.id") ? false : true;
      await Promise.all([
        this.getOrganisasi(),
        this.cekUserSelf(),
      ]);
    },

    async cekUserSelf() {
      await get(`user/self`).then((response) => {
        if (response.status === 200) {
          this.detail = response.data.data
          if (response.data.data === null) {
            console.log('awww');
            this.dialog.form = true
            this.form.email = this.user.email
            this.form.nama = this.user.name
            this.getStatus();
            this.getInstansi();
          } else {
            console.log('wawwww');
            const role = response.data.data.role.namaRole
            const flag = response.data.data.flagAktif
            if (flag === 0) {
              console.log('masuk a');
              this.dialog.konfirmasi = true
            } else {
              console.log('masuk b');
              if (role !== null) {
                console.log('masuk c');
                this.dialog.konfirmasi = false
                this.logout()
              } else {
                this.dialog.konfirmasi = true
                console.log('masuk d');
              }
            }
          
            this.dialog.form = false
          }
        }
      })
    },
    
    async getStatus() {
      await get(`status`).then((response) => {
        if (response.status === 200) {
          let res = response.data.data;
          for (const i in res) {
            this.list.status.push({
              kd_status: res[i].kdStatus,
              nama: res[i].status,
            })
          }
        }
      });
    },

    async getInstansi() {
      await get(`institutes`).then((response) => {
        if (response.status === 200) {
          let res = response.data.data;
          for (const i in res) {
            this.list.instansi.push({
              kd_institusi: res[i].kdInstitusi,
              institusi: res[i].institusi,
            })
          }
        }
      });
    },

    async getOrganisasi() {
      try {
        const params = {
          kd_level: 'fakultas'
        };
        const { data, status } = await get('organization', { params });
        if (status === 200 && data.data) {
          this.list.fakultas = data.data.map(({namaOrganisasi, kdOrganisasi}) => ({nama_organisasi: namaOrganisasi, kd_organisasi: kdOrganisasi}));
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getProdi(item) {
      try {
        const params = {
          kd_level: 'prodi',
          kd_organisasi: item
        }
        const { data, status } = await get('organization', { params });
        if (status === 200 && data.data) {
          this.list.program_studi = data.data.map(({namaOrganisasi, kdOrganisasi}) => ({nama_organisasi: namaOrganisasi, kd_program_studi: kdOrganisasi}));
        }
      } catch (error) {
        console.error(error);
      }
    },

    cekConditionForm() {
      const email = this.form.email;
      if (email.endsWith("uii.ac.id")) {
        if (this.form.status === '1' || this.form.status === '2') {
          this.form.instansi = '1'
          this.form.perguruan_tinggi = 'Universitas Islam Indonesia'
          this.isdisabledForm = true
          this.isLainnya = false
          this.isNim = false
          this.isPetugas = false
        } else if (this.form.status === '3' || this.form.status === '4') {
          this.form.instansi = ''
          this.isdisabledForm = false
          this.isPetugas = false
          this.isNim = true
        } else if (this.form.status === '5') {
          this.isPetugas = true;
          this.isLainnya = true
        }
      } else {
        if (this.form.status === '1' || this.form.status === '2') {
          this.form.instansi = '1'
          this.isdisabledInstansi = true
          this.isLainnya = false
          this.isNim = false
        } else if (this.form.status === '5') {
          this.isPetugas = true;
          this.isLainnya = true
          this.isNim = false
        } else {
          this.form.instansi = ''
          this.isdisabledInstansi = false
          this.isNim = true
        }
      }
    },

    settingInstansi() {
     const email = this.user.email
     if (email.endsWith("uii.ac.id")) {
      if (this.form.status === '1' || this.form.status === '2') {
        console.log('');
      } else if (this.form.status === '3' || this.form.status === '4') {
        if (this.form.instansi === '1') {
          this.label = 'Nama perguruan tinggi'
          this.form.perguruan_tinggi = 'Universitas Islam Indonesia'
          this.isdisabledInstansi = false
          this.isLainnya = false
          this.isDisabledPerguruan = true
        } else {
          this.label = 'Nama instansi'
          this.form.perguruan_tinggi = ''
          this.isLainnya = true
          this.isDisabledPerguruan = false
          this.isdisabledInstansi = false
        }
      }
     } else {
        if (this.form.status === '1' || this.form.status === '2') {
          console.log('');
        } else {
          if (this.form.instansi === '1') {
            this.label = 'Nama perguruan tinggi'
            this.isLainnya = false
          } else {
            this.label = 'Nama instansi'
            this.form.perguruan_tinggi = ''
            this.isLainnya = true
          }
        }
     }
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        this.save()
      }
    },

    async save() {
      this.process.save = true
      const nama_institusi = this.list.instansi.find(x => x.kd_institusi === this.form.instansi)?.institusi;
      const nama_fakultas = this.list.fakultas.find(x => x.kd_organisasi === this.form.fakultas)?.nama_organisasi ?? this.form.fakultas;
      const nama_prodi = this.list.program_studi.find(x => x.kd_program_studi === this.form.program_studi)?.nama_organisasi ?? this.form.program_studi;
      this.process.save = true
      const body = {
        google_id: this.user.id,
        kd_status: this.form.status,
        no_induk: this.form.nik,
        nama: this.user.name,
        email: this.user.email,
        no_hp: this.form.whatsapp,
        kd_institusi: this.form.instansi,
        nama_institusi: nama_institusi === undefined ? '' : nama_institusi,
        nama_perguruan_tinggi: this.form.perguruan_tinggi,
        kd_fakultas: this.form.fakultas,
        nama_fakultas: nama_fakultas === undefined ? this.form.fakultas : nama_fakultas,
        kd_program_studi: this.form.program_studi,
        nama_program_studi: nama_prodi === undefined ? this.form.program_studi : nama_prodi
      }
      await post(`user`, {
      data: body,
      }).then((response) => {
        if (response.status === 201) {
          this.dialog.form = false
          this.text = 'Data berhasil ditambah'
          this.snackbar = true
          this.process.save = false
          this.cekUserSelf()
        } else {
          this.process.save = false
          this.dialog.konfirmasi = false
        }
      })
    },

    logout() {
      TokenService.removeToken()
      window.location = '/login'
    },
  },
}


</script>