<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <div>
      <LoadingComponent v-if="process.loading"></LoadingComponent>
      <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
        <v-col cols="12">
          <v-card class="border-radius box-shadow pa-1">   
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <div class="color-first title font-weight-bold title mt-3">
                    Parameter uji
                  </div>
                  <!-- row filter -->
                  <v-row class="pt-5">
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.lab"
                        item-text="nama_laboratorium"
                        item-value="id"
                        v-model="filter.lab"
                        v-on:change="onSelectLab()"
                        label="Laboratorium"
                        color="#0299B7"
                        hide-details>
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <!-- end row filter -->

                  <!-- row  button-->
                  <v-row class="mt-7 justify-space-between">
                    <v-col cols="12" md="3">
                      <v-btn
                        @click="toPage('tambah')"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.run"
                        class="text-capitalize white--text">
                          <v-icon size="20" class="mr-3">mdi-plus</v-icon>
                          Tambah
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :disabled="process.run"
                        outlined
                        dense
                        :hide-details="true"
                        placeholder="Cari . . ."
                        clearable
                        color="#0299B7"
                        class="mx-3"
                        v-model="form.cari"
                        @click:clear="form.cari = ''; getList();"
                        v-on:keyup.enter="getList();"
                        prepend-inner-icon="mdi-magnify">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- end row button -->

                  <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="row"
                        :items-per-page="10"
                        class="elevation-1 required">
                          <template v-slot:[`item.status`]="{ item }">
                            <td>
                              <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                            </td>
                          </template>
                          <template v-slot:[`item.aksi`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="#0299B7"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="toPage(item, 'detail')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Detail</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  x-small
                                  fab
                                  icon
                                  min-width="30"
                                  color="orange"
                                  :disabled="process.edit"
                                  :loading="process.edit"
                                  @click="toPage(item, 'edit')"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip> 
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="item.flag_dapat_hapus === true"
                                  x-small
                                  fab
                                  icon
                                  min-width="25"
                                  color="red"
                                  :disabled="process.detail"
                                  :loading="process.detail"
                                  @click="deleteForm(item)"
                                  class="text-capitalize color-first pa-0 mx-1"
                                  v-bind="attrs"
                                  v-on="on">
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>        
                </v-card-text>      
              </v-col>
            </v-row>
          </v-card>     
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { get } from "@/service/Axios";
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
import LoadingComponent from '@/components/Loading.vue'
import { TokenService } from "@/service/Storage.Service";
export default {
  mixins: [statusMixin],  
  data() {
    return {
      form : { 
        lab: '',
        cari: '',
        dialog: {
          id_laboratorium: '',
          kode_instrumen: '',
          nama_instrumen: '',
          durasi: '',
          satuan: '',
          start_date: '',
          end_date: '',
          item_start: false,
          item_end: false,
          time_start: '',
          time_end: '',
          start_time:false,
          end_time:false,
          end_time_min: '',
          start_time_min: '',
          harga_internal: '',
          harga_external: '',
          keterangan: '',
          uuid: ''
        },
      },
      filter: {
        lab: ''
      },
      list: {
        lab: [],
      },
      process: {
        run: false,
        loading: false
      },
    headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Parameter uji', value: 'parameter_uji', sortable: false, },
        { text: 'Harga internal (Rp)', value: 'harga_internal', align: 'right', sortable: false, },
        { text: 'Harga eksternal (Rp)', value: 'harga_external', align: 'right', sortable: false, },
        { text: 'Satuan', value: 'satuan', sortable: false, },
        { text: 'Terdapat replikasi', value: 'terdapat_replikasi', sortable: false, },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '14%'
        },
      ],
      switch_status: null,
      save_type: '',
      type: '',
      row: [],
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },

  components: {
    Snackbar,
    LoadingComponent,
  },

  computed: {
    token () {
      return this.$store.state.access_token
    },
  },
  mounted () {
    TokenService.removeUUID(TokenService.getUuid())
    this.getLaboratory()
  },

  methods: {
    async getLaboratory() {
      try {
        this.process.loading = true
        this.process.run = true
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory-master', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          const labListSelect = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          labList.unshift({
            id: 'all',
            kd_laboratorium: 'all',
            nama_laboratorium: 'Semua',
          })
          this.list.lab = labList
          this.list.lab_select = labListSelect
          this.filter.lab = labList[0].id
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.loading = false
        this.process.run = false;
        this.getList()
      }
    },

    async getList() {
      try {
        this.process.run = true;
        const params = {
          id_laboratorium: this.filter.lab,
          search: this.form.cari,
        };
        const { data, status } = await get('test-parameter', { params });
        if (status === 200 && data.data) {
          const row = data.data.map((item, index) => ({
            no: index + 1,
            parameter_uji: item.kdParameterUji,
            harga_internal: item.hargaInternal.toLocaleString('id-ID'),
            harga_external: item.hargaEksternal.toLocaleString('id-ID'),
            satuan: item.unit.namaSatuan,
            terdapat_replikasi: item.flagReplikasi === 0 ? 'Tidak' : 'Ya',
            uuid: item.uuid
          }));
          this.row = row;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
      }
    },

    onSelectLab() {
      this.getList()
    },

    toPage(val, page) {
      try {
        const { form } = this
        const data = {
          lab: form.lab,
          jenis: form.jenis,
        }
        localStorage.setItem('filter', JSON.stringify(data))
        localStorage.setItem('page', page)
        TokenService.setUuid(val.uuid);
        let path;
        path = page === 'detail' ? '/parameter-uji/detail' : page === undefined ? '/parameter-uji/tambah' : '/parameter-uji/edit';
        this.$router.push({path: path})
      } catch (error) {
        console.error(error);
      }
    },

    deleteForm(val) {
      console.log(val);
    }
  }
}


</script>