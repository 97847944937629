const TOKEN_KEY = "access_token"
const USER = "access_user"
const UUID = "uuid"
const ROLES = "roles"

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },
  
  getUser() {
    return localStorage.getItem(USER)
  },

  getUuid() {
    return localStorage.getItem(UUID)
  },

  getRoles() {
    return localStorage.getItem(ROLES)
  },

  setUuid(item) {
    localStorage.setItem(UUID, item);
  },

  removeUUID(item) {
    localStorage.removeItem(UUID)
  },

  saveRoles(roles) {
    localStorage.setItem(ROLES, roles)
  },

  saveToken(accessToken, accessUser) {
    localStorage.setItem(TOKEN_KEY, accessToken)
    localStorage.setItem(USER, accessUser)
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER)
    localStorage.removeItem(ROLES)
  }
}

export { TokenService }