<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-5' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
       <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang di inputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
          <!-- <v-alert type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert> -->
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3 px-3" v-if="!process.loading">
      <v-col cols="12" >
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <ValidationObserver ref="observer">
                  <v-form>
                    <!-- row data diri -->
                    <div>
                      <v-row>
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold mt-2">
                            Data diri
                          </div>
                        </v-col>
                      </v-row>       
                      <v-row>
                        <v-col cols="12" md="6" class="pb-0">
                          <ValidationProvider
                            name="ID registerasi">
                            <v-text-field
                              dense
                              filled
                              disabled
                              outlined
                              label="ID registerasi"
                              color="#0299B7"
                              v-model="form.data_diri.id_registerasi"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0">
                          <ValidationProvider
                            name="Status"
                            rules="required"
                            v-slot="{ errors }">
                            <v-select
                              dense
                              v-model="form.data_diri.status"
                              :items="list.status"
                              label="Status"
                              color="#0299B7"
                              item-text="nama"
                              item-value="kd_status"
                              v-on:change='cekConditionForm()'
                              :error-messages="errors"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0" v-if="!isNim">
                          <ValidationProvider 
                              name="Nomor induk mahasiswa"
                              rules="required|min:4|max:16"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                type="number"
                                :label="form.data_diri.status == '2' ? 'Nomor induk kepegawaian' : 'Nomor induk mahasiswa'"
                                color="#0299B7"
                                v-model="form.data_diri.nik"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Nama lengkap"
                            rules="required"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="Nama lengkap"
                              color="#0299B7"
                              v-model="form.data_diri.nama"
                              :error-messages="errors"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Email"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                filled
                                disabled
                                outlined
                                label="Email aktif"
                                color="#0299B7"
                                v-model="form.data_diri.email"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" :md="form.data_diri.status !== '5' ? '6' : '12'" class="pb-0 pt-0">
                          <ValidationProvider
                            name="No. whatsapp aktif"
                            rules="required|min:10|max:14"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="No. whatsapp aktif"
                              color="#0299B7"
                              v-model="form.data_diri.whatsapp"
                              :error-messages="errors"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0" v-if="form.data_diri.status !== '5'">
                          <ValidationProvider
                            name="Instansi">
                            <v-select
                              dense
                              :filled="isdisabledInstansi"
                              :disabled="isdisabledInstansi"
                              :items="list.instansi"
                              v-model="form.data_diri.instansi"
                              v-on:change="settingInstansi()"
                              label="Instansi"
                              color="#0299B7"
                              item-text="institusi"
                              item-value="kd_institusi"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0" v-if="form.data_diri.status !== '5'">
                          <ValidationProvider
                            name="Nama perguruan tinggi"
                            rules="required"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              :filled="isdisabledPerguruan"
                              :disabled="isdisabledPerguruan"
                              v-model="form.data_diri.perguruan_tinggi"
                              :label="label"
                              color="#0299B7"
                              :error-messages="errors"
                              outlined>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0" v-if="!isLainnya && form.data_diri.status !== '5'">
                          <ValidationProvider
                            name="Fakultas"
                            rules="required"
                            v-slot="{ errors }">
                            <div v-if="!isFreeText">
                              <v-select
                                dense
                                v-model="form.data_diri.fakultas"
                                :items="list.fakultas"
                                item-text="nama_organisasi"
                                item-value="kd_organisasi"
                                v-on:change="onSelectFaculty()"
                                label="Fakultas"
                                color="#0299B7"
                                :error-messages="errors"
                                outlined>
                              </v-select>
                            </div>

                            <div v-if="isFreeText">
                              <v-text-field
                                dense
                                outlined
                                label="Fakultas"
                                color="#0299B7"
                                v-model="form.data_diri.fakultas"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                            </div>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0" v-if="!isLainnya && form.data_diri.status !== '5'"">
                          <ValidationProvider
                            name="Program studi"
                            rules="required"
                            v-slot="{ errors }">
                            <div v-if="!isFreeText">
                              <v-select
                                dense
                                v-model="form.data_diri.program_studi"
                                label="Program studi"
                                color="#0299B7"
                                :items="list.program_studi"
                                item-text="nama_organisasi"
                                v-on:change="onSelectProdi()"
                                item-value="kd_program_studi"
                                :error-messages="errors"
                                outlined>
                              </v-select>
                            </div>

                            <div v-if="isFreeText">
                              <v-text-field
                                dense
                                outlined
                                label="Program studi"
                                color="#0299B7"
                                v-model="form.data_diri.program_studi"
                                :error-messages="errors"
                                require>
                              </v-text-field>
                            </div>
                          </ValidationProvider>
                      </v-col>
                      </v-row>
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                      </v-col>
                    </v-row>
                    <!-- end row data diri -->

                    <!-- row jenis penggunaan -->
                    <div>
                      <v-row>
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold mt-2 pb-1">
                            Jenis penggunaan
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" class="pt-0">
                          <v-radio-group v-model="sort.selected" class="mt-0">
                            <v-radio
                              v-for="(status, index) in sort.status_jenis_pengguna"
                              :key="index" 
                              :label="status.text"
                              :value="status.id"
                              color="#0499b7"
                              class="py-0"
                              @click="onSelectTypeOfUse(sort.selected)">
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row v-if="sort.selected == '1'">
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Jenis"
                            rules="required"
                            v-slot="{ errors }">
                            <v-select
                              dense
                              v-model="form.jenis_penggunaan.jenis"
                              :items="list.jenis"
                              label="Jenis"
                              color="#0299B7"
                              item-text="jenis_penelitian"
                              item-value="kd_jenis_penelitian"
                              v-on:change="onSelectType($event)"
                              :error-messages="errors"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="12" md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Keterangan">
                            <v-text-field
                              dense
                              outlined
                              label="Keterangan (Optional)"
                              color="#0299B7"
                              v-model="form.jenis_penggunaan.keterangan">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- end row jenis penggunaan -->
                    
                    <!-- --- PENELITIAN --- -->
                    <div v-if="sort.selected === '1'">
                      <!-- row judul -->
                      <div>
                        <v-row>
                          <v-col cols="12">
                            <div class="color-first title font-weight-bold mt-2 pb-1">
                              Judul
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Judul"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Judul"
                                color="#0299B7"
                                :error-messages="errors"
                                v-model="form.judul.judul">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <ValidationProvider
                                name="Judul payung">
                                <v-text-field
                                  dense
                                  outlined
                                  label="Judul payung (Optional)"
                                  color="#0299B7"
                                  v-model="form.judul.judul_payung">
                                </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="12" class="pb-0 pt-0" v-if="isPetaPenelitian || form.data_diri.program_studi === '613'">
                            <ValidationProvider
                              name="Peta penelitian"
                              rules="required"
                              v-slot="{ errors }">
                              <v-select
                                dense
                                :items="list.peta_penelitian"
                                v-model="form.judul.peta_penelitian"
                                label="Peta penelitian"
                                color="#0299B7"
                                item-text="peta_penelitian"
                                item-value="id_peta_penelitian"
                                :error-messages="errors"
                                outlined>
                              </v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" class="pb-0 pt-0 px-0" v-if="isNonLabor">
                            <v-col md="6" class="pb-0 pt-0">
                              <ValidationProvider
                                name="Lab"
                                rules="required"
                                v-slot="{ errors }">
                                <v-select
                                  dense
                                  :items="list.lab_non_labor"
                                  v-model="form.judul.lab"
                                  label="Lab"
                                  color="#0299B7"
                                  item-text="nama_laboratorium"
                                  item-value="kd_laboratorium"
                                  :error-messages="errors"
                                  outlined>
                                </v-select>
                              </ValidationProvider>
                            </v-col>
                          </v-col>
                          <v-col md="6" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Dosen pembimbing"
                            rules="required"
                            v-slot="{ errors }">
                            <v-select
                              dense
                              :items="list.dosen"
                              v-model="form.judul.dosen_pembimbing"
                              label="Dosen pembimbing / Ketua peneliti"
                              color="#0299B7"
                              item-text="nama_dosen"
                              item-value="id"
                              v-on:change="onSelectDosen()"
                              :error-messages="errors"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0 pt-0" v-if="form.judul.dosen_pembimbing === '999'">
                            <ValidationProvider
                              name="Nama dosen"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Nama dosen pembimbing/Ketua peneliti"
                                color="#0299B7"
                                :error-messages="errors"
                                v-model="form.judul.dosen_freetext">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="7" class="pb-0 pt-0" v-if="isLabor">
                            <div v-if="!isTidakAda">
                              <p v-if="!isProposal && !isUII" class="mb-2">Upload surat persetujuan Dosen pembimbing / Ketua peneliti</p>
                              <p v-if="isProposal  && isUII" class="mb-2">Proposal penelitian</p>
                              <v-card
                                flat
                                width="100%"
                                height="290"
                                class="pa-0"
                                style="background: transparent;">
                                <file-input v-model="files" >
                                  <div slot="activator">
                                    <v-avatar 
                                      width="100%"
                                      height="220"
                                      class="grey lighten-3 cursor-pointer"
                                      v-ripple 
                                      tile
                                      v-if="!files || files.url === undefined">
                                      <v-icon v-if="!files && addFiles === ''" color="grey darken-3" large>mdi-file-pdf-box</v-icon>
                                      <div v-if="addFiles && files.url === undefined">
                                      <v-progress-linear
                                        color="#0299B7"
                                        indeterminate
                                        rounded
                                        height="6"
                                        style="width: 200px;">
                                      </v-progress-linear>
                                      <p class="caption mt-2 mb-0 color-first">
                                        Menunggu data . . .
                                      </p>
                                    </div>
                                    </v-avatar>
                                  </div>
                                </file-input>
                                <p class="caption text-left mt-1 red--text"
                                  v-show="error.libraryFile">
                                  {{ error.libraryFile }}
                                </p>
                                <div v-if="files && !addFiles">
                                  <iframe 
                                    width="100%" 
                                    height="210px" 
                                    :src="files.url" 
                                    frameborder="0"
                                    allowfullscreen>
                                    Oops! an error has occurred.
                                  </iframe>
                                </div>
                                <file-input
                                  v-model="files" >
                                  <div slot="activator">
                                    <v-btn
                                      rounded
                                      light
                                      width="100%"
                                      color="#0299B7"
                                      elevation="0"
                                      :disabled="addFiles || process.run"
                                      :loading="addFiles"
                                      v-if="addFiles !== '' && files.url !== undefined"
                                      class="white--text text-capitalize">
                                      Ganti files
                                    </v-btn>
                                  </div>
                                </file-input>
                                <i class="mt-1 caption color-second" style="font-size: 12px;">
                                  Maks 5MB dengan format file .pdf <br>Template surat persetujuan dapat diunduh pada link berikut: <span class="color-third font-weight-bold cursor-pointer"><a href="https://drive.google.com/drive/folders/1fpEnqWU1h9DA0OaLqRSk4L905hUPSEVL" target="_blnak"> Unduh template surat persetujuan </a></span>
                                </i>
                              </v-card>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- end row judul -->

                      <!-- row safety lab -->
                      <div>
                        <div v-if="!isNonLabor">
                          <v-row>
                            <v-col cols="12">
                              <div class="color-first title font-weight-bold mt-2 pb-1">
                                Sertifikat pelatihan safety lab
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="7" class="pb-0 pt-0">
                              <p class="mb-2">Sertifikat pelatihan safety lab</p>
                              <v-card
                                flat
                                width="100%"
                                height="290"
                                class="pa-0"
                                style="background: transparent;">
                                <file-input v-model="safetyFiles" >
                                  <div slot="activator">
                                    <v-avatar 
                                      width="100%"
                                      height="220"
                                      class="grey lighten-3 cursor-pointer"
                                      v-ripple 
                                      tile
                                      v-if="!safetyFiles || safetyFiles.url === undefined">
                                      <v-icon v-if="!safetyFiles && addSafetyFiles === ''" color="grey darken-3" large>mdi-file-pdf-box</v-icon>
                                      <div v-if="safetyFiles && safetyFiles.url === undefined">
                                        <v-progress-linear
                                          color="#0299B7"
                                          indeterminate
                                          rounded
                                          height="6"
                                          style="width: 200px;">
                                        </v-progress-linear>
                                        <p class="caption mt-2 mb-0 color-first">
                                          Menunggu data . . .
                                        </p>
                                      </div>
                                    </v-avatar>
                                  </div>
                                </file-input>
                                <p class="caption text-left mt-1 red--text"
                                  v-show="error.libraryFileSafety">
                                  {{ error.libraryFileSafety }}
                                </p>
                                <div v-if="safetyFiles && !addSafetyFiles">
                                  <iframe 
                                    width="100%" 
                                    height="210px" 
                                    :src="safetyFiles.url" 
                                    frameborder="0"
                                    allowfullscreen>
                                    Oops! an error has occurred.
                                  </iframe>
                                </div>
                                <file-input
                                  v-model="safetyFiles" >
                                  <div slot="activator">
                                    <v-btn
                                      rounded
                                      light
                                      width="100%"
                                      color="#0299B7"
                                      elevation="0"
                                      :disabled="addSafetyFiles || process.run"
                                      :loading="addSafetyFiles"
                                      v-if="addSafetyFiles !== '' && safetyFiles.url !== undefined"
                                      class="white--text text-capitalize">
                                      Ganti files
                                    </v-btn>
                                  </div>
                                </file-input>
                                <i class="mt-1 caption color-second">
                                  Maks 5MB dengan format file .pdf <br>Jika belum mempunyai, silahkan melakukan pendaftaran terlebih dahulu di link berikut: <span class="color-third font-weight-bold cursor-pointer"><a href="https://s.id/daftar-safety-lab" target="_blank"> Pendaftaran safety lab </a></span>
                                </i>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <!-- end safety lab -->

                      <!-- row rencana penggunaan -->
                      <div v-if="isLabor && form.jenis_penggunaan.jenis !== ''">
                        <v-row>
                          <v-col cols="12">
                            <div class="color-first title font-weight-bold mt-2 pb-1">
                              Rencana penggunaan
                            </div>
                          </v-col>
                        </v-row>
                        <v-row 
                          v-for="(item, i) in form.rencana_penggunaan.rencana_penggunaan" 
                          :key="i">
                          <v-col cols="12" class="pb-0">
                            <ValidationProvider
                              name="Lab"
                              rules="required"
                              v-slot="{ errors }">
                              <v-select
                                dense
                                v-model="item.lab"
                                v-on:change="changeLab(item, i)"
                                :items="list.lab"
                                label="Lab"
                                color="#0299B7"
                                item-text="nama_laboratorium"
                                item-value="id"
                                :error-messages="errors"
                                outlined>
                              </v-select>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" md="3" class="pt-0">
                            <v-menu
                              ref="menustart"
                              v-model="item.item_start"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              class="z-my"
                              transition="scale-transition"
                              offset-y
                              min-width="290px">
                              <template v-slot:activator="{ on }">
                                <ValidationProvider
                                  name="Tanggal mulai"
                                  rules="required"
                                  v-slot="{ errors }">
                                  <v-text-field
                                    :value="formatDateStart(i)"
                                    label="Tanggal mulai"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    color="#0299B7"
                                    :error-messages="errors"
                                    v-on="on">
                                  </v-text-field>
                                </ValidationProvider>
                              </template>
                              <v-date-picker
                                v-model="item.start_date"
                                :min="reminder"
                                v-on:change="startdate(i)" 
                                color="#0299B7"
                                @input="item.item_start = false">
                              </v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" md="3" class="pt-0">
                            <v-menu
                              ref="menuend"
                              v-model="item.item_end"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              class="z-my"
                              transition="scale-transition"
                              offset-y
                              min-width="290px">
                              <template v-slot:activator="{ on }">
                                <ValidationProvider
                                  name="Tanggal selesai"
                                  rules="required"
                                  v-slot="{ errors }">
                                  <v-text-field
                                    label="Tanggal selesai"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    :value="formatDateEnd(i)"
                                    dense
                                    color="#0299B7"
                                    :error-messages="errors"
                                    v-on="on">
                                  </v-text-field>
                                </ValidationProvider>
                              </template>
                              <v-date-picker 
                                v-model="item.end_date"
                                :min="item.start_date" 
                                v-on:change="enddate(i)" 
                                color="#0299B7"
                                @input="item.item_end = false">
                              </v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" md="3" class="pt-0">
                            <v-menu
                              ref="menuTimeStart"
                              v-model="item.start_time"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="item.time_start"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="item.time_start"
                                  label="Jam mulai (setiap hari)(optional)"
                                  prepend-inner-icon="mdi-clock"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  hide-details
                                  color="#0299B7"
                                  v-on="on"
                                  clearable
                                  :disabled="item.start_date === ''">
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="item.start_time"
                                v-model="item.time_start"
                                full-width
                                color="#0299B7"
                                format="24hr"
                                @click:minute="$refs.menuTimeStart[i].save(item.time_start)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" md="3" class="pb-0 pt-0">
                            <v-menu
                              ref="menuTimeEnd"
                              v-model="item.end_time"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="item.time_end"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="item.time_end"
                                  label="Jam selesai (setiap hari)(optional)"
                                  prepend-inner-icon="mdi-clock"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  hide-details
                                  color="#0299B7"
                                  v-on="on"
                                  clearable
                                  :disabled="item.start_date === ''">
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="item.end_time"
                                v-model="item.time_end"
                                full-width
                                color="#0299B7"
                                format="24hr"
                                @click:minute="$refs.menuTimeEnd[i].save(item.time_end)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" class="pb-0">
                            <ValidationProvider
                              name="Alat">
                              <v-combobox
                                dense
                                :disabled="isDisabledLab[i] || isDisabledAlatLab[i]"
                                :filled="isDisabledLab[i] || isDisabledAlatLab[i]"
                                v-model="item.alat"
                                label="Alat (Optional)"
                                color="#0299B7"
                                :items="list.alat"
                                item-text="nama_alat"
                                item-value="id"
                                multiple
                                chips
                                item-closeable
                                outlined>
                              </v-combobox>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Bahan">
                              <v-combobox
                                dense
                                :disabled="isDisabledLab[i]"
                                :filled="isDisabledLab[i]"
                                v-model="item.bahan"
                                :items="list.bahan"
                                label="Bahan (Optional)"
                                color="#0299B7"
                                item-text="nama_bahan"
                                item-value="id"
                                chips
                                multiple
                                outlined>
                              </v-combobox>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Instrumen"
                              :rules="isDisabledLab[i] || isDisabledInstrumenLab[i] ? '' : 'required'"
                              v-slot="{ errors }">
                              <v-combobox
                                dense
                                :disabled="isDisabledLab[i] || isDisabledInstrumenLab[i]"
                                :filled="isDisabledLab[i] || isDisabledInstrumenLab[i]"
                                v-model="item.instrumen"
                                :items="list.instrumen"
                                label="Instrumen "
                                color="#0299B7"
                                item-text="nama_instrumen"
                                item-value="id"
                                chips
                                :error-messages="errors"
                                multiple
                                outlined>
                              </v-combobox>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" class="pt-0">
                            <div class="d-flex align-center">
                              <v-btn
                                v-if="i === form.rencana_penggunaan.rencana_penggunaan.length - 1"
                                large
                                elevation="3"
                                color="#099a55"
                                @click="addForm()"
                                class="text-capitalize white--text mr-2">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                              <v-btn
                                v-if="i > 0 || form.rencana_penggunaan.rencana_penggunaan > 1"
                                large
                                elevation="3"
                                color="red"
                                @click="removeForm(i)"
                                class="text-capitalize white--text">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- end row rencana penggunaan -->

                      <!-- row tempat penelitian -->
                      <div v-if="!isLabor && form.jenis_penggunaan.jenis === '2'">
                        <v-row>
                          <v-col cols="12">
                            <div class="color-first title font-weight-bold mt-2 pb-1">
                              Tempat penelitian
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pb-0 pt-0">
                            <ValidationProvider
                              name="Tempat"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Tempat"
                                color="#0299B7"
                                :error-messages="errors"
                                v-model="form.tempat_penelitian.tempat">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          
                          <v-col cols="12" md="3" class="pt-0">
                            <v-menu
                              ref="menustart"
                              v-model="form.tempat_penelitian.item_start"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              class="z-my"
                              transition="scale-transition"
                              offset-y
                              min-width="290px">
                              <template v-slot:activator="{ on }">
                                <ValidationProvider
                                  name="Tanggal mulai"
                                  rules="required"
                                  v-slot="{ errors }">
                                  <v-text-field
                                    :value="formatDateStart()"
                                    label="Tanggal mulai"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    hide-details
                                    color="#0299B7"
                                    :error-messages="errors"
                                    v-on="on">
                                  </v-text-field>
                                </ValidationProvider>
                              </template>
                              <v-date-picker
                                v-model="form.tempat_penelitian.start_date"
                                :min="reminder" 
                                v-on:change="startdate()" 
                                color="#0299B7"
                                @input="form.tempat_penelitian.item_start = false">
                              </v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" md="3" class="pt-0">
                            <v-menu
                              ref="menuend"
                              v-model="form.tempat_penelitian.item_end"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              class="z-my"
                              transition="scale-transition"
                              offset-y
                              min-width="290px">
                              <template v-slot:activator="{ on }">
                                <ValidationProvider
                                  name="Tanggal selesai"
                                  rules="required"
                                  v-slot="{ errors }">
                                  <v-text-field
                                    label="Tanggal selesai"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    :value="formatDateEnd()"
                                    dense
                                    hide-details
                                    color="#0299B7"
                                    :error-messages="errors"
                                    v-on="on">
                                  </v-text-field>
                                </ValidationProvider>
                              </template>
                              <v-date-picker 
                                v-model="form.tempat_penelitian.end_date"
                                format="D Month, Yr"
                                :min="form.tempat_penelitian.start_date" 
                                v-on:change="enddate()" 
                                color="#0299B7"
                                @input="form.tempat_penelitian.item_end = false">
                              </v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" md="3" class="pt-0">
                            <v-menu
                              ref="menuTimeStart"
                              v-model="form.tempat_penelitian.start_time"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="form.tempat_penelitian.time_start"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="form.tempat_penelitian.time_start"
                                  label="Jam mulai (setiap hari)(optional)"
                                  prepend-inner-icon="mdi-clock"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  hide-details
                                  color="#0299B7"
                                  v-on="on"
                                  :disabled="form.tempat_penelitian.start_date === ''">
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="form.tempat_penelitian.start_time"
                                v-model="form.tempat_penelitian.time_start"
                                format="24hr"
                                full-width
                                color="#0299B7"
                                @click:minute="$refs.menuTimeStart.save(form.tempat_penelitian.time_start)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" md="3" class="pb-0 pt-0">
                            <v-menu
                              ref="menuTimeEnd"
                              v-model="form.tempat_penelitian.end_time"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="form.tempat_penelitian.time_end"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="form.tempat_penelitian.time_end"
                                  label="Jam selesai (setiap hari)(optional)"
                                  prepend-inner-icon="mdi-clock"
                                  readonly
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  hide-details
                                  color="#0299B7"
                                  v-on="on"
                                  :disabled="form.tempat_penelitian.start_date === ''">
                                </v-text-field>
                              </template>
                              <v-time-picker
                                v-if="form.tempat_penelitian.end_time"
                                v-model="form.tempat_penelitian.time_end"
                                full-width
                                format="24hr"
                                color="#0299B7"
                                @click:minute="$refs.menuTimeEnd.save(form.tempat_penelitian.time_end)"
                              ></v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- end row penelitian -->

                      <!-- row pembiyayaan -->
                      <div class="color-first title font-weight-bold mt-7 pb-1">
                        Pembiayaan
                      </div>
                      <v-row>
                        <v-col cols="12" md="12" class="pt-0">
                          <v-radio-group v-model="sort.selected_pembiyayaan">
                            <v-radio
                              v-for="(status, index) in sort.status_pembiyayaan"
                              :key="index" 
                              :label="status.text"
                              :value="status.id"
                              color="#0499b7"
                              class="py-0">
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row v-if="sort.selected_pembiyayaan == '2' ">
                        <v-col cols="12" :md="form.pembiyayaan.dosen_pembimbing_pembiyayaan === '999' ? 6 : 12" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Dosen pembimbing"
                            rules="required"
                            v-slot="{ errors }">
                            <v-select
                              dense
                              :items="list.dosen"
                              v-model="form.pembiyayaan.dosen_pembimbing_pembiyayaan"
                              label="Dosen pembimbing / Ketua peneliti"
                              color="#0299B7"
                              item-text="nama_dosen"
                              item-value="id"
                              :error-messages="errors"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="6" class="pb-0 pt-0" v-if="form.pembiyayaan.dosen_pembimbing_pembiyayaan === '999'">
                            <ValidationProvider
                              name="Nama dosen"
                              rules="required"
                              v-slot="{ errors }">
                              <v-text-field
                                dense
                                outlined
                                label="Nama dosen pembimbing/Ketua peneliti"
                                color="#0299B7"
                                :error-messages="errors"
                                v-model="form.pembiyayaan.dosen_freetext">
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                      </v-row>
                      <v-row v-if="sort.selected_pembiyayaan == '3'">
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Lainnya"
                            rules="required"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="Lainnya"
                              color="#0299B7"
                              v-model="form.pembiyayaan.lainnya"
                              :error-messages="errors"
                              require>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <!-- end row pembiyayaan -->
                    </div>
                    <!-- --- END PENELITIAN --- -->

                    <!-- --- PENGUJIAN --- -->
                    <div v-if="sort.selected === '2'">
                      <v-row>
                        <v-col cols="12">
                          <div class="color-first title font-weight-bold mt-2">
                           Sampel
                          </div>
                        </v-col>
                      </v-row>      
                      <v-row v-for="(item, i) in form.sampel.sampel" :key="i">
                        <v-col cols="12" md="12" class="pb-0">
                          <ValidationProvider
                            name="Nama"
                            rules="required"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="Nama"
                              color="#0299B7"
                              :error-messages="errors"
                              v-model="item.nama">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="3" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Jumlah"
                            rules="required|numeric"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="Jumlah"
                              color="#0299B7"
                              :error-messages="errors"
                              v-model="item.jumlah">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="3" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Bentuk sampel"
                            rules="required"
                            v-slot="{ errors }">
                            <v-select
                              dense
                              v-model="item.bentuk_sampel"
                              :items="list.bentuk_sampel"
                              label="Bentuk sampel"
                              color="#0299B7"
                              item-text="label"
                              item-value="value"
                              :error-messages="errors"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="3" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Penyimpanan"
                            rules="required"
                            v-slot="{ errors }">
                            <v-select
                              dense
                              v-model="item.penyimpanan"
                              :items="list.penyimpanan"
                              label="Penyimpanan"
                              color="#0299B7"
                              item-text="label"
                              item-value="value"
                              :error-messages="errors"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="3" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Kondisi"
                            rules="required"
                            v-slot="{ errors }">
                            <v-select
                              dense
                              v-model="item.kondisi"
                              :items="list.kondisi"
                              label="Kondisi"
                              color="#0299B7"
                              item-text="label"
                              item-value="value"
                              :error-messages="errors"
                              outlined>
                            </v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <ValidationProvider
                            name="Kemasan"
                            rules="required"
                            v-slot="{ errors }">
                            <v-text-field
                              dense
                              outlined
                              label="Kemasan"
                              color="#0299B7"
                              :error-messages="errors"
                              v-model="item.kemasan">
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="12" class="pt-0">
                          <ValidationProvider
                            name="Gambar">
                            <v-file-input
                              v-model="item.gambar"
                              label="Gambar (Optional)"
                              outlined
                              dense
                              hide-details
                              color="#0299B7"
                              accept=".jpg,.jpeg,.pdf"
                              :rules="[fileSizeRule]">
                            </v-file-input>
                            <i class="mt-1 caption color-second">
                              Maks 5MB dengan format file .jpg/.pdf
                            </i>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0" v-for="(data, j) in item.parameter" :key="j">
                          <v-row>
                            <v-col cols="12" md="8" class="pb-0 pr-0">
                            <ValidationProvider
                              name="Parameter uji"
                              rules="required"
                              v-slot="{ errors }">
                              <v-select
                                dense
                                v-model="data.parameter_uji"
                                :items="list.parameter"
                                :label="'Parameter uji ' + (j + 1)"                                 
                                color="#0299B7"
                                item-text="label"
                                item-value="value"
                                v-on:change="onSelectParameter($event, i, j)"
                                :error-messages="errors"
                                outlined>
                              </v-select>
                            </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="2" class="pb-0">
                              <ValidationProvider
                                name="Replikasi"
                                :rules="data.flag_replikasi === 0 ? 'numeric' : 'numeric|required'"
                                v-slot="{ errors }">
                                <v-text-field
                                  dense
                                  :disabled="data.flag_replikasi === 1 ? false : true"
                                  :filled="data.flag_replikasi === 1 ? false : true"
                                  outlined
                                  label="Replikasi"
                                  color="#0299B7"
                                  :error-messages="errors"
                                  v-model="data.replikasi">
                                </v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" md="2">
                              <div class="d-flex align-center">
                                <v-btn
                                  v-if="j === item.parameter.length - 1"
                                  large
                                  elevation="3"
                                  color="#099a55"
                                  @click="addFormReplika(i, j)"
                                  class="text-capitalize white--text mr-2">
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="j > 0 || item.parameter > 1"
                                  large
                                  elevation="3"
                                  color="red"
                                  @click="removeFormReplika(i, j)"
                                  class="text-capitalize white--text">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <div class="d-flex align-center">
                            <v-btn
                              v-if="i === form.sampel.sampel.length - 1"
                              large
                              elevation="3"
                              color="#099a55"
                              @click="addFormSampel()"
                              class="text-capitalize white--text mr-2">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              v-if="i > 0 || form.sampel.sampel > 1"
                              large
                              elevation="3"
                              color="red"
                              @click="removeFormSampel(i)"
                              class="text-capitalize white--text">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            dense
                            outlined
                            label="Catatan (Optional)"
                            color="#0299B7"
                            v-model="form.sampel.catatan">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
                        <v-col cols="12">
                          <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
                        </v-col>
                      </v-row> -->
                    </div>
                    <!-- --- END PENGUJIAN --- -->

                    <!-- row button -->
                    <v-card-actions class="mt-8 justify-center">
                      <v-btn
                        large
                        width="120"
                        elevation="0"
                        :disabled="process.form"
                        class="text-capitalize"
                        @click="reset(); toHome()">
                        Batal
                      </v-btn>
                      <v-btn
                        large
                        width="120"
                        color="#0299B7"
                        elevation="0"
                        :disabled="process.form"
                        @click="cekForm()"
                        class="white--text text-capitalize">
                        Simpan
                      </v-btn>
                    </v-card-actions>
                    <!-- end row button  -->
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import FileInput from '@/components/FileInput.vue'
import LoadingComponent from '@/components/Loading.vue'
import { get } from "@/service/Axios";
import { serialize } from 'object-to-formdata';
import moment from 'moment';
import 'moment/locale/id';
import Snackbar from '@/components/Snackbar.vue';
export default {
  data() {
    return {
      addFiles:'',
      addSafetyFiles: '',
      dialog: {
        konfirmasi: false
      },
      error: {
        libraryFile: '',
        libraryFileSafety: ''
      },
      fileSizeRule: (value) => {
        if (!value) {
          return true;
        }
        const size = value.size / 1024 / 1024; // konversi byte ke megabyte
        return size <= 5 ? true : 'Ukuran file tidak boleh lebih dari 5MB';
      },
      files: null,
      safetyFiles: null,
      form: {
        data_diri : {
          id_registerasi : "",
          status : "",
          nik : "",
          nama : "",
          email: "",
          whatsapp : "",
          instansi : "",
          perguruan_tinggi : "",
          fakultas: "",
          program_studi : "",
        },
        jenis_penggunaan : {
          jenis: "",
          keterangan: ""
        },
        judul: {
          judul: '',
          dosen_pembimbing: '',
          judul_payung: '',
          surat_persetujuan_dosen_pembimbing: '',
          dosen_freetext: '',
          peta_penelitian: '',
          lab: ''
        },
        sertifikat_safety: {
          sertifikat_safety: ''
        },
        rencana_penggunaan: {
          rencana_penggunaan: [
            {
              lab: [],
              start_date: '',
              end_date: '',
              item_start: false,
              item_end: false,
              time_start: '',
              time_end: '',
              start_time:false,
              end_time:false,
              end_time_min: '',
              start_time_min: '',
              alat: [],
              bahan: [],
              instrumen: [],
            }
          ],
        },
        sampel: {
          sampel: [
           {
            nama: '',
            jumlah: 0,
            bentuk_sampel: '',
            penyimpanan: '',
            kondisi: '',
            kemasan: '',
            gambar: null,
            parameter: [{
              parameter_uji: '',
              replikasi: '',
              flag_replikasi: ''
            }],
           }
          ],
          catatan: ''
        },
        tempat_penelitian: {
          tempat: '',
          start_date: '',
          end_date: '',
          item_start: false,
          item_end: false,
          time_start: '',
          time_end: '',
          start_time:false,
          end_time:false,
          end_time_min: '',
          start_time_min: '',
        },
        pembiyayaan: {
          dosen_pembimbing_pembiyayaan: '',
          pembiayaan_lainnya: '',
          dosen_freetext: '',
        }
      },
      end_time_min: '',
      start_time_min: '',
      process: {
        form: false,
        run: false,
        start: false,
        loading: false,
      },
      list: {
        alat: [],
        bahan: [],
        instansi: [],
        lab: [],
        instrumen: [],
        status: [],
        jenis: [],
        universitas: [],
        program_studi: [],
        fakultas: [],
        dosen: [],
        peta_penelitian: [],
        lab_non_labor: [],
        bentuk_sampel: [],
        penyimpanan: [],
        kondisi: [],
        parameter: []
      },
      isReplikasi: [false],
      isUII: false,
      isDisabledLab: [true],
      isDisabledAlatLab: [false],
      isDisabledInstrumenLab: [false],
      isdisabledInstansi: false,
      isdisabledPerguruan: false,
      isLainnya: false,
      isFreeText: false,
      isLabor: false,
      isNim: false,
      isPetaPenelitian: false,
      isProposal: false,
      isTidakAda: false,
      isNonLabor: false,
      label: '',
      sort: {
        selected: '1',
        status_jenis_pengguna: [
          {
            id: '1',
            text: 'Penelitian'
          },
          // di hide sementara
          {
            id: '2',
            text: 'Pengujian'
          },
          // {
          //   id: '3',
          //   text: 'Praktikum'
          // },
        ],
        selected_pembiyayaan: '1',
        status_pembiyayaan: [
          {
            id: '1',
            text: 'Saya sendiri'
          },
          {
            id: '2',
            text: 'Dosen pembimbing'
          },
          {
            id: '3',
            text: 'Lainnya'
          },
        ],
      },
      snackbar: false,
      save_status: '',
      text: '',
      timeout: 3000,
      validFile: false,
      validFileSafety: false,
    }
  },
  components: {
    FileInput,
    LoadingComponent,
    Snackbar
  },
  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
    roles () {
      return this.$store.state.roles
    },
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },
  watch: {
    files: {
      handler: function () {
        if (this.files.url !== '') {
          this.validFile = true;
        }
        this.form.judul.surat_persetujuan_dosen_pembimbing = this.files.url
        this.error.libraryFile = ''
        this.addFiles = this.files.add
      },
      deep: true
    },
    safetyFiles: {
      handler: function () {
        if (this.safetyFiles.url !== '') {
          this.validFileSafety = true;
        }
        this.form.sertifikat_safety.sertifikat_safety = this.safetyFiles.url
        this.error.libraryFile = ''
        this.addSafetyFiles = this.safetyFiles.add
      },
      deep: true
    },
  },
 
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const data = JSON.parse(localStorage.getItem('filter'));
      this.save_status = data;
      try {
        this.isLoading = true
        if (this.form.data_diri.instansi === '1') {
          this.label = 'Nama perguruan tinggi'
          this.isLainnya = false
        } else {
          this.label = 'Nama instansi'
          this.isLainnya = true
        }
        const email = this.user.email;
        if (email.endsWith("uii.ac.id")) {
          this.isFreeText = false
          this.isProposal = false
          this.isUII = false
          if (this.isUII === false) {
            this.sort.status_pembiyayaan = [
              {
                id: '1',
                text: 'Saya sendiri'
              },
              {
                id: '2',
                text: 'Dosen pembimbing'
              },
              {
                id: '3',
                text: 'Lainnya'
              },
            ]
          }
        } else {
          this.isFreeText = true
          this.isProposal = true
          this.isUII = true
          if (this.isUII === true) {
            this.sort.status_pembiyayaan = [
            {
              id: '1',
              text: 'Saya sendiri'
            },
            ]
          }
        }
        this.userSelf();
        this.getReserchType();
        this.getLecturer();
        this.getLaboratory();
        this.getNonLaboratory()
      } catch (error) {
        console.log(error)
      } finally {
        this.getStatus();
        this.getInstansi();
        this.isLoading = false
      }
    },

    async userSelf() {
      try {
        this.process.start = true;
        this.process.loading = true
        const { data, status } = await get(`user/self`);
        if (status === 200 && data.data) {
         const value = data.data
         const {
          idRegistrasi,
          nama,
          email,
          kdStatus,
          noHp,
          kdInstitusi,
          namaPerguruanTinggi,
          kdFakultas,
          namaFakultas,
          kdProgramStudi,
          namaProgramStudi,
          noInduk
         } = value
         this.form.data_diri = {
            id_registerasi: idRegistrasi,
            nama,
            email,
            status: kdStatus,
            whatsapp: noHp,
            instansi: kdInstitusi,
            perguruan_tinggi: namaPerguruanTinggi,
            fakultas: kdFakultas || namaFakultas,
            program_studi: kdProgramStudi || namaProgramStudi,
            nik: noInduk
          };
          this.cekConditionForm();
          this.getOrganisasi();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.process.start = false;
        this.process.loading = false
      }
    },

    async getStatus() {
      try {
        const { data, status } = await get(`status`);
        if (status === 200 && data.data) {
          const statusList = data.data.map(item => ({kd_status: item.kdStatus, nama: item.status}));
          this.list.status = statusList;
          // this.form.data_diri.status = statusList;
        } 
      } catch (error) {
        console.log(error);
      }  
    },

    async getOrganisasi() {
      try {
        const params = {
          kd_level: 'fakultas'
        }
        const { data, status } =  await get(`organization`, { params })
        if (status === 200 && data.data) {
          this.list.fakultas = data.data.map((organisasi) => ({
            nama_organisasi: organisasi.namaOrganisasi,
            kd_organisasi: organisasi.kdOrganisasi,
          }));
          this.getProdi();
        } 
      } catch (error) {
        console.log(error);
      }
    },

    async getProdi() {
      try {
        const params = {
          kd_level: 'prodi',
          kd_organisasi: this.form.data_diri.fakultas
        }
        const { data,status } = await get(`organization`, { params })
        if (status === 200 && data.data) {
          const programStudi = data.data.map((item) => ({
            nama_organisasi: item.namaOrganisasi,
            kd_program_studi: item.kdOrganisasi,
          }))
          this.list.program_studi = programStudi
          // this.form.data_diri.program_studi = programStudi[0].kd_program_studi
        }
      } catch (error) {
        console.log(error); 
      }
    },

    async getReserchType() {
      try {
        const { data, status } = await get(`research-type`);
        if (status === 200 && data.data) {
          const researchType = data.data.map((item) => ({
            kd_jenis_penelitian: item.kdJenisPenelitian,
            jenis_penelitian: item.jenisPenelitian,
          }));
          this.list.jenis = researchType
          this.form.jenis_penggunaan.jenis = researchType
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getInstansi() {
      try {
        const { data, status } = await get(`institutes`);
        if (status === 200 && data.data) {
          const instansi = data.data.map((item) => ({
            kd_institusi: item.kdInstitusi,
            institusi: item.institusi,
          }))
          this.list.instansi = instansi
          // this.form.data_diri.instansi = instansi
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getMapReserch()
      }
    },

    async getMapReserch() {
      try {
        const { data, status } = await get(`research-map`);
        if (status === 200 && data.data) {
          const map = data.data.map((item) => ({
            peta_penelitian: item.petaPenelitian,
            id_peta_penelitian: item.id
          }))
          this.list.peta_penelitian = map
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getLecturer() {
       try {
        const response = await get(`lecturer`);
        if (response.status === 200) {
          const res = response.data.data;
          const dosen = res.map((item) => ({
            nama_dosen: item.namaDosen,
            id: item.nipDosen
          }));
          dosen.unshift({
            id: '0',
            nama_dosen: 'Tidak ada'
          }, {
            id: '999',
            nama_dosen: 'Lainnya'
          })
          this.list.dosen = dosen
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getLaboratory() {
      try {
        const { data, status } = await get(`laboratory`);
        if (status === 200 && data.data) {
          const lab = data.data.map((item) => ({
            id: item.id,
            kd_laboratorium: item.kdLaboratorium,
            nama_laboratorium: item.namaLaboratorium,
          }));
          this.list.lab = lab
          this.form.rencana_penggunaan.rencana_penggunaan.lab = lab;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getNonLaboratory() {
      try {
        const params = {
          flag_non_laboratorium: '1'
        }
        const { data, status } = await get(`laboratory`, {params});
        if (status === 200 && data.data) {
          const lab = data.data.map((item) => ({
            id: item.id,
            kd_laboratorium: item.kdLaboratorium,
            nama_laboratorium: item.namaLaboratorium,
          }));
          this.list.lab_non_labor = lab
          // this.form.judul.lab = lab;
        }
      } catch (error) {
        console.log(error);
      }
    },
    
    async getAlat(val) {
      try {
        const params = {
          id_laboratorium: val
        }
        const { data, status } = await get(`tool`, {params});
        if (status === 200 && data.data) {
          const alat = data.data.map((item) => ({
            id: item.id,
            nama_alat: item.namaAlat,
          }));
          this.list.alat = alat
          this.form.rencana_penggunaan.rencana_penggunaan.alat = alat;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getBahan(val) {
      try {
        const params = {
          id_laboratorium: val
        }
        const { data, status } = await get(`item`, {params});
        if (status === 200 && data.data) {
          const bahan = data.data.map((item) => ({
            id: item.id,
            kd_bahan: item.kdBahan,
            nama_bahan: item.namaBahan,
          }));
          this.list.bahan = bahan
          this.form.rencana_penggunaan.rencana_penggunaan.bahan = bahan;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getInstrumen(val) {
      try {
        const params = {
          id_laboratorium: val
        }
        const { data, status } = await get(`instrument`, {params});
        if (status === 200 && data.data) {
          const instrumen = data.data.map((item) => ({
            id: item.id,
            kd_instrumen: item.kdInstrumen,
            nama_instrumen: item.namaInstrumen,
            id_laboratorium: item.idLaboratorium
          }));
          this.list.instrumen = instrumen
          this.form.rencana_penggunaan.rencana_penggunaan.instrumen = instrumen;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getSampleForm() {
      try {
        const { data, status } = await get(`test-sample-phase`);
        if (status === 200 && data.data) {
          const value = data.data.map((item) => ({
            label: item.namaBentukSampel,
            value: item.id,
            flag_aktif: item.flagAktif,
          }));
          this.list.bentuk_sampel = value
          this.form.sampel.sampel.bentuk_sampel = value;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getStorageForm()
      }
    },

    async getStorageForm() {
      try {
        const { data, status } = await get(`test-sample-storage`);
        if (status === 200 && data.data) {
          const value = data.data.map((item) => ({
            label: item.namaPenyimpananSampel,
            value: item.id,
            flag_aktif: item.flagAktif,
          }));
          this.list.penyimpanan = value
          this.form.sampel.sampel.penyimpanan = value;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getConditionForm()
      }
    },

    async getConditionForm() {
      try {
        const { data, status } = await get(`test-sample-condition`);
        if (status === 200 && data.data) {
          const value = data.data.map((item) => ({
            label: item.namaKondisiSampel,
            value: item.id,
            flag_aktif: item.flagAktif,
          }));
          this.list.kondisi = value
          this.form.sampel.sampel.kondisi = value;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getParameter()
      }
    },

    async getParameter() {
      try {
        const { data, status } = await get(`test-parameter`);
        if (status === 200 && data.data) {
          const value = data.data.map((item) => ({
            label: item.namaParameterUji,
            value: item.id,
            flag_replikasi: item.flagReplikasi,
          }));
          this.list.parameter = value
          this.form.sampel.sampel.parameter = value;
        }
      } catch (error) {
        console.log(error);
      }
    },

    addForm() {
      let rencana_penggunaan = {
        lab: [],
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        alat: [],
        bahan: [],
        instrumen: [],
      }
      this.form.rencana_penggunaan.rencana_penggunaan.push(rencana_penggunaan);
      this.isDisabledLab.push(true);
      this.isDisabledAlatLab.push(false);
      this.isDisabledInstrumenLab.push(false);
    },

    removeForm(index) {
       this.form.rencana_penggunaan.rencana_penggunaan.splice(index, 1)
    },

    addFormSampel() {
      let sampel = {
        nama: '',
        jumlah: 0,
        bentuk_sampel: '',
        penyimpanan: '',
        kondisi: '',
        kemasan: '',
        gambar: null,
        parameter: [{ 
          parameter_uji: '',
          replikasi: '',
          flag_replikasi: ''
        }],
      }
      this.isReplikasi.push(true);
      this.form.sampel.sampel.push(sampel);
    },

    removeFormSampel(index) {
       this.form.sampel.sampel.splice(index, 1)
    },

    addFormReplika(i) {
      let parameter = {
        parameter_uji: '',
        replikasi: '',
        flag_replikasi: ''
      }
      this.form.sampel.sampel[i].parameter.push(parameter);
    },

    removeFormReplika(i, j) {
      this.form.sampel.sampel[i].parameter.splice(j, 1);
    },

    startdate(index){
      let today = new Date()
      let time = today.getHours()+ ":" + today.getMinutes()+ ":" + today.getSeconds();
      if (index !== undefined) {
        if(this.form.rencana_penggunaan.rencana_penggunaan[index].start_date === this.reminder){
          this.form.rencana_penggunaan.rencana_penggunaan[index].start_time_min = time
        }else{        
          this.form.rencana_penggunaan.rencana_penggunaan[index].start_time_min = ''
        }
        this.form.rencana_penggunaan.rencana_penggunaan[index].time_start = ''
        this.form.rencana_penggunaan.rencana_penggunaan[index].end_date = ''
        this.form.rencana_penggunaan.rencana_penggunaan[index].time_end = ''
      } else {
        if (this.form.tempat_penelitian.start_date === this.reminder) {
          this.form.tempat_penelitian.start_time_min = time
        } else {
          this.form.tempat_penelitian.start_time_min = ''
        }
        this.form.tempat_penelitian.time_start = ''
        this.form.tempat_penelitian.end_date = ''
        this.form.tempat_penelitian.time_end = ''
      }
      this.formatDateStart(index)
    },

    enddate(index){
      if (index !== undefined) {
        if(this.form.rencana_penggunaan.rencana_penggunaan[index].end_date === this.form.rencana_penggunaan.rencana_penggunaan[index].start_date){
          this.form.rencana_penggunaan.rencana_penggunaan[index].end_time_min = this.form.rencana_penggunaan.rencana_penggunaan[index].start_date
        }else{
          this.form.rencana_penggunaan.rencana_penggunaan[index].end_time_min = ''
        }
      } else {
        if (this.form.rencana_penggunaan.rencana_penggunaan.end_date ===  this.form.rencana_penggunaan.rencana_penggunaan.start_date) {
          this.form.rencana_penggunaan.rencana_penggunaan.end_time_min = this.form.rencana_penggunaan.rencana_penggunaan.start_date
        } else {
          this.form.rencana_penggunaan.rencana_penggunaan.end_time_min = ''
        }
      }
      this.formatDateEnd(index)
    },

    formatDateStart(index) {
      if (index !== undefined) {
        return this.form.rencana_penggunaan.rencana_penggunaan[index].start_date ? moment(this.form.rencana_penggunaan.rencana_penggunaan[index].start_date).format('DD MMMM YYYY') : ''
      } else {
        return this.form.tempat_penelitian.start_date  ? moment(this.form.tempat_penelitian.start_date ).format('DD MMMM YYYY') : ''
      }
    },

    formatDateEnd(index) {
      if (index !== undefined) {
        return this.form.rencana_penggunaan.rencana_penggunaan[index].end_date ? moment(this.form.rencana_penggunaan.rencana_penggunaan[index].end_date).format('DD MMMM YYYY') : ''
      } else {
        return this.form.tempat_penelitian.end_date  ? moment(this.form.tempat_penelitian.end_date ).format('DD MMMM YYYY') : ''
      }
    },

    changeLab(data, i) {
      Promise.all([
      this.getAlat(data.lab),
      this.getBahan(data.lab),
      this.getInstrumen(data.lab),
      this.form.rencana_penggunaan.rencana_penggunaan[i].alat = [],
      this.form.rencana_penggunaan.rencana_penggunaan[i].bahan = [],
      this.form.rencana_penggunaan.rencana_penggunaan[i].instrumen = [],
      this.$refs.observer.reset()
      ]).then(() => {
        this.$set(this.isDisabledLab, i, false);
        this.$set(this.isDisabledAlatLab, i, this.list.alat.length < 1 ? true : false);
        this.$set(this.isDisabledInstrumenLab, i, this.list.instrumen.length < 1 ? true : false);
      });
    },

    cekConditionForm() {
      const email = this.user.email;
      if (email.endsWith("uii.ac.id")) {
        if (this.form.data_diri.status === '1' || this.form.data_diri.status === '2') {
          this.isdisabledInstansi = true
          this.isdisabledPerguruan = true
          this.isLainnya = false
          this.isNim = false
          if (this.form.data_diri.instansi === '1') {
            this.label = 'Nama perguruan tinggi'
          } else {
            this.label = 'Nama instansi'
          }
        } else {
          if (this.form.data_diri.instansi === '1') {
            this.label = 'Nama perguruan tinggi'
          } else {
            this.label = 'Nama instansi'
          }
          this.isNim = true
          this.isdisabledInstansi = false
          this.isdisabledPerguruan = false
        }
      } else {
        if (this.form.data_diri.status === '1' || this.form.data_diri.status === '2') {
          this.form.data_diri.instansi = '1'
          this.isdisabledInstansi = true
          this.isLainnya = false
          this.isNim = false
          if (this.form.data_diri.instansi === '1') {
            this.label = 'Nama perguruan tinggi'
          } else {
            this.label = 'Nama instansi'
          }
        } else if (this.form.status === '5') {
          this.isLainnya = true
          this.isNim = false
        } else {
          this.form.data_diri.instansi = ''
          this.isdisabledInstansi = false
          this.isNim = true
        }
      }
    },

    settingInstansi() {
      const email = this.user.email
      if (email.endsWith("uii.ac.id")) {
        if (this.form.data_diri.status === '3' || this.form.data_diri.status === '4') {
          if (this.form.data_diri.instansi === '1') {
            this.label = 'Nama perguruan tinggi'
            this.isdisabledInstansi = false
            this.isLainnya = false
            this.isdisabledPerguruan = false
          } else {
            this.label = 'Nama instansi'
            this.isLainnya = true
            this.isdisabledPerguruan = false
            this.isdisabledInstansi = false
          }
        }
      } else {
        if (this.form.data_diri.status === '3' || this.form.data_diri.status === '4') {
          if (this.form.data_diri.instansi === '1') {
            this.label = 'Nama perguruan tinggi'
            this.isLainnya = false
          } else {
            this.label = 'Nama instansi'
            this.form.perguruan_tinggi = ''
            this.isLainnya = true
          }
        }
      }

    },

    removeSelectedAlat(i) {
      this.form.rencana_penggunaan.rencana_penggunaan[i].alat.splice(i ,1);
    },

    onSelectFaculty() {
      this.form.data_diri.fakultas === '61' && this.form.data_diri.program_studi === '613' ?  this.isPetaPenelitian = true :  this.isPetaPenelitian = false
      this.getProdi();
    },
    
    onSelectProdi() {
      this.form.data_diri.fakultas === '61' && this.form.data_diri.program_studi === '613' ?  this.isPetaPenelitian = true :  this.isPetaPenelitian = false
      if (this.form.data_diri.program_studi === '613') {
        this.getMapReserch();
      }
    },

    onSelectDosen() {
      const data = this.list.dosen.find(x => x.id === this.form.judul.dosen_pembimbing);
      if (data.id === '0') {
        this.isTidakAda = true
      } else {
        this.isTidakAda = false
      }
      if (this.isNonLabor) {
        this.isTidakAda = data.id === '0' ? true : false
      }
    },

    onSelectParameter(val, i, j) {
      const isReplikasi = this.list.parameter.find(x => x.value === val);
      this.form.sampel.sampel[i].parameter[j].flag_replikasi = isReplikasi.flag_replikasi
      this.$set(this.isReplikasi, j, isReplikasi.flag_replikasi === 0);
    },

    onSelectTypeOfUse() {
      const { sort } = this
      if (sort.selected === '1') {
        console.log('penelitian');
      } else if (sort.selected === '2') {
        this.getSampleForm()
      }
    },

    onSelectType() {
      const jenis = this.form.jenis_penggunaan.jenis;
      this.isLabor = jenis === '1';
      this.isNonLabor = jenis === '2';
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      this.files?.url !== undefined ? this.validFile = true : false
      this.safetyFiles?.url !== undefined ? this.validFileSafety = true : false

      console.log('aaasa', this.files?.url);
      console.log('this.validFile', this.validFile);
      console.log('this.validFileSafety', this.validFileSafety);

      if (this.isLabor) {
        if ((this.validFile || this.validFileSafety) && isValid) {
          this.dialog.konfirmasi = true
        } else {
          this.dialog.konfirmasi = false
        }
      } else {
        this.dialog.konfirmasi = isValid
      }
    
      const { safetyFiles, files } = this
      const isSafetyFileEmpty = safetyFiles === null;
      const isFileEmpty = files === null;

      this.error.libraryFile = isFileEmpty ? 'Kolom tidak boleh kosong' : '';
      this.error.libraryFileSafety = isSafetyFileEmpty ? 'Kolom tidak boleh kosong' : '';
    },

    async save() {
      switch (this.sort.selected) {
        case '1':
          this.createsPenelitian()
          break;
        case '2':
          this.createsPengujian();
          break;
        default:
          break;
      }
    },

    async createsPenelitian() {
      const { form, list, sort, files, safetyFiles, token } = this;
      const nama_dosen = list.dosen.find(x => x.id === form.judul.dosen_pembimbing)?.nama_dosen;
      const nama_dosen_pembiayaan = list.dosen.find(x => x.id === form.pembiyayaan.dosen_pembimbing_pembiyayaan)?.nama_dosen;
      const rencana_penggunaan = form.rencana_penggunaan.rencana_penggunaan.map(element => {
        const alat = element.alat.map(val => ({ id_alat: val.id }));
        const bahan = element.bahan.map(val => ({id_bahan: val.id}));
        const instrumen = element.instrumen.map(val => ({id_instrumen: val.id}));
        return {
          id_laboratorium: element.lab,
          tgl_mulai: element.start_date,
          tgl_selesai: element.end_date,
          jam_mulai: element.time_start,
          jam_selesai: element.time_end,
          alat,
          bahan,
          instrumen
        };
      });
      const tempat = {
        tempat_penelitian: form.tempat_penelitian.tempat,
        tgl_mulai: form.tempat_penelitian.start_date,
        tgl_selesai: form.tempat_penelitian.end_date,
        jam_mulai: form.tempat_penelitian.time_start,
        jam_selesai: form.tempat_penelitian.time_end,
      }
      const jenis_penggunaan = form.jenis_penggunaan.jenis === '1' ? { rencana_penggunaan } : tempat;
      const file_persetujuan_dosen = files?.files;
      const file_proposal = files?.files;
      const file_sertifikat_pelatihan = safetyFiles?.files;
      const id_peta_penelitian = form.judul.peta_penelitian ?? 'default_value';

      const value = {
        id_jenis_penggunaan: sort.selected,
        id_jenis_penelitian: form.jenis_penggunaan.jenis,
        keterangan_jenis_penelitian: form.jenis_penggunaan.keterangan,
        judul: form.judul.judul,
        judul_payung: form.judul.judul_payung,
        nik_dosen: form.judul.dosen_pembimbing,
        nama_dosen: nama_dosen === 'Lainnya' ? form.judul.dosen_freetext : nama_dosen || '',
        id_jenis_pembiayaan: sort.selected_pembiyayaan,
        nik_dosen_pembiayaan: form.pembiyayaan.dosen_pembimbing_pembiyayaan,
        nama_dosen_pembiayaan: nama_dosen_pembiayaan === 'Lainnya' ? form.pembiyayaan.dosen_freetext : nama_dosen_pembiayaan || '',
        nama_pembiayaan: form.pembiyayaan.lainnya,
        ...jenis_penggunaan,
        file_persetujuan_dosen,
        file_proposal,
        file_sertifikat_pelatihan,
        id_peta_penelitian,
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(
        value,
        options,
      );
      
      try {
        const authHeader = new Headers();
        authHeader.append('Authorization', 'Bearer ' + token);
        const config = {
          method: 'POST',
          headers: authHeader,
          body: formData
        };
        this.process.form = true;
        const response = await fetch(`${VUE_APP_API_URL}research`, config);
        if (response.status === 201) {
          this.dialog.konfirmasi = false;
          this.text = 'Data penelitian berhasil disimpan';
          this.snackbar = true;
          this.process.form = false;
          this.reset();
          this.$router.push(`/pendaftaran`);
        }

      } catch (error) {
        console.log(error)
      } finally {
        this.dialog.konfirmasi = false;
      }
    },

    async createsPengujian() {
      const { form, sort, token } = this;
      const sampel = form.sampel.sampel.map(element => {
        const parameter_uji = element.parameter.map(val => (
          { 
            id_pengujian_parameter_uji: val.parameter_uji, 
            jumlah_replikasi: val.replikasi,
            flag_replikasi: val.flag_replikasi
          }
        ));
        return {
          nama: element.nama,
          jumlah: element.jumlah,
          id_pengujian_bentuk_sampel: element.bentuk_sampel,
          id_pengujian_penyimpanan_sampel: element.penyimpanan,
          id_pengujian_kondisi_sampel: element.kondisi,
          kemasan: element.kemasan,
          file_gambar: element.gambar,
          parameter_uji,
        };
      });

      const value = {
        id_jenis_penggunaan: sort.selected,
        catatan_tambahan: form.sampel.catatan,
        sampel
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(
        value,
        options,
      );
      
      try {
        const authHeader = new Headers();
        authHeader.append('Authorization', 'Bearer ' + token);
        const config = {
          method: 'POST',
          headers: authHeader,
          body: formData
        };
        this.process.form = true;
        const response = await fetch(`${VUE_APP_API_URL}test`, config);
        if (response.status === 201) {
          this.dialog.konfirmasi = false;
          this.text = 'Data pengujian berhasil disimpan';
          this.snackbar = true;
          this.process.form = false;
          this.reset();
          this.$router.push(`/pendaftaran`);
        }

      } catch (error) {
        console.log(error)
      } finally {
        this.dialog.konfirmasi = false;
      }
    },

    reset(){
      this.$refs.observer.reset()
      this.form = {
        data_diri : {
          id_registerasi : this.form.data_diri.id_registerasi,
          status : this.form.data_diri.status,
          nik : this.form.data_diri.nik,
          nama : this.form.data_diri.nama,
          email: this.form.data_diri.email,
          whatsapp : this.form.data_diri.whatsapp,
          instansi : this.form.data_diri.instansi,
          perguruan_tinggi : this.form.data_diri.perguruan_tinggi,
          fakultas: this.form.data_diri.fakultas,
          program_studi : this.form.data_diri.program_studi,
        },
        jenis_penggunaan : {
          jenis: "",
          keterangan: ""
        },
        judul: {
          judul: '',
          dosen_pembimbing: '',
          judul_payung: '',
          surat_persetujuan_dosen_pembimbing: '',
          dosen_freetext: '',
          peta_penelitian: '',
          lab: ''
        },
        sertifikat_safety: {
          sertifikat_safety: ''
        },
        rencana_penggunaan: {
          rencana_penggunaan: [
            {
              lab: [],
              start_date: '',
              end_date: '',
              item_start: false,
              item_end: false,
              time_start: '',
              time_end: '',
              start_time:false,
              end_time:false,
              end_time_min: '',
              start_time_min: '',
              alat: [],
              bahan: [],
              instrumen: [],
            }
          ],
        },
        sampel: {
          sampel: [
           {
            nama: '',
            jumlah: 0,
            bentuk_sampel: '',
            penyimpanan: '',
            kondisi: '',
            kemasan: '',
            gambar: null,
            parameter: [{
              parameter_uji: '',
              replikasi: ''
            }],
           }
          ],
          catatan: ''
        },
        tempat_penelitian: {
          tempat: '',
          start_date: '',
          end_date: '',
          item_start: false,
          item_end: false,
          time_start: '',
          time_end: '',
          start_time:false,
          end_time:false,
          end_time_min: '',
          start_time_min: '',
        },
        pembiyayaan: {
          dosen_pembimbing_pembiyayaan: '',
          pembiayaan_lainnya: '',
          dosen_freetext: '',
        }
      }
    },

    toHome() {
      this.$router.push(`/pendaftaran`)
    }
  }
}
</script>

<style>
.v-skeleton-loader__text {
  border-radius: 6px;
  flex: 1 0 auto;
  height: 30px;
  margin-bottom: 25px;
}
.loading {
  height: 80vh;
  align-items: center;
}
</style>