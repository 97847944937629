<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog bayar riwayat invoice -->
    <v-dialog
      v-model="dialog.pengembalian_deposit"
      scrollable
      persistent
      width="780">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Pengembalian deposit</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="dialog.pengembalian_deposit = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="color-first title font-weight-bold title mt-7 mb-2">
            Dari rekening
          </div>
          <v-row>
            <v-col cols="12" class="pt-0">
              <div class="background-blue">
                <div>
                  <div>
                      <span>Rekening <b>Bank Mandiri</b></span>
                  </div>
                  <div>
                    <span>No. rekening <b>137-00-0747021-0</b></span>
                  </div>
                  <div>
                    <span><b>a.n Universitas Islam Indonesia - Lab Farmasi</b></span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <div class="color-first title font-weight-bold title mt-7 mb-2">
            Rekening tujuan
          </div>
          <ValidationObserver ref="observer">
            <v-form>
              <v-row>
                <v-col cols="12" md="12" class="pb-0">
                  <ValidationProvider
                    name="No invoice"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      filled
                      disabled
                      readonly
                      type="text"
                      v-model="form.pengembalian_deposit.no_invoice"
                      label="No. invoice"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Total pengembalian"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      filled
                      disabled
                      readonly
                      type="text"
                      v-model="form.pengembalian_deposit.total_tagihan"
                      label="Total pengembalian deposit (Rp)"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Nomor rekening tujuan"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.pengembalian_deposit.nomor_rekening"
                      label="Nomor rekening tujuan"
                      type="number"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Bank"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.pengembalian_deposit.bank"
                      label="Bank tujuan"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Nama pemilik rekening"
                    rules="required"
                    v-slot="{ errors }">
                    <v-text-field
                      v-model="form.pengembalian_deposit.nama_pemilik_rekening"
                      label="Nama pemilik rekening"
                      outlined
                      dense
                      color="#0299B7"
                      :error-messages="errors">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <v-menu
                    ref="menunow"
                    v-model="form.pengembalian_deposit.item_now"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    class="z-my"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                      <ValidationProvider
                        name="Tanggal pengembalian"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          v-model="form.pengembalian_deposit.tanggal_bayar"
                          label="Tanggal pengembalian"
                          readonly
                          outlined
                          dense
                          color="#0299B7"
                          :error-messages="errors"
                          v-on="on">
                        </v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="form.pengembalian_deposit.tanggal_bayar"
                      :max="reminder" 
                      v-on:change="changedate()" 
                      color="#0299B7"
                      format="D MMMM YYYY"
                      @input="form.pengembalian_deposit.item_now = false">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Bukti pembayaran"
                    rules="required"
                    v-slot="{ errors }">
                    <v-file-input
                      v-model="form.pengembalian_deposit.file"
                      label="Bukti pembayaran"
                      outlined
                      dense
                      color="#0299B7"
                      prepend-inner-icon="mdi-attachment"
                      accept=".jpg,.jpeg,.png"
                      :rules="[fileSizeRule]"
                      :error-messages="errors"
                      max-size="500KB">
                    </v-file-input>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                  <ValidationProvider
                    name="Keterangan">
                    <v-text-field
                      v-model="form.pengembalian_deposit.keterangan"
                      label="Keterangan(Optional)"
                      outlined
                      dense
                      color="#0299B7">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
      <v-card-actions class="mt-2 pb-3 justify-center">
        <v-btn
          outlined
          class="text-capitalize color-first mx-1"
          :disabled="process.form"
          @click="dialog.pengembalian_deposit = false;">
          Tutup
        </v-btn>
        <v-btn
          color="#0299B7"
          elevation="0"
          class="text-capitalize white--text mx-1"
          :disabled="process.form"
          @click="cekForm()">
          Simpan
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog bayar riwayat invoice -->

    <!-- dialog download -->
    <v-dialog
      v-model="dialog.download"
      scrollable
      persistent
      width="400">
      <v-card flat>
        <v-card-text class="text-center">
          <p class="mb-0 mt-3">Proses download pdf....</p>
          <div class="mt-2">
            <v-progress-linear
              color="#0299B7"
              indeterminate
              rounded
              height="6">
            </v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog download -->
    
    <v-row class="mt-3 px-3">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                Pengembalian deposit
                </div>

                <!-- row filter -->
                <v-row class="pt-5">
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.lab"
                      item-text="nama_laboratorium"
                      item-value="id"
                      v-model="form.filter.lab"
                      v-on:change="onSelectLab()"
                      label="Laboratorium"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.usage_type"
                      item-text="jenis_penggunaan"
                      item-value="id"
                      v-model="form.filter.jenis"
                      v-on:change="onSelectJenis()"
                      label="Jenis penggunaan"
                      color="#0299B7"
                      hide-details>>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col> -->
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.status"
                      item-text="status_pengembalian"
                      item-value="id"
                      v-model="form.filter.status"
                      v-on:change="onSelectStatus()"
                      label="Status pembayaran"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- end row filter -->

                <!-- row  button-->
                <v-row class="mt-8 justify-space-between">
                  <v-col cols="12" md="3">
                    <div class="title">
                     Pengembalian deposit
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :disabled="process.run"
                      outlined
                      dense
                      :hide-details="true"
                      placeholder="Cari . . ."
                      clearable
                      color="#0299B7"
                      class="mx-3"
                      v-model="filter.title"
                      @click:clear="filter.title = ''; getList();"
                      v-on:keyup.enter="getList();"
                      prepend-inner-icon="mdi-magnify">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- end row button -->

                <!-- row table -->
                <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                  <v-skeleton-loader
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                    type="table-tbody">
                    <v-data-table
                      :headers="headers"
                      :items="rows"
                      :items-per-page="10"
                      class="elevation-1 required">
                      <template v-slot:[`item.status`]="{ item }">
                        <td>
                          <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                        </td>
                      </template>
                      <template v-slot:[`item.aksi`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="#0299B7"
                              :disabled="process.run"
                              @click="handleBeforeDownload(item)"
                              class="text-capitalize color-first pa-0 mx-1"
                              v-bind="attrs"
                              v-on="on">
                              <div class="d-flex align-center text-capitalize body-2">
                                <img :src="require('@/assets/image/download_pdf.png')" alt="">
                              </div>
                            </v-btn>
                          </template>
                          <span>Invoice</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="item.status === 'Belum dikembalikan'"
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="#0299B7"
                              :disabled="process.run"
                              :loading="process.run"
                              @click=openDialogVerif(item)
                              class="text-capitalize color-first pa-0 mx-1"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Verifikasi</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-skeleton-loader>
                </v-card-text>
                <!-- end row table -->

                <!-- pdf -->
                <div>
                  <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="1400"
                    :filename="pdf.noInvoice"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    :pdf-margin="10"
                    pdf-orientation="portrait"
                    pdf-content-width="800px"
                    ref="html2Pdf">
                    <section slot="pdf-content">
                      <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files"/>
                    </section>
                  </vue-html2pdf>
                </div>
                <!-- end pdf -->
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const { VUE_APP_API_URL } = process.env;
import { get } from "@/service/Axios";
import Pdf from "@/components/Pdf.vue"
import { serialize } from 'object-to-formdata';
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
export default {
  mixins: [statusMixin],
  data() {
    return {
      detailUuid: '',
      dialog: {
        download: false,
        konfirmasi: false,
        pengembalian_deposit: false
      },
      form: {
        dialog: {
          catatan: ''
        },
        filter: {
          lab: '',
          jenis: '',
          status: ''
        },
        pengembalian_deposit: {
          uuid: '',
          no_invoice: '',
          item_now: false,
          tanggal_bayar: '',
          total_tagihan: '',
          nomor_rekening: '',
          bank: '',
          nama_pemilik_rekening: '',
          file: null,
          keterangan: '',
        },
      },
      fileSizeRule: (value) => {
        if (!value) {
          return true;
        }
        const size = value.size / 1024 / 1024; // konversi byte ke megabyte
        return size <= 500 ? true : 'Ukuran file tidak boleh lebih dari 500KB';
      },
      files: null,
      filter: {
        title: ''
      },
      process: {
        run: false,
        form: false
      },
      list: {
        lab: [],
        usage_type: [],
        status: []
      },
      nilai_parameter: 'pengembalian',
      rows: [],
      headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Nama', value: 'nama', sortable: false, },
        { text: 'Jenis', value: 'jenis_penelitian', sortable: false, },
        { text: 'Judul atau nama sampel', value: 'judul', sortable: false, },
        { text: 'No. invoice', value: 'no_invoice', sortable: false, },
        { text: 'Pengembalian deposit (Rp)', value: 'pengembalian_deposit',sortable: false, },
        { text: 'Status pengembalian', value: 'status', sortable: false, width: '12%' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  components: {
    Pdf,
    Snackbar
  },
  watch: {
    files: {
      handler: function () {
       this.dialog.download = this.files.add
      },
      deep: true
    },
  },
  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
    token () {
      return this.$store.state.access_token
    },
    pdf () {
      return this.$store.state.data_pdf
    }
  },
  mounted () {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.process.run = true
      this.cekUserSelf();
    },

    async cekUserSelf() {
      try {
        const response = await get('user/self');
        const { data } = response;
        if (response.status === 200) {
          if (data === null) {
            this.getInstansi();
          } else {
            const roleName = data.data.role.namaRole;
            this.roles_type = roleName;
            this.getLab(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getLab(data) {
      try {
        const lab = data.data.admin;
        const labData = lab.map(element => ({
          id: element.lab.id,
          kd_laboratorium: element.lab.kdLaboratorium,
          nama_laboratorium: element.lab.namaLaboratorium,
        }));
        labData.unshift({
          id: 'all',
          kd_laboratorium: 'all',
          nama_laboratorium: 'Semua',
        })
        this.list.lab = labData
        this.form.filter.lab = labData[0].id
      } catch (error) {
        console.error(error);
      } finally {
        this.getUsageType();
      }
    },

    async getUsageType() {
      try {
        const response = await get(`usage-type`);
        if (response.status === 200) {
          const res = response.data.data;
          const usageType = res.map((res) => ({
            id: res.id,
            kd_jenis_penggunaan: res.kdJenisPenggunaan,
            jenis_penggunaan: res.jenisPenggunaan,
          }))
          usageType.unshift({
            id: 'all',
            kd_jenis_penggunaan: 'all',
            jenis_penggunaan: 'Semua',
          })
          this.list.usage_type = usageType
          this.form.filter.jenis = usageType[0].id
          this.process.run = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getStatusSubmission();
      }
    },

    async getStatusSubmission() {
      try {
        const response = await get(`return-deposit-status`);
        if (response.status === 200) {
          const res = response.data.data;
          const statuses = res.map((status) => ({
            id: status.id,
            kd_status_pengembalian: status.kdStatusPengembalian,
            status_pengembalian: status.statusPengembalian,
          }));
          statuses.unshift({
            id: 'all',
            kd_status_pengembalian: 'all',
            status_pengembalian: 'Semua',
          })
          this.list.status = statuses;
          this.form.filter.status = statuses[0].id;
        } else {
          this.process.run = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.getList()
      }
    },

    async getList() {
      try {
        this.process.run = true
        const { form, filter } = this
        const params = {
          flag_officer: '1',
          id_jenis_penggunaan: '1',
          id_laboratorium: form.filter.lab,
          id_status_pengembalian_deposit: form.filter.status,
          search: filter.title,
        }
        const response = await get(`return-deposit`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          this.rows = res.map((item, index) => {
            const totalTagihan = item?.nominalPengembalian.toLocaleString('id-ID');
            return {
              no: index + 1,
              nama: item?.nama,
              jenis_penelitian: item?.jenisPenggunaan,
              judul: item?.judul,
              nama_bank_tujuan: item?.namaBankTujuan,
              nama_pemilik_rekening_tujuan: item?.namaPemilikRekeningTujuan,
              no_invoice: item?.noInvoice,
              no_rekening_tujuan: item?.noRekeningTujuan,
              pengembalian_deposit: totalTagihan,
              status: item.statusPengembalian,
              uuid: item.uuid
            }
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.process.run = false
      }
    },

    reset() {
      this.$refs.observer.reset()
      this.form = {
        pengembalian_deposit: {
          uuid: '',
          no_invoice: '',
          item_now: false,
          tanggal_bayar: '',
          total_tagihan: '',
          nomor_rekening: '',
          bank: '',
          nama_pemilik_rekening: '',
          file: null,
          keterangan: '',
        },
      }
    },

    async cekForm() {
      const isValid = await this.$refs.observer.validate()
      this.dialog.konfirmasi = isValid 
    },

    async save() {
      this.process.form = true;
      const { token, form } = this
      const value = {
        uuid: this.detailUuid,
        tgl_pengembalian: form.pengembalian_deposit.tanggal_bayar,
        no_rekening_tujuan: form.pengembalian_deposit.nomor_rekening,
        nama_bank_tujuan: form.pengembalian_deposit.bank,
        nama_pemilik_rekening_tujuan: form.pengembalian_deposit.nama_pemilik_rekening,
        file_bukti_pembayaran: form.pengembalian_deposit.file,
        keterangan: form.pengembalian_deposit.keterangan,
      };

      const options = {
        indices: true,
        nullsAsUndefineds: false,
        booleansAsIntegers: false,
        allowEmptyArrays: false,
        noFilesWithArrayNotation: false,
        dotsForObjectNotation: false,
      };

      const formData = serialize(value, options);

      const authHeader = new Headers({ 'Authorization': `Bearer ${token}` });

      const config = { method: 'POST', headers: authHeader, body: formData };

      const response = await fetch(`${VUE_APP_API_URL}return-deposit/return`, config);
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.pengembalian_deposit = false
        this.text = 'Data pengembalian deposit berhasil disimpan';
        this.snackbar = true;
        this.process.form = false;
        this.getList();
        this.$refs.observer.reset()
      } else {
        this.dialog.konfirmasi = false;
      }
    },

    handleBeforeDownload(data) {
      this.dialog.download = true
      this.getInvoicePrint(data, this.$refs.html2Pdf);
    },

    async getInvoicePrint(data, ref) {
      try {
        const params = {
          uuid: data.uuid,
          flag_officer: '1'
        };
        const response = await get(`return-deposit`, {params});
        if (response.status === 200) {
          const res = response.data.data
          console.log('isi response', res)
          this.$store.dispatch('getDataPdf', res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.pdf.downloadPdf(data, ref);
      }
    },

    openDialogVerif(item) {
      console.log('isi item', item);
      this.detailUuid = item.uuid
      const { form } = this
      form.pengembalian_deposit.no_invoice = item.no_invoice
      form.pengembalian_deposit.total_tagihan = item.pengembalian_deposit
      form.pengembalian_deposit.bank = item.nama_bank_tujuan
      form.pengembalian_deposit.nomor_rekening = item.no_rekening_tujuan
      form.pengembalian_deposit.nama_pemilik_rekening = item.nama_pemilik_rekening_tujuan
      this.dialog.pengembalian_deposit = true
    },

    changedate() {
      this.form.pengembalian_deposit.tanggal_bayar = this.reminder
    },
  
    onSelectLab() {
      this.form.filter.lab = this.form.filter.lab
      this.getList()
    },

    onSelectJenis() {
      this.form.filter.jenis = this.form.filter.jenis
      this.getList()
    },

    onSelectStatus() {
      this.form.filter.status = this.form.filter.status
      this.getList()
    },
  },
}
</script>

<style>
.background-warning {
  background: #FFF59D;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
</style>