<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- dialog download -->
    <v-dialog
      v-model="dialog.download"
      scrollable
      persistent
      width="400">
      <v-card flat>
        <v-card-text class="text-center">
          <p class="mb-0 mt-3">Proses download pdf....</p>
          <div class="mt-2">
            <v-progress-linear
              color="#0299B7"
              indeterminate
              rounded
              height="6">
            </v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog download -->
    
    <!-- <LoadingComponent v-if="process.loading"></LoadingComponent> -->

    <v-row class="mt-3 px-3">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                  Riwayat pembayaran
                </div>

                <!-- row filter -->
                <v-row class="pt-5">
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.lab"
                      item-text="nama_laboratorium"
                      item-value="id"
                      v-model="form.filter.lab"
                      v-on:change="onSelectLab()"
                      label="Laboratorium"
                      color="#0299B7"
                      hide-details>>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.usage_type"
                      item-text="jenis_penggunaan"
                      item-value="id"
                      v-model="form.filter.jenis"
                      v-on:change="onSelectJenis()"
                      label="Jenis penggunaan"
                      color="#0299B7"
                      hide-details>>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.status"
                      item-text="status_pembayaran"
                      item-value="id"
                      v-model="form.filter.status"
                      v-on:change="onSelectStatus()"
                      label="Status pembayaran"
                      color="#0299B7"
                      hide-details>>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- end row filter -->

                <!-- row  button-->
                <v-row class="mt-8 justify-space-between">
                  <v-col cols="12" md="3">
                    <div class="title">
                      Riwayat pembayaran
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :disabled="process.run"
                      outlined
                      dense
                      :hide-details="true"
                      placeholder="Cari . . ."
                      clearable
                      color="#0299B7"
                      class="mx-3"
                      v-model="filter.title"
                      @click:clear="filter.title = ''; getHistorySubmission();"
                      v-on:keyup.enter="getHistorySubmission();"
                      prepend-inner-icon="mdi-magnify">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- end row button -->

                  <!-- row table -->
                  <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="rows"
                        :items-per-page="10"
                        class="elevation-1 required">
                        <template v-slot:[`item.status`]="{ item }">
                          <td>
                            <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                          </td>
                        </template>
                        <template v-slot:[`item.aksi`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                fab
                                icon
                                min-width="25"
                                color="#0299B7"
                                :disabled="process.run"
                                @click="handleBeforeDownload(item)"
                                class="text-capitalize color-first pa-0 mx-1"
                                v-bind="attrs"
                                v-on="on">
                                <div class="d-flex align-center text-capitalize body-2">
                                  <img :src="require('@/assets/image/download_pdf.png')" alt="">
                                </div>
                              </v-btn>
                            </template>
                            <span>Invoice</span>
                          </v-tooltip>
                        
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>
                  <!-- end row table -->

                  <!-- pdf -->
                  <div>
                    <vue-html2pdf
                      :show-layout="false"
                      :float-layout="true"
                      :enable-download="true"
                      :preview-modal="false"
                      :paginate-elements-by-height="1400"
                      :filename="pdf.noInvoice"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      :pdf-margin="10"
                      pdf-orientation="portrait"
                      pdf-content-width="800px"
                      ref="html2Pdf">
                      <section slot="pdf-content">
                        <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files"/>
                      </section>
                    </vue-html2pdf>
                  </div>
                  <!-- end pdf -->
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get } from "@/service/Axios";
import Pdf from "@/components/Pdf.vue"
import LoadingComponent from '@/components/Loading.vue'
import statusMixin from '@/mixin';
export default {
  mixins: [statusMixin],
  data() {
    return {
      dialog: {
        download: false
      },
      form: {
        dialog: {
          catatan: ''
        },
        filter: {
          lab: '',
          jenis: '',
          status: ''
        },
      },
      files: null,
      filter: {
        title: ''
      },
      process: {
        run: false,
        loading: false
      },
      list: {
        lab: [],
        usage_type: [],
        status: []
      },
      nilai_parameter: 'pembayaran',
      rows: [],
      headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Nama', value: 'nama', sortable: false, },
        { text: 'Jenis', value: 'jenis_penelitian', sortable: false, },
        { text: 'Judul atau nama sampel', value: 'judul', sortable: false, },
        { text: 'No. invoice', value: 'no_invoice', sortable: false, },
        { text: 'Total tagihan (Rp)', value: 'total_tagihan',sortable: false, },
        { text: 'Status', value: 'status', sortable: false, width: '12%' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  components: {
    // LoadingComponent,
    Pdf,
  },
  watch: {
    files: {
      handler: function () {
       this.dialog.download = this.files.add
      },
      deep: true
    },
  },
  computed: {
    pdf () {
      return this.$store.state.data_pdf
    }
  },
  mounted () {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.process.loading = true
      this.process.run = true;
      this.cekUserSelf();
    },

    async cekUserSelf() {
      try {
        const response = await get('user/self');
        const { data } = response;
        if (response.status === 200) {
          this.process.loading = false
          if (data === null) {
            this.getInstansi();
          } else {
            const roleName = data.data.role.namaRole;
            this.roles_type = roleName;
            this.getLab(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getLab(data) {
      try {
        const lab = data.data.admin;
        const labData = lab.map(element => ({
          id: element.lab.id,
          kd_laboratorium: element.lab.kdLaboratorium,
          nama_laboratorium: element.lab.namaLaboratorium,
        }));
        labData.unshift({
          id: 'all',
          kd_laboratorium: 'all',
          nama_laboratorium: 'Semua',
        })
        this.list.lab = labData
        this.form.filter.lab = labData[0].id
      } catch (error) {
        console.error(error);
      } finally {
        this.getUsageType();
      }
    },

    async getUsageType() {
      try {
        const response = await get(`usage-type`);
        if (response.status === 200) {
          const res = response.data.data;
          const usageType = res.map((res) => ({
            id: res.id,
            kd_jenis_penggunaan: res.kdJenisPenggunaan,
            jenis_penggunaan: res.jenisPenggunaan,
          }))
          usageType.unshift({
            id: 'all',
            kd_jenis_penggunaan: 'all',
            jenis_penggunaan: 'Semua',
          })
          this.list.usage_type = usageType
          this.form.filter.jenis = usageType[0].id
          this.process.run = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getStatusSubmission();
      }
    },

    async getStatusSubmission() {
      try {
        const response = await get(`payment-status`);
        if (response.status === 200) {
          const res = response.data.data;
          const statuses = res.map((status) => ({
            id: status.id,
            kd_status_pembayaran: status.kdStatusPembayaran,
            status_pembayaran: status.statusPembayaran,
          }));
          statuses.unshift({
            id: 'all',
            kd_status_pembayaran: 'all',
            status_pembayaran: 'Semua',
          })
          this.list.status = statuses;
          this.form.filter.status = statuses[0].id;
        } else {
          this.process.run = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.getHistorySubmission()
      }
    },

    async getHistorySubmission() {
      try {
        this.process.run = true
        const { form, filter } = this
        const params = {
          id_jenis_penggunaan: form.filter.jenis,
          id_laboratorium: form.filter.lab,
          id_status_pembayaran: form.filter.status,
          search: filter.title,
        }
        const response = await get(`research-payment/invoice`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          this.rows = res.map((item, index) => {
            const totalTagihan = item.totalTagihan.toLocaleString('id-ID');
            return {
              no: index + 1,
              nama: item.research.user.nama,
              jenis_penelitian: item.research.usageType?.jenisPenggunaan,
              judul: item.research.judul,
              no_invoice: item.noInvoice,
              total_tagihan: totalTagihan,
              status: item.statusPayment.statusPembayaran,
              uuid: item.uuid
            }
          })
        }
      } catch (error) {
        console.log(error)
        this.process.run = false
        this.process.loading = false
      }
    },

    handleBeforeDownload(data) {
      this.dialog.download = true
      this.getInvoicePrint(data, this.$refs.html2Pdf);
    },

    async getInvoicePrint(data, ref) {
      try {
        const params = {
          uuid: data.uuid
        };
        const response = await get(`research-payment/invoice/print`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.$store.dispatch('getDataPdf', res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.pdf.downloadPdf(data, ref);
      }

    },
  
    onSelectLab() {
      this.form.filter.lab = this.form.filter.lab
      this.getHistorySubmission()
    },

    onSelectJenis() {
      this.form.filter.jenis = this.form.filter.jenis
      this.getHistorySubmission()
    },

    onSelectStatus() {
      this.form.filter.status = this.form.filter.status
      this.getHistorySubmission()
    },
  },
}
</script>