<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog konfirmasi -->
    <v-dialog v-model="dialog.konfirmasi" persistent width="450">
       <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Apakah anda yakin ingin {{ text_confirmed }} petugas ini ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="red"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.form"
            :disabled="process.form"
            @click="save">
            <span>Ya, hapus</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog detail -->
    <v-dialog v-model="dialog.detail" persistent width="650">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Detail</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.detail = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12">
              <table width="100%" style="font-family: Arial, Helvetica, sans-serif; line-height: 1.7; font-size: 14px;">
                <tr>
                  <td width="35%">Nama lengkap</td>
                  <td width="3%">:</td>
                  <td>{{ detail?.nama }}</td>
                </tr>
                <tr>
                  <td width="35%">Email aktif</td>
                  <td width="3%">:</td>
                  <td>{{ detail?.email }}</td>
                </tr>
                <tr>
                  <td width="35%">Nomor Mahasiswa/Kepegawaian</td>
                  <td width="3%">:</td>
                  <td>{{ detail?.nik }}</td>
                </tr>
                <tr>
                  <td width="35%">No. WhatsApp aktif</td>
                  <td width="3%">:</td>
                  <td>{{ detail?.whatsapp }}</td>
                </tr>
                <tr>
                  <td width="35%">Otoritas</td>
                  <td width="3%">:</td>
                  <td>{{ detail?.otoritas }}</td>
                </tr>
                <tr v-if="detail?.status !== 'Perlu verifikasi'">
                  <td width="35%">Status akun</td>
                  <td width="3%">:</td>
                  <td>{{ detail?.flag_aktif === 1 ? 'Aktif' : 'Tidak aktif'}}</td>
                    <!-- <v-switch
                      class="mt-0 switch"
                      v-model="switch_status"
                      @change="toActive()"
                      color="#0499b7"
                      value = "true"
                      :label="switch_status === 'true' ? 'Aktif' : 'Tidak aktif'"
                      hide-details
                    ></v-switch> -->
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.form"
            @click="dialog.detail = false;">
            Kembali
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoadingComponent v-if="process.loading"></LoadingComponent>

    <v-row class="mt-3" :class="$vuetify.breakpoint.name !== 'xs' ? 'px-3' : 'pr-0 pl-3'" v-if="!process.loading">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                  Petugas
                </div>

                <!-- row filter -->
                <v-row class="pt-5">
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.otoritas"
                      item-text="nama_role"
                      item-value="id"
                      v-model="form.filter.otoritas"
                      v-on:change="onSelectOtoritas($event)"
                      label="Otoritas"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.lab"
                      item-text="nama_laboratorium"
                      item-value="id"
                      v-model="form.filter.lab"
                      v-on:change="onSelectLab($event)"
                      label="Laboratorium"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      dense
                      :disabled="process.run"
                      outlined
                      :items="list.status"
                      item-text="status_verifikasi_user"
                      item-value="id"
                      v-model="form.filter.status"
                      v-on:change="onSelectStatus($event)"
                      label="Status"
                      color="#0299B7"
                      hide-details>
                      <template v-slot:append>
                        <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <!-- end row filter -->

                <!-- row  button-->
                <v-row class="mt-8 justify-space-between">
                  <v-col cols="12" md="3">
                    <div class="title">
                     Petugas
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :disabled="process.run"
                      outlined
                      dense
                      :hide-details="true"
                      placeholder="Cari . . ."
                      clearable
                      color="#0299B7"
                      class="mx-3"
                      v-model="filter.title"
                      @click:clear="filter.title = ''; getList();"
                      v-on:keyup.enter="getList();"
                      prepend-inner-icon="mdi-magnify">
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- end row button -->

                <v-card-text class="pa-5 px-0">
                  <v-skeleton-loader
                    :loading="process.run"
                    transition="scale-transition"
                    :tile="false"
                    :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                    type="table-tbody">
                    <v-data-table
                      :headers="headers"
                      :items="rows"
                      :items-per-page="10"
                      class="elevation-1 required">
                      <template v-slot:[`item.status`]="{ item }">
                        <td>
                          <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                        </td>
                      </template>
                      <template v-slot:[`item.aksi`]="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="#0299B7"
                              :disabled="process.run"
                              @click="openDialogDetail(item)"
                              class="text-capitalize color-first pa-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Detail</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="orange"
                              :disabled="process.run"
                              :loading="process.run"
                              @click="toPage(item, 'edit')"
                              class="text-capitalize color-first pa-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.kd_status === 2">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              fab
                              icon
                              min-width="25"
                              color="red"
                              :disabled="process.run"
                              :loading="process.run"
                              @click="deleteForm(item)"
                              class="text-capitalize color-first pa-0 mx-1"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-skeleton-loader>
                </v-card-text>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get, put, destroy } from '@/service/Axios'
import { TokenService } from "@/service/Storage.Service";
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
import LoadingComponent from '@/components/Loading.vue'
export default ({
  mixins: [statusMixin],
  data() {
    return {
      detailUuid: '',
      detail: {},
      dialog: {
        konfirmasi: false,
        detail: false
      },
      form: {
        dialog: {
          catatan: ''
        },
        filter: {
          otoritas: '',
          lab: '',
          status: '',
        },
      },
      filter: {
        title: ''
      },
      id: '',
      rows: [],
      headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Nama', value: 'nama', sortable: false, },
        { text: 'email', value: 'email', sortable: false, },
        { text: 'Nomor Induk Kepegawaian', value: 'nik', sortable: false, },
        { text: 'Otoritas', value: 'otoritas', sortable: false, },
        { text: 'Laboratorium', value: 'laboratorium',sortable: false, },
        { text: 'No. WhatsApp aktif', value: 'whatsapp', sortable: false,},
        { text: 'Status', value: 'status', sortable: false, width: '11%' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      process: {
        run: false,
        form: false,
        loading: false
      },
      list: {
        otoritas: [],
        lab: [],
        status: []
      },
      text_confirmed: '',
      save_type: '',
      type: '',
      switch_status: null,
      hasDetail: false,
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  components: {
    Snackbar,
    LoadingComponent
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    }
  },
  watch: {
  },
  mounted () {
   this.fetch()
  },
  methods: {
    async fetch() {
      const data = JSON.parse(localStorage.getItem('filter'))
      if (data) {
        const { form } = this
        this.hasDetail = true
        form.filter.otoritas = data.otoritas
        form.filter.lab = data.lab
        form.filter.status = data.status
        localStorage.removeItem("filter");
      }
      this.getRole()
    },
    
    async getRole() {
      try {
        this.process.run = true;
        this.process.loading = true
        const response = await get(`role`)
          if (response.status === 200) {
            const res = response.data.data
            const otoritas = res.map(el => ({
              id: el.id,
              nama_role: el.namaRole,
              uuid: el.uuid
            }))
            otoritas.unshift({
              id: 'all',
              nama_role: 'Semua',
            })
            this.list.otoritas = otoritas
            this.form.filter.otoritas = this.hasDetail ?  this.form.filter.otoritas :  this.form.filter.otoritas === undefined ? otoritas[0].id : otoritas[0].id;

          }
      } catch (error) {
        console.error(error);
      } finally {
        this.process.run = false;
        this.process.loading = false
        this.getLaboratory()
      }
    },
    
    async getLaboratory() {
      try {
        this.process.run = true
        const params = {
          flag_lainnya: '1'
        }
        const { data, status } =  await get('laboratory', { params })
        if (status === 200 && data.data) {
          const labList = data.data.map((lab) => ({
            id: lab.id,
            kd_laboratorium: lab.kdLaboratorium,
            nama_laboratorium: lab.namaLaboratorium,
          }))
          labList.unshift({
            id: 'all',
            kd_laboratorium: 'all',
            nama_laboratorium: 'Semua',
          })
          this.list.lab = labList
          this.form.filter.lab = this.hasDetail ?  this.form.filter.lab :  this.form.filter.lab === undefined ? labList[0].id : labList[0].id;
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.process.run = false;
        this.getStatus()
      }
    },

    async getStatus() {
     try {
      this.process.run = true
      const response = await get(`verification-user-status`)
      if (response.status === 200) {
        const res = response.data.data;
        this.process.run = false
        const status = res.map((el) => ({
          id: el.id,
          kd_status_verifikasi_user: el.kdStatusVerifikasiUser,
          status_verifikasi_user: el.statusVerifikasiUser,
          proses_selanjutnya: el.prosesSelanjutnya
        }))
        status.unshift({
          id: 'all',
          kd_status_verifikasi_user: 'all',
          status_verifikasi_user: 'Semua',
        })
        this.list.status = status
        this.form.filter.status = this.hasDetail ?  this.form.filter.status :  this.form.filter.status === undefined ? status[0].id : status[0].id;

      }
     } catch (error) {
        console.log(error) 
     } finally {
        this.process.run = false;
        this.getList()
     }
    },

    async getList() {
      try {
        this.process.run = true
        const { filter, form } = this
        const params = {
          id_role: form.filter.otoritas,
          id_laboratorium: form.filter.lab,
          id_status_verifikasi: form.filter.status,
          search: filter.title,
        }
        const response = await get(`officer`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          this.rows = res.map((item, index) => {
            return {
              no: index + 1,
              nama: item?.nama || '-',
              email: item?.email || '-',
              nik: item?.noInduk || '-',
              otoritas: item?.role?.namaRole || '-',
              laboratorium: item?.laboratorium || '-',
              whatsapp: item?.noHp || '-',
              status: item?.statusVerification.statusVerifikasiUser || '-',
              kd_status: item?.statusVerification.kdStatusVerifikasiUser || '-',
              uuid: item?.uuid,
              flag_aktif: item?.flagAktif,
            }
          })
        } else {
          this.process.run = false
        }
      } catch (error) {
        console.log(error)
        this.process.run = false
      }
    },

    // async getActivation() {
    //   try {
    //     this.process.run = true
    //     const flag_aktif = this.switch_status === 'true' ? '1' : '0';
    //     const params = {
    //       flag_aktif: flag_aktif,
    //       uuid: this.detailUuid
    //     }
    //     const response = await put(`officer/activate`, {params})
    //     if (response.status === 201) {
    //       this.process.run = false
    //       this.dialog.konfirmasi = false
    //       this.dialog.detail = false
    //       this.text = response.data.info;
    //       this.snackbar = true;
    //       this.getList()
    //     } else {
    //       this.process.run = false
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     this.process.run = false
    //   }
    // },

    onSelectOtoritas(val) {
      this.form.filter.otoritas = val
      this.getList()
    },

    onSelectLab(val) {
      this.form.filter.lab = val
      this.getList()
    },

    onSelectStatus(val) {
      this.form.filter.status = val
      this.getList()
    },

    openDialogDetail(item) {
      this.switch_status = item.flag_aktif === 1 ? 'true' : 'false'
      this.detailUuid = item.uuid
      this.detail = item
      this.dialog.detail = true
    },

    deleteForm(item) {
      this.type = 'Hapus'
      this.text_confirmed = 'menghapus'
      this.dialog.konfirmasi = true
      this.id = item.uuid
      this.save_type = 'deletes'
    },

    async save() {
      this.process.run = false
      try {
        const params = {
          uuid: this.id,
        }
        const response = await destroy(`officer`, {params})
        if (response.status === 200) {
          this.dialog.konfirmasi = false
          this.text = response.data.info;
          this.process.run = false
          this.getList()
        }
      } catch (error) {
        console.log(error)
        this.process.run = false
      }
    },

    toPage(val, page) {
      try {
      const { form } = this
      const data = {
        otoritas: form.filter.otoritas,
        lab: form.filter.lab,
        status: form.filter.status
      }
      localStorage.setItem('filter', JSON.stringify(data))
        TokenService.setUuid(val.uuid);
        const path = page === undefined || page === 'edit' ? 'tambah' : page;
        this.$router.push(`/petugas/${path}`);
      } catch (error) {
        console.error(error);
      }
    },
  },
})
</script>

<style scoped>
.switch .v-label {
  font-size: 14px;
}
</style>