<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'px-2' : 'px-5'">
    <!-- snackbar -->
    <Snackbar :snackbar="snackbar" :text="text" :timeout="timeout"></Snackbar>
    <!-- end snackbar -->

    <!-- dialog verifikasi pembayaran -->
    <v-dialog
      v-model="dialog.verifikasi_pembayaran"
      scrollable
      persistent
      width="850">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Verifikasi pembayaran</span>
          <v-spacer />
          <v-btn
            color="#0299B7"
            small
            elevation="0"
            fab
            class="text-capitalize color-first"
            @click="dialog.verifikasi_pembayaran = false">
            <v-icon small color="#FFFFFF">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-row class="mb-4">
            <v-col cols="12" class="pb-0">
              <div class="color-first title font-weight-bold title mb-2">
                Invoice
              </div>
            </v-col>
            <v-col cols="12" class="pt-0">
              <table width="100%" class="body-2">
                <tr>
                  <td width="30%"><b>No invoice</b></td>
                  <td width="1%">:</td>
                  <td>{{ detail?.noInvoice }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <div class="color-first title font-weight-bold title mb-2">
                Pembayaran
              </div>
            </v-col>
            <v-col cols="12">
              <table width="100%" class="body-2">
                <tr>
                  <td width="30%"><b>Tanggal</b></td>
                  <td width="1%">:</td>
                  <td>{{detail?.tglPembayaran | date}}</td>
                </tr>
                <tr>
                  <td width="30%"><b>Total tagihan (Rp)</b></td>
                  <td width="1%">:</td>
                  <td>{{detail?.totalTagihan | price}}</td>
                </tr>
                <tr>
                  <td width="30%"><b>Bukti pembayaran</b></td>
                  <td width="1%">:</td>
                  <td>
                    <iframe 
                      width="100%" 
                      :height="file === null ? '110px' : '400px'" 
                      :src="file" 
                      :lazy-src="file"
                      frameborder="0"
                      allowfullscreen>
                      Oops! an error has occurred.
                    </iframe>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <div class="color-first title font-weight-bold pb-1">
                Verifikasi pembayaran
              </div>
              <v-radio-group v-model="sort.selected" class="mt-0">
                <v-radio
                  v-for="(status, index) in sort.list_verifikasi"
                  :key="index" 
                  :label="status.text"
                  :value="status.id"
                  color="#0499b7"
                  class="py-0">
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" class="pt-0">
              <v-text-field
                dense
                outlined
                label="Catatan (Optional)"
                color="#0299B7"
                v-model="form.dialog.catatan">
              </v-text-field>
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.verifikasi_pembayaran = false;">
            Tutup
          </v-btn>
          <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :disabled="process.run"
            @click="cekForm()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog verifikasi pembayaran -->

    <!-- dialog konfirmasi -->
     <v-dialog v-model="dialog.konfirmasi" persistent width="450">
      <v-card>
        <v-card-title class="background-first body-2 white--text py-1 pl-5 pr-2">
          <span>Konfirmasi</span>
           <v-spacer />
            <v-btn
              color="#0299B7"
              small
              elevation="0"
              fab
              class="text-capitalize color-first"
              @click="dialog.konfirmasi = false">
              <v-icon small color="#FFFFFF">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-5 text-center mt-2">
          <span class="font-weight-bold title">
            Simpan data yang diinputkan ?
          </span>
          <v-card-subtitle class="pt-0">
            <span class="body-2">Pastikan data sudah sesuai</span>
          </v-card-subtitle>
        </v-card-text>
        <v-divider style="border-color: #BAC0C6; border-radius: 25px;"></v-divider>
        <v-card-text class="pt-0 pb-0">
          <!-- <v-alert type="error" text dense prominent 
            v-show="error.message.length > 0"
            v-html="error.message">
          </v-alert> -->
        </v-card-text>
        <v-card-actions class="mt-2 pb-3 justify-center">
          <v-btn
            outlined
            class="text-capitalize color-first mx-1"
            :disabled="process.run"
            @click="dialog.konfirmasi = false;">
            Batal
          </v-btn>
           <v-btn
            color="#0299B7"
            elevation="0"
            class="text-capitalize white--text mx-1"
            :loading="process.run"
            :disabled="process.run"
            @click="save">
            Ya, simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog konfirmasi -->

    <!-- dialog download -->
     <v-dialog
      v-model="dialog.download"
      scrollable
      persistent
      width="400">
      <v-card flat>
        <v-card-text class="text-center">
          <p class="mb-0 mt-3">Proses download pdf....</p>
          <div class="mt-2">
            <v-progress-linear
              color="#0299B7"
              indeterminate
              rounded
              height="6">
            </v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog download -->

    <v-row class="mt-3 px-3">
      <v-col cols="12">
        <v-card class="border-radius box-shadow pa-1">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text>
                <div class="color-first title font-weight-bold title mt-3">
                  Pembayaran
                </div>
                  <!-- row filter -->
                  <v-row class="pt-5">
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.lab"
                        item-text="nama_laboratorium"
                        item-value="id"
                        v-model="form.filter.lab"
                        v-on:change="onSelectLab($event)"
                        label="Laboratorium"
                        color="#0299B7"
                        hide-details>                        
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.usage_type"
                        item-text="jenis_penggunaan"
                        item-value="id"
                        v-model="form.filter.jenis"
                        v-on:change="onSelectJenis($event)"
                        label="Jenis penggunaan"
                        color="#0299B7"
                        hide-details>                        
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        dense
                        :disabled="process.run"
                        outlined
                        :items="list.status"
                        item-text="status_pembayaran"
                        item-value="id"
                        v-model="form.filter.status"
                        v-on:change="onSelectStatus($event)"
                        label="Status pembayaran"
                        color="#0299B7"
                        hide-details>                        
                        <template v-slot:append>
                          <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <!-- end row filter -->

                  <!-- row  button-->
                  <v-row class="mt-8 justify-space-between">
                    <v-col cols="12" md="3">
                    <div class="title">
                      Riwayat pendaftaran
                    </div>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :disabled="process.run"
                        outlined
                        dense
                        :hide-details="true"
                        placeholder="Cari . . ."
                        clearable
                        color="#0299B7"
                        class="mx-3"
                        v-model="filter.title"
                        @click:clear="filter.title = ''; getHistorySubmission();"
                        v-on:keyup.enter="getHistorySubmission();"
                        prepend-inner-icon="mdi-magnify">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- end row button -->

                  <!-- row table -->
                  <v-card-text class="pa-5 px-0" style="height: calc(100vh - 195px);">
                    <v-skeleton-loader
                      :loading="process.run"
                      transition="scale-transition"
                      :tile="false"
                      :style="process.run ? 'height: calc(100vh - 305px)' : ''"
                      type="table-tbody">
                      <v-data-table
                        :headers="headers"
                        :items="rows"
                        :items-per-page="10"
                        class="elevation-1 required">
                        <template v-slot:[`item.status`]="{ item }">
                          <td>
                            <span :class="getStatusClass(item.status)">{{ item.status }}</span>
                          </td>
                        </template>
                        <template v-slot:[`item.aksi`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                fab
                                icon
                                min-width="25"
                                color="#0299B7"
                                :disabled="process.run"
                                @click="handleBeforeDownload(item)"
                                class="text-capitalize color-first pa-0 mx-1"
                                v-bind="attrs"
                                v-on="on">
                                <div class="d-flex align-center text-capitalize body-2">
                                  <img :src="require('@/assets/image/download_pdf.png')" alt="">
                                </div>
                              </v-btn>
                            </template>
                            <span>Invoice</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="item.status === 'Verifikasi pembayaran'"
                                x-small
                                fab
                                icon
                                min-width="25"
                                color="#0299B7"
                                :disabled="process.run"
                                :loading="process.run"
                                @click=openDialogVerif(item)
                                class="text-capitalize color-first pa-0 mx-1"
                                v-bind="attrs"
                                v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Verifikasi</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card-text>
                  <!-- end row table -->

                <!-- pdf -->
                <div>
                  <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="1400"
                    :filename="pdf.noInvoice"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    :pdf-margin="10"
                    pdf-orientation="portrait"
                    pdf-content-width="800px"
                    ref="html2Pdf">
                    <section slot="pdf-content">
                      <Pdf :parameter="nilai_parameter" ref="pdf" v-model="files"/>
                    </section>
                  </vue-html2pdf>
                </div>
                <!-- end pdf -->
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get, put, } from "@/service/Axios";
import Pdf from "@/components/Pdf.vue"
import statusMixin from '@/mixin';
import Snackbar from '@/components/Snackbar.vue';
export default {
  mixins: [statusMixin],
  data() {
    return {
      dialog: {
        verifikasi_pembayaran: false,
        konfirmasi: false,
        download: false
      },
      detail: {},
      detailUuid: '',
      file: '',
      files: null,
      form: {
        dialog: {
          catatan: ''
        },
        filter: {
          lab: '',
          jenis: '',
          status: ''
        },
      },
      filter: {
        title: ''
      },
      process: {
        run: false
      },
      list: {
        lab: [],
        usage_type: [],
        status: []
      },
      nilai_parameter: 'pembayaran',
      selected: '1',
      save_type: '',
      sort: {
        selected: '1',
        list_verifikasi: [
          {
            id: '1',
            text: 'Terbayar lunas'
          },
          {
            id: '0',
            text: 'Belum lunas'
          },
        ]
      },
      rows: [],
      headers: [
        {
          text: 'No.',
          value: 'no',
          sortable: false,
        },
        { text: 'Nama', value: 'nama', sortable: false, },
        { text: 'Jenis', value: 'jenis_penelitian', sortable: false, },
        { text: 'Judul atau nama sampel', value: 'judul', sortable: false, },
        { text: 'No. invoice', value: 'no_invoice', sortable: false, },
        { text: 'Total tagihan (Rp)', value: 'total_tagihan',sortable: false, },
        { text: 'Status', value: 'status', sortable: false, width: '12%' },
        { 
          text: 'Aksi', 
          value: 'aksi',
          align: 'center',
          width: '12%'
        },
      ],
      snackbar: false,
      text: '',
      timeout: 3000,
    }
  },
  components: {
    Pdf,
    Snackbar
  },
  watch: {
    files: {
      handler: function () {
       this.dialog.download = this.files.add
      },
      deep: true
    },
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    token () {
      return this.$store.state.access_token
    },
    roles () {
      return this.$store.state.roles
    },
    pdf () {
      return this.$store.state.data_pdf
    }
  },
  mounted () {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.process.run = true;
      this.cekUserSelf();
    },

    async cekUserSelf() {
      try {
        const response = await get('user/self');
        const { data } = response;
        if (response.status === 200) {
          if (data === null) {
            this.getInstansi();
          } else {
            const roleName = data.data.role.namaRole;
            this.roles_type = roleName;
            this.getLab(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getLab(data) {
      try {
        const lab = data.data.admin;
        const labData = lab.map(element => ({
          id: element.lab.id,
          kd_laboratorium: element.lab.kdLaboratorium,
          nama_laboratorium: element.lab.namaLaboratorium,
        }));
        labData.unshift({
          id: 'all',
          kd_laboratorium: 'all',
          nama_laboratorium: 'Semua',
        })
        this.list.lab = labData
        this.form.filter.lab = labData[0].id
      } catch (error) {
        console.error(error);
      } finally {
        this.getUsageType();
      }
    },

    async getUsageType() {
      try {
        const response = await get(`usage-type`);
        if (response.status === 200) {
          const res = response.data.data;
          const usageType = res.map((res) => ({
            id: res.id,
            kd_jenis_penggunaan: res.kdJenisPenggunaan,
            jenis_penggunaan: res.jenisPenggunaan,
          }))
          usageType.unshift({
            id: 'all',
            kd_jenis_penggunaan: 'all',
            jenis_penggunaan: 'Semua',
          })
          this.list.usage_type = usageType
          this.form.filter.jenis = usageType[0].id
          this.process.run = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.getStatusSubmission();
      }
    },

    async getStatusSubmission() {
      try {
        const response = await get(`payment-status`);
        if (response.status === 200) {
          const res = response.data.data;
          const statuses = res.map((status) => ({
            id: status.id,
            kd_status_pembayaran: status.kdStatusPembayaran,
            status_pembayaran: status.statusPembayaran,
          }));
          statuses.unshift({
            id: 'all',
            kd_status_pembayaran: 'all',
            status_pembayaran: 'Semua',
          })
          this.list.status = statuses;
          this.form.filter.status = statuses[0].id;
        } else {
          this.process.run = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.getHistorySubmission()
      }
    },

    async getHistorySubmission() {
      try {
        this.process.run = true
        const { form, filter } = this
        const params = {
          id_jenis_penggunaan: form.filter.jenis,
          id_laboratorium: form.filter.lab,
          id_status_pembayaran: form.filter.status,
          search: filter.title,
        }
        const response = await get(`research-payment/invoice`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.process.run = false
          this.rows = res.map((item, index) => {
            const totalTagihan = item.totalTagihan.toLocaleString('id-ID');
            return {
              no: index + 1,
              nama: item.research.user.nama,
              jenis_penelitian: item.research.usageType?.jenisPenggunaan,
              judul: item.research.judul,
              no_invoice: item.noInvoice,
              total_tagihan: totalTagihan,
              status: item.statusPayment.statusPembayaran,
              uuid: item.uuid
            }
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.process.run = false;
      }
    },

    async getDetaiHistorySubmission() {
      try {
        const params = {
          uuid: this.detailUuid,
        }
        const response = await get(`research-payment/invoice/detail`, {params})
        if (response.status === 200) {
          const res = response.data.data;
          this.detail = res
          this.getFile();
        }
      } catch (error) {
        console.log(error)
      }
    },

    getFile() {
      const params = {
        url: this.detail.fileBuktiPembayaran
      }
      get(`file-invoice`, {params}).then((response) => {
        if (response.status === 200) {
          let res = response.data
          this.file = res.data
        }
      })
    },

    async cekForm() {
      if (this.save_type === 'creates_verifikasi'){
        this.dialog.konfirmasi = true
      }
    },

    save() {
      switch(this.save_type) {
        case 'creates_verifikasi':
          this.creates();
          break;
        default:
      }
    },

    async creates() {
      this.process.run = true;
      const { form,sort,detailUuid } = this
      const params = {
        uuid: detailUuid,
        flag_verifikasi: sort.selected,
        catatan: form.dialog.catatan
      };
      const response = await put(`/research-payment/invoice/verification`, { params });
      if (response.status === 201) {
        this.dialog.konfirmasi = false;
        this.dialog.verifikasi_pembayaran = false;
        this.text = response.data.info;
        this.snackbar = true;
        this.process.run = false;
        this.getHistorySubmission();
      } else {
        this.dialog.konfirmasi = false;
        this.dialog.verifikasi_pembayaran = false;
      }
    },

    openDialogVerif(item) {
      this.detailUuid = item.uuid
      this.save_type = 'creates_verifikasi'
      this.getDetaiHistorySubmission();
      this.dialog.verifikasi_pembayaran = true
    },

    handleBeforeDownload(data) {
      this.dialog.download = true
      this.getInvoicePrint(data, this.$refs.html2Pdf);
    },

    async getInvoicePrint(data, ref) {
      try {
        const params = {
          uuid: data.uuid
        };
        const response = await get(`research-payment/invoice/print`, {params});
        if (response.status === 200) {
          const res = response.data.data
          this.$store.dispatch('getDataPdf', res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.pdf.downloadPdf(data, ref);
      }

    },
  
    onSelectLab(val) {
      this.form.filter.lab = val
      this.getHistorySubmission()
    },

    onSelectJenis(val) {
      this.form.filter.jenis = val
      this.getHistorySubmission()
    },

    onSelectStatus(val) {
      this.form.filter.status = val
      this.getHistorySubmission()
    },
  },
}
</script>